/* eslint-disable jsx-a11y/no-static-element-interactions */
import * as React from 'react';
import {
  useState, useCallback, useEffect, useRef, useMemo,
} from 'react';
import classnames from 'classnames';
import { QueryDefinition } from '@reduxjs/toolkit/dist/query';
import { UseQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { useFormContext } from 'react-hook-form';
import { InputLabel } from '../InputLabel';
import { baseQuery } from '../../../api/baseQuery';
import useMessages from '../../../hooks/useMessages';
import Pill from '../../Pill/Pill';

interface Option {
  id: string,
  name: string,
}

const inputDefaultStyles = `inline-block px-2 py-1 font-normal text-typography-black bg-white bg-clip-padding
border border-solid border-stroke-grey-300 rounded transition ease-in-out my-1 text-text-md-semibold
focus:text-typography-black focus:bg-white focus:border-blue-600 focus:outline-none`;

interface Props {
  id: string,
  label?: string,
  query: UseQuery<QueryDefinition<Record<string, string>, typeof baseQuery, string, Option[], string>>,
  queryOptions?: Record<string, string | undefined>,
  className?: string,
  defaultValue?: Option[],
  ariaLabel?: string,
}

const FormSearchableMultiselectDropdown = ({
  id,
  label,
  query,
  queryOptions,
  className,
  defaultValue = [],
  ariaLabel,
}: Props) => {
  const [focusedIndex, setFocusedIndex] = useState(-1);
  const [showResults, setShowResults] = useState(false);
  const [selectedItems, setSelectedItems] = useState<Option[]>(defaultValue);
  const [typedValue, setTypedValue] = useState('');
  const { data } = query({ search: typedValue, ...queryOptions });
  const getMessage = useMessages();
  const listRef = useRef<HTMLDivElement>(null);

  const { setValue, watch } = useFormContext();
  const currentValue = watch(id);

  useEffect(() => setSelectedItems(defaultValue), [defaultValue?.length]);

  const resetSearch = useCallback(() => {
    setFocusedIndex(-1);
    setShowResults(false);
  }, []);

  const handleSelection = (item: Option) => {
    setTypedValue('');
    if (!selectedItems.includes(item)) {
      setSelectedItems(selectedItems.concat([item]));
    }
    if (currentValue && !currentValue.includes(item.id)) {
      const value = (currentValue || []).concat([item.id]);
      setValue(id, value);
    }
    resetSearch();
  };

  const handleKeyDown: React.KeyboardEventHandler<HTMLDivElement> = (e) => {
    if (data) {
      const { key } = e;
      let nextIndexCount = 0;

      if (key === 'ArrowDown') { nextIndexCount = (focusedIndex + 1) % data.length; }
      if (key === 'ArrowUp') { nextIndexCount = (focusedIndex + data.length - 1) % data.length; }
      if (key === 'Escape') { resetSearch(); }
      if (key === 'Enter') {
        e.preventDefault();
        const item = data[focusedIndex];
        if (item) handleSelection(item);
      }

      setFocusedIndex(nextIndexCount);
      setTimeout(() => {
        listRef.current?.querySelector('.active')?.scrollIntoView({ block: 'nearest' });
      }, 300);
    }
  };

  const resetValues = useCallback(() => {
    setTypedValue('');
    setValue(id, []);
    setSelectedItems([]);
  }, [id, setValue]);

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setTypedValue(e.target.value);
    setShowResults(true);
  };

  const showValues = useCallback(() => {
    setShowResults(true);
  }, [setShowResults]);

  const removeValue = (idToRemove: string) => {
    setValue(id, currentValue.filter((itemId: string) => itemId !== idToRemove));
    setSelectedItems(selectedItems.filter((item) => item.id !== idToRemove));
  };

  const selectedIds = useMemo(() => selectedItems.map(({ id }) => id), [selectedItems]);

  return (
    <div
      onBlur={resetSearch}
      onKeyDown={handleKeyDown}
      className={`relative ${className} mb-2`}
    >
      {label && <InputLabel id={id} label={label} showReset={currentValue?.length !== 0} onReset={resetValues} />}
      <input
        value={typedValue}
        id={id}
        // eslint-disable-next-line jsx-a11y/tabindex-no-positive
        tabIndex={0}
        onChange={handleChange}
        onFocus={showValues}
        type="text"
        placeholder={getMessage('filters.search')}
        className={classnames('transition w-full', inputDefaultStyles)}
        autoComplete="off"
        aria-label={ariaLabel}
      />
      <input
        type="select"
        value={currentValue}
        style={{ display: 'none' }}
      />
      {showResults && data && data.length > 0 && (
        <div
          className="absolute w-full bg-white shadow-lg rounded-bl rounded-br max-h-56 overflow-y-auto z-50"
          ref={listRef}
        >
          {data?.filter((item) => selectedIds.includes(item.id) === false).map((item: {
            id: string,
            name: string,
          }, index) => (
            <div
              key={item.id}
              onMouseDown={() => handleSelection(item)}
              className={classnames(
                inputDefaultStyles,
                'w-full hover:text-hover hover:bg-light-200 border-none',
                { 'bg-light-200 active': index === focusedIndex },
              )}
            >
              {item.name}
            </div>
          ))}
        </div>
      )}
      <div className="text-text-md-medium text-main flex flex-wrap">
        {selectedItems.map(({ name, id }) => (
          <Pill key={id} label={name} onRemove={() => removeValue(id)} />
        ))}
      </div>
    </div>
  );
};

export default FormSearchableMultiselectDropdown;
