import * as React from 'react';
import { NavLink } from 'react-router-dom';

export interface TabElement {
  label: string,
  path: string
}

interface Props {
  tabElements: TabElement[]
}

const Tabs = ({ tabElements }: Props) => (
  <div className="flex flex-row justify-start w-full px-2 sm:px-3 md:px-7 lg:px-8 xl:px-12 bg-background-white border-b border-stroke-grey-100 overflow-x-auto no-scrollbar flex-shrink-0">
    {tabElements.map((element) => (
      <NavLink
        to={element.path}
        key={element.label}
        className={({ isActive }) => `flex items-center h-9 text-title-semibold mr-4 md:mr-6 lg:mr-8 last-of-type:m-0 border-b-2 hover:text-main ${isActive ? 'text-main border-main' : 'text-typography-dark border-background-white'}`}
        role="button"
        end
      >
        {element.label}
      </NavLink>
    ))}
  </div>
);

export default Tabs;
