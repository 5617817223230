import * as React from 'react';
import { useMemo, useEffect } from 'react';
import format from 'date-fns/format';
import { useGetActivityLogQuery } from '../../../api/appApi';
import { TableBodyCell } from '../../../components/Table/Table';
import ResponsiveTable, { ResponsiveTableHeader, ResponsiveTableBody, ResponsiveTableBodyRow } from '../../../components/Table/ResponsiveTable';
import Chip from '../../../components/Chip/Chip';
import PaginationControls from '../../../components/Table/PaginationControls';
import useMessages from '../../../hooks/useMessages';
import usePagination from '../../../hooks/usePagination';
import useSortParams from '../../../hooks/useSortParams';
import useFilters from '../../../hooks/useFilters';
import { usePermissions } from '../../../hooks/usePermissions';
import { TableId } from '../../../store/appSlice';
import TableContentPlaceholder from '../../../components/Table/TableContentPlaceholder';

const UsersActivityLog = () => {
  const getMessage = useMessages();
  const [selectedPage, setSelectedPage] = usePagination('usersActivityLog');
  const { sortParamsValues } = useSortParams('usersActivityLog');
  const { filterValues } = useFilters('usersActivityLog');
  const { hasRoleType } = usePermissions();

  useEffect(() => {
    setSelectedPage(1);
  }, []);

  const { data } = useGetActivityLogQuery({
    page: selectedPage.toString(),
    ...sortParamsValues,
    ...filterValues,
  });

  const ids = data?.events.map(({ date }) => date) || [];

  const columnLabels = useMemo(() => {
    const columns = [
      {
        label: 'users.activityLog.date',
        id: 'created_at',
      },
      { label: 'users.activityLog.id' },
      { label: 'users.activityLog.fullName' },
      { label: 'users.activityLog.role' },
      { label: 'users.activityLog.description' },
      { label: 'users.activityLog.menuOption' },
      { label: 'users.activityLog.venue' },
    ];
    return columns;
  }, []);

  return (
    <>
      <ResponsiveTable className="col-span-full">
        {data?.events && data.events.length === 0 ? (<TableContentPlaceholder />) : (
          <>
            <ResponsiveTableHeader ids={ids} columnLabels={columnLabels} tableName={'usersActivityLog' as TableId} />
            <ResponsiveTableBody>
              {data?.events.map(({
                date, description, menuOption, venue, externalId, fullName, roles, url,
              }) => (
                <ResponsiveTableBodyRow key={date} className="border-b last:border-b-0 border-separate font-medium">
                  <TableBodyCell>
                    <Chip chipStyle="ghost" className="w-fit whitespace-nowrap">
                      {format(new Date(date), 'dd-MM-yyyy / HH:mm')}
                    </Chip>
                  </TableBodyCell>
                  <TableBodyCell className="w-fit text-typography-black">{externalId}</TableBodyCell>
                  <TableBodyCell className="w-fit text-typography-black whitespace-nowrap">{fullName}</TableBodyCell>
                  <TableBodyCell className="w-fit text-typography-black">
                    <div className="mobile:flex flex-wrap">
                      {roles?.map((roleItem, index) => (
                        <Chip key={`${roleItem}-${index + 1}`} chipStyle="gray" className="w-fit mobile:mr-1 mobile:mb-1">
                          {roleItem}
                        </Chip>
                      ))}
                    </div>
                  </TableBodyCell>
                  {hasRoleType('worker') ? (
                    <>
                      {url && <TableBodyCell className="w-fit text-typography-blue mobile:whitespace-pre-wrap"><a href={url} className="hover:text-hover hover:underline">{description}</a></TableBodyCell>}
                      {!url && <TableBodyCell className="w-fit text-typography-black mobile:whitespace-pre-wrap">{description}</TableBodyCell>}
                    </>
                  ) : (
                    <TableBodyCell className="w-fit text-typography-black mobile:whitespace-pre-wrap">{description}</TableBodyCell>
                  )}
                  <TableBodyCell className="w-fit text-typography-dark">{getMessage(`users.activityLog.menuOption.${menuOption}`)}</TableBodyCell>
                  <TableBodyCell className="w-fit text-typography-dark">{venue}</TableBodyCell>
                </ResponsiveTableBodyRow>
              ))}
            </ResponsiveTableBody>

          </>
        )}
      </ResponsiveTable>
      {data?.metadata && (
        <PaginationControls
          className="col-span-full"
          selectedPage={selectedPage}
          setSelectedPage={setSelectedPage}
          paginationMetadata={data.metadata}
        />
      )}
    </>
  );
};

export default UsersActivityLog;
