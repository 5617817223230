import * as React from 'react';
import { ReadingRequest } from '../../../../../api/modules/meters/metersTypes';
import Card from '../../../../../components/Card/Card';
import MeterTitleLocationContainer from '../../../MeterTitleLocationContainer/MeterTitleLocationContainer';

interface Props {
  meterGroup: ReadingRequest[],
}

const DisabledCard = ({ meterGroup }: Props) => (
  <Card className="mb-3">
    {meterGroup.map((meter) => (
      <div key={meter.meterId} className="flex flex-wrap border-b last:border-b-0 py-6 last:pb-0 first:pt-0 items-center">
        <MeterTitleLocationContainer meter={meter} />
      </div>
    ))}
  </Card>
);
export default DisabledCard;
