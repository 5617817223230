import resolveConfig from 'tailwindcss/resolveConfig';

const tailwindConfig = require('../../../tailwind.config.js');

export const { sm, md, lg } = resolveConfig(tailwindConfig).theme?.screens as {
  sm: string,
  md: string,
  lg: string,
};

export const { mobile, tablet } = resolveConfig(tailwindConfig).theme?.screens as {
  mobile: { raw: string },
  tablet: { raw: string },
};

export const PDF_PREVIEW_WIDTH_MOBILE = 300;
export const PDF_PREVIEW_WIDTH_TABLET = 647;
export const PDF_PREVIEW_WIDTH_DESKTOP = 638;
