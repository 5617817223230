import { rest } from 'msw';
import queryString from 'query-string';

const homeownerAssociations = [
  {
    id: 'a6ab-3fd397a3c031',
    name: 'SM Tęcza',
    venues: [
      {
        id: '2454-4f43',
        name: 'Agrestowa 1/1',
        tenants: [
          {
            id: 'b4e53836',
            name: 'Adam Banialuka',
          },
        ],
      },
      {
        id: '7539-4282',
        name: 'Agrestowa 1/2',
        tenants: [
          {
            id: 'b4e53836',
            name: 'Adam Banialuka',
          },
        ],
      },
      {
        id: '432rs-54g54h',
        name: 'Agrestowa 1/3',
        tenants: [
          {
            id: '36b4e538',
            name: 'Beata Cytrynowicz',
          },
        ],
      },
      {
        id: 'f8f7s-8f9af6',
        name: 'Porzeczkowa 1/9',
        tenants: [
          {
            id: '432g435',
            name: 'Dariusz Eliasz',
          },
        ],
      },
    ],
  },

  {
    id: '464-664',
    name: 'SM Jarząbek',
    venues: [
      {
        id: '32r2f-42f434',
        name: 'Ziębia 2/7',
        tenants: [
          {
            id: 'jd97gd8',
            name: 'Felicja Hiacynt',
          },
        ],
      },
      {
        id: '534g43-4g345g',
        name: 'Ziębia 1/3',
        tenants: [
          {
            id: 'jd97gd8',
            name: 'Felicja Hiacynt',
          },
        ],
      },
    ],
  },
];

const filteredhomeownerAssociations = ({
  search, homeowner_association_id, tenant_id, venue_id,
}) => {
  let filteredhomeownerAssociations = homeownerAssociations;
  if (search) {
    filteredhomeownerAssociations = filteredhomeownerAssociations.filter(
      (homeowner_association) => homeowner_association.name.toLowerCase().includes(search.toLowerCase()),
    );
  }
  if (homeowner_association_id) {
    filteredhomeownerAssociations = filteredhomeownerAssociations.filter(
      (homeowner_association) => homeowner_association.id === homeowner_association_id,
    );
  }
  if (venue_id) {
    filteredhomeownerAssociations = filteredhomeownerAssociations.filter(
      (homeownerAssociation) => homeownerAssociation.venues.find((venue) => venue.id === venue_id),
    );
  }
  if (tenant_id) {
    filteredhomeownerAssociations = filteredhomeownerAssociations.filter(
      (homeownerAssociation) => homeownerAssociation.venues.find((venue) => venue.tenants.find((tenant) => tenant.id === tenant_id)),
    );
  }

  return filteredhomeownerAssociations;
};

export const homeownerAssociationsMocks = [
  rest.get('/homeowner_associations/list', (req, res, ctx) => {
    const { query: params } = queryString.parseUrl(req.url.href);
    return res(
      ctx.status(200),
      ctx.json({
        homeowner_associations: filteredhomeownerAssociations(params),
      }),
    );
  }),
];
