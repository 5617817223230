import * as React from 'react';
import { useMemo } from 'react';
import useMessages from '../../../../hooks/useMessages';
import Filters from '../../../../components/Filters/Filters';

interface Props {
  voteOptions: string[],
  id: string,
}

const VotingsListFilters = ({ voteOptions, id }: Props) => {
  const getMessage = useMessages();

  const pwiDataSourceOptions = ([{
    value: 'true',
    optionLabel: getMessage('resolutions.votings.filters.votedOnline'),
  },
  {
    value: 'false',
    optionLabel: getMessage('resolutions.votings.filters.votedNotOnline'),
  }]);

  const voteValueSearch = useMemo(() => voteOptions?.map((item) => ({
    value: item,
    optionLabel: getMessage(`resolutions.votings.${item}`),
  })), []);

  return (
    <Filters
      filterId={`resolutionVotes${id}`}
      filterFields={[
        {
          type: 'text',
          id: 'account_association_search',
          label: getMessage('resolutions.votings.filters.tenantExternalIdFullName'),
          ariaLabel: 'Szukaj po imieniu i nazwisku lub kodzie lokatora',
        },
        {
          type: 'select',
          id: 'pwi_data_source',
          label: getMessage('resolutions.votings.filters.votingVia'),
          selectOptions: pwiDataSourceOptions,
        },
        {
          type: 'dateRange',
          id: 'from_voted_at',
          toDateId: 'to_voted_at',
          label: getMessage('resolutions.votings.filters.voteDate'),
        },
        {
          type: 'select',
          id: 'vote_value_search',
          label: getMessage('resolutions.votings.filters.voteValue'),
          selectOptions: voteValueSearch,
        },
      ]}
    />
  );
};

export default VotingsListFilters;
