import * as React from 'react';
import { useState } from 'react';
import classnames from 'classnames';
import ArrowUp from '../../icons/dropdown-arrow-up.svg';
import ArrowDown from '../../icons/dropdown-arrow-down.svg';

interface Props {
  children: JSX.Element | JSX.Element[] | React.ReactNode | string | undefined,
  title: JSX.Element | JSX.Element[] | string,
  defaultOpen?: boolean,
  className?: string,
  additionalInfo?: string,
  contentClassName?: string,
  onOpen?: () => void,
  onClose?: () => void,
  ariaLabel?: string,
}

const ExpandableCard = ({
  children, title, defaultOpen, className, additionalInfo, contentClassName, onOpen, onClose, ariaLabel,
}: Props) => {
  const [isOpen, setIsOpen] = useState(defaultOpen || false);

  const handleToggle = () => {
    if (onClose && isOpen) {
      onClose();
    } else if (onOpen && !isOpen) {
      onOpen();
    }
    setIsOpen((isOpen) => !isOpen);
  };

  return (
    <div className={classnames('w-full bg-white border rounded border-stroke-grey-100 shadow-gray mobile:mt-6 first:mt-0', className)}>
      <div
        className="flex justify-between items-center w-full p-4 lg:p-8 group select-none"
        role="button"
        tabIndex={0}
        onClick={handleToggle}
        onKeyPress={handleToggle}
      >
        {typeof title === 'string'
          ? <h3 className="font-bold capitalize text-xl inline-block" aria-label={ariaLabel}>{title}</h3>
          : title}
        <div className="flex items-center">
          <span className="float-right text-sm text-green mr-2 leading-7">{additionalInfo}</span>
          {isOpen ? <ArrowUp className="w-3 h-3 inline group-hover:text-hover" /> : <ArrowDown className="w-3 h-3 inline group-hover:text-hover" />}
        </div>
      </div>
      {isOpen && (
        <div className={classnames('px-4 lg:px-8 pb-8', contentClassName)}>
          {children}
        </div>
      )}
    </div>
  );
};

export default ExpandableCard;
