import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import useMessages from '../../../../hooks/useMessages';
import Popup from '../../../../components/Popup/Popup';
import Button from '../../../../components/Button/Button';

import FormSearchableDropdown from '../../../../components/FormInputs/FormSearchableDropdown';
import { FormTextarea, FormDatePicker, FormInput } from '../../../../components/FormInputs';
import { InputLabel } from '../../../../components/FormInputs/InputLabel';
import { FullTask, postTaskCommissionsPayload } from '../../../../api/modules/tasks/tasksTypes';

import {
    usePostTaskCommissionMutation,
    useGetPortalProServicesQuery,
} from '../../../../api/appApi';

interface Props {
    isOpen: boolean,
    task: FullTask,
    onClose: () => void,
    setPopupOpen: (val: boolean) => void,
}

const PortalProPopup = ({
    isOpen, onClose, task, setPopupOpen
}: Props) => {

    const methods = useForm();
    const getMessage = useMessages();
    const [postTaskCommissionsData] = usePostTaskCommissionMutation();

    const onPostSubmit = (data: postTaskCommissionsPayload) => {
        console.log(data)
        postTaskCommissionsData({
            id: task.id,
            payload: {
                commission:
                {
                    provider: 'PortalPro',
                    service_id: data.serviceId,
                    description: data.description,
                    street_address: data.streetAddress,
                    postal_code: data.postalCode,
                    city: data.city,
                    contact_phone: data.contactPhone,
                    contact_email: data.contactEmail,
                    deadline: data.deadline,
                    offer_limit: data.offerLimit,
                }
            }
        });
        methods.reset();

        setPopupOpen(false);
    };

    return (
        <Popup isOpen={isOpen}>
            <div className="h-1/2 flex flex-col justify-around items-center w-full">
                <p className="mb-8 text-center text-lg font-semibold">Edytuj dane do PortalPro</p>
                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(onPostSubmit)} className="lg-w-full mobile:w-3/4 pt-2 text-sm flex flex-col">
                        <InputLabel label={getMessage('tasks.taskCommission.form.serviceId')} autofocus />
                        <FormSearchableDropdown
                            label=" "
                            className="w-full"
                            id="serviceId"
                            required={true}
                            query={useGetPortalProServicesQuery}
                        />

                        <FormTextarea
                            label={getMessage('tasks.taskCommission.form.description')}
                            inputClassName="mobile:w-72"
                            id="description"
                            defaultValue={task.description}
                            rows={4}
                            options={{ required: getMessage('tasks.taskCommission.form.error.required') }}
                        />

                        <div className="flex mobile:flex-col">
                            <div className="flex-none px-1 md:flex-col">
                                <FormInput
                                    type="text"
                                    label={getMessage('tasks.taskCommission.form.contactEmail')}
                                    className="mt-1 w-full"
                                    id="contactEmail"
                                    defaultValue={task.contactEmail}
                                    options={{ required: getMessage('tasks.taskCommission.form.error.required') }}

                                />
                            </div>
                            <div className="flex-none px-1">
                                <FormInput
                                    type="text"
                                    label={getMessage('tasks.taskCommission.form.contactPhone')}
                                    className="mt-1 w-full"
                                    id="contactPhone"
                                    defaultValue={task.contactPhoneNumber}
                                />
                            </div>
                        </div>

                        <div className="flex mobile:flex-col">
                            <div className="flex-none px-1">
                                <FormInput
                                    type="text"
                                    label={getMessage('tasks.taskCommission.form.streetAddress')}
                                    className="mt-1 w-full"
                                    id="streetAddress"
                                    defaultValue={task.venueAddress}
                                    options={{ required: getMessage('tasks.taskCommission.form.error.required') }}

                                />
                            </div>
                            <div className="flex-none px-1">
                                <FormInput
                                    type="text"
                                    label={getMessage('tasks.taskCommission.form.postalCode')}
                                    className="mt-1 w-full"
                                    id="postalCode"
                                    defaultValue={task.venuePostalCode}
                                />
                            </div>

                            <div className="flex-none px-1">
                                <FormInput
                                    type="text"
                                    label={getMessage('tasks.taskCommission.form.city')}
                                    className="mt-1 w-full"
                                    id="city"
                                    defaultValue={task.venueCity}
                                    options={{ required: getMessage('tasks.taskCommission.form.error.required') }}
                                />
                            </div>
                        </div>

                        <div className="flex mobile:flex-col">
                            <div className="flex-none px-1">
                                <FormDatePicker
                                    className="mt-1 w-full"
                                    id="deadline"
                                    minDate={new Date()}
                                    label={getMessage('tasks.taskCommission.form.deadline')}
                                />
                            </div>
                            <div className="flex-none px-1">
                                <FormDatePicker
                                    className="mt-1 w-full"
                                    id="offerLimit"
                                    minDate={new Date()}
                                    label={getMessage('tasks.taskCommission.form.offerLimit')}
                                />
                            </div>
                        </div>

                        <div className="flex mt-5 mobile:mt-2">
                            <div className="flex-none px-5 mobile:px-1">
                                <Button type="submit" styleType="primary">
                                    {getMessage('tasks.taskCommission.form.submit')}
                                </Button>
                            </div>
                            <div className="flex-none px-5 mobile:px-1">
                                <Button styleType="secondary" onClick={onClose} size="small">
                                    {getMessage('tasks.taskCommission.form.close')}
                                </Button>
                            </div>
                        </div>
                    </form>
                </FormProvider>
            </div>
        </Popup>
    );
};

export default PortalProPopup;
