import { rest } from 'msw';
import queryString from 'query-string';

const documentsCategories = [
  {
    id: '1',
    name: 'kat1',
  },
  {
    id: '2',
    name: 'kat2',
  },
  {
    id: '3',
    name: 'kat3',
  },
];

const documentsStatuses = [
  {
    id: '1',
    external_name: 'open',
    name: 'open',
  },
  {
    id: '2',
    external_name: 'ongoing',
    name: 'custom_1',
  },
  {
    id: '3',
    external_name: 'completed',
    name: 'completed',
  },
];

const documents = [
  {
    category: 'kat2',
    created_at: '2023-12-10T12:09:01Z',
    creator: 'John Doe',
    description: 'Adipisci illo accusamus.',
    document_number: '13/12/2023',
    id: '1c401997-505c',
    journal: 'dziennik testowy',
    realizer: 'Jane Doe',
    revoked: true,
    status: 'open',
    title: 'Incidunt libero natus ipsa.',
  },
  {
    created_at: '2023-12-15T19:09:01Z',
    creator: 'Jane Doe',
    description: 'Impedit hic rerum. Fuga est dolorum. Eligendi similique modi.',
    document_number: 'dz2/1/2023',
    id: '230a9e64-ad11',
    journal: 'dziennik auto',
    realizer: 'Jane Doe',
    revoked: false,
    status: 'ongoing',
    title: 'Voluptas commodi corporis perspiciatis.',
    category: 'kat1',
  },
  {
    category: 'kat2',
    created_at: '2023-12-20T07:09:01Z',
    creator: 'Joe Doe',
    description: 'Fugiat qui et. Tempore quasi assumenda. Eligendi sunt velit.',
    document_number: 'dz2/2/2023',
    id: '85ae7f97-6680',
    journal: 'dziennik auto',
    realizer: 'Jane Doe',
    revoked: false,
    status: 'completed',
    title: 'Unde eos repellat aut.',
  },
];

const documentToShow = {
  id: '230a9e64-ad11',
  document_number: 'dz2/1/2023',
  title: 'Voluptas commodi corporis perspiciatis.',
  sender: 'Hilarion Gajda',
  receiver: 'Hanna Śliwa',
  description: 'Impedit hic rerum. Fuga est dolorum. Eligendi similique modi.',
  revoked: false,
  created_at: '2023-12-15T19:09:01Z',
  journal: {
    id: '099e49ff',
    autonumbering: true,
    prefix: 'dz2',
    name: 'dziennik auto',
    description: null,
  },
  status: {
    id: '2',
    object_type: 'document',
    name: 'ongoing',
    active: true,
    internal_name: 'w trakcie',
    external_name: 'w trakcie',
    description: null,
  },
  category: {
    id: '1',
    name: 'kat1',
  },
  creator: {
    id: '51ed4cf2',
    full_name: 'Jane Doe',
  },
  realizer: {
    id: '51ed4cf2',
    full_name: 'Jane Doe',
  },
  tenant: {
    name: 'Dariusz Eliasz',
    id: '432g435',
  },
  attachments: [
    {
      description: null,
      filename: 'test.pdf',
      content_type: 'application/pdf',
      path: 'vwfqafeb2',
    },
  ],
  comments: [
    {
      content: 'Testowy komentarz',
      public: false,
      author: 'Halina Skryba',
      created_at: '2023-12-27T15:41:36',
      attachments: [],
    },
  ],
  tasks: [
    {
      id: '25d322b0-6100-4b3c-943b-a97f195579ba',
      description: 'fwqfqwa',
      task_number: 18,
      title: 'Clining',
      status: {
        id: '1ed0fa3c-4273-4d05-80f1-e8469297a3be',
        object_type: 'task',
        name: 'open',
        active: true,
        internal_name: 'otwarte',
        external_name: 'otwarte',
        description: null,
      },
      category: {
        id: '5d325736-458b-4036-a671-2daa17dbbe12',
        name: 'cleaning',
      },
      created_at: '2023-06-23T12:14:16Z',
      last_comment_at: null,
      estimated_start_time: null,
      estimated_finish_time: null,
      started_at: null,
      finished_at: null,
      creator: 'Jane Doe',
      creator_external_id: null,
      realizer: 'Anna Nowak',
      realizer_external_id: 'prac1',
      venue_address: 'Odolańska 56/2',
      venue_code: '01-001-002',
      homeowner_association_name: null,
      homeowner_association_code: null,
      ticket: null,
      document: {
        id: '230a9e64-ad11',
      },
      attachments: [],
      comments: [],
    },
  ],
};

const mockSeries = (page) => {
  switch (page) {
    case '1':
      return ['1', 2, 3, 'gap', 6];
    case '2':
      return [1, '2', 3, 4, 5, 6];
    case '3':
      return [1, 2, '3', 4, 5, 6];
    case '4':
      return [1, 2, 3, '4', 5, 6];
    case '5':
      return [1, 2, 3, 4, '5', 6];
    case '6':
      return [1, 'gap', 4, 5, '6'];
    default:
      return null;
  }
};

const metadata = (page) => ({
  count: 58,
  first_url: '/documents/list?page=1&items=10',
  from: page * 10 - 9,
  in: 10,
  items: 10,
  last: 6,
  last_url: '/documents/list?page=6&items=10',
  next: page === 6 ? null : page + 1,
  page,
  pages: 6,
  prev: page === 1 ? null : page - 1,
  series: mockSeries(page),
  to: page * 10,
});

export const documentsMocks = [
  rest.get('/documents/list', (req, res, ctx) => {
    const { query: params } = queryString.parseUrl(req.url.href);
    return res(
      ctx.status(200),
      ctx.json({
        metadata: metadata(params.page),
        documents,
      }),
    );
  }),
  rest.get('/documents/:id/show', (req, res, ctx) => res(
    ctx.status(200),
    ctx.json({
      document: documentToShow,
    }),
  )),
  rest.patch('/documents/:id/revoke', (req, res, ctx) => res(
    ctx.status(200),
  )),
  rest.get('/document_categories/list', (req, res, ctx) => res(
    ctx.status(200),
    ctx.json({ document_categories: documentsCategories }),
  )),
  rest.get('/tickets/statuses/document/list', (req, res, ctx) => res(
    ctx.status(200),
    ctx.json({
      statuses: documentsStatuses,
    }),
  )),
  rest.post('/documents/add', (req, res, ctx) => res(
    ctx.status(200),
  )),
  rest.post('/documents/:id/add_task', (req, res, ctx) => res(
    ctx.status(200),
  )),
  rest.post('/document_categories/add', (req, res, ctx) => req.json()
    .then((data) => documentsCategories.find((category) => category.name === data.name))
    .then((result) => {
      const nameExist = result || false;
      return nameExist ? res(
        ctx.status(422),
        ctx.body(JSON.stringify({
          errors:
          'Nazwa nie jest unikalna',
        })),
      ) : res(ctx.status(200));
    })),
  rest.patch('/document_categories/:id/change', (req, res, ctx) => req.json()
    .then((data) => documentsCategories.find((category) => category.name === data.name))
    .then((result) => {
      const nameExist = result || false;
      return nameExist ? res(
        ctx.status(422),
        ctx.body(JSON.stringify({
          errors:
          'Nazwa nie jest unikalna',
        })),
      ) : res(ctx.status(200));
    })),
  rest.delete('/document_categories/:id/remove', (req, res, ctx) => {
    const { id } = req.params;
    const categoryToRemove = documentsCategories.find((category) => category.id === id);
    const categoryInUse = documents.find((document) => document.category === categoryToRemove.name);
    return categoryToRemove && categoryInUse ? res(
      ctx.status(422),
      ctx.body(JSON.stringify({
        errors:
          'Nie można usunąć kategorii w której istnieją dokumenty',
      })),
    ) : res(ctx.status(200));
  }),
];
