import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import queryString from 'query-string';
import {
  ApiBaseQuery, ApiTagTypes,
} from '../../baseQuery';
import { HomeownerAssociations, HomeownerAssociation } from './homeownerAssociatonsTypes';

export const homeownerAssociationsQueries = (builder: EndpointBuilder<ApiBaseQuery, ApiTagTypes, 'api'>) => ({
  getHomeownerAssociations: builder.query<HomeownerAssociation[], Record<string, string | null>>({
    query: (params) => {
      const stringifiedParams = queryString.stringify(params, { arrayFormat: 'bracket' });
      return `homeowner_associations/list?${stringifiedParams}`;
    },
    transformResponse(baseQueryReturnValue: HomeownerAssociations): HomeownerAssociation[] {
      return baseQueryReturnValue.homeowner_associations;
    },
  }),
});
