import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

const baseUrl = process.env.NODE_ENV === 'test' ? 'http://localhost/' : '/';
const getToken = () => {
  const element = document.querySelector('[name=csrf-token]') as HTMLMetaElement;
  return element?.content;
};
export const baseQuery = fetchBaseQuery({
  baseUrl,
  prepareHeaders: (headers) => {
    headers.set('X-CSRF-TOKEN', getToken());
    return headers;
  },
});

export const tagTypes = [
  'Meters', 'Profile', 'Tickets', 'Ticket', 'TicketComment',
  'Resolutions', 'Resolution', 'UserAccess', 'UsersRoles',
  'Account', 'Roles', 'Announcements', 'Announcement', 'AnnouncementRecipients',
  'AnnouncementsRecipientsForFilter', 'AnnouncementsAuthors', 'ActivityLog',
  'Residency', 'ColumnSettings', 'Tenants', 'Venues', 'HomeownerAssociations',
  'SettlementsBalance', 'MonthSettlementsBalance', 'SettlementsTitleBalance',
  'Payments', 'PaymentsHistory', 'PaymentsBalances', 'Employees', 'Tasks', 'Task',
  'VotingsList', 'MetersVenuesList', 'Users', 'TicketsCategories', 'TasksCategories',
  'TicketsStatuses', 'TasksStatuses', 'Company', 'CompanyDetails', 'CompanyTaxDetails', 'DocumentsStatuses',
  'Document', 'Documents', 'DocumentsCategories', 'Journals', 'Journal',
  'MailServerConfig', 'ProPortalServices'
] as const;

export type ApiBaseQuery = typeof baseQuery;
export type ApiTagTypes = typeof tagTypes[number];
