import * as React from 'react';
import { useEffect } from 'react';
import { format } from 'date-fns';
import { useGetTicketsQuery } from '../../../../api/appApi';
import { useAppSelector } from '../../../../hooks/reduxHooks';
import ResponsiveTable, {
  ResponsiveTableHeader, ResponsiveTableBody, ResponsiveTableBodyRow,
} from '../../../../components/Table/ResponsiveTable';
import { TableBodyCell } from '../../../../components/Table/Table';
import Chip from '../../../../components/Chip/Chip';
import capitalize from '../../../../../utils/formatters';
import { TenantTicketsListItemData } from '../../../../api/modules/tickets/ticketsTypes';
import usePagination from '../../../../hooks/usePagination';
import PaginationControls from '../../../../components/Table/PaginationControls';
import useFilters from '../../../../hooks/useFilters';
import useSortParams from '../../../../hooks/useSortParams';
import { TableId } from '../../../../store/appSlice';
import TableContentPlaceholder from '../../../../components/Table/TableContentPlaceholder';

const TicketsList = () => {
  const [selectedPage, setSelectedPage] = usePagination('ticketsTable');
  const { sortParamsValues } = useSortParams('ticketsTable');
  const { filterValues } = useFilters('ticketsTable');
  const { selectedVenueId } = useAppSelector((state) => state.app);

  useEffect(() => {
    setSelectedPage(1);
  }, []);

  const { data } = useGetTicketsQuery({
    ...sortParamsValues,
    ...filterValues,
    venues_ids: selectedVenueId,
    page: selectedPage.toString(),
  }, { refetchOnMountOrArgChange: true });
  const ticketsData = data?.tickets || [] as TenantTicketsListItemData[];

  const ids = data?.tickets.map(({ id }) => id) || [];

  const columnLabels = [
    {
      label: 'tickets.ticket.id',
      id: 'ticket_number',
    },
    {
      label: 'tickets.ticket.reportedAt',
      id: 'reported_at',
    },
    { label: 'tickets.ticket.title' },
    { label: 'tickets.ticket.category' },
    { label: 'tickets.ticket.status' },
  ];

  return (
    <>
      <ResponsiveTable className="col-span-full">
        {ticketsData.length === 0 ? <TableContentPlaceholder /> : (
          <>
            <ResponsiveTableHeader ids={ids} columnLabels={columnLabels} tableName={'ticketsTable' as TableId} />
            <ResponsiveTableBody>
              {ticketsData.map(({
                id, ticketNumber, reportedAt, title, category, status,
              }) => (
                <ResponsiveTableBodyRow
                  key={id}
                  clickable={({
                    href: `/zgloszenia/${id}`,
                    ariaLabel: `Przejdź do szczegółów zgłoszenia nr ${ticketNumber} ${title}`,
                  })}
                >
                  <TableBodyCell>{ticketNumber}</TableBodyCell>
                  <TableBodyCell>
                    <Chip chipStyle="ghost" className="w-fit">
                      {format(new Date(reportedAt), 'dd-MM-yyyy')}
                    </Chip>
                  </TableBodyCell>
                  <TableBodyCell className="font-semibold text-typography-black">{title}</TableBodyCell>
                  <TableBodyCell className="font-semibold text-typography-black">{category && capitalize(category)}</TableBodyCell>
                  <TableBodyCell>
                    <Chip chipStyle={status === 'completed' ? 'gray' : 'primary'} className="w-fit">
                      {status && capitalize(status)}
                    </Chip>
                  </TableBodyCell>
                </ResponsiveTableBodyRow>
              ))}
            </ResponsiveTableBody>
          </>
        )}
      </ResponsiveTable>
      {data?.metadata && (
        <PaginationControls
          className="col-span-full"
          selectedPage={selectedPage}
          setSelectedPage={setSelectedPage}
          paginationMetadata={data.metadata}
        />
      )}
    </>
  );
};

export default TicketsList;
