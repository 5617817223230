import * as React from 'react';
import { useGetVenueDetailsQuery } from '../../../../../api/appApi';
import DetailsItemsList from './DetailsItemsList';
import MobileInfoCard from '../../../../../components/InfoCard/MobileInfoCard/MobileInfoCard';
import useMessages from '../../../../../hooks/useMessages';

interface Props {
  id: string,
}

const MeterListMobileInfoCard = ({ id }: Props) => {
  const { data } = useGetVenueDetailsQuery(id);
  const getMessage = useMessages();

  if (!data) return null;

  return (
    <MobileInfoCard title={getMessage('meters.metersList.info')} ariaLabel="Informacje dotyczące lokalu">
      <DetailsItemsList data={data} />
    </MobileInfoCard>
  );
};

export default MeterListMobileInfoCard;
