import { rest } from 'msw';

const rolesResponse = {
  roles: [
    {
      id: 'c959b6c6-9e59-4e4b-8935-b49affd9c361',
      name: 'custom role',
      permissions: [],
      default: false,
    },
    {
      id: 'b9f75002-b1bd-4ae4-8078-d0c89c1939a7',
      name: 'pracownik',
      permissions: [
        'meters_list',
        'meters_measurement_add',
        'list_tickets',
        'report_ticket',
        'add_ticket_comment',
      ],
      role_type: 'worker',
      default: true,
    },
    {
      id: '06a8a4f2-d02c-43af-8fd1-df27d17f4bc7',
      name: 'członek',
      permissions: [],
      role_type: 'member',
      default: true,
    },
    {
      id: '3c6cc310-8ae1-4652-8483-a9ddb15cd71f',
      name: 'lokator',
      permissions: [
        'meters_list',
        'meters_measurement_add',
        'list_tickets',
        'report_ticket',
        'add_ticket_comment',
      ],
      role_type: 'tenant',
      default: true,
    },
    {
      id: 'b12672f3-ce71-4b93-85e9-5c476b6ec9ed',
      name: 'admin',
      permissions: [],
      role_type: 'admin',
      default: true,
    },
  ],
};

const accounts = [
  {
    id: '040017b4-6a59-4162-b15c-f0ab2f7df14a',
    name: 'test@example.org',
    permissions: [],
    roles: [],
  },
  {
    id: '5fc2668d-338e-4baa-89b0-45e1ba6e18bf',
    name: 'test2@example.org',
    permissions: [],
    roles: [
      {
        id: 'c959b6c6-9e59-4e4b-8935-b49affd9c361',
        name: 'custom role',
        permissions: [],
      },
      {
        id: 'b9f75002-b1bd-4ae4-8078-d0c89c1939a7',
        name: 'pracownik',
        permissions: [
          'meters_list',
          'meters_measurement_add',
          'list_tickets',
          'report_ticket',
          'add_ticket_comment',
        ],
      },
    ],
  },
  {
    id: 'de1f4a88-b43c-4e27-ac39-dd0e52e08a69',
    name: 'lokator@example.org',
    permissions: [],
    roles: [
      {
        id: '3c6cc310-8ae1-4652-8483-a9ddb15cd71f',
        name: 'lokator',
        permissions: [
          'meters_list',
          'meters_measurement_add',
          'list_tickets',
          'report_ticket',
          'add_ticket_comment',
        ],
      },
    ],
  },
  {
    id: '790c4214-d33a-4b00-9119-fdb893cdf2b5',
    name: 'pracownik@example.org',
    permissions: [],
    roles: [
      {
        id: 'b9f75002-b1bd-4ae4-8078-d0c89c1939a7',
        name: 'pracownik',
        permissions: [
          'meters_list',
          'meters_measurement_add',
          'list_tickets',
          'report_ticket',
          'add_ticket_comment',
        ],
      },
    ],
  },
  {
    id: 'dad6073a-bd7e-4eff-86ff-f7aebc24e951',
    name: 'admin@example.org',
    permissions: [],
    roles: [
      {
        id: 'b12672f3-ce71-4b93-85e9-5c476b6ec9ed',
        name: 'admin',
        permissions: [],
      },
    ],
  },
];

const accountsResponse = (roleName) => ({
  accounts,
  metadata: {
    count: accounts.reduce((val, account) => (account.roles.find((role) => role.name === roleName) ? val + 1 : val), 0),
    series: ['1'],
  },
});

const singleAccountResponse = {
  account: {
    id: 'dad6073a-bd7e-4eff-86ff-f7aebc24e951',
    name: 'admin@example.org',
    permissions: [
      'list_tickets',
      'report_ticket',
      'add_ticket_comment',
    ],
    roles: [
      {
        id: 'b12672f3-ce71-4b93-85e9-5c476b6ec9ed',
        name: 'admin',
        role_type: 'admin',
        permissions: [],
      },
      {
        id: 'b9f75002-b1bd-4ae4-8078-d0c89c1939a7',
        name: 'pracownik',
        role_type: 'worker',
        permissions: [
          'tickets_list',
          'tickets_add',
        ],
      },
    ],
  },
};

export const workerMe = {
  me: {
    full_name: 'Super Admin',
    login: 'login',
    roles: [
      {
        id: 'workerId',
        default: true,
        role_type: 'worker',
        name: 'pracownik',
      },
    ],
    permissions: ['documents_list'],
    all_permissions: ['meters_list', 'meters_measurement_add', 'meters_history_list',
      'tickets_list', 'tickets_add', 'tasks_list', 'tasks_add',
      'resolutions_list', 'resolutions_vote_add', 'resolutions_votings_list',
      'announcements_list', 'announcements_notice_add', 'activity_log_register_entries_list',
      'settlements_balance_list', 'settlements_payments_list', 'settlements_payment_make',
      'tenants_people_entries_manage', 'documents_list', 'documents_add',
    ],
    tenant: {
      email: 'login@email.pl',
      venues: [
        {
          id: 'venueid-1',
          code: '05-800',
          address: 'ul. Czereśniowa 20',
          main_tenant: true,
        },

        {
          id: 'venueid-2',
          code: '05-830',
          address: 'ul. Wiśniowa 30',
          main_tenant: false,
        },
      ],
    },
  },
};

export const adminMe = {
  me: {
    full_name: 'Super Admin',
    login: 'login',
    roles: [
      {
        default: true,
        id: 'adminId',
        name: 'admin',
        role_type: 'admin',
      },
    ],
    permissions: ['documents_list'],
    all_permissions: ['meters_list', 'meters_measurement_add', 'meters_history_list',
      'tickets_list', 'tickets_add', 'tasks_list', 'tasks_add',
      'resolutions_list', 'resolutions_vote_add', 'resolutions_votings_list',
      'announcements_list', 'announcements_notice_add', 'activity_log_register_entries_list',
      'settlements_balance_list', 'settlements_payments_list', 'settlements_payment_make',
      'tenants_people_entries_manage', 'documents_list', 'documents_add',
    ],
    tenant: {
      email: 'login@email.pl',
      venues: [
        {
          id: 'venueid-1',
          code: '05-800',
          address: 'ul. Czereśniowa 20',
          main_tenant: true,
        },

        {
          id: 'venueid-2',
          code: '05-830',
          address: 'ul. Wiśniowa 30',
          main_tenant: false,
        },
      ],
    },
  },
};

const me = {
  me: {
    full_name: 'Super Admin',
    login: 'login',
    roles: [
      {
        id: 'tenantID',
        default: true,
        role_type: 'tenant',
        name: 'pracownik',
      },
    ],
    permissions: [
      'meters_list',
      'documents_list',
    ],
    all_permissions: ['meters_list', 'meters_measurement_add', 'meters_history_list',
      'tickets_list', 'tickets_add', 'tasks_list', 'tasks_add',
      'resolutions_list', 'resolutions_vote_add', 'resolutions_votings_list',
      'announcements_list', 'announcements_notice_add', 'activity_log_register_entries_list',
      'settlements_balance_list', 'settlements_payments_list', 'settlements_payment_make',
      'tenants_people_entries_manage', 'settlements_document_read', 'documents_list', 'documents_add',
    ],
    tenant: {
      email: 'login@email.pl',
      venues: [
        {
          id: 'venueid-1',
          code: '05-800',
          address: 'ul. Czereśniowa 20',
          main_tenant: true,
        },

        {
          id: 'venueid-2',
          code: '05-830',
          address: 'ul. Wiśniowa 30',
          main_tenant: false,
        },
      ],
    },
  },
};

const getRoleByName = (roleName) => rolesResponse.roles.find((role) => role.name === roleName);

const assignRoles = (id, roles) => {
  accounts.find((acc) => acc.id === id).roles = roles.roles_names.map(
    (roleName) => getRoleByName(roleName),
  );
};

const assignPermissions = (permissions) => {
  singleAccountResponse.account.permissions = permissions.permissions_names;
};

const addNewRole = ({ name, permission_names }) => {
  rolesResponse.roles.push({
    id: 'abcd1234',
    name,
    permissions: permission_names,
  });
};

const deleteRole = (id) => {
  rolesResponse.roles = rolesResponse.roles.filter((role) => role.id !== id);
};

export const accessMocks = [
  rest.get('/access/accounts/list', (req, res, ctx) => {
    const roleName = req.url.searchParams.get('roles_names[]');
    return res(
      ctx.status(200),
      ctx.json(accountsResponse(roleName)),
    );
  }),

  rest.post('access/accounts/:id/assign_roles', (req, res, ctx) => {
    const { id } = req.params;
    req.json().then((postData) => {
      assignRoles(id, postData);
    });
    return res(
      ctx.status(200),
    );
  }),

  rest.get('access/roles/list', (req, res, ctx) => res(
    ctx.status(200),
    ctx.json(rolesResponse),
  )),

  rest.get('/users/accounts/me', (req, res, ctx) => res(
    ctx.status(200),
    ctx.json(me),
  )),

  rest.get('access/accounts/:id/show', (req, res, ctx) => res(
    ctx.status(200),
    ctx.json(singleAccountResponse),
  )),

  rest.post('access/accounts/:id/assign_permissions', (req, res, ctx) => {
    req.json().then((postData) => {
      assignPermissions(postData);
    });
    return res(
      ctx.status(200),
    );
  }),

  rest.post('access/roles/add', (req, res, ctx) => {
    req.json().then((postData) => {
      addNewRole(postData);
    });
    return res(
      ctx.status(200),
      ctx.json({
        role: {
          id: 'abcd1234',
        },
      }),
    );
  }),

  rest.delete('access/roles/:id/remove', (req, res, ctx) => {
    const { id } = req.params;
    deleteRole(id);
    return res(
      ctx.status(200),
    );
  }),

  rest.get('access/role/:id', (req, res, ctx) => {
    const { id } = req.params;
    req.json().then((postData) => {
      assignRoles(id, postData);
    });
    return res(
      ctx.status(200),
      ctx.json({
        role: rolesResponse.roles.find((role) => role.id === id),
      }),
    );
  }),
];
