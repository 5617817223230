import * as React from 'react';
import XIcon from '../../icons/x.svg';
import useMessages from '../../hooks/useMessages';

interface Props {
  label: string,
  onRemove?: () => void;
}

const Pill = ({ label, onRemove }: Props) => {
  const getMessage = useMessages();

  return (
    <div className="flex items-center mb-1 mr-2 bg-light-200 rounded-full min-w-fit px-2 py-1 text-text-md-medium mobile:leading-5 text-hover">
      {label}
      {onRemove && (
      <button
        onClick={onRemove}
        onKeyDown={(e) => (e.key === 'Enter') && onRemove()}
        type="button"
        className="flex items-center"
        aria-label={getMessage('remove')}
      >
        <XIcon className="ml-1 stroke-current mobile:w-4 mobile:h-4" />
      </button>
      )}
    </div>
  );
};

export default Pill;
