/* eslint-disable react/jsx-props-no-spreading */

import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useEffect } from 'react';
import {
  usePutCompanyTaxDetailsMutation,
} from '../../api/appApi';
import useMessages from '../../hooks/useMessages';
import Card from '../../components/Card/Card';
import {
  FormCompanyTaxDetails,
  CompanyTaxDetails,
} from '../../api/modules/company/companyTypes';
import { FormInput } from '../../components/FormInputs';
import Button from '../../components/Button/Button';
import {
  translateCompanyTaxDetailsToApi,
} from '../../api/modules/company/companyTranslator';
import { FormNotice } from '../../components/FormNotice/FormNotice';

interface Props {
  companyTaxData: CompanyTaxDetails,
}

const CompanyTaxDetailsForm = ({ companyTaxData }: Props) => {
  const getMessage = useMessages();
  const methods = useForm<CompanyTaxDetails>({
    values: {
      vatCode: companyTaxData.vatCode || '',
      companyCode: companyTaxData.companyCode || '',
      contactEmail: companyTaxData.contactEmail || '',
      contactPhoneNumber: companyTaxData.contactPhoneNumber || ''
    },
    mode: 'onTouched',
  });

  const [postCompanyTaxDetails, detailsTaxResult] = usePutCompanyTaxDetailsMutation();

  const onSubmit = (data: CompanyTaxDetails) => {
    postCompanyTaxDetails(translateCompanyTaxDetailsToApi(data));
  };


  return (
    <Card className="col-span-full grid grid-cols-1">
      {detailsTaxResult.isSuccess && <FormNotice type="success" message={getMessage('form.success')} />}
      {detailsTaxResult.isError && <FormNotice type="error" message={getMessage('form.error')} />}
      {/* @ts-ignore */}
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} className="col-span-full grid grid-cols-1 md:grid-cols-2">
          <section className="col-span-full md:col-span-1">
            <FormInput
              id="vatCode"
              type="text"
              label={getMessage('company.taxDetails.vatCode')}
              className="w-full md:w-2/3"
              inputClassName="w-full"
              options={{}}
            />
            <FormInput
              id="companyCode"
              type="text"
              label={getMessage('company.taxDetails.companyCode')}
              className="w-full md:w-2/3"
              inputClassName="w-full"
              options={{}}
            />
          </section>
          <section>
            <FormInput
              id="contactEmail"
              type="text"
              label={getMessage('company.taxDetails.contactEmail')}
              className="w-full md:w-2/3"
              inputClassName="w-full"
            />
            <FormInput
              id="contactPhoneNumber"
              type="text"
              label={getMessage('company.taxDetails.contactPhoneNumber')}
              className="w-full md:w-2/3"
              inputClassName="w-full"
            />
          </section>
          <div className="col-span-2 flex justify-end mt-8">
            <Button className="smallRange:w-full" type="submit">{getMessage('save')}</Button>
          </div>
        </form>
      </FormProvider>
    </Card>
  );
};
export default CompanyTaxDetailsForm;
