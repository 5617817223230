import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import {
  ApiResolutionsResponse, ApiResolutionResponse, ResolutionData, ApiPostResolutionVoteValue, Resolutions, ApiVotingsResponse, Votings,
} from './resolutionsTypes';
import { ApiBaseQuery, ApiTagTypes } from '../../baseQuery';
import { translateApiToResolutions, translateSingleResolution, translateVotings } from './resolutionsTranslator';

export const resolutionsQueries = (builder: EndpointBuilder<ApiBaseQuery, ApiTagTypes, 'api'>) => ({
  getResolutions: builder.query<Resolutions, Record<string, string | boolean>>({
    query: (params) => ({ url: 'resolutions/list', params }),
    transformResponse(baseQueryReturnValue: ApiResolutionsResponse): Promise<Resolutions> | Resolutions {
      return translateApiToResolutions(baseQueryReturnValue);
    },
    providesTags: () => ['Resolutions'],
  }),
  getResolution: builder.query<ResolutionData, string | void>({
    query: (resolutionId) => `resolutions/${resolutionId}/show`,
    transformResponse(baseQueryReturnValue: ApiResolutionResponse): Promise<ResolutionData> | ResolutionData {
      return translateSingleResolution(baseQueryReturnValue);
    },
    providesTags: () => ['Resolution'],
  }),
  getResolutionVotingsList: builder.query<Votings, any>({
    query: ({ id, ...rest }) => ({ url: `resolutions/${id}/votings/list`, params: rest }),
    transformResponse(baseQueryReturnValue: ApiVotingsResponse): Promise<Votings> | Votings {
      return translateVotings(baseQueryReturnValue);
    },
    providesTags: () => ['VotingsList'],
  }),
  postContentAccepted: builder.mutation<any, string>({
    query: (id) => ({
      url: `/resolutions/${id}/accept_content`,
      method: 'POST',
    }),
    invalidatesTags: () => ['Resolution', 'ActivityLog'],
  }),
  postResolutionVoteValue: builder.mutation<any, ApiPostResolutionVoteValue>({
    query: ({ id, payload }) => ({
      url: `/resolutions/${id}/vote`,
      method: 'POST',
      body: payload,
    }),
    invalidatesTags: () => ['Resolution', 'Resolutions', 'ActivityLog'],
  }),
});
