import { rest } from 'msw';
import queryString from 'query-string';

let selectedColumns = [
  'ticket_number',
  'title',
  'status',
  'reported_at',
  'last_comment_at',
  'venue_address',
  'homeowner_association_code',
  'venue_code',
  'homeowner_association_name',
  'category',
  'reporter_external_id',
  'reporter',
  'realizer_external_id',
  'realizer',
];

const venues = [
  {
    id: 'venueid-1',
    code: '05-800',
    address: 'ul. Czereśniowa 20',
    main_tenant: true,
  },

  {
    id: 'venueid-2',
    code: '05-830',
    address: 'ul. Wiśniowa 30',
    main_tenant: false,
  },
];

const tickets = [
  {
    id: 'b4e53836-2454-4f43-a6ab-3fd397a3c031',
    ticket_number: 'ak47',
    title: 'Zalanie',
    description: 'Lorem ipsum dolor sit amet. Rem porro quaerat est culpa internos eos quam autem. In omnis aliquid ab nemo voluptas et exercitationem culpa. Id suscipit molestiae nam enim voluptas et temporibus provident ut earum optio et necessitatibus voluptatem ex aperiam unde nam ducimus dolorem! ',
    status: 'open',
    category: 'malfunction',
    reporter: 'Jan Niezbedny',
    reporter_external_id: null,
    tenant_name: 'Adam Banialuka',
    homeowner_association_name: 'Babie doły',
    last_comment_at: '2023-02-21T10:26:17',
    venue_address: 'ul. Czereśniowa 20',
    venue_code: '01-001-001',
    reported_at: '2023-02-14T14:08:28',
    realizer: null,
    realizer_external_id: null,
    attachments: [{
      description: 'attachment to ticket',
      filename: 'test1.jpg',
      content_type: 'image/jpg',
      path: '1fega3fgebb09',
    }],
    public_comments: [{
      content: 'Quaerat cumque odio velit.',
      author: 'Joe Doe',
      created_at: '2023-02-20T08:26:17',
      attachments: [{
        description: 'attachment to comment',
        filename: 'test2.jpg',
        content_type: 'image/jpg',
        path: '1313fnbb09',
      }],
      public: true,
    }],
    private_comments: [{
      content: 'Zbyszku, zajmij się tym proszę niezwłocznie',
      author: 'Stefan Baryła',
      created_at: '2023-02-21T09:26:17',
      attachments: [],
      public: false,
    },
    {
      content: 'Stefan, dzwoniłem do lokatora. Naprawię popołudniu.',
      author: 'Zbigniew Stonoga',
      created_at: '2023-02-21T10:26:17',
      attachments: [],
      public: false,
    }],
    tasks: [{
      id: '5d0ea6ee-c12d-4bf3-a1f6-1a511dc29d32',
      task_number: 15,
      title: 'Tatarata',
      status: 'open',
      category: 'cleaning',
      creator: 'Jane Doe',
      creator_external_id: null,
      realizer: null,
      realizer_external_id: null,
      venue_address: 'ul. Czereśniowa 20',
      venue_code: '01-001-001',
      homeowner_association_name: null,
      homeowner_association_code: null,
      created_at: '2023-06-23T12:03:59Z',
      last_comment_at: null,
      estimated_start_time: '2023-06-13T00:00:00Z',
      estimated_finish_time: '2023-06-08T00:00:00Z',
      started_at: '2023-06-26T00:00:00Z',
      finished_at: null,
    },
    {
      id: 'a7c3e6f7-20e9-42e2-b84c-f2caaa01d7e4',
      task_number: 14,
      title: 'Tatarata',
      status: 'open',
      category: 'cleaning',
      creator: 'Kreator',
      creator_external_id: null,
      realizer: null,
      realizer_external_id: null,
      venue_address: 'ul. Czereśniowa 20',
      venue_code: '01-001-001',
      homeowner_association_name: null,
      homeowner_association_code: null,
      created_at: '2023-06-23T12:03:51Z',
      last_comment_at: null,
      estimated_start_time: '2023-06-13T00:00:00Z',
      estimated_finish_time: '2023-06-08T00:00:00Z',
      started_at: '2023-06-26T00:00:00Z',
      finished_at: null,
    }],
  },
  {
    id: 'b4e53836-2454-4f43-a6ab-3fd397a3c032',
    ticket_number: 'wind',
    title: 'Nie działa winda',
    description: 'Lorem ipsum dolor sit amet.',
    status: 'completed',
    category: 'malfunction',
    last_comment_at: null,
    realizer: null,
    realizer_external_id: null,
    reporter: 'Jan Niezbedny',
    reporter_external_id: null,
    reported_at: '2022-02-15T14:08:28',
    venue_address: 'ul. Czereśniowa 20',
    attachments: [],
    public_comments: [],
    private_comments: [],
  },
  {
    id: '5efe87c7-ed4f-466c-8af3-11097757d2e4',
    ticket_number: '12a3b',
    title: 'Dewastacja budynku',
    description: 'Ex perspiciatis eaque ut nesciunt animi non tempore aliquid non sapiente velit id sint obcaecati ut provident omnis sed beatae accusantium.',
    status: 'ongoing',
    category: 'complaint',
    last_comment_at: '2023-02-20T08:36:17',
    realizer: 'Jadwiga Kuna',
    realizer_external_id: null,
    reporter: 'Anna Staranna',
    reporter_external_id: null,
    reported_at: '2023-02-12T08:38:08',
    venue_address: 'ul. Wiśniowa 30',
    venue_code: '01-001-002',
    attachments: [],
    public_comments: [
      {
        content: 'Inventore quia magnam fugiat.',
        author: 'Jane Doe',
        created_at: '2023-02-20T08:36:17',
        attachments: [],
        public: true,
      }],
  },
  {
    id: '8b359308-0888-4535-b9e6-22b4c03d8b1e',
    ticket_number: '98z76y',
    title: 'Zepsuty domofon',
    description: 'Eum delectus galisum et dicta quae aut dolor minima quo dolor blanditiis. Ut quia sunt ea earum reprehenderit et facilis perferendis est autem illum eum facilis aperiam quo voluptas nemo aut quaerat velit.',
    status: 'completed',
    category: 'garbage',
    last_comment_at: null,
    realizer: 'Anna Mockowa',
    realizer_external_id: null,
    reporter: 'Eustachy Motyka',
    reported_at: '2023-01-13T22:11:03',
    venue_address: 'ul. Czereśniowa 20',
    venue_code: '01-001-001',
    attachments: [],
    public_comments: [],
    private_comments: [],
  },
];

let ticketsToShow = {
  id: '8b359308-0888-4535-b9e6-22b4c03d8b1e',
  ticket_number: '98z76y',
  title: 'Zepsuty domofon',
  description: 'Eum delectus galisum et dicta quae aut dolor minima quo dolor blanditiis. Ut quia sunt ea earum reprehenderit et facilis perferendis est autem illum eum facilis aperiam quo voluptas nemo aut quaerat velit.',
  status: 'completed',
  category: 'garbage',
  last_comment_at: null,
  realizer: 'Anna Mockowa',
  realizer_external_id: null,
  reporter: 'Eustachy Motyka',
  reporter_external_id: null,
  reported_at: '2023-01-13T22:11:03',
  venue_address: 'ul. Czereśniowa 20',
  venue_code: '01-001-001',
  attachments: [],
  public_comments: [],
  private_comments: [],
};

const ticketCategories = [
  {
    id: '1',
    name: 'garbage',
  },
  {
    id: '2',
    name: 'malfunction',
  },
  {
    id: '3',
    name: 'complaint',
  },
];

export const ticketStatuses = [
  {
    id: '1',
    external_name: 'open',
    internal_name: 'open',
    name: 'open',
    description: 'some description',
    active: true,
  },
  {
    id: '2',
    external_name: 'ongoing',
    internal_name: 'ongoing',
    name: 'custom_1',
    description: 'some description',
    active: true,
  },
  {
    id: '3',
    external_name: 'completed',
    internal_name: 'completed',
    name: 'completed',
    description: 'some description',
    active: true,
  },
];

const ticketToShow = { ...tickets[0] };
ticketToShow.status = { id: '1', external_name: 'open' };

const filterTickets = ({
  from_date: date_from, to_date: date_to, from_last_comment_at, to_last_comment_at, number_title_search, venue_search, homeowner_association_search,
  reporter_search, realizer_search,
  sort, order, venues_ids: venue_id, page, ...rest
}) => {
  let filteredTickets = tickets;
  if (rest['status_ids[]']) {
    const statuses = !Array.isArray(rest['status_ids[]']) ? [rest['status_ids[]']] : rest['status_ids[]'];
    filteredTickets = filteredTickets.filter((ticket) => statuses.find((statusId) => ticket.status === ticketStatuses[statusId - 1].external_name));
  }

  if (date_from && date_to) {
    filteredTickets = filteredTickets.filter((ticket) => new Date(ticket.reported_at) >= new Date(date_from))
      .filter((ticket) => new Date(ticket.reported_at) <= new Date(date_to));
  }
  if (from_last_comment_at && to_last_comment_at) {
    filteredTickets = filteredTickets.filter((ticket) => new Date(ticket.last_comment_at) >= new Date(from_last_comment_at))
      .filter((ticket) => new Date(ticket.last_comment_at) <= new Date(to_last_comment_at));
  }
  if (number_title_search) {
    filteredTickets = filteredTickets.filter(
      (ticket) => ticket.ticket_number.includes(number_title_search) || ticket.title.includes(number_title_search),
    );
  }
  if (venue_search) {
    filteredTickets = filteredTickets.filter(
      (ticket) => ticket.venue_address.toLowerCase().includes(venue_search) || ticket.venue_code.toLowerCase().includes(venue_search),
    );
  }
  if (homeowner_association_search) {
    filteredTickets = filteredTickets.filter(
      (ticket) => ticket.homeowner_association_name.includes(homeowner_association_search)
        || ticket.homeowner_association_code.includes(homeowner_association_search),
    );
  }
  if (rest['category_ids[]']) {
    const categories = !Array.isArray(rest['category_ids[]']) ? [rest['category_ids[]']] : rest['category_ids[]'];
    filteredTickets = filteredTickets.filter((ticket) => categories.find((categoryId) => ticket.category === ticketCategories[categoryId - 1].name));
  }
  if (reporter_search) {
    filteredTickets = filteredTickets.filter(
      (ticket) => (ticket.reporter || '').includes(reporter_search) || (ticket.reporter_external_id || '').includes(reporter_search),
    );
  }
  if (realizer_search) {
    filteredTickets = filteredTickets.filter(
      (ticket) => (ticket.realizer || '').includes(realizer_search) || (ticket.realizer_external_id || '').includes(realizer_search),
    );
  }
  if (venue_id) {
    const { address } = venues.find((venue) => venue.id === venue_id);
    filteredTickets = filteredTickets.filter((ticket) => ticket.venue_address === address);
  }
  if (sort) {
    filteredTickets = filteredTickets.sort((a, b) => (order === 'desc' ? b[sort].localeCompare(a[sort]) : a[sort].localeCompare(b[sort])));
  }
  return filteredTickets;
};

const mockSeries = (page) => {
  switch (page) {
    case '1':
      return ['1', 2, 3, 'gap', 6];
    case '2':
      return [1, '2', 3, 4, 5, 6];
    case '3':
      return [1, 2, '3', 4, 5, 6];
    case '4':
      return [1, 2, 3, '4', 5, 6];
    case '5':
      return [1, 2, 3, 4, '5', 6];
    case '6':
      return [1, 'gap', 4, 5, '6'];
    default:
      return null;
  }
};

const metadata = (page) => ({
  count: 58,
  first_url: '/tickets/list?page=1&items=10',
  from: page * 10 - 9,
  in: 10,
  items: 10,
  last: 6,
  last_url: '/tickets/list?page=6&items=10',
  next: page === 6 ? null : page + 1,
  page,
  pages: 6,
  prev: page === 1 ? null : page - 1,
  series: mockSeries(page),
  to: page * 10,
});

const updateTickets = (ticketsChanges) => {
  Object.keys(ticketsChanges).forEach((id) => {
    const i = tickets.findIndex((ticket) => ticket.id === id);
    tickets[i] = {
      ...tickets[i],
      status: Object.keys(ticketsChanges[id]).includes('status_id') ? 'mocked category' : tickets[i].category,
      realizer: Object.keys(ticketsChanges[id]).includes('realizer_id') ? 'mocked realizer' : tickets[i].realizer,
      category: Object.keys(ticketsChanges[id]).includes('category_id') ? 'mocked category' : tickets[i].category,
    };
  });
};

export const ticketsMocks = [
  rest.get('/tickets/list', (req, res, ctx) => {
    const { query: params } = queryString.parseUrl(req.url.href);
    return res(
      ctx.status(200),
      ctx.json({
        metadata: metadata(params.page),
        tickets: filterTickets(params),
      }),
    );
  }),
  rest.get('/tickets/:id/show', (req, res, ctx) => res(
    ctx.status(200),
    ctx.json({
      ticket: ticketToShow,
    }),
  )),
  rest.get('/ticket_categories/list', (req, res, ctx) => res(
    ctx.status(200),
    ctx.json({ ticket_categories: ticketCategories }),
  )),
  rest.get('/tickets/statuses/ticket/list', (req, res, ctx) => res(
    ctx.status(200),
    ctx.json({ statuses: ticketStatuses }),
  )),
  rest.post('/tickets/add', (req, res, ctx) => res(
    ctx.status(200),
  )),
  rest.post('/tickets/:id/add_comment', (req, res, ctx) => {
    req.then((postData) => postData);
    return res(
      ctx.status(200),
    );
  }),
  rest.get('/tickets/list/columns_settings', (req, res, ctx) => res(
    ctx.status(200),
    ctx.json({ selected_columns: selectedColumns }),
  )),
  rest.post('/tickets/list/change_columns_settings', (req, res, ctx) => {
    req.json().then((postData) => {
      selectedColumns = postData.columns;
    });
    return res(
      ctx.status(200),
    );
  }),
  rest.post('/tickets/change', (req, res, ctx) => {
    req.json().then((postData) => {
      updateTickets(postData.tickets);
      ticketsToShow = {
        ...ticketsToShow,
        ...postData.tickets['8b359308-0888-4535-b9e6-22b4c03d8b1e'],
      };
    });
    return res(
      ctx.status(200),
    );
  }),
  rest.post('/tickets/:id/add_task', (req, res, ctx) => res(
    ctx.status(200),
  )),
  rest.put('/tickets/statuses/:id/change', (req, res, ctx) => res(
    ctx.status(200),
  )),
];
