import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import queryString from 'query-string';
import {
  ApiBaseQuery, ApiTagTypes,
} from '../../baseQuery';
import { Tenants, Tenant } from './tenantsTypes';

export const tenantsQueries = (builder: EndpointBuilder<ApiBaseQuery, ApiTagTypes, 'api'>) => ({
  getTenants: builder.query<Tenant[], Record<string, string | null>>({
    query: (params) => {
      const stringifiedParams = queryString.stringify(params, { arrayFormat: 'bracket' });
      return `tenants/list?${stringifiedParams}`;
    },
    transformResponse(baseQueryReturnValue: Tenants): Tenant[] {
      return baseQueryReturnValue.tenants;
    },
  }),
});
