import * as React from 'react';
import { useCallback, useEffect } from 'react';
import { format } from 'date-fns';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import useMessages from '../../../hooks/useMessages';
import {
  resetSelectedDocumentIds, saveSelectedDocumentId, TableId,
} from '../../../store/appSlice';
import TableActionsBar from '../../../components/Table/TableActionsBar';
import Table, {
  TableBody, TableBodyCell,
  TableBodyRow,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow,
} from '../../../components/Table/Table';
import TableBodyPlaceholder from '../../../components/Table/TableBodyPlaceholder';
import Chip from '../../../components/Chip/Chip';
import { Document } from '../../../api/modules/documents/documentsTypes';
import DocumentBulkActions from './DocumentsBulkActions/DocumentBulkActions';
import DocumentTableCustomisation from './DocumentsBulkActions/DocumentTableCustomisation';
import { useGetSelectedDocumentsColumnsQuery } from '../../../api/appApi';
import { TaskColumnId } from '../../../api/modules/tasks/tasksTypes';

interface Props {
  documents: Document[],
}

const EmployeeDocumentsTableDesktop = ({ documents }: Props) => {
  const getMessage = useMessages();
  const selectedDocumentsIds = useAppSelector((state) => state.app.selectedDocumentsIds);
  const { data: selectedColumns } = useGetSelectedDocumentsColumnsQuery();
  const isSelected = useCallback((columnId: TaskColumnId): boolean => !!selectedColumns?.includes(columnId), [selectedColumns]);

  const dispatch = useAppDispatch();

  const handleChecked = (id: string) => {
    dispatch(saveSelectedDocumentId({ id }));
  };

  useEffect(() => {
    dispatch(resetSelectedDocumentIds());
  }, []);

  return (
    <>
      <TableActionsBar>
        <div className="flex justify-center">
          <DocumentBulkActions selectedTasks={selectedDocumentsIds} />
          <DocumentTableCustomisation />
        </div>
      </TableActionsBar>
      <Table className="col-span-full px-0 py-0 table-fixed whitespace-nowrap overflow-auto overscroll-none !p-0">
        <TableHeader className="sticky top-0 z-20 bg-background-white border-none shadow-main shadow-sm">
          <TableHeaderRow className="[&>*:nth-child(2)]:pl-8 [&>*:last-child]:pr-8">
            <TableHeaderCell className="sticky top-0 left-0 pt-8 bg-main " />
            <TableHeaderCell className="pt-8 pb-4" id="document_number" tableName={'documentsList' as TableId}>
              {getMessage('documents.column.documentNumber')}
            </TableHeaderCell>
            <TableHeaderCell className="pt-8 pb-4" id="created_at" tableName={'documentsList' as TableId}>
              {getMessage('documents.column.date')}
            </TableHeaderCell>
            <TableHeaderCell className="pt-8 pb-4">{getMessage('documents.column.title')}</TableHeaderCell>
            <TableHeaderCell className="pt-8 pb-4">{getMessage('documents.column.description')}</TableHeaderCell>
            <TableHeaderCell className="pt-8 pb-4">{getMessage('documents.column.journal')}</TableHeaderCell>
            <TableHeaderCell className="pt-8 pb-4">{getMessage('documents.column.status')}</TableHeaderCell>
            <TableHeaderCell className="pt-8 pb-4">{getMessage('documents.column.creator')}</TableHeaderCell>
            <TableHeaderCell className="pt-8 pb-4">{getMessage('documents.column.realizer')}</TableHeaderCell>
            <TableHeaderCell className="pt-8 pb-4">{getMessage('documents.column.category')}</TableHeaderCell>
            {isSelected('sender') && (<TableHeaderCell className="pt-8 pb-4">{getMessage('documents.column.sender')}</TableHeaderCell>)}
            {isSelected('receiver') && (<TableHeaderCell className="pt-8 pb-4">{getMessage('documents.column.receiver')}</TableHeaderCell>)}
            {isSelected('tenant') && (<TableHeaderCell className="pt-8 pb-4">{getMessage('documents.column.tenant')}</TableHeaderCell>)}
          </TableHeaderRow>
        </TableHeader>
        <TableBody>
          {documents.length === 0
            && (
              <TableBodyPlaceholder />
            )}
          {documents.map(({
            id, documentNumber, creationDate, title, description, journal, status,
            creator, realizer, sender, receiver, tenant, category, revoked,
          }) => (
            <TableBodyRow key={id} className="relative border-none odd:bg-light-100 text-typography-black [&>*:nth-child(2)]:pl-8 [&>*:last-child]:pr-8">
              <TableHeaderCell className="sticky left-0 z-15 m-10 pl-0 !pr-0 pt-0 bg-main border-main w-1">
                <label htmlFor={id} className="hover:cursor-pointer">
                  <div className="text-[0px] h-0">{getMessage('documents.document.checkbox.label')}</div>
                  <input
                    className="m-4"
                    type="checkbox"
                    checked={selectedDocumentsIds.includes(id)}
                    onChange={() => handleChecked(id)}
                    id={id}
                  />
                </label>
              </TableHeaderCell>
              <TableBodyCell>{documentNumber}</TableBodyCell>
              <TableBodyCell>
                {creationDate && (
                <Chip chipStyle="ghost" className="w-fit">
                  {format(new Date(creationDate), 'dd-MM-yyyy')}
                </Chip>
                )}
              </TableBodyCell>
              <TableBodyCell
                clickable={({ href: `/dokumenty/${id}`, ariaLabel: `Przejdź do szczegółów dokumentu ${documentNumber} ${title}` })}
                className="text-typography-black"
              >
                {title}
              </TableBodyCell>
              <TableBodyCell className="overflow-ellipsis">{revoked ? getMessage('documents.document.revoked') : description}</TableBodyCell>
              <TableBodyCell>{journal}</TableBodyCell>
              <TableBodyCell>
                <Chip chipStyle="primary" className="w-fit">
                  {status}
                </Chip>
              </TableBodyCell>
              <TableBodyCell>{creator}</TableBodyCell>
              <TableBodyCell>{realizer}</TableBodyCell>
              <TableBodyCell>
                <Chip chipStyle="primary" className="w-fit">
                  {category}
                </Chip>
              </TableBodyCell>
              {isSelected('sender') && (<TableBodyCell>{sender}</TableBodyCell>)}
              {isSelected('receiver') && (<TableBodyCell>{receiver}</TableBodyCell>)}
              {isSelected('tenant') && (<TableBodyCell>{tenant}</TableBodyCell>)}
            </TableBodyRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

export default EmployeeDocumentsTableDesktop;
