import { profileMocks } from './mocks/profileMocks';
import { metersMocks } from './mocks/metersMocks';
import { ticketsMocks } from './mocks/ticketsMocks';
import { accessMocks } from './mocks/accessMocks';
import { resolutionsMocks } from './mocks/resolutionsMocks';
import { announcementsMocks } from './mocks/announcementsMocks';
import { activityLogMocks } from './mocks/activityLogMocks';
import { settlementsMocks } from './mocks/settlementsMocks';
import { tasksMocks } from './mocks/tasksMocks';
import { employeesMocks } from './mocks/employeesMocks';
import { usersMocks } from './mocks/usersMocks';
import { tenantsMocks } from './mocks/tenantsMocks';
import { venuesMocks } from './mocks/venuesMocks';
import { homeownerAssociationsMocks } from './mocks/homeownerAssociationsMocks';
import { companyMocks } from './mocks/companyMocks';
import { journalsMocks } from './mocks/journalsMocks';
import { documentsMocks } from './mocks/documentsMocks';

// eslint-disable-next-line import/prefer-default-export
export const mocks = [
  ...profileMocks,
  ...metersMocks,
  ...ticketsMocks,
  ...accessMocks,
  ...resolutionsMocks,
  ...announcementsMocks,
  ...activityLogMocks,
  ...settlementsMocks,
  ...tasksMocks,
  ...employeesMocks,
  ...usersMocks,
  ...tenantsMocks,
  ...venuesMocks,
  ...homeownerAssociationsMocks,
  ...companyMocks,
  ...journalsMocks,
  ...documentsMocks,
];
