import * as React from 'react';
import { useIntl } from 'react-intl';
import useMessages from '../../../hooks/useMessages';

const DownloadAttachment = ({ path, filename }: { path: string, filename: string }) => {
  const getMessage = useMessages();
  const intl = useIntl();

  return (
    <div className="text-center">
      <span className="block text-text-lg-medium text-typography-black">{getMessage('attachment.previewUnavailable.download')}</span>
      <button type="button" className="mt-8 mb-12">
        <a
          href={path}
          target={path ? '' : '_blank'}
          rel="noreferrer"
          title={intl.formatMessage(
            { id: 'download' },
            { filename },
          )}
          className="py-2 px-8 lg:h-auto text-text-lg-semibold border border-stroke-grey-300 rounded bg-none text-main hover:text-hover hover:bg-light-100 hover:border-hover"
        >
          {getMessage('attachment.download')}
        </a>
      </button>
    </div>
  );
};

export default DownloadAttachment;
