import * as React from 'react';
import { usePermissions } from '../../../hooks/usePermissions';
import PageHeader from '../../../components/Layout/PageHeader';
import ContentWrapper from '../../../components/ContentWrapper/ContentWrapper';
import Layout from '../../../components/Layout/Layout';
import useMessages from '../../../hooks/useMessages';
import NewUserForm from './NewUserForm';

const NewUserPage = () => {
  const getMessage = useMessages();
  const { checkRoleType } = usePermissions();

  checkRoleType('admin');

  return (
    <Layout currentPage="users">
      <PageHeader pageTitle={getMessage('users.newUser.header')} currentPage="users" path="/uzytkownicy" />
      <ContentWrapper>
        <NewUserForm />
      </ContentWrapper>
    </Layout>
  );
};

export default NewUserPage;
