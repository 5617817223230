/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */

import * as React from 'react';
import { useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router';
import classNames from 'classnames';
import { useGetEmployeesQuery, usePostChangeTaskMutation } from '../../../../api/appApi';
import useMessages from '../../../../hooks/useMessages';
import RealizerIcon from '../../../../icons/user.svg';
import Dialog from '../../../../components/Dialog/Dialog';
import { InputLabel } from '../../../../components/FormInputs/InputLabel';
import FormSearchableDropdown from '../../../../components/FormInputs/FormSearchableDropdown';
import { FormCheckboxInput } from '../../../../components/FormInputs';

interface Props {
  selectedTasksIds: string[],
}

const TaskAssignEmployeeAction = ({ selectedTasksIds }: Props) => {
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const [postChangeTask] = usePostChangeTaskMutation();
  const getMessage = useMessages();
  const methods = useForm();
  const watchRealizerId = methods.watch('realizer_id');
  const watchRemoveRealizer = methods.watch('removeRealizer');
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = () => {
    if (selectedTasksIds.length > 0) setIsPopupOpen(true);
  };

  const onAccept = useCallback(() => {
    const tasks: {
      [key: string]: {
        realizer_id: string | null
      }
    } = {};

    selectedTasksIds.forEach((taskId) => {
      tasks[taskId] = { realizer_id: watchRealizerId || null };
    });
    postChangeTask({ tasks });

    methods.setValue('realizer_id', '');
    methods.setValue('removeRealizer', false);
    navigate(location.pathname);
  }, [watchRealizerId, watchRemoveRealizer]);

  const onClose = () => {
    methods.setValue('realizer_id', '');
    methods.setValue('removeRealizer', false);
    setIsPopupOpen(false);
  };

  const dynamicStyles = (selectedTasksIds.length > 0)
    ? 'border-typography-dark hover:border-hover hover:cursor-pointer hover:text-hover hover:bg-light-200'
    : 'text-stroke-grey-300 border-stroke-grey-300 hover:cursor-default';

  return (
    <div title={getMessage('tasks.bulkAction.changeRealizer')}>
      <RealizerIcon
        role="button"
        className={classNames(dynamicStyles, 'border rounded text-typography-dark p-2 mr-1')}
        onClick={handleClick}
        tabIndex={(selectedTasksIds.length > 0) ? 0 : undefined}
        onKeyDown={(e) => e.key === 'Enter' && setIsPopupOpen(true)}
        aria-label={getMessage('tasks.bulkAction.changeRealizer')}
      />
      {isPopupOpen
        && (
          <Dialog
            isOpen={isPopupOpen}
            acceptButtonText={getMessage('confirm')}
            onClose={onClose}
            closeButtonText={getMessage('cancel')}
            onAccept={onAccept}
            isAcceptButtonDisabled={!watchRealizerId && !watchRemoveRealizer}
          >
            <div className="mb-10">
              <InputLabel label={getMessage('tasks.changeTasksRealizer.popUp.label')} autofocus />
              <FormProvider {...methods}>
                <FormSearchableDropdown
                  label=" "
                  className="mt-4 w-full"
                  id="realizer_id"
                  query={useGetEmployeesQuery}
                  disabled={watchRemoveRealizer}
                  ariaLabel={getMessage('form.ariaLabel.realizer')}
                />
                <p className="text-center my-2">{getMessage('tasks.changeTasksRealizer.popUp.or')}</p>
                <FormCheckboxInput
                  label={getMessage('tasks.changeTasksRealizer.popUp.removeRealizer')}
                  id="removeRealizer"
                  className="hover:text-red-500"
                  disabled={watchRealizerId}
                />
              </FormProvider>
            </div>
          </Dialog>
        ) }
    </div>
  );
};

export default TaskAssignEmployeeAction;
