import * as React from 'react';
import { useNavigate } from 'react-router';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { usePermissions } from '../../hooks/usePermissions';
import useMessages from '../../hooks/useMessages';
import { useAppSelector } from '../../hooks/reduxHooks';
import useFilters from '../../hooks/useFilters';
import Layout from '../../components/Layout/Layout';
import PageHeader from '../../components/Layout/PageHeader';
import { FormInput } from '../../components/FormInputs';
import Button from '../../components/Button/Button';
import FiltersIcon from '../../icons/filters.svg';
import PlusIcon from '../../icons/plus.svg';
import EmployeeDocumentsFilters from './EmployeeDocumentsPage/EmployeeDocumentsFIlters';
import ContentWrapper from '../../components/ContentWrapper/ContentWrapper';
import EmployeeDocumentsList from './EmployeeDocumentsPage/EmployeeDocumentsList';
import TenantDocumentsList from './TenantDocumentsPage/TenantDocumentsList';
import TenantDocumentsFilters from './TenantDocumentsPage/TenantDocumentsFilters';

interface DocumentSearchInput {
  number_title_search?: string | '',
}

const DocumentsPage = () => {
  const getMessage = useMessages();
  const navigate = useNavigate();
  const {
    hasPermission, checkPermission, hasRoleType, checkModuleEnabled,
  } = usePermissions();

  const hasFilterSet = useAppSelector((state) => !!state.app.filters.find((filter) => filter.id === 'documentsList'));
  const [filtersOpen, setFiltersOpen] = useState(hasFilterSet);

  const { saveFilterValues, filterValues } = useFilters<DocumentSearchInput>('documentsList');
  const methods = useForm<DocumentSearchInput>({
    defaultValues: {
      number_title_search: filterValues?.number_title_search || '',
    },
  });

  useEffect(() => {
    methods.watch(
      (filters) => {
        saveFilterValues(filters);
      },
    );
  }, [methods.watch]);

  checkPermission('documents_list');
  checkModuleEnabled('documents');

  return (
    <Layout currentPage="documents">
      <PageHeader pageTitle={getMessage('documents.list.title')} currentPage="documents">
        {!filtersOpen && (
          /* eslint-disable-next-line react/jsx-props-no-spreading */
          <FormProvider {...methods}>
            <FormInput
              className="col-span-2"
              id="number_title_search"
              type="search"
              inputClassName="w-full my-0"
              placeholder={getMessage('filters.search')}
              ariaLabel="Szukaj dokumentu"
            />
          </FormProvider>
        )}
        <Button
          Icon={FiltersIcon}
          styleType="secondary"
          size="small"
          onClick={() => setFiltersOpen((filtersOpen) => !filtersOpen)}
          className="mx-3 py-1 appearance-none"
        >
          {getMessage('filters')}
        </Button>
        {hasPermission('documents_add') && hasRoleType('worker') && (
          <Button
            onClick={() => navigate('/dokumenty/nowy')}
            className="py-1"
            size="small"
            Icon={PlusIcon}
          >
            {getMessage('documents.list.newDocument')}
          </Button>
        )}
      </PageHeader>
      {filtersOpen && (hasRoleType('worker') ? <EmployeeDocumentsFilters /> : <TenantDocumentsFilters />)}
      <ContentWrapper className="md:grid-rows-[auto_minmax(200px,_max-content)_auto]">
        {hasRoleType('worker') ? <EmployeeDocumentsList /> : <TenantDocumentsList />}
      </ContentWrapper>
    </Layout>
  );
};

export default DocumentsPage;
