import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import { Permission } from '../../utils/generated/permissionsTypes';
import { useGetCompanySettingsQuery, useGetFullUserAccountQuery } from '../api/appApi';
import { RoleType } from '../api/modules/access/accessTypes';
import { UserRole } from '../api/modules/user/userTypes';

export type ModuleName = 'meters' | 'resolutions' | 'settlements' | 'payments' | 'tickets' | 'documents' | 'portal_pro';

export const usePermissions = () => {
  const userQuery = useGetFullUserAccountQuery();
  const navigate = useNavigate();
  const { data: companySettings, isSuccess: isCompanySettingsFetchSuccess } = useGetCompanySettingsQuery();

  const hasPermission = (permission: Permission): boolean => {
    if (userQuery.isSuccess && userQuery.data) {
      return (userQuery.data.allPermissions || []).includes(permission);
    }
    return false;
  };

  const hasAnyPermission = (permissions: Permission[]): boolean => {
    if (userQuery.isSuccess && userQuery.data) {
      return !!permissions.find((permission) => (userQuery.data.allPermissions || []).includes(permission));
    }
    return false;
  };

  const hasAllPermissions = (permissions: Permission[]): boolean => {
    if (userQuery.isSuccess && userQuery.data) {
      return !permissions.find((permission) => !(userQuery.data.allPermissions || []).includes(permission));
    }
    return false;
  };

  const hasRoleType = (roleType: RoleType): boolean => {
    if (userQuery.isSuccess && userQuery.data) {
      return !!userQuery.data.roles.find((userRole: UserRole) => userRole.roleType === roleType);
    }
    return false;
  };

  const isModuleEnabled = useCallback((module: ModuleName) => {
    if (isCompanySettingsFetchSuccess && companySettings) {
      switch (module) {
        case 'resolutions':
          return !!companySettings?.resolutionsModuleEnabled;
        case 'settlements':
          return !!companySettings?.settlementsModuleEnabled;
        case 'tickets':
          return !!companySettings?.ticketsModuleEnabled;
        case 'meters':
          return !!companySettings?.metersModuleEnabled;
        case 'payments':
          return !!companySettings?.paymentsModuleEnabled;
        case 'documents':
          return !!companySettings?.documentsModuleEnabled;
        case 'portal_pro':
          return !!companySettings?.portalProModuleEnabled;
        default:
          return false;
      }
    }
    return false;
  }, [companySettings]);

  const navigateToFirstPermittedEndpoint = useCallback((): void => {
    userQuery.data?.roles.forEach((userRole: UserRole) => {
      switch (userRole.roleType) {
        case 'admin':
          if (isCompanySettingsFetchSuccess && companySettings) {
            navigate(companySettings?.documentsModuleEnabled || companySettings?.ticketsModuleEnabled ? '/parametry/statusy' : '/uzytkownicy');
          }
          break;
        case 'tenant':
        case 'worker':
          navigate('/');
          break;
        default:
          navigate('/profil');
      }
    });
  }, [userQuery, companySettings]);

  const checkRoleType = useCallback((roleType: RoleType): void => {
    if (userQuery.status === 'fulfilled' && !hasRoleType(roleType)) {
      navigateToFirstPermittedEndpoint();
    }
  }, [userQuery]);

  const checkPermission = useCallback((permission: Permission): void => {
    if (userQuery.status === 'fulfilled' && !hasPermission(permission)) {
      navigateToFirstPermittedEndpoint();
    }
  }, [userQuery]);

  const checkModuleEnabled = useCallback((module: ModuleName): void => {
    if (isCompanySettingsFetchSuccess && !isModuleEnabled(module)) {
      navigateToFirstPermittedEndpoint();
    }
  }, [companySettings]);

  return {
    hasPermission,
    hasAnyPermission,
    hasAllPermissions,
    hasRoleType,
    navigateToFirstPermittedEndpoint,
    checkRoleType,
    checkPermission,
    isModuleEnabled,
    checkModuleEnabled,
  };
};
