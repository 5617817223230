import * as React from 'react';
import { PropsWithChildren } from 'react';
import classnames from 'classnames';

interface Props {
  chipStyle: 'primary' | 'success' | 'error' | 'gray' | 'ghost',
  className?: string,
  tooltipText?: string,
  screenReaderOnlyText?: string,
}

const Chip = ({
  chipStyle, className, children, tooltipText, screenReaderOnlyText,
}: PropsWithChildren<Props>) => {
  const styleVariants = {
    primary: 'bg-light-200 text-hover text-text-sm-semibold border-light-200',
    gray: 'bg-background-light text-typography-dark text-text-sm-semibold border-background-light',
    ghost: 'text-typography-dark text-text-sm-medium border-stroke-grey-100',
    success: 'bg-interactions-success-bg text-interactions-success-text text-text-sm-semibold border-interactions-success-bg w-[80px]',
    error: 'bg-interactions-error-bg text-interactions-error-text text-text-sm-semibold border-interactions-error-bg w-[80px]',
  };

  return (
    <div
      className={classnames(styleVariants[chipStyle], 'border rounded text-center py-1.5 px-2 min-w-[80px] max-w-[220px] h-fit my-1 mobile:my-0', className)}
      title={tooltipText}
    >
      <p className="inline-block">
        {screenReaderOnlyText
        && <span className="visually-hidden">{screenReaderOnlyText}</span>}
        {children}
      </p>
    </div>
  );
};

export default Chip;
