import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import queryString from 'query-string';
import {
  ApiBaseQuery, ApiTagTypes,
} from '../../baseQuery';
import {
  translateDocuments,
  translateDocumentStatus,
  translateFullDocument,
  translateFullDocumentStatuses,
  translateJournals,
} from './documentsTranslator';
import {
  ApiDocumentCategories,
  ApiDocumentsResponse,
  ApiDocumentStatuses,
  Journals,
  DocumentCategory,
  DocumentsResponse,
  DocumentStatus,
  FullDocumentStatus,
  FullDocument,
  ApiFullDocument,
  ApiPostDocumentComment,
  ApiPostChangeDocumentData,
  FullJournal,
  ApiFullJournal,
  ApiPostFullJournal,
} from './documentsTypes';
import { ApiColumnsSettings, ApiPostColumnsSettings, TicketColumnId } from '../tickets/ticketsTypes';
import { ApiPostTask } from '../tasks/tasksTypes';

export const documentsQueries = (builder: EndpointBuilder<ApiBaseQuery, ApiTagTypes, 'api'>) => ({
  getDocuments: builder.query<DocumentsResponse, Record<string, string> | void>({
    query: (params) => {
      const stringifiedParams = params && queryString.stringify(params, { arrayFormat: 'bracket' });
      return `documents/list?${stringifiedParams}`;
    },
    transformResponse(baseQueryReturnValue: ApiDocumentsResponse): DocumentsResponse {
      return translateDocuments(baseQueryReturnValue);
    },
    providesTags: ['Documents'],
  }),
  getDocument: builder.query<FullDocument, string | void>({
    query: (documentId) => `documents/${documentId}/show`,
    transformResponse(baseQueryReturnValue: ApiFullDocument): FullDocument {
      return translateFullDocument(baseQueryReturnValue);
    },
    providesTags: () => ['Document'],
  }),
  postDocument: builder.mutation<any, FormData>({
    query: (document) => ({
      url: '/documents/add',
      method: 'POST',
      body: document,
    }),
    invalidatesTags: () => ['Documents'],
  }),
  postChangeDocument: builder.mutation<any, ApiPostChangeDocumentData>({
    query: (documents) => ({
      url: '/documents/change',
      method: 'POST',
      body: documents,
    }),
    invalidatesTags: () => ['Document', 'Documents', 'ActivityLog'],
  }),
  postRevokeDocument: builder.mutation<any, string>({
    query: (id) => ({
      url: `/documents/${id}/revoke`,
      method: 'PATCH',
    }),
    invalidatesTags: () => ['Document', 'Documents', 'ActivityLog'],
  }),
  postDocumentComment: builder.mutation<any, ApiPostDocumentComment>({
    query: ({ id, payload }) => ({
      url: `/documents/${id}/add_comment`,
      method: 'POST',
      body: payload,
    }),
    invalidatesTags: () => ['Document', 'Documents'],
  }),
  getDocumentsCategories: builder.query<DocumentCategory[], void>({
    query: () => '/document_categories/list',
    transformResponse(baseQueryReturnValue: ApiDocumentCategories): DocumentCategory[] {
      return baseQueryReturnValue.document_categories;
    },
    providesTags: ['DocumentsCategories'],
  }),
  postDocumentCategory: builder.mutation<any, { name: string }>({
    query: (body) => ({
      url: '/document_categories/add',
      method: 'POST',
      body,
    }),
    invalidatesTags: ['DocumentsCategories'],
  }),
  patchDocumentCategory: builder.mutation<any, { name: string, id: string }>({
    query: ({ id, name }) => ({
      url: `/document_categories/${id}/change`,
      method: 'PATCH',
      body: { name },
    }),
    invalidatesTags: ['DocumentsCategories'],
  }),
  deleteDocumentCategory: builder.mutation<any, { id: string }>({
    query: ({ id }) => ({
      url: `/document_categories/${id}/remove`,
      method: 'DELETE',
    }),
    invalidatesTags: ['DocumentsCategories'],
  }),
  getDocumentStatuses: builder.query<DocumentStatus[], void>({
    query: () => 'tickets/statuses/document/list',
    transformResponse(baseQueryReturnValue: ApiDocumentStatuses) {
      return translateDocumentStatus(baseQueryReturnValue);
    },
    providesTags: ['DocumentsStatuses'],
  }),
  postTaskForDocument: builder.mutation<any, ApiPostTask>({
    query: ({ id, task }) => ({
      url: `/documents/${id}/add_task`,
      method: 'POST',
      body: task,
    }),
    invalidatesTags: () => ['Tasks', 'Task', 'Documents', 'ActivityLog'],
  }),
  getFullDocumentStatuses: builder.query<FullDocumentStatus[], void>({
    query: () => 'tickets/statuses/document/list',
    transformResponse(baseQueryReturnValue: ApiDocumentStatuses) {
      return translateFullDocumentStatuses(baseQueryReturnValue);
    },
    providesTags: ['DocumentsStatuses'],
  }),
  getSelectedDocumentsColumns: builder.query<TicketColumnId[], void>({
    query: () => '/documents/list/columns_settings',
    transformResponse(baseQueryReturnValue: ApiColumnsSettings): TicketColumnId[] {
      return baseQueryReturnValue.selected_columns;
    },
    providesTags: () => ['ColumnSettings'],
  }),
  postSelectedDocumentsColumns: builder.mutation<any, ApiPostColumnsSettings>({
    query: (body) => ({
      url: '/documents/list/change_columns_settings',
      method: 'POST',
      body,
    }),
    invalidatesTags: () => ['ColumnSettings', 'Documents'],
  }),
  getFullJournals: builder.query<Journals, Record<string, string> | void>({
    query: (params) => {
      const stringifiedParams = params && queryString.stringify(params, { arrayFormat: 'bracket' });
      return `/journals/list?${stringifiedParams}`;
    },
    providesTags: ['Journals'],
  }),
  getJournals: builder.query<{ id: string, name: string }[], Record<string, string>>({
    query: (params) => {
      const stringifiedParams = params && queryString.stringify(params, { arrayFormat: 'bracket' });
      return `/journals/list?${stringifiedParams}`;
    },
    transformResponse(baseQueryReturnValue: Journals) {
      return translateJournals(baseQueryReturnValue);
    },
    providesTags: ['Journals'],
  }),
  getJournal: builder.query<FullJournal, string | void>({
    query: (id) => `/journals/${id}/show`,
    transformResponse(baseQueryReturnValue: ApiFullJournal) {
      return baseQueryReturnValue.journal;
    },
    providesTags: () => ['Journal'],
  }),
  postJournal: builder.mutation<any, FullJournal>({
    query: (journal) => ({
      url: '/journals/add',
      method: 'POST',
      body: journal,
    }),
    invalidatesTags: () => ['Journals', 'Journal'],
  }),
  changeJournal: builder.mutation<any, ApiPostFullJournal>({
    query: ({ id, journal }) => ({
      url: `/journals/${id}/change`,
      method: 'PATCH',
      body: journal,
    }),
    invalidatesTags: () => ['Journals', 'Journal'],
  }),
  deleteJournal: builder.mutation<any, { id: string }>({
    query: ({ id }) => ({
      url: `/journals/${id}/remove`,
      method: 'DELETE',
    }),
    invalidatesTags: () => ['Journals', 'Journal'],
  }),
});
