import * as React from 'react';
import { useState, useMemo, useCallback } from 'react';
import classNames from 'classnames';
import CategoryIcon from '../../../../icons/category.svg';
import {
  useGetTaskCategoriesQuery,
  usePostChangeTaskMutation,
} from '../../../../api/appApi';
import Dialog from '../../../../components/Dialog/Dialog';
import useMessages from '../../../../hooks/useMessages';
import { InputLabel } from '../../../../components/FormInputs/InputLabel';
import { Select } from '../../../../components/FormInputs/inputs/FormSelect';
import capitalize from '../../../../../utils/formatters';

interface Props {
  selectedTasksIds: string[];
}

const TaskChangeCategoryAction = ({ selectedTasksIds }: Props) => {
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const [postChangeTask] = usePostChangeTaskMutation();
  const getMessage = useMessages();
  const { data: taskCategories } = useGetTaskCategoriesQuery();

  const categoryOptionsValues = useMemo(() => taskCategories?.map(({ id, name }) => ({
    value: id,
    optionLabel: capitalize(name),
  })), [taskCategories]);

  const handleClick = () => {
    if (selectedTasksIds.length > 0) setIsPopupOpen(true);
  };

  const changeCategory = (value: string) => {
    setSelectedCategory(value);
  };

  const onAccept = useCallback(() => {
    const tasks: {
      [key: string]: {
        category_id: string
      }
    } = {};
    function populatePayload(id: string) {
      tasks[id] = { category_id: selectedCategory };
    }
    selectedTasksIds.forEach((item) => populatePayload(item));
    postChangeTask({ tasks });
  }, [selectedCategory]);

  const onClose = () => {
    setSelectedCategory('');
    setIsPopupOpen(false);
  };

  const dynamicStyles = (selectedTasksIds.length > 0) ? 'border-typography-dark hover:border-hover hover:cursor-pointer hover:text-hover hover:bg-light-200' : 'text-stroke-grey-300 border-stroke-grey-300 hover:cursor-default';

  return (
    <div title={getMessage('tasks.bulkAction.changeCategory')}>
      <CategoryIcon
        role="button"
        className={classNames(dynamicStyles, 'border rounded text-typography-dark p-2 mr-1')}
        onClick={handleClick}
        tabIndex={(selectedTasksIds.length > 0) ? 0 : undefined}
        onKeyDown={(e) => e.key === 'Enter' && setIsPopupOpen(true)}
        aria-label={getMessage('tasks.bulkAction.changeCategory')}
      />
      {isPopupOpen
        && (
          <Dialog
            isOpen={isPopupOpen}
            acceptButtonText={getMessage('confirm')}
            onClose={onClose}
            closeButtonText={getMessage('cancel')}
            onAccept={onAccept}
            isAcceptButtonDisabled={!selectedCategory}
          >
            <div className="mb-10">
              <InputLabel
                label={getMessage('tasks.changeTasksCategory')}
              />
              {categoryOptionsValues && (
                <Select
                  selectOptions={categoryOptionsValues}
                  onChange={(value) => changeCategory(value)}
                  value={selectedCategory}
                  placeholder={getMessage('choose')}
                  autofocus
                />
              )}
            </div>
          </Dialog>
        ) }
    </div>
  );
};

export default TaskChangeCategoryAction;
