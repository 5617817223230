import * as React from 'react';
import MobileInfoCard from '../../../../../components/InfoCard/MobileInfoCard/MobileInfoCard';
import TicketInfoCard from './TicketInfoCard';

const TicketMobileInfoCard = () => (
  <MobileInfoCard title="Info">
    <TicketInfoCard />
  </MobileInfoCard>
);

export default TicketMobileInfoCard;
