import * as React from 'react';
import format from 'date-fns/format';
import {
  useGetFullUserAccountQuery,
  useGetPaymentsHistoryQuery,
} from '../../../api/appApi';
import useMessages from '../../../hooks/useMessages';
import usePagination from '../../../hooks/usePagination';
import useFilters from '../../../hooks/useFilters';
import ResponsiveTable, {
  ResponsiveTableBody,
  ResponsiveTableBodyRow,
  ResponsiveTableHeader,
} from '../../../components/Table/ResponsiveTable';
import { TableBodyCell, TableBodyRow } from '../../../components/Table/Table';
import Chip from '../../../components/Chip/Chip';
import PaginationControls from '../../../components/Table/PaginationControls';
import { formatBankAccount, formatNumber } from '../../../../utils/formatters';
import Spinner from '../../../components/Spinner/Spinner';
import { usePermissions } from '../../../hooks/usePermissions';

interface Props {
  venueId: string
}

const PaymentsHistoryTable = ({ venueId }: Props) => {
  const getMessage = useMessages();
  const [selectedPage, setSelectedPage] = usePagination(`paymentsHistoryTable${venueId}`);
  const { filterValues } = useFilters('paymentsHistoryTable');
  const filters = { ...filterValues };

  const { data: user } = useGetFullUserAccountQuery();
  const selectedSettlementsTenantId = localStorage.getItem(`selectedSettlementsTenantId-${user?.id}`);

  const { hasRoleType } = usePermissions();
  const queryParams: { page:string, venue: string, tenant_id?: string } = {
    ...filters,
    page: selectedPage.toString(),
    venue: venueId,
  };
  if (hasRoleType('worker') && selectedSettlementsTenantId) queryParams.tenant_id = selectedSettlementsTenantId;
  const { data: history, isLoading } = useGetPaymentsHistoryQuery(queryParams);

  const ids = history?.payments.map((payment) => payment.description + payment.amount) || [];
  const columnLabels = [
    { label: 'settlements.history.column.bankAccount' },
    { label: 'settlements.history.column.title' },
    { label: 'settlements.history.column.date' },
    { label: 'settlements.history.column.amount', divClassName: 'md:justify-end' },
    { label: 'settlements.history.column.status' },
  ];

  return (
    isLoading ? <Spinner className="m-auto" /> : (
      <>
        <ResponsiveTable className="col-span-full">
          <ResponsiveTableHeader ids={ids} columnLabels={columnLabels} />
          <ResponsiveTableBody>
            {history?.payments && history.payments.length === 0
            && (
              <TableBodyRow>
                <TableBodyCell>{getMessage('settlements.history.noResults')}</TableBodyCell>
              </TableBodyRow>
            )}
            {history?.payments.map(({
              bankAccount, description, paymentDate, amount, status,
            }, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <ResponsiveTableBodyRow key={description + amount + index + selectedPage} className="border-b last:border-b-0 border-separate">
                <TableBodyCell>{bankAccount ? formatBankAccount(bankAccount) : ''}</TableBodyCell>
                <TableBodyCell>{description}</TableBodyCell>
                <TableBodyCell>
                  {paymentDate && (
                    <Chip chipStyle="ghost" className="w-fit">
                      {format(new Date(paymentDate), 'dd-MM-yyyy HH:mm:ss')}
                    </Chip>
                  )}
                </TableBodyCell>
                <TableBodyCell className="md:text-right">{formatNumber(amount)}</TableBodyCell>
                <TableBodyCell>
                  {status === 'pending' && (
                    <Chip chipStyle="gray" className="w-fit">
                      {getMessage('settlements.history.column.status.pending')}
                    </Chip>
                  )}
                  {status === 'success' && (
                    <Chip chipStyle="success" className="w-fit">
                      {getMessage('settlements.history.column.status.success')}
                    </Chip>
                  )}
                  {status === 'failure' && (
                    <Chip chipStyle="error" className="w-fit">
                      {getMessage('settlements.history.column.status.failure')}
                    </Chip>
                  )}
                </TableBodyCell>
              </ResponsiveTableBodyRow>
            ))}
          </ResponsiveTableBody>
        </ResponsiveTable>
        {history?.metadata && (
          <PaginationControls
            className="col-span-full"
            selectedPage={selectedPage}
            setSelectedPage={setSelectedPage}
            paginationMetadata={history.metadata}
          />
        )}
      </>
    )
  );
};

export default PaymentsHistoryTable;
