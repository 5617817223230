import * as React from 'react';
import { useMemo } from 'react';
import { FullTicketStatus } from '../../../api/modules/tickets/ticketsTypes';
import { FullTaskStatus } from '../../../api/modules/tasks/tasksTypes';
import { FullDocumentStatus } from '../../../api/modules/documents/documentsTypes';
import ResponsiveTable, {
  ResponsiveTableBody,
  ResponsiveTableBodyRow,
  ResponsiveTableHeader,
} from '../../../components/Table/ResponsiveTable';
import { TableBodyCell } from '../../../components/Table/Table';

interface Props {
  title: string,
  statuses: FullTicketStatus[] | FullTaskStatus[] | FullDocumentStatus[],
}

const StatusesList = ({ title, statuses }: Props) => {
  const columnLabels = [
    { label: 'parameters.statuses.list.active' },
    { label: 'parameters.statuses.list.externalName' },
    { label: 'parameters.statuses.list.internalName' },
  ];
  const ids = useMemo(() => statuses.map((status) => status.id), [statuses]);

  return (
    <ResponsiveTable header={(<div className="text-header-sm">{title}</div>)} className="col-span-full">
      <ResponsiveTableHeader ids={ids} columnLabels={columnLabels} />
      <ResponsiveTableBody>
        {statuses.map((status) => (
          <ResponsiveTableBodyRow
            key={status.id}
            className="border-b last:border-b-0 border-separate font-medium"
            clickable={({ href: (`/parametry/statusy/${status.id}`), ariaLabel: 'Przejdź do edycji statusu' })}
          >
            <TableBodyCell className="max-w-[4rem]">
              <div>
                <input
                  type="checkbox"
                  checked={status.active}
                  disabled
                  className="m-2 ml-4"
                  aria-label={status.active ? 'Status aktywny' : 'Status nieaktywny'}
                />
              </div>
            </TableBodyCell>
            <TableBodyCell>
              {status.externalName}
            </TableBodyCell>
            <TableBodyCell>
              {status.internalName}
            </TableBodyCell>
          </ResponsiveTableBodyRow>
        ))}
      </ResponsiveTableBody>
    </ResponsiveTable>
  );
};

export default StatusesList;
