import * as React from 'react';
import classnames from 'classnames';
import SpinnerIcon from '../../icons/spinner.svg';

interface Props {
  className?: string,
}

const Spinner = ({ className }: Props) => <SpinnerIcon className={classnames('animate-spin-slow text-main', className)} />;

export default Spinner;
