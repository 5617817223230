import * as React from 'react';
import format from 'date-fns/format';
import { useNavigate } from 'react-router-dom';
import { TableBodyCell } from '../../../components/Table/Table';
import ResponsiveTable, { ResponsiveTableHeader, ResponsiveTableBody, ResponsiveTableBodyRow } from '../../../components/Table/ResponsiveTable';
import Chip from '../../../components/Chip/Chip';
import { TableId } from '../../../store/appSlice';
import capitalize from '../../../../utils/formatters';
import Button from '../../../components/Button/Button';
import useMessages from '../../../hooks/useMessages';
import { Task } from '../../../api/modules/tasks/tasksTypes';
import TableBodyPlaceholder from '../../../components/Table/TableBodyPlaceholder';

interface Props {
  tasks: Task[]
}

const TasksListMobile = ({ tasks }: Props) => {
  const navigate = useNavigate();
  const handleNavigate = (id: string) => navigate(`/zadania/${id}`);
  const getMessage = useMessages();

  const ids = tasks?.map(({ id }) => id);

  const columnLabels = [
    { label: 'tasks.column.status' },
    { label: 'tasks.column.creationDate' },
    { label: 'tasks.column.lastCommentDate' },
    { label: 'tasks.column.number' },
    { label: 'tasks.column.title' },
    { label: 'tasks.column.venueAddress' },
    { label: 'tasks.column.placeholder', divClassName: 'sr-only' },
  ];

  return (
    <ResponsiveTable className="col-span-full md:mb-0">
      {tasks && tasks?.length === 0 ? <TableBodyPlaceholder /> : (
        <>
          <ResponsiveTableHeader ids={ids} columnLabels={columnLabels} tableName={'tasksTable' as TableId} />
          <ResponsiveTableBody>
            {tasks && tasks?.map(({
              status, createdAt, lastCommentAt, taskNumber, title, venueAddress, id,
            }) => (

              <ResponsiveTableBodyRow key={taskNumber} className="border-b last:border-b-0 border-separate font-medium">
                <TableBodyCell>
                  <Chip chipStyle={status === 'completed' ? 'gray' : 'primary'} className="w-fit">
                    {status && capitalize(status)}
                  </Chip>
                </TableBodyCell>
                <TableBodyCell>
                  <Chip chipStyle="ghost" className="w-fit">
                    {format(new Date(createdAt), 'dd-MM-yyyy')}
                  </Chip>
                </TableBodyCell>
                <TableBodyCell>
                  {lastCommentAt && (
                  <Chip chipStyle="ghost" className="w-fit">
                    {format(new Date(lastCommentAt), 'dd-MM-yyyy')}
                  </Chip>
                  )}
                </TableBodyCell>
                <TableBodyCell className="max-w-[4rem]">{taskNumber}</TableBodyCell>
                <TableBodyCell className={`w-fit text-typography-black ${status === 'completed' ? '' : 'font-bold'}`}>{title}</TableBodyCell>
                <TableBodyCell className="max-w-[13rem] truncate">{venueAddress}</TableBodyCell>
                <TableBodyCell>
                  <Button
                    onClick={() => handleNavigate(id)}
                    styleType="secondary"
                    size="small"
                    ariaLabel={getMessage('tasks.task.taskDetails')}
                  >
                    {getMessage('more')}
                  </Button>
                </TableBodyCell>
              </ResponsiveTableBodyRow>
            ))}
          </ResponsiveTableBody>
        </>
      )}
    </ResponsiveTable>
  );
};

export default TasksListMobile;
