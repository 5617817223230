import * as React from 'react';
import DesktopInfoCard from '../../../components/InfoCard/DesktopInfoCard/DesktopInfoCard';
import DocumentInfoCard from './DocumentInfoCard';

const DocumentDesktopInfoCard = () => (
  <DesktopInfoCard className="row-span-2">
    <DocumentInfoCard />
  </DesktopInfoCard>
);

export default DocumentDesktopInfoCard;
