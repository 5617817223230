/* eslint-disable react/jsx-props-no-spreading */

import * as React from 'react';
import { useParams } from 'react-router-dom';
import Layout from '../../../components/Layout/Layout';
import PageHeader from '../../../components/Layout/PageHeader';
import ContentWrapper from '../../../components/ContentWrapper/ContentWrapper';
import useMessages from '../../../hooks/useMessages';
import { useGetAnnouncementQuery } from '../../../api/appApi';
import { usePermissions } from '../../../hooks/usePermissions';
import AnnouncementChangeForm from './AnnouncementChangeForm';

const AnnouncementChange = () => {
  const getMessage = useMessages();
  const { id } = useParams();
  const { data } = useGetAnnouncementQuery(id);
  const { checkPermission } = usePermissions();

  checkPermission('announcements_notice_add');

  if (!id || !data) return null;

  return (
    <Layout currentPage="announcements">
      <PageHeader pageTitle={getMessage('announcements.notice.editNotice')} currentPage="announcements" path="/aktualnosci" />
      <ContentWrapper>
        <AnnouncementChangeForm announcement={data} id={id} />
      </ContentWrapper>
    </Layout>
  );
};

export default AnnouncementChange;
