/* eslint-disable react/button-has-type */

import * as React from 'react';
import {
  ComponentType, PropsWithChildren, SVGProps, forwardRef,
} from 'react';

interface Props {
  onClick?: (e:React.KeyboardEvent<HTMLButtonElement> | React.MouseEvent<HTMLButtonElement>) => void,
  styleType?: 'primary' | 'secondary' | 'ghost';
  size?: 'large' | 'default' | 'small';
  type?: 'button' | 'submit',
  disabled?: boolean,
  className?: string,
  Icon?: ComponentType<SVGProps<SVGSVGElement>>;
  ariaLabel?: string,
}

const Button = forwardRef(({
  onClick, styleType, size, disabled, className = '', children, type, Icon, ariaLabel,
}: PropsWithChildren<Props>, ref: React.Ref<any>) => {
  const primaryClasses = `${disabled
    ? 'text-typography-dark bg-background-light border border-background-light'
    : 'text-typography-white bg-main border border-main hover:bg-hover hover:border-hover'}`;
  const secondaryClasses = `border border-stroke-grey-300 bg-none 
    ${disabled
    ? 'text-typography-dark'
    : 'text-main hover:text-hover hover:bg-light-100 hover:border-hover'}`;
  const ghostClasses = `bg-none ${disabled
    ? 'text-typography-dark'
    : 'text-main hover:text-hover'}`;

  const defaultSizeClasses = 'py-0 h-8 w-28 lg:w-44 lg:py-2 lg:h-auto';
  const largeClasses = 'w-44 py-2 h-auto lg:w-72';
  const smallClasses = 'text-text-sm-semibold w-20 px-3 lg:py-0 lg:h-8 lg:w-28 md:text-text-lg-semibold lg:px-6';

  const getCorrectClasses: () => string = () => {
    let classes;
    switch (styleType) {
      case 'ghost':
        classes = ghostClasses;
        break;
      case 'secondary':
        classes = secondaryClasses;
        break;
      case 'primary':
      default:
        classes = primaryClasses;
        break;
    }
    switch (size) {
      case 'large':
        classes = `${classes} ${largeClasses}`;
        break;
      case 'small':
        classes = `${classes} ${smallClasses}`;
        break;
      case 'default':
      default:
        classes = `${classes} ${defaultSizeClasses}`;
        break;
    }
    return classes;
  };

  return (
    <button
      className={`px-6 py-2 text-text-lg-semibold rounded min-w-max select-none ${getCorrectClasses()} ${className}`}
      type={type || 'button'}
      onClick={onClick}
      disabled={disabled}
      aria-label={ariaLabel}
      ref={ref}
    >
      {Icon && <Icon className="stroke-current inline-block mr-2" />}
      {children}
    </button>
  );
});

export default Button;
