import * as React from 'react';
import format from 'date-fns/format';
import { useCallback } from 'react';
import { DocumentAttachment, DocumentItem } from '../../../../api/modules/settlements/settlementsTypes';
import useMessages from '../../../../hooks/useMessages';
import Table, {
  TableBody, TableBodyCell,
  TableBodyRow,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow,
} from '../../../../components/Table/Table';
import DocumentIcon from '../../../../icons/document.svg';
import Chip from '../../../../components/Chip/Chip';
import { usePatchDocumentReadMutation } from '../../../../api/appApi';
import { usePermissions } from '../../../../hooks/usePermissions';

interface Props {
  items?: DocumentItem[],
  value: string,
  vat: string
  attachment?: DocumentAttachment | null,
  readAt?: string,
  documentId: string,
}

const ItemsTable = ({
  items, value, vat, attachment, readAt, documentId,
}: Props) => {
  const getMessage = useMessages();
  const { hasRoleType, hasPermission } = usePermissions();
  const [markAsRead] = usePatchDocumentReadMutation();

  const handleOpenDocument = useCallback((id: string, readAt: string | undefined): void => {
    if (!readAt && hasRoleType('tenant')) {
      markAsRead({ documentId: id });
    }
  }, []);

  return (
    <Table
      className="bg-light-100 rounded border border-light-200 col-span-full px-0 py-0 table-fixed whitespace-nowrap overflow-auto"
      tableClassName="mobile:m-4"
    >
      <TableHeader>
        <TableHeaderRow className="border-b-0">
          <TableHeaderCell colspan={5}>
            <div className="flex flex-row justify-start mb-4 items-center">
              {attachment && hasPermission('settlements_document_read') && (
                <a
                  href={attachment.path}
                  rel="noreferrer"
                  target="_blank"
                  className="flex flex-row justify-start"
                  onClick={() => handleOpenDocument(documentId, readAt)}
                  title={getMessage('opensInNewTab')}
                  aria-label="Pobierz dokument - otwiera się w nowej zakładce"
                >
                  <span className="text-typography-black text-text-lg-semibold">
                    {attachment.filename}
                  </span>
                  <DocumentIcon className="ml-2 text-typography-dark cursor-pointer stroke-typography-dark" />
                </a>
              )}
              {readAt && (
                <Chip chipStyle="gray" className="w-fit ml-2">
                  {`${getMessage('settlements.titleBalance.item.header.read')}: ${format(new Date(readAt), 'dd-MM-yyyy')}`}
                </Chip>
              )}
            </div>
          </TableHeaderCell>
        </TableHeaderRow>
        <TableHeaderRow>
          <TableHeaderCell className="h-12">{getMessage('settlements.titleBalance.item.header.code')}</TableHeaderCell>
          <TableHeaderCell className="h-12">{getMessage('settlements.titleBalance.item.header.name')}</TableHeaderCell>
          <TableHeaderCell className="h-12" divClassName="justify-end">{getMessage('settlements.titleBalance.item.header.count')}</TableHeaderCell>
          <TableHeaderCell className="h-12" divClassName="justify-end">{getMessage('settlements.titleBalance.item.header.price')}</TableHeaderCell>
          <TableHeaderCell className="h-12" divClassName="justify-end">{getMessage('settlements.titleBalance.item.header.value')}</TableHeaderCell>
        </TableHeaderRow>
      </TableHeader>
      <TableBody>
        {items && items.map((item) => (
          <TableBodyRow key={item.position} className="h-8">
            <TableBodyCell className="h-12">{item.position}</TableBodyCell>
            <TableBodyCell className="h-12">{item.itemName}</TableBodyCell>
            <TableBodyCell className="h-12 text-end">{item.amount}</TableBodyCell>
            <TableBodyCell className="h-12 text-end">{`${item.price} ${getMessage('currency')}`}</TableBodyCell>
            <TableBodyCell className="h-12 text-end text-text-lg-semibold text-typography-black">{`${item.value} ${getMessage('currency')}`}</TableBodyCell>
          </TableBodyRow>
        ))}
        <TableBodyRow>
          <TableBodyCell colspan={4} />
          <TableBodyCell className="h-12 text-end">
            <div className="flex flex-col items-end">
              <span>{`${getMessage('settlements.titleBalance.item.header.vat')} ${vat} ${getMessage('currency')}`}</span>
              <span className="text-text-lg-semibold text-typography-black">{`${getMessage('settlements.titleBalance.item.header.sum')} ${value} ${getMessage('currency')}`}</span>
            </div>
          </TableBodyCell>
        </TableBodyRow>
      </TableBody>
    </Table>
  );
};

export default ItemsTable;
