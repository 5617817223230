/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { useState, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useGetMailServerConfigQuery, usePutChangeMailServerConfigMutation } from '../../api/appApi';
import { FormInput, FormSelect } from '../../components/FormInputs';
import Button from '../../components/Button/Button';
import useMessages from '../../hooks/useMessages';
import { MailServerConfigFormInputs, authentications, communicationEncryptions } from '../../api/modules/company/companyTypes';
import { translateMailServerConfigToApi } from '../../api/modules/company/companyTranslator';
import { FormNotice } from '../../components/FormNotice/FormNotice';

const MailServerConfigForm = () => {
  const getMessage = useMessages();
  const { data: currentConfig } = useGetMailServerConfigQuery();
  const [isNoAuthenticationSelected, setIsNoAuthenticationSelected] = useState(false);
  const [changeMailServerConfig, { isError, isSuccess }] = usePutChangeMailServerConfigMutation();

  const methods = useForm<MailServerConfigFormInputs>({
    values: {
      address: currentConfig?.address || '',
      authentication: currentConfig?.authentication || authentications[0],
      domain: currentConfig?.domain || '',
      port: currentConfig?.port || '',
      userName: currentConfig?.userName || '',
      password: '',
      fromEmail: currentConfig?.fromEmail || '',
      communicationEncryption: currentConfig?.communicationEncryption || communicationEncryptions[0],
    },
    mode: 'onTouched',
  });

  const watchAuthentication = methods.watch('authentication');

  useEffect(() => {
    if (watchAuthentication && watchAuthentication.toLowerCase() === 'none') {
      setIsNoAuthenticationSelected(true);
    } else {
      setIsNoAuthenticationSelected(false);
    }
  }, [watchAuthentication]);

  const onSubmit = (data: MailServerConfigFormInputs) => {
    changeMailServerConfig(translateMailServerConfigToApi(data));
  };

  const authenticationOptions = authentications.map((method) => ({ value: method, optionLabel: method.toUpperCase() }));
  const communicationEncryptionOptions = communicationEncryptions.map((method) => ({ value: method, optionLabel: method === 'none' ? method : method.toUpperCase() }));

  const validateLength = (value: string) => {
    if (!isNoAuthenticationSelected && value.length === 0) {
      return getMessage('company.mailServerConfig.form.error.required');
    }
    return true;
  };

  return (
    <FormProvider {...methods}>
      {isError && <FormNotice noticeClassName="col-span-full" type="error" message={getMessage('form.error')} />}
      {isSuccess && <FormNotice noticeClassName="col-span-full" type="success" message={getMessage('form.success')} />}
      <form onSubmit={methods.handleSubmit(onSubmit)} className="col-span-full grid grid-cols-1 md:grid-cols-2 pb-6">
        <FormInput
          id="address"
          type="text"
          label={`${getMessage('company.mailServerConfig.form.address')}*`}
          inputClassName="w-full md:w-2/3"
          placeholder="np. http://mail.example.com"
          options={{ required: getMessage('company.mailServerConfig.form.error.required') }}
        />
        <FormInput
          id="domain"
          type="text"
          label={`${getMessage('company.mailServerConfig.form.domain')}*`}
          className="md:col-start-1"
          inputClassName="w-full md:w-2/3"
          placeholder="np. example.com"
          options={{ required: getMessage('company.mailServerConfig.form.error.required') }}
        />
        <FormInput
          id="port"
          type="text"
          label={`${getMessage('company.mailServerConfig.form.port')}*`}
          className="md:col-start-1"
          inputClassName="w-full md:w-2/3"
          placeholder="np. 8080"
          options={{ required: getMessage('company.mailServerConfig.form.error.required') }}
        />
        <FormSelect
          id="communicationEncryption"
          label={`${getMessage('company.mailServerConfig.form.communicationEncryption')}*`}
          className="md:col-start-1"
          inputClassName="w-full md:w-2/3"
          selectOptions={communicationEncryptionOptions}
          options={{ required: getMessage('company.mailServerConfig.form.error.required') }}
        />
        <FormSelect
          id="authentication"
          label={`${getMessage('company.mailServerConfig.form.authentication')}*`}
          className="md:row-start-1 md:col-start-2"
          inputClassName="w-full md:w-2/3"
          selectOptions={authenticationOptions}
          options={{ required: getMessage('company.mailServerConfig.form.error.required') }}
        />
        <FormInput
          id="userName"
          type="text"
          label={`${getMessage('company.mailServerConfig.form.userName')}${isNoAuthenticationSelected ? '' : '*'}`}
          className="md:row-start-2 md:col-start-2"
          inputClassName="w-full md:w-2/3"
          disabled={isNoAuthenticationSelected}
          options={{ validate: (value) => validateLength(value) }}

        />
        <div className="md:row-start-3 md:col-start-2 w-full md:w-2/3 flex justify-between items-end">
          <FormInput
            id="password"
            type="password"
            label={`${getMessage('company.mailServerConfig.form.password')}${isNoAuthenticationSelected ? '' : '*'}`}
            className="w-1/2"
            inputClassName="w-full"
            disabled={isNoAuthenticationSelected}
            options={{ validate: (value) => validateLength(value) }}
          />
        </div>
        <FormInput
          id="fromEmail"
          type="email"
          label={`${getMessage('company.mailServerConfig.form.fromEmail')}*`}
          className="md:row-start-4 md:col-start-2"
          inputClassName="w-full md:w-2/3"
          options={{ required: getMessage('company.mailServerConfig.form.error.required') }}
          placeholder="np. noreply@example.com"
        />
        <div className="md:col-span-2 flex justify-end mt-8">
          <Button type="submit">{getMessage('save')}</Button>
        </div>
      </form>
    </FormProvider>
  );
};

export default MailServerConfigForm;
