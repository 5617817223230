/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import useMessages from '../../../hooks/useMessages';
import PageHeader from '../../../components/Layout/PageHeader';
import Card from '../../../components/Card/Card';
import ContentWrapper from '../../../components/ContentWrapper/ContentWrapper';
import Layout from '../../../components/Layout/Layout';
import EmployeeTicketForm from './EmployeeTicketForm/EmployeeTicketForm';
import TenantTicketForm from './TenantTicketForm/TenantTicketForm';
import { usePermissions } from '../../../hooks/usePermissions';

type TicketAttachment = {
  file: FileList | undefined,
  description: string
};

export type TicketInputs = {
  title: string,
  venue_id?: string,
  category_id: string,
  description: string,
  attachments: TicketAttachment[],
  homeowner_association_id?: string,
  tenant_id?: string,
  realizer_id?: string,
  status_id?: string,
};

const TicketForm = () => {
  const getMessage = useMessages();
  const { hasRoleType, checkModuleEnabled } = usePermissions();

  checkModuleEnabled('tickets');

  return (
    <Layout currentPage="tickets">
      <PageHeader pageTitle={getMessage('local.tickets.newTicket')} currentPage="tickets" path="/zgloszenia" />
      <ContentWrapper>
        <Card className="col-span-full">
          <h4 className="text-header-sm inline-block mb-7">{getMessage('tickets.ticket.describeProblem')}</h4>
          {hasRoleType('worker') ? <EmployeeTicketForm />
            : <TenantTicketForm /> }
        </Card>
      </ContentWrapper>
    </Layout>
  );
};
export default TicketForm;
