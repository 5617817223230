import * as React from 'react';
import useMessages from '../../../hooks/useMessages';
import { usePermissions } from '../../../hooks/usePermissions';
import { useGetDocumentsCategoriesQuery } from '../../../api/appApi';
import Layout from '../../../components/Layout/Layout';
import ParametersSidebar from '../ParametersSidebar';
import PageHeader from '../../../components/Layout/PageHeader';
import ContentWrapper from '../../../components/ContentWrapper/ContentWrapper';
import CategoriesTable from '../CategoryTable/CategoriesTable';

const DocumentsParametersPage = () => {
  const getMessage = useMessages();
  const { checkModuleEnabled } = usePermissions();
  const { data: documentCategories } = useGetDocumentsCategoriesQuery();

  checkModuleEnabled('documents');

  return (
    <Layout currentPage="parameters">
      <div className="flex flex-grow h-full">
        <ParametersSidebar selectedPage="documentsParameters" />
        <div className="flex flex-col flex-grow">
          <PageHeader currentPage="parameters" pageTitle={getMessage('navigation.parameters.documents')} />
          <ContentWrapper>
            <CategoriesTable categoryType="document" categories={documentCategories || []} />
          </ContentWrapper>
        </div>
      </div>
    </Layout>
  );
};

export default DocumentsParametersPage;
