/* eslint-disable react/jsx-props-no-spreading */

import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { useForm, FormProvider, useFieldArray } from 'react-hook-form';
import { useNavigate } from 'react-router';
import useMessages from '../../../../hooks/useMessages';
import {
  usePostTicketMutation, useGetTicketCategoriesQuery, useGetTenantsQuery, useGetVenuesQuery, useGetHomeownerAssociationsQuery,
  useGetEmployeesQuery, useGetFullTicketStatusesQuery,
} from '../../../../api/appApi';
import {
  FormInput, FormSelect, FormTextarea, FormFileInput,
} from '../../../../components/FormInputs';
import Button from '../../../../components/Button/Button';
import FileUploadPreview from '../../../../components/AttachmentPreview/FileUploadPreview/FileUploadPreview';
import { translateTicketsToFormData } from '../../../../api/modules/tickets/ticketsTranslator';
import { TicketInputs } from '../TicketForm';
import FormSearchableDropdown from '../../../../components/FormInputs/FormSearchableDropdown';
import capitalize from '../../../../../utils/formatters';
import useHomeownerAssociationsMode from '../../../../hooks/useHomeownerAssociationsMode';
import { FormNotice } from '../../../../components/FormNotice/FormNotice';

const EmployeeTicketForm = () => {
  const getMessage = useMessages();
  const navigate = useNavigate();
  const [postTicketData, result] = usePostTicketMutation();
  const { data: ticketCategories } = useGetTicketCategoriesQuery();
  const { data: ticketStatuses } = useGetFullTicketStatusesQuery();
  const hoaMode = useHomeownerAssociationsMode();

  const methods = useForm<TicketInputs>({
    defaultValues: {
      attachments: [{ description: '' }],
    },
  });

  useEffect(() => {
    methods.setValue('status_id', ticketStatuses?.find((status) => status.name === 'open')?.id);
  }, [ticketStatuses]);

  const homeownerAssociationId = methods.watch('homeowner_association_id');
  const venueId = methods.watch('venue_id');
  const tenantId = methods.watch('tenant_id');

  const { fields, append, remove } = useFieldArray({ name: 'attachments', control: methods.control });

  const attachments = methods.watch('attachments');
  const allAttachmentsSelected = attachments.every((attachment) => attachment.file?.length);

  useEffect(() => {
    if (allAttachmentsSelected) {
      append({ file: undefined, description: '' });
    }
  }, [allAttachmentsSelected]);

  const onSubmit = (data: TicketInputs) => {
    postTicketData({
      ticket: translateTicketsToFormData(data),
    });
  };

  useEffect(() => {
    if (result.status === 'fulfilled') navigate('/zgloszenia');
  }, [result.status]);

  const categorySelectOptions = useMemo(() => ticketCategories?.map(({ id, name }) => ({
    value: id,
    optionLabel: capitalize(name),
  })), [ticketCategories]);

  const statusSelectOptions = useMemo(() => ticketStatuses?.map(({ id, externalName }) => ({
    value: id,
    optionLabel: capitalize(externalName),
  })) || [], [ticketStatuses]);

  return (
    <FormProvider {...methods}>
      {/* @ts-ignore */}
      {result.error && <FormNotice type="error" message={result.error.data?.errors || getMessage('form.error')} />}
      <form onSubmit={methods.handleSubmit(onSubmit)} className="grid grid-cols-12 col-span-12">
        <section className="col-span-12 md:col-span-6">
          {categorySelectOptions && (
          <FormSelect
            className="mt-4"
            id="category_id"
            label={`${getMessage('tickets.ticket.category')}*`}
            inline
            selectOptions={categorySelectOptions}
            options={{ required: getMessage('tickets.ticket.error.category.required') }}
          />
          )}
          <FormSearchableDropdown
            label={getMessage('tickets.ticket.tenant')}
            className="mt-4 w-full"
            id="tenant_id"
            query={useGetTenantsQuery}
            queryOptions={{ venue_id: venueId }}
            ariaLabel="Wyszukaj lokatora"
          />
          <FormSearchableDropdown
            label={getMessage('tickets.ticket.venue')}
            className="mt-4 w-full"
            id="venue_id"
            query={useGetVenuesQuery}
            queryOptions={{ homeowner_association_id: homeownerAssociationId, tenant_id: tenantId }}
            ariaLabel="Wyszukaj lokal"
          />
          {hoaMode && (
            <FormSearchableDropdown
              label={getMessage('tickets.ticket.homeownerAssociation')}
              className="mt-4 w-full"
              id="homeowner_association_id"
              query={useGetHomeownerAssociationsQuery}
              queryOptions={{ tenant_id: tenantId, venue_id: venueId }}
              ariaLabel="Wyszukaj wspólnotę"
            />
          )}
          <FormInput
            className="mt-4 w-full"
            inputClassName="w-full"
            id="title"
            type="text"
            label={`${getMessage('tickets.ticket.title')}*`}
            options={{ required: getMessage('tickets.ticket.error.title.required'), maxLength: { value: 150, message: getMessage('tickets.ticket.error.title.maxLength') } }}
          />
          <FormTextarea
            className="mt-6 w-full"
            inputClassName="w-full"
            id="description"
            label={`${getMessage('tickets.ticket.description')}*`}
            options={{ required: getMessage('tickets.ticket.error.description.required') }}
          />
          {fields.map(({ file, id }, index) => {
            const fileSelected = file && file.length > 0;
            return (
              <div key={id} id={id} className="flex align-start mb-4 w-full">
                {fileSelected && (
                  <FileUploadPreview attachment={attachments[index]} onDelete={() => remove(index)} />
                )}
                <div className="w-full">
                  {fileSelected && (
                    <FormInput
                      className="w-full"
                      inputClassName="text-xs w-full"
                      id={`attachments.${index}.description`}
                      type="text"
                      placeholder={getMessage('tickets.ticket.addAttachmentDescription')}
                    />
                  )}
                  <FormFileInput
                    inputClassName={fileSelected ? 'hidden' : ''}
                    id={`attachments.${index}.file`}
                    labelText={getMessage('tickets.ticket.addAttachment')}
                    labelClassName={fileSelected ? 'hidden' : ''}
                    withIcon
                  />
                </div>
              </div>
            );
          })}
        </section>
        <section className="col-span-12 md:col-span-2 md:col-start-10">
          <FormSelect
            className="mt-4"
            id="status_id"
            label={getMessage('tickets.ticket.status')}
            inline
            selectOptions={statusSelectOptions}
          />
          <FormSearchableDropdown
            label={getMessage('tickets.ticket.assignedTo')}
            className="mt-4 w-full"
            id="realizer_id"
            query={useGetEmployeesQuery}
            ariaLabel={getMessage('form.ariaLabel.realizer')}
          />
        </section>
        <div className="col-span-12 col-start-1">
          <Button type="submit" className="mt-6">{getMessage('save')}</Button>
        </div>
      </form>
    </FormProvider>
  );
};
export default EmployeeTicketForm;
