import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import format from 'date-fns/format';
import useMessages from '../../../hooks/useMessages';
import ResponsiveTable, {
  ResponsiveTableBody,
  ResponsiveTableBodyRow,
  ResponsiveTableHeader,
} from '../../../components/Table/ResponsiveTable';
import TableBodyPlaceholder from '../../../components/Table/TableBodyPlaceholder';
import { TableId } from '../../../store/appSlice';
import { TableBodyCell } from '../../../components/Table/Table';
import Chip from '../../../components/Chip/Chip';
import Button from '../../../components/Button/Button';
import { Document } from '../../../api/modules/documents/documentsTypes';

interface Props {
  documents: Document[]
}

const EmployeeDocumentsTableMobile = ({ documents }: Props) => {
  const navigate = useNavigate();
  const handleNavigate = (id: string) => navigate(`/dokumenty/${id}`);
  const getMessage = useMessages();

  const ids = documents?.map(({ id }) => id);

  const columnLabels = [
    { label: 'documents.column.documentNumber' },
    { label: 'documents.column.date' },
    { label: 'documents.column.title' },
    { label: 'documents.column.description' },
    { label: 'documents.column.journal' },
    { label: 'documents.column.status' },
    { label: 'documents.column.category' },
    { label: 'documents.document.placeholder', divClassName: 'sr-only' },
  ];

  return (
    <ResponsiveTable className="col-span-full md:mb-0">
      {documents && documents?.length === 0 ? <TableBodyPlaceholder /> : (
        <>
          <ResponsiveTableHeader ids={ids} columnLabels={columnLabels} tableName={'documentsList' as TableId} />
          <ResponsiveTableBody>
            {documents && documents?.map(({
              documentNumber, creationDate, title, description, journal, status, id, category, revoked,
            }) => (
              <ResponsiveTableBodyRow key={id} className="border-b last:border-b-0 border-separate font-medium">
                <TableBodyCell>
                  {documentNumber}
                </TableBodyCell>
                <TableBodyCell>
                  <Chip chipStyle="primary" className="w-fit">
                    {format(new Date(creationDate), 'dd-MM-yyyy')}
                  </Chip>
                </TableBodyCell>
                <TableBodyCell className="text-typography-black">
                  {title}
                </TableBodyCell>
                <TableBodyCell className="overflow-ellipsis">{revoked ? getMessage('documents.document.revoked') : description}</TableBodyCell>
                <TableBodyCell>{journal}</TableBodyCell>
                <TableBodyCell>
                  <Chip chipStyle="primary" className="w-fit">
                    {status}
                  </Chip>
                </TableBodyCell>
                <TableBodyCell>
                  <Chip chipStyle="primary" className="w-fit">
                    {category}
                  </Chip>
                </TableBodyCell>
                <TableBodyCell>
                  <Button
                    onClick={() => handleNavigate(id)}
                    styleType="secondary"
                    size="small"
                    ariaLabel={getMessage('documents.document.documentDetails')}
                  >
                    {getMessage('more')}
                  </Button>
                </TableBodyCell>
              </ResponsiveTableBodyRow>
            ))}
          </ResponsiveTableBody>
        </>
      )}
    </ResponsiveTable>
  );
};

export default EmployeeDocumentsTableMobile;
