import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import AttachmentPreview from '../../components/AttachmentPreview/AttachmentPreview';
import FileUploadPreview from '../../components/AttachmentPreview/FileUploadPreview/FileUploadPreview';
import { FormFileInput } from '../../components/FormInputs';
import { CompanyAttachment } from '../../api/modules/company/companyTypes';
import Button from '../../components/Button/Button';
import useMessages from '../../hooks/useMessages';

interface Props {
  oldAttachment?: CompanyAttachment,
  id: string,
  changeAttachmentLabel: string,
  newAttachmentLabel: string,
  oldId: string,
}

const CompanyAttachmentInput = ({
  oldAttachment, id, changeAttachmentLabel, newAttachmentLabel, oldId,
}: Props) => {
  const getMessage = useMessages();
  const methods = useFormContext();
  const newAttachment = methods.watch(id);

  return (
    <div className="flex justify-between w-full">
      <div className="flex flex-wrap items-center mb-8">
        {oldAttachment && (!newAttachment || newAttachment.length === 0) && (
          <AttachmentPreview
            filename={oldAttachment.filename}
            path={oldAttachment.path}
            contentType={oldAttachment.content_type}
            className="border border-gray rounded shadow-md shadow-gray-300 mr-6 relative overflow-hidden h-[100px] w-[140px] my-0 mx-0"
            imageClassName="object-cover h-[100px] w-[140px]"
          />
        )}
        {newAttachment && newAttachment.length > 0 && (
          <FileUploadPreview
            attachment={{ file: newAttachment, description: id }}
            onDelete={() => {
              methods.setValue(id, null);
            }}
          />
        )}
        <FormFileInput
          id={id}
          labelText={oldAttachment ? changeAttachmentLabel : newAttachmentLabel}
          withIcon
        />
      </div>
      {oldAttachment && (!newAttachment || newAttachment.length === 0) && (
        <Button styleType="ghost" className="hover:underline" onClick={() => methods.setValue(oldId, null)}>
          {getMessage('remove')}
        </Button>
      )}
    </div>
  );
};

export default CompanyAttachmentInput;
