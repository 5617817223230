import * as React from 'react';
import { useMemo, useEffect } from 'react';
import format from 'date-fns/format';
import { useGetActivityLogQuery } from '../../../api/appApi';
import { TableBodyCell } from '../../../components/Table/Table';
import ResponsiveTable, { ResponsiveTableHeader, ResponsiveTableBody, ResponsiveTableBodyRow } from '../../../components/Table/ResponsiveTable';
import Chip from '../../../components/Chip/Chip';
import PaginationControls from '../../../components/Table/PaginationControls';
import useMessages from '../../../hooks/useMessages';
import usePagination from '../../../hooks/usePagination';
import useSortParams from '../../../hooks/useSortParams';
import useFilters from '../../../hooks/useFilters';
import { usePermissions } from '../../../hooks/usePermissions';
import { TableId } from '../../../store/appSlice';
import TableContentPlaceholder from '../../../components/Table/TableContentPlaceholder';

const ActivityLog = () => {
  const getMessage = useMessages();
  const [selectedPage, setSelectedPage] = usePagination('myActivityLog');
  const { sortParamsValues } = useSortParams('myActivityLog');
  const { filterValues } = useFilters('myActivityLog');
  const { hasRoleType } = usePermissions();

  useEffect(() => {
    setSelectedPage(1);
  }, []);

  const { data } = useGetActivityLogQuery({
    page: selectedPage.toString(),
    me_log: !!hasRoleType('admin'),
    ...sortParamsValues,
    ...filterValues,
  });

  const ids = data?.events.map(({ date }) => date) || [];

  const columnLabels = useMemo(() => {
    const columns = [
      {
        label: 'profile.user.activityLog.date',
        id: 'created_at',
      },
      { label: 'profile.user.activityLog.description' },
      { label: 'profile.user.activityLog.menuOption' },
    ];
    if (hasRoleType('admin')) {
      columns.splice(1, 0, ...[
        { label: 'profile.user.activityLog.role' },
      ]);
    }
    if (hasRoleType('tenant')) columns.push({ label: 'profile.user.activityLog.venue' });
    return columns;
  }, [hasRoleType]);

  return (
    <>
      <ResponsiveTable className="col-span-full">
        {data?.events && data.events.length === 0 ? (<TableContentPlaceholder />) : (
          <>
            <ResponsiveTableHeader ids={ids} columnLabels={columnLabels} tableName={'myActivityLog' as TableId} />
            <ResponsiveTableBody>
              {data?.events.map(({
                date, description, menuOption, venue, url,
              }) => (
                <ResponsiveTableBodyRow key={date} className="border-b last:border-b-0 border-separate font-medium">
                  <TableBodyCell>
                    <Chip chipStyle="ghost" className="w-fit whitespace-nowrap">
                      {format(new Date(date), 'dd-MM-yyyy / HH:mm')}
                    </Chip>
                  </TableBodyCell>
                  {(hasRoleType('worker') || hasRoleType('tenant')) ? (
                    <>
                      {url && (
                      <TableBodyCell
                        className="w-fit text-typography-blue mobile:whitespace-pre-wrap"
                        clickable={({
                          href: url,
                          linkState: { returnTo: '/profil/rejestr_zdarzen' },
                        })}
                      >
                        {description}
                      </TableBodyCell>
                      )}
                      {!url && <TableBodyCell className="w-fit text-typography-black mobile:whitespace-pre-wrap">{description}</TableBodyCell>}
                    </>
                  ) : (
                    <TableBodyCell className="w-fit text-typography-black mobile:whitespace-pre-wrap">{description}</TableBodyCell>
                  )}
                  <TableBodyCell className="w-fit text-typography-dark">{getMessage(`profile.user.activityLog.menuOption.${menuOption}`)}</TableBodyCell>
                  {hasRoleType('tenant') && <TableBodyCell className="w-fit text-typography-dark">{venue}</TableBodyCell>}
                </ResponsiveTableBodyRow>
              ))}
            </ResponsiveTableBody>

          </>
        )}
      </ResponsiveTable>
      {data?.metadata && (
        <PaginationControls
          className="col-span-full"
          selectedPage={selectedPage}
          setSelectedPage={setSelectedPage}
          paginationMetadata={data.metadata}
        />
      )}
    </>
  );
};

export default ActivityLog;
