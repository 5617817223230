import { rest } from 'msw';
import queryString from 'query-string';

const events = [
  {
    created_at: '2023-02-14T00:00:00Z',
    description: 'Login',
    category: 'authentication',
  },
  {
    created_at: '2023-02-15T00:00:00Z',
    description: 'Logout',
    category: 'authentication',
  },
  {
    created_at: '2023-02-16T00:00:00Z',
    description: 'Zagłosowano',
    category: 'resolutions',
    venue_address: 'ul. Czereśniowa 20',
  },
  {
    created_at: '2023-02-14T00:00:00Z',
    description: 'Zgłoszono zgłoszenie',
    category: 'tasks',
    venue_address: 'ul. Wiśniowa 30',
  },
];

const filterEvents = ({
  from_date: date_from, to_date: date_to, ...rest
}) => {
  let filteredEvents = events;
  if (date_from && date_to) {
    filteredEvents = filteredEvents.filter((event) => new Date(event.created_at) >= new Date(date_from))
      .filter((event) => new Date(event.created_at) <= new Date(date_to));
  }
  if (rest['category[]']) {
    const categories = !Array.isArray(rest['category[]']) ? [rest['category[]']] : rest['category[]'];
    filteredEvents = filteredEvents.filter((event) => categories.find((category) => event.category === category));
  }
  if (rest['venues_ids[]']) {
    const venues = !Array.isArray(rest['venues_ids[]']) ? [rest['venues_ids[]']] : rest['venues_ids[]'];
    filteredEvents = filteredEvents.filter((event) => venues.find(
      (venueId) => ((venueId === 'venueid-1' && event.venue_address === 'ul. Czereśniowa 20') || (venueId === 'venueid-2' && event.venue_address === 'ul. Wiśniowa 30')),
    ));
  }
  return filteredEvents;
};

const mockSeries = (page) => {
  switch (page) {
    case '1':
      return ['1', 2, 3, 'gap', 6];
    case '2':
      return [1, '2', 3, 4, 5, 6];
    case '3':
      return [1, 2, '3', 4, 5, 6];
    case '4':
      return [1, 2, 3, '4', 5, 6];
    case '5':
      return [1, 2, 3, 4, '5', 6];
    case '6':
      return [1, 'gap', 4, 5, '6'];
    default:
      return null;
  }
};

const metadata = (page) => ({
  count: 58,
  first_url: '/activity_log/list?page=1&items=10',
  from: page * 10 - 9,
  in: 10,
  items: 10,
  last: 6,
  last_url: '/activity_log/list?page=6&items=10',
  next: page === 6 ? null : page + 1,
  page,
  pages: 6,
  prev: page === 1 ? null : page - 1,
  series: mockSeries(page),
  to: page * 10,
});

export const activityLogMocks = [
  rest.get('/activity_log/list', (req, res, ctx) => {
    const { query: params } = queryString.parseUrl(req.url.href);
    return res(
      ctx.status(200),
      ctx.json({
        metadata: metadata(params.page),
        events: filterEvents(params),
      }),
    );
  }),
];
