/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import useMessages from '../../hooks/useMessages';
import { FormInput } from '../../components/FormInputs';
import Button from '../../components/Button/Button';
import { usePostTestMailServerConfigMutation } from '../../api/appApi';
import { FormNotice } from '../../components/FormNotice/FormNotice';
import { ApiTestMailServerConfig } from '../../api/modules/company/companyTypes';

const TestMailServerConfigForm = () => {
  const getMessage = useMessages();
  const [testMailServerConfig, { isError, isSuccess }] = usePostTestMailServerConfigMutation();
  const methods = useForm<{ email: string }>({
    values: {
      email: '',
    },
  });

  useEffect(() => {
    if (isSuccess) methods.setValue('email', '');
  }, [isSuccess]);

  const onSubmit = (data: ApiTestMailServerConfig) => testMailServerConfig(data);

  return (
    <div className="border-t border-t-stroke-grey-300 py-6">
      {isError && <FormNotice type="error" message={getMessage('company.mailServerConfig.test.error')} />}
      {isSuccess && <FormNotice type="success" message={getMessage('company.mailServerConfig.test.success')} />}
      <div className="text-title-semibold mb-4">{getMessage('company.mailServerConfig.test.form.label')}</div>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} className="grid grid-cols-1 md:grid-cols-2 mt-4">
          <FormInput
            type="email"
            id="email"
            inputClassName="w-full md:w-2/3"
            label={getMessage('company.mailServerConfig.test.form.email')}
            options={{ required: getMessage('company.mailServerConfig.test.form.email.required') }}
          />
          <div className="flex justify-end items-center mt-8">
            <Button type="submit">{getMessage('company.mailServerConfig.test.form.button')}</Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default TestMailServerConfigForm;
