import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import {
  ApiAccountResponse,
  ApiAccountsResponse,
  ApiRoles,
  ApiPostAssignRole,
  ApiPostChangeRole,
  ApiPostCreateRole,
  Role,
  User, Users, AccountApiQueryParams,
} from './accessTypes';
import { ApiBaseQuery, ApiTagTypes } from '../../baseQuery';
import { translateApiToRoles, translateApiToUser, translateApiToUsers } from './accessTranslator';

export const accessQueries = (builder: EndpointBuilder<ApiBaseQuery, ApiTagTypes, 'api'>) => ({
  getAccounts: builder.query<Users, AccountApiQueryParams>({
    query: (params) => `access/accounts/list?page=${params.page}${!!params.filters && `&roles_names[]=${params.filters.role}`}`,
    providesTags: ['UsersRoles'],
    transformResponse(baseQueryReturnValue: ApiAccountsResponse): Users {
      return translateApiToUsers(baseQueryReturnValue);
    },
  }),
  getAccount: builder.query<User, string>({
    query: (accountId) => `access/accounts/${accountId}/show`,
    transformResponse(baseQueryReturnValue: ApiAccountResponse): User {
      return translateApiToUser(baseQueryReturnValue);
    },
    providesTags: ['Account'],
  }),
  postAssignRole: builder.mutation<any, ApiPostAssignRole>({
    query: ({ id, payload }) => ({
      url: `access/accounts/${id}/assign_roles`,
      method: 'POST',
      body: payload,
    }),
    invalidatesTags: ['UsersRoles', 'UserAccess', 'Account'],
  }),
  postAssignPermissions: builder.mutation<any, ApiPostChangeRole>({
    query: ({ id, payload }) => ({
      url: `/access/accounts/${id}/assign_permissions`,
      method: 'POST',
      body: payload,
    }),
    invalidatesTags: ['Account', 'UserAccess', 'UsersRoles', 'Users'],
  }),

  getRoles: builder.query<Role[], void>({
    query: () => 'access/roles/list',
    transformResponse(baseQueryReturnValue: ApiRoles): Promise<Role[]> | Role[] {
      return translateApiToRoles(baseQueryReturnValue);
    },
    providesTags: ['Roles'],
  }),

  postCreateRole: builder.mutation<any, ApiPostCreateRole>({
    query: (payload) => ({
      url: '/access/roles/add',
      method: 'POST',
      body: payload,
    }),
    invalidatesTags: ['Roles'],
  }),
  postChangeRole: builder.mutation<any, ApiPostChangeRole>({
    query: ({ id, payload }) => ({
      url: `/access/roles/${id}/change`,
      method: 'PUT',
      body: payload,
    }),
    invalidatesTags: ['Account', 'UserAccess', 'UsersRoles', 'Roles'],
  }),
  deleteRole: builder.mutation<any, string>({
    query: (id) => ({
      url: `/access/roles/${id}/remove`,
      method: 'DELETE',
    }),
    invalidatesTags: ['Roles'],
  }),
});
