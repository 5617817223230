import { rest } from 'msw';
import queryString from 'query-string';

const announcements = [
  {
    announcement_number: 'ABC123',
    author: 'John Doe',
    category: 'notice',
    created_at: '2023-04-04T13:05:59Z',
    description: 'Dignissimos adipisci exercitationem. Nihil asperiores sit. Eos soluta ut.',
    id: '26ec35a5-3257-45be-8e2d-a40e0ddfc1a2',
    status: 'unread',
    title: 'Eum cumque ipsa totam.',
    attachments: [{
      filename: 'test1.pdf',
      content_type: 'application/pdf',
      path: '1fega3fgebb09',
    }],
    recipients: {
      'tenants/homeowner_associations': [
        { id: '60b41436-4659', name: 'SM Słoneczko' },
        { id: 'ae5e4eb5-4160', name: 'SM Tęcza' },
      ],
      'tenants/buildings': [{ id: '21861464-273b', name: 'Odolańska 56' }],
      'tenants/estates': [{ id: '60b4e8a2-1436', name: 'Cztery Pory Roku' }],
    },
  },
  {
    announcement_number: 'DEF456',
    author: 'Jane Doe',
    category: 'statement',
    created_at: '2023-04-05T16:05:59Z',
    description: 'Quas officia quam. Quaerat rerum nostrum. Eos qui iusto.',
    id: '122294d1-6e71-4d82-8a20-7e9710abbff0',
    status: 'read',
    title: 'Provident amet aut voluptatum.',
    attachments: [],
    recipients: {
      'tenants/estates': [
        { id: '60b4e8a2-1436', name: 'Cztery Pory Roku' },
        { id: 'ae5e4eb5-4160', name: 'SM Tęcza' },
      ],
      'tenants/tenants': [{ id: 'b690eb90-e77c', name: 'Eustachy Motyka' }],
      'users/accounts': [{ id: '4df6abcd-123', name: 'Eugeniusz Krosny' }],
    },
  },
  {
    announcement_number: 'MNO789',
    author: 'Jane Doe',
    category: 'document',
    created_at: '2023-04-10T18:05:59Z',
    description: 'Cat ipsum dolor sit amet, groom yourself 4 hours - checked.',
    id: 'a90e67a9-dbd7-4501-bb3d-546532f044dc',
    status: 'archived',
    title: 'Cat ipsum',
    attachments: [],
    recipients: {
      'users/accounts': [{ id: '4df6abcd-123', name: 'Eugeniusz Krosny' }],
    },
  },
  {
    announcement_number: 'JKL111',
    author: 'Jane Doe',
    category: 'statement',
    created_at: '2023-04-12T08:05:59Z',
    description: 'Mewl for food at 4am plays league of legends so scratch the box',
    id: '2912500d-810c-4052-bc4e-8ef47cf348d9',
    status: 'unread',
    title: 'Cat ipsum II',
    attachments: [],
    recipients: {
      'tenants/homeowner_associations': [{ id: '60b41436-4659', name: 'SM Słoneczko' },
      ],
    },
  },
];

const authors = [
  {
    id: 'd673a47c-bcd4-4c58-9dba-3038c3c8e1e6',
    full_name: 'John Doe',
  }, {
    id: 'cc00adba-c05b-4ebe-9449-8cf306f3bc00',
    full_name: 'Jane Doe',
  },
];

const mockSeries = (page) => {
  switch (page) {
    case '1':
      return ['1', 2];
    case '2':
      return [1, '2'];
    default:
      return null;
  }
};

const metadata = (page) => ({
  count: 14,
  first_url: '/announcements/list?page=1&items=10',
  from: page * 10 - 9,
  in: 10,
  items: 10,
  last: 2,
  last_url: '/announcements/list?page=6&items=10',
  next: page === 2 ? null : page + 1,
  page,
  pages: 2,
  prev: page === 1 ? null : page - 1,
  series: mockSeries(page),
  to: page * 10,
});

const filterAnnouncements = ({
  search, from_date: date_from, to_date: date_to, category, sort, order, archived, ...rest
}) => {
  let filteredAnnouncements = announcements;
  if (rest['created_by_ids[]']) {
    const authorsIds = !Array.isArray(rest['created_by_ids[]']) ? [rest['created_by_ids[]']] : rest['created_by_ids[]'];
    const selectedAuthorsNames = authors.filter((author) => authorsIds.find((id) => author.id === id)).map((author) => author.full_name);
    filteredAnnouncements = filteredAnnouncements.filter((item) => selectedAuthorsNames.find((author) => author === item.author));
  }
  if (search) {
    filteredAnnouncements = filteredAnnouncements.filter((item) => item.title.toLowerCase().includes(search.toLowerCase()));
  }
  if (date_from && date_to) {
    filteredAnnouncements = filteredAnnouncements.filter((item) => new Date(item.created_at) >= new Date(date_from))
      .filter((item) => new Date(item.created_at) <= new Date(date_to));
  }
  if (sort) {
    filteredAnnouncements = filteredAnnouncements.sort((a, b) => (order === 'desc' ? b[sort].localeCompare(a[sort]) : a[sort].localeCompare(b[sort])));
  }
  if (archived) {
    filteredAnnouncements = filteredAnnouncements.filter((item) => (archived === 'true' ? item.status === 'archived' : item.status !== 'archived'));
  }
  return filteredAnnouncements;
};

const recipients = {
  homeownerAssociations: [[
    { id: '60b41436-4659', name: 'SM Słoneczko' },
    { id: 'ae5e4eb5-4160', name: 'SM Tęcza' },
  ]],
  buildings: [
    { id: '21861464-273b', name: 'Odolańska 56' },
    { id: 'd32e2300-7aeb', name: 'Czereśniowa 1' },
  ],
  estates: [
    { id: '60b4e8a2-1436', name: 'Cztery Pory Roku' },
    { id: 'ae5e4eb5-3752-4160', name: 'Osiedle Zielony Zakątek' },
  ],
  tenants: [
    { id: 'b690eb90-e77c', name: 'Eustachy Motyka' },
    { id: '3712cd29-0a94', name: 'Andrzej Nowakowski' },
  ],
  employees: [
    { id: '10d911b2-2ebb', name: 'Aneta Nowak' },
    { id: '0fbb1ccc-4df6', name: 'Janusz Kowalski' },
    { id: '4df6abcd-123', name: 'Eugeniusz Krosny' }],
  roles: [
    { id: 'b973a928-23d7', name: 'lokator' },
    { id: 'd690a6e5-94b8', name: 'pracownik' },
    { id: 'f0705dac-a9a1', name: 'członek' },
    { id: '5300db0f-3472', name: 'admin' }],
};

const getRecipient = (group, params) => {
  if (params.search.length > 0) {
    return recipients[group].filter((item) => item.name.includes(params.search));
  }
  return recipients[group];
};

export const announcementsMocks = [
  rest.get('/announcements/list', (req, res, ctx) => {
    const { query: params } = queryString.parseUrl(req.url.href);
    return res(
      ctx.status(200),
      ctx.json({
        metadata: metadata(params.page),
        announcements: filterAnnouncements(params),
      }),
    );
  }),
  rest.get('/announcements/:id/show', (req, res, ctx) => {
    const { id } = req.params;
    return res(
      ctx.status(200),
      ctx.json({
        announcement: announcements.find((announcement) => announcement.id.toLocaleLowerCase() === id),
      }),
    );
  }),
  rest.get('/announcements/authors', (req, res, ctx) => res(
    ctx.status(200),
    ctx.json({
      authors,
    }),
  )),
  rest.get('/announcements/recipients/:group/list', (req, res, ctx) => {
    const { group } = req.params;
    const { query: params } = queryString.parseUrl(req.url.href);
    return res(
      ctx.status(200),
      ctx.json({ recipients: getRecipient(group, params) }),
    );
  }),
  rest.post('/announcements/add', (req, res, ctx) => res(
    ctx.status(200),
  )),
  rest.post('/announcements/:id/archive', (req, res, ctx) => res(
    ctx.status(200),
  )),
];
