import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import queryString from 'query-string';
import {
  ApiColumnsSettings, ApiPostChangeTicketData, ApiPostColumnsSettings,
  ApiPostTicket,
  ApiPostTicketComment,
  ApiTicketCategories,
  ApiTicketStatuses,
  ApiTicketResponse,
  ApiTicketsResponse,
  TicketCategory,
  TicketStatus,
  TicketColumnId,
  Tickets,
  TenantShowTicketData,
  EmployeeShowTicketData, FullTicketStatus,
} from './ticketsTypes';
import {
  translateApiToTickets,
  translateFullTicketStatus,
  translateSingleTicket,
  translateTicketStatus,
} from './ticketsTranslator';
import {
  ApiBaseQuery, ApiTagTypes,
} from '../../baseQuery';
import { ApiPostTask } from '../tasks/tasksTypes';

export const ticketsQueries = (builder: EndpointBuilder<ApiBaseQuery, ApiTagTypes, 'api'>) => ({
  getTickets: builder.query<Tickets, Record<string, string> | void>({
    query: (params) => {
      const stringifiedParams = params && queryString.stringify(params, { arrayFormat: 'bracket' });
      return `tickets/list?${stringifiedParams}`;
    },
    transformResponse(baseQueryReturnValue: ApiTicketsResponse): Promise<Tickets> | Tickets {
      return translateApiToTickets(baseQueryReturnValue);
    },
    providesTags: () => ['Tickets'],
  }),
  getTicket: builder.query<TenantShowTicketData | EmployeeShowTicketData, string | undefined>({
    query: (ticketId) => `tickets/${ticketId}/show`,
    transformResponse(baseQueryReturnValue: ApiTicketResponse):
    Promise<TenantShowTicketData | EmployeeShowTicketData> | TenantShowTicketData | EmployeeShowTicketData {
      return translateSingleTicket(baseQueryReturnValue);
    },
    providesTags: () => ['Ticket'],
  }),
  postTicket: builder.mutation<any, ApiPostTicket>({
    query: ({ ticket }) => ({
      url: '/tickets/add',
      method: 'POST',
      body: ticket,
    }),
    invalidatesTags: () => ['Tickets'],
  }),
  postTicketComment: builder.mutation<any, ApiPostTicketComment>({
    query: ({ id, payload }) => ({
      url: `/tickets/${id}/add_comment`,
      method: 'POST',
      body: payload,
    }),
    invalidatesTags: () => ['Ticket', 'Tickets'],
  }),
  getSelectedTicketsColumns: builder.query<TicketColumnId[], void>({
    query: () => '/tickets/list/columns_settings',
    transformResponse(baseQueryReturnValue: ApiColumnsSettings): TicketColumnId[] {
      return baseQueryReturnValue.selected_columns;
    },
    providesTags: () => ['ColumnSettings'],
  }),
  postSelectedTicketsColumns: builder.mutation<any, ApiPostColumnsSettings>({
    query: (body) => ({
      url: '/tickets/list/change_columns_settings',
      method: 'POST',
      body,
    }),
    invalidatesTags: () => ['ColumnSettings', 'Tickets'],
  }),
  postTaskForTicket: builder.mutation<any, ApiPostTask>({
    query: ({ id, task }) => ({
      url: `/tickets/${id}/add_task`,
      method: 'POST',
      body: task,
    }),
    invalidatesTags: () => ['Tasks', 'Task', 'Ticket', 'ActivityLog'],
  }),
  postChangeTicket: builder.mutation<any, ApiPostChangeTicketData>({
    query: (tickets) => ({
      url: '/tickets/change',
      method: 'POST',
      body: tickets,
    }),
    invalidatesTags: () => ['Ticket', 'Tickets', 'ActivityLog'],
  }),
  getTicketCategories: builder.query<TicketCategory[], void>({
    query: () => '/ticket_categories/list',
    transformResponse(baseQueryReturnValue: ApiTicketCategories) {
      return baseQueryReturnValue.ticket_categories;
    },
    providesTags: ['TicketsCategories'],
  }),
  postTicketCategory: builder.mutation<any, { name: string }>({
    query: (body) => ({
      url: '/ticket_categories/add',
      method: 'POST',
      body,
    }),
    invalidatesTags: ['TicketsCategories'],
  }),
  patchTicketCategory: builder.mutation<any, { name: string, id: string }>({
    query: ({ id, name }) => ({
      url: `/ticket_categories/${id}/change`,
      method: 'PATCH',
      body: { name },
    }),
    invalidatesTags: ['TicketsCategories'],
  }),
  deleteTicketCategory: builder.mutation<any, { id: string }>({
    query: ({ id }) => ({
      url: `/ticket_categories/${id}/remove`,
      method: 'DELETE',
    }),
    invalidatesTags: ['TicketsCategories'],
  }),
  getTicketStatuses: builder.query<TicketStatus[], void>({
    query: () => 'tickets/statuses/ticket/list',
    transformResponse(baseQueryReturnValue: ApiTicketStatuses) {
      return translateTicketStatus(baseQueryReturnValue).statuses;
    },
    providesTags: ['TicketsStatuses'],
  }),
  getFullTicketStatuses: builder.query<FullTicketStatus[], void>({
    query: () => 'tickets/statuses/ticket/list',
    transformResponse(baseQueryReturnValue: ApiTicketStatuses) {
      return translateFullTicketStatus(baseQueryReturnValue);
    },
    providesTags: ['TicketsStatuses'],
  }),
  putStatus: builder.mutation<any, any>({
    query: ({ id, ...body }) => ({
      url: `tickets/statuses/${id}/change`,
      method: 'PUT',
      body,
    }),
    invalidatesTags: ['TicketsStatuses', 'TasksStatuses', 'DocumentsStatuses'],
  }),
});
