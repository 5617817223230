import * as React from 'react';
import { useState, useMemo, useCallback } from 'react';
import classNames from 'classnames';
import { useGetTicketStatusesQuery, usePostChangeTicketMutation } from '../../../../../api/appApi';
import { useAppDispatch } from '../../../../../hooks/reduxHooks';
import { resetSelectedTicketIds } from '../../../../../store/appSlice';
import useMessages from '../../../../../hooks/useMessages';
import StatusIcon from '../../../../../icons/bell.svg';
import Dialog from '../../../../../components/Dialog/Dialog';
import { InputLabel } from '../../../../../components/FormInputs/InputLabel';
import { Select } from '../../../../../components/FormInputs/inputs/FormSelect';
import capitalize from '../../../../../../utils/formatters';

const TicketChangeStatusAction = ({ selectedTicketsIds }: { selectedTicketsIds: string[] }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState('select.placeholder');
  const { data: ticketStatuses } = useGetTicketStatusesQuery();
  const [postChangeTicket] = usePostChangeTicketMutation();
  const getMessage = useMessages();

  const statusOptionsValues = useMemo(() => ticketStatuses?.map(({ id, name }) => ({
    value: id,
    optionLabel: capitalize(name),
  })) || [], [ticketStatuses]);
  const dispatch = useAppDispatch();

  const handleClick = () => {
    if (selectedTicketsIds.length > 0) setIsPopupOpen(true);
  };

  const changeStatus = (value: string) => {
    setSelectedStatus(value);
  };

  const onAccept = useCallback(() => {
    const tickets: {
      [key: string]: {
        status_id: string
      }
    } = {};
    function populatePayload(id: string) {
      tickets[id] = { status_id: selectedStatus };
    }
    selectedTicketsIds.forEach((item) => populatePayload(item));
    postChangeTicket({ tickets });
  }, [selectedStatus]);

  const onClose = () => {
    setSelectedStatus('select.placeholder');
    setIsPopupOpen(false);
    dispatch(resetSelectedTicketIds());
  };

  const dynamicStyles = (selectedTicketsIds.length > 0) ? 'border-typography-dark hover:border-hover hover:cursor-pointer hover:text-hover hover:bg-light-200' : 'text-stroke-grey-300 border-stroke-grey-300 hover:cursor-default';

  return (
    <div title={getMessage('tickets.bulkAction.changeStatus')}>
      <StatusIcon
        role="button"
        className={classNames(dynamicStyles, 'border rounded text-typography-dark p-2 mr-1')}
        onClick={handleClick}
        tabIndex={(selectedTicketsIds.length > 0) ? 0 : undefined}
        onKeyDown={(e) => e.key === 'Enter' && setIsPopupOpen(true)}
        aria-label={getMessage('tickets.bulkAction.changeStatus')}
      />
      {isPopupOpen
        && (
        <Dialog
          isOpen={isPopupOpen}
          acceptButtonText={getMessage('confirm')}
          onClose={onClose}
          closeButtonText={getMessage('cancel')}
          onAccept={onAccept}
        >
          <div className="mb-10">
            <InputLabel
              label={getMessage('tickets.ticket.changeTicketsStatus')}
            />
            <Select
              selectOptions={statusOptionsValues}
              onChange={(value) => changeStatus(value)}
              value={selectedStatus}
              placeholder={getMessage('choose')}
              autofocus
            />
          </div>
        </Dialog>
        ) }
    </div>
  );
};

export default TicketChangeStatusAction;
