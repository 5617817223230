import * as React from 'react';
import MobileInfoCard from '../../../../components/InfoCard/MobileInfoCard/MobileInfoCard';
import TaskInfoCard from './TaskInfoCard';
import { FullTask } from '../../../../api/modules/tasks/tasksTypes';

interface Props {
  title?: string,
  task: FullTask,
}

const TaskMobileInfoCard = ({ title, task }: Props) => (
  <MobileInfoCard title={title}>
    <TaskInfoCard task={task} />
  </MobileInfoCard>
);

export default TaskMobileInfoCard;
