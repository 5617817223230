import {
  ApiDocumentsResponse,
  ApiDocumentStatuses,
  DocumentFormData,
  DocumentsResponse,
  DocumentStatus,
  FullDocumentStatus,
  ApiFullDocument,
  FullDocument,
  ApiDocumentAttachment,
  ApiDocumentComment,
  ApiDocumentStatus,
  Attachment,
  DocumentComment,
  Journals,
} from './documentsTypes';
import { CommentFormData } from '../../../components/CommentsList/CommentForm/CommentForm';
import { translateTask } from '../tasks/tasksTranslator';

export const translateDocuments = (apiDocuments: ApiDocumentsResponse): DocumentsResponse => ({
  metadata: apiDocuments.metadata,
  documents: apiDocuments.documents.map((apiDoc) => ({
    category: apiDoc.category,
    creationDate: apiDoc.created_at,
    creator: apiDoc.creator,
    description: apiDoc.description,
    documentNumber: apiDoc.document_number,
    id: apiDoc.id,
    journal: apiDoc.journal,
    realizer: apiDoc.realizer,
    receiver: apiDoc.receiver,
    sender: apiDoc.sender,
    status: apiDoc.status,
    tenant: apiDoc.tenant,
    title: apiDoc.title,
    revoked: apiDoc.revoked,
  })),
});

export const translateAttachment = (apiAttachment: ApiDocumentAttachment): Attachment => ({
  description: apiAttachment.description,
  filename: apiAttachment.filename,
  path: apiAttachment.path,
  contentType: apiAttachment.content_type,
});

export const translateComment = (apiComment: ApiDocumentComment): DocumentComment => ({
  author: apiComment.author,
  content: apiComment.content,
  createdAt: apiComment.created_at,
  attachments: apiComment.attachments.map((apiAttachment) => translateAttachment(apiAttachment)),
});

export const translateDocumentStatus = ({ statuses }: ApiDocumentStatuses): DocumentStatus[] => statuses.map((status) => ({
  id: status.id,
  name: status.external_name,
}));

export const translateFullDocumentStatus = (apiStatus: ApiDocumentStatus): FullDocumentStatus => ({
  id: apiStatus.id,
  objectType: 'document',
  name: apiStatus.name,
  active: apiStatus.active,
  internalName: apiStatus.internal_name,
  externalName: apiStatus.external_name,
  description: apiStatus.description,
});

// eslint-disable-next-line max-len
export const translateFullDocumentStatuses = ({ statuses }: ApiDocumentStatuses): FullDocumentStatus[] => statuses.map((status) => translateFullDocumentStatus(status));

export const translateFullDocument = ({ document: apiDocument }: ApiFullDocument): FullDocument => ({
  id: apiDocument.id,
  documentNumber: apiDocument.document_number,
  title: apiDocument.title,
  sender: apiDocument.sender,
  receiver: apiDocument.receiver,
  description: apiDocument.description,
  creationDate: apiDocument.created_at,
  journal: apiDocument.journal,
  status: translateFullDocumentStatus(apiDocument.status),
  category: apiDocument.category,
  creator: {
    id: apiDocument.creator?.id,
    name: apiDocument.creator?.full_name,
  },
  realizer: {
    id: apiDocument.realizer?.id,
    name: apiDocument.realizer?.full_name,
  },
  tenant: apiDocument.tenant,
  attachments: apiDocument.attachments.map((apiAttachment) => translateAttachment(apiAttachment)),
  comments: apiDocument.comments.map((apiAttachment) => translateComment(apiAttachment)),
  tasks: (apiDocument.tasks || []).map((apiTask) => translateTask(apiTask)),
  revoked: apiDocument.revoked,
});

export const translateDocumentToFormData = (data: DocumentFormData): FormData => {
  const form = new FormData();
  form.append('document[title]', data.title);
  form.append('document[journal_id]', data.journalId);
  form.append('document[category_id]', data.categoryId);
  form.append('document[description]', data.description);
  if (data.number) form.append('document[document_number]', data.number);
  if (data.sender) form.append('document[sender]', data.sender);
  if (data.receiver) form.append('document[receiver]', data.receiver);
  if (data.tenantId) form.append('document[tenant_id]', data.tenantId);
  if (data.statusId) form.append('document[status_id]', data.statusId);
  if (data.assigneeId) form.append('document[realizer_id]', data.assigneeId);

  data.attachments.forEach((attachment, index) => {
    if (attachment.file?.length) {
      form.append(`document[attachments][${index}][file]`, attachment.file[0]);
      form.append(`document[attachments][${index}][description]`, attachment.description);
    }
  });
  return form;
};

export const translateDocumentCommentToFormData = (data: CommentFormData): FormData => {
  const form = new FormData();
  form.append('comment[content]', data.content);
  form.append('comment[public]', 'false');
  data.attachments.forEach((attachment, index) => {
    if (attachment.file?.length) {
      form.append(`comment[attachments][${index}][file]`, attachment.file[0]);
      form.append(`comment[attachments][${index}][description]`, attachment.description);
    }
  });
  return form;
};

export const translateJournals = ({ journals }: Journals) => journals.map((journal) => ({
  id: journal.id,
  name: journal.name,
}));
