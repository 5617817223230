import { rest } from 'msw';
import queryString from 'query-string';

export const resolutions = [
  {
    active: true,
    attachments: [{
      content_type: 'application/pdf',
      filename: 'attachment.pdf',
      path: '/fake_url/1',
    }, {
      content_type: 'application/pdf',
      filename: 'attachment2.pdf',
      path: '/fake_url/2',
    }],
    content_accepted_at: null,
    created_at: '2023-03-05T10:10:10Z',
    title: 'Donec efficitur ante ligula, tempus elementum velit interdum in.',
    id: 'e48dac81-6682-491a-b057-584a5fdc4515',
    text: 'Pellentesque ornare orci at quam consectetur euismod. Fusce scelerisque tortor vel augue dictum molestie. Integer non lacus at lorem dapibus posuere non in nisl. Nunc luctus, ante at convallis aliquet, massa velit pulvinar magna, condimentum eleifend diam leo eu eros.',
    vote_options: ['agree', 'disagree', 'abstain'],
    vote_value: null,
    voted_at: null,
    voting_deadline: '2023-05-25T02:00:01Z',
    status: 'ongoing',
    resolution_number: '1/2023',
  },
  {
    active: true,
    attachments: [],
    content_accepted_at: null,
    created_at: '2023-01-15T07:12:20Z',
    title: 'Etiam mattis aliquam sapien eget blandit.',
    id: '9c9f24e4-9396-4afc-a13f-60b198cdd2a9',
    text: 'Donec viverra erat quis auctor pellentesque. Morbi non dapibus sapien.',
    vote_options: ['agree', 'disagree'],
    vote_value: null,
    voted_at: null,
    voting_deadline: '2023-04-13T00:00:00Z',
    status: 'ongoing',
    resolution_number: '2/2023',
  },
  {
    active: true,
    attachments: [{
      content_type: 'application/pdf',
      filename: 'attachment.pdf',
      path: '/fake_url/3',
    }],
    content_accepted_at: '2023-02-12T13:17:13Z',
    created_at: '2023-01-02T09:21:43Z',
    title: 'Ut enim ad minim veniam',
    id: '398feb89-a62d-438e-ae63-fdab80573ff1',
    text: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
    vote_options: ['agree', 'disagree'],
    vote_value: null,
    voted_at: null,
    voting_deadline: '2023-03-29T00:00:00Z',
    status: 'ongoing',
    resolution_number: '12/2023',
  },
  {
    active: false,
    attachments: [],
    content_accepted_at: '2022-12-12T11:47:23Z',
    created_at: '2022-12-02T12:42:43Z',
    title: 'Etiam tempus nibh quis tellus pharetra, vitae auctor metus varius.',
    id: '0e589956-74c7-4a76-88db-14afd98e4109',
    text: 'Curabitur ac ligula convallis, porttitor dolor eu, egestas ligula. Mauris gravida odio eu faucibus ultricies. Nam id massa vel ante gravida pretium. Nulla mattis augue nec ipsum congue facilisis.',
    vote_options: ['agree', 'disagree', 'abstain'],
    vote_value: null,
    voted_at: '2022-12-12T22:47:23Z',
    voting_deadline: '2023-01-29T00:00:00Z',
    status: 'outvoted',
    resolution_number: '22/2023',
  },
  {
    active: false,
    attachments: [],
    content_accepted_at: '2022-06-12T11:25:09Z',
    created_at: '2022-06-01T11:54:49Z',
    title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    id: 'f551b74b-60a9-435b-8d7a-1d7bbb0f396e',
    text: 'Nunc vel purus hendrerit, auctor massa eget, vehicula nisl. Donec et sem fermentum, ullamcorper arcu ac, accumsan odio. Ut dictum, ante aliquam ultrices ultricies, libero magna tempus dolor, semper aliquam leo quam in mauris.',
    vote_options: ['agree', 'disagree', 'abstain'],
    vote_value: null,
    voted_at: '2022-06-11T13:25:09Z',
    voting_deadline: '2022-07-01T02:11:21Z',
    status: 'rejected',
    resolution_number: '32/2023',
  },
];

const filterResolutions = ({
  active, title_search, from_created_at: created_date_from, to_created_at: created_date_to, from_voting_deadline: voting_deadline_from,
  to_voting_deadline: voting_deadline_to, voted,
}) => {
  let filteredResolutions = resolutions;
  if (active) {
    filteredResolutions = filteredResolutions.filter(
      (resolution) => (active === 'true' ? resolution.status === 'ongoing' : resolution.status !== 'ongoing'),
    );
  }
  if (voted) {
    filteredResolutions = filteredResolutions.filter(
      (resolution) => (voted === 'true' ? resolution.voted_at !== null : resolution.voted_at === null),
    );
  }
  if (title_search) {
    filteredResolutions = filteredResolutions.filter(
      (resolution) => resolution.title.includes(title_search),
    );
  }
  if (created_date_from && created_date_to) {
    filteredResolutions = filteredResolutions.filter((resolution) => new Date(resolution.created_at) >= new Date(created_date_from))
      .filter((resolution) => new Date(resolution.created_at) <= new Date(created_date_to));
  }
  if (voting_deadline_from && voting_deadline_to) {
    filteredResolutions = filteredResolutions.filter((resolution) => new Date(resolution.voting_deadline) >= new Date(voting_deadline_from))
      .filter((resolution) => new Date(resolution.voting_deadline) <= new Date(voting_deadline_to));
  }
  return filteredResolutions;
};

const mockSeries = (page) => {
  switch (page) {
    case '1':
      return ['1', 2, 3, 'gap', 6];
    case '2':
      return [1, '2', 3, 4, 5, 6];
    case '3':
      return [1, 2, '3', 4, 5, 6];
    case '4':
      return [1, 2, 3, '4', 5, 6];
    case '5':
      return [1, 2, 3, 4, '5', 6];
    case '6':
      return [1, 'gap', 4, 5, '6'];
    default:
      return null;
  }
};

const metadata = (page) => ({
  count: 58,
  first_url: '/resolutions/list?page=1&items=10',
  from: page * 10 - 9,
  in: 10,
  items: 10,
  last: 6,
  last_url: '/resolutions/list?page=6&items=10',
  next: page === 6 ? null : page + 1,
  page,
  pages: 6,
  prev: page === 1 ? null : page - 1,
  series: mockSeries(page),
  to: page * 10,
});

const vote = (resolutionId, postData) => {
  resolutions.find(({ id }) => id === resolutionId).vote_value = postData.vote_value;
};
const acceptContent = (resolutionId) => {
  resolutions.find((resolution) => resolution.id === resolutionId).content_accepted_at = '2022-12-12T22:47:23Z';
};

export const resolutionsMocks = [
  rest.get('/resolutions/list', (req, res, ctx) => {
    const { query: params } = queryString.parseUrl(req.url.href);
    return res(
      ctx.status(200),
      ctx.json({
        metadata: metadata(params.page),
        resolutions: filterResolutions(params),
      }),
    );
  }),
  rest.get('/resolutions/:id/show', (req, res, ctx) => {
    const { id } = req.params;
    return res(
      ctx.status(200),
      ctx.json({
        resolution: resolutions.find((resolution) => resolution.id.toLocaleLowerCase() === id),
      }),
    );
  }),
  rest.post('/resolutions/:id/vote', (req, res, ctx) => {
    const { id } = req.params;
    req.json().then((postData) => (
      vote(id, postData)
    ));
    return res(
      ctx.status(200),
    );
  }),
  rest.post('/resolutions/:id/accept_content', (req, res, ctx) => {
    const { id } = req.params;
    acceptContent(id);
    return res(
      ctx.status(200),
    );
  }),
];
