import * as React from 'react';
import useMessages from '../../../hooks/useMessages';
import Layout from '../../../components/Layout/Layout';
import PageHeader from '../../../components/Layout/PageHeader';
import ContentWrapper from '../../../components/ContentWrapper/ContentWrapper';
import ParametersSidebar from '../ParametersSidebar';
import CategoriesTable from '../CategoryTable/CategoriesTable';
import { useGetTaskCategoriesQuery } from '../../../api/appApi';
import { usePermissions } from '../../../hooks/usePermissions';

const TasksParametersPage = () => {
  const getMessage = useMessages();
  const { checkModuleEnabled } = usePermissions();
  const { data: taskCategories } = useGetTaskCategoriesQuery();

  checkModuleEnabled('tickets');

  return (
    <Layout currentPage="parameters">
      <div className="flex flex-grow h-full">
        <ParametersSidebar selectedPage="tasksParameters" />
        <div className="flex flex-col flex-grow">
          <PageHeader currentPage="parameters" pageTitle={getMessage('navigation.parameters.tasks')} />
          <ContentWrapper>
            <CategoriesTable categoryType="task" categories={taskCategories || []} />
          </ContentWrapper>
        </div>
      </div>
    </Layout>
  );
};

export default TasksParametersPage;
