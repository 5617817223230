import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useMemo } from 'react';
import { ChangeUserFormData } from '../../../api/modules/user/userTypes';
import Card from '../../../components/Card/Card';
import { FormInput, FormMultiSelect } from '../../../components/FormInputs';
import useMessages from '../../../hooks/useMessages';
import {
  useGetAccessGroupsQuery,
  useGetCompanySettingsQuery,
  useGetRolesForUserQuery,
  useGetUserAccountQuery,
  usePutUserAccountMutation,
  useGetJournalsQuery,
  useGetFullUserAccountQuery,
} from '../../../api/appApi';
import { usePermissions } from '../../../hooks/usePermissions';
import Button from '../../../components/Button/Button';
import Chip from '../../../components/Chip/Chip';
import { SelectOption } from '../../../components/FormInputs/inputs/FormSelect';
import FormSearchableMultiselectDropdown from '../../../components/FormInputs/inputs/FormSearchableMultiselectDropdown';
import { translateUserFormToPutRequest } from '../../../api/modules/user/userTranslator';
import { FormNotice } from '../../../components/FormNotice/FormNotice';
import PermissionTreeDisplay from '../../../components/PermissionTree/PermissionTreeDisplay';
import { EMAIL_REGEX } from '../../../../utils/consts';

interface Props {
  userModifiable: boolean,
}

const UserForm = ({ userModifiable }: Props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const getMessage = useMessages();
  const meQuery = useGetFullUserAccountQuery();
  const { data: user } = useGetUserAccountQuery(id as string);
  const { data: roles } = useGetRolesForUserQuery();
  const { data: settings } = useGetCompanySettingsQuery();
  const [changeUserData, result] = usePutUserAccountMutation();
  const methods = useForm<ChangeUserFormData>({
    values: {
      id: id || '',
      email: user?.email || '',
      fullName: user?.fullName || '',
      login: user?.login || '',
      active: user?.active || false,
      phone: user?.phone || '',
      roleIds: user?.roles.map((role) => role.id) || [],
      homeownerAssociationIds: user?.accessGroups?.homeownerAssociations?.map((hoa) => hoa.id) || [],
      estatesIds: user?.accessGroups?.estates?.map((estate) => estate.id) || [],
      buildingsIds: user?.accessGroups?.buildings?.map((building) => building.id) || [],
      journalsIds: user?.accessGroups?.journals?.map((journal) => journal.id) || [],
    },
    mode: 'onTouched',
  });
  const { checkRoleType } = usePermissions();

  checkRoleType('admin');

  const onSubmit = (formData: ChangeUserFormData) => {
    changeUserData(translateUserFormToPutRequest(formData));
  };

  useEffect(() => {
    if (result.status === 'fulfilled') navigate('/uzytkownicy');
  }, [result]);

  const userIsMe = useMemo(() => !!meQuery.data && !!user && user.id === meQuery.data.id, [meQuery, user]);
  const hasAdminRoleType = useMemo(() => user?.roles?.find((role) => role.roleType === 'admin'), [user?.roles]);

  const rolesSelectOptions = useMemo(() => (roles || []).reduce((rolesToSelect, role) => {
    if (role.roleType === 'worker' || role.roleType === 'admin') {
      return rolesToSelect.concat([{
        value: role.id,
        optionLabel: role.name,
      }]);
    }
    return rolesToSelect;
  }, [] as SelectOption[]), [roles, userIsMe]);

  const hasWorkerRoleType = useMemo(() => user?.roles?.find((role) => role.roleType === 'worker'), [user?.roles]);

  const validateAdminHasRoleAdmin = (val: any[]) => {
    if (userIsMe && !!hasAdminRoleType) {
      return (val.length > 0 && val.includes(hasAdminRoleType.id)) || getMessage('users.user.roles.error.meAdmin');
    }
    return true;
  };

  return (
    <Card className="col-span-full">
      {/* @ts-ignore */}
      {result.isError && <FormNotice type="error" message={result.error.data?.errors || getMessage('form.error')} />}
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <section className="grid grid-cols-1 md:grid-cols-2 border-b pb-8">
            <div className="w-full md:w-2/3 grid-span-2 md:grid-span-1 flex flex-col">
              <div className="flex justify-start my-1 text-text-lg-semibold text-typography-black items-center mb-6">
                <span className="mr-2">{getMessage('users.user.status')}</span>
                <Chip chipStyle="gray">{user?.active ? getMessage('users.user.status.active') : getMessage('users.user.status.notActive')}</Chip>
              </div>
              <div />
              {userModifiable ? (
                <FormMultiSelect
                  className="mb-6"
                  label={getMessage('users.user.roles')}
                  id="roleIds"
                  selectOptions={rolesSelectOptions}
                  options={{
                    validate: {
                      required: (val: any[]) => val.length > 0 || getMessage('users.newUser.roles.error.required'),
                      meAdmin: (val: any[]) => validateAdminHasRoleAdmin(val),
                    },
                  }}
                />
              ) : (
                <div className="flex justify-start my-1 text-text-lg-semibold text-typography-black items-center mb-6">
                  <span className="mr-2">{getMessage('users.user.roles')}</span>
                  {(user?.roles || []).map((role) => (
                    <Chip
                      key={role.id}
                      chipStyle="gray"
                    >
                      {role.name}
                    </Chip>
                  ))}
                </div>
              )}
              <FormInput
                className="mt-2 w-full"
                inputClassName="w-full"
                id="login"
                type="text"
                label={getMessage('users.user.login')}
                disabled
              />
              <FormInput
                className="mt-2 w-full"
                inputClassName="w-full"
                id="fullName"
                type="text"
                label={getMessage('users.user.name')}
                options={{ required: getMessage('users.user.name.error.required') }}
                disabled={!userModifiable}
              />
              <FormInput
                className="mt-2 w-full"
                inputClassName="w-full"
                id="email"
                type="email"
                label={getMessage('users.user.email')}
                options={{
                  pattern: {
                    value: EMAIL_REGEX,
                    message: getMessage('form.email.error.pattern'),
                  },
                  required: getMessage('users.user.email.error.required'),
                }}
                disabled={!userModifiable}
              />
              <FormInput
                type="tel"
                className="mt-2 w-full"
                inputClassName="w-full"
                id="phone"
                label={getMessage('users.user.phone')}
                options={{
                  pattern: {
                    value: /^[0-9- +]+$/,
                    message: getMessage('form.phone.error.pattern'),
                  },
                }}
                disabled={!userModifiable}
              />
            </div>
            {hasWorkerRoleType && (
              <div>
                <div className="flex flex-wrap justify-between mb-2">
                  <p className="text-text-lg-semibold">{getMessage('user.additionalPermissions')}</p>
                  <Button styleType="ghost" className="!p-0" onClick={() => navigate(`/uprawnienia/${id}`)}>{getMessage('user.managePermissions')}</Button>
                </div>
                {user?.permissions && user.permissions.length > 0
                  ? <PermissionTreeDisplay permissions={user.permissions} />
                  : <p className="text-xs text-typography-dark">{getMessage('user.noAdditionalPermissions')}</p>}
              </div>
            )}
          </section>
          {userModifiable && (
            <>
              <p className="text-header-sm my-4">{getMessage('users.user.accessGroups')}</p>
              <section className="col-span-12">
                {settings?.homeownerAssociationsMode && (
                <FormSearchableMultiselectDropdown
                  label={getMessage('users.user.accessGroup.homeownerAssociations')}
                  id="homeownerAssociationIds"
                  query={useGetAccessGroupsQuery}
                  queryOptions={{ accessGroup: 'homeowner_associations' }}
                  defaultValue={user?.accessGroups?.homeownerAssociations}
                  ariaLabel={getMessage('form.ariaLabel.hoa')}
                />
                )}
                <FormSearchableMultiselectDropdown
                  label={getMessage('users.user.accessGroup.estates')}
                  id="estatesIds"
                  query={useGetAccessGroupsQuery}
                  queryOptions={{ accessGroup: 'estates' }}
                  defaultValue={user?.accessGroups?.estates}
                  ariaLabel={getMessage('form.ariaLabel.estates')}
                />
                <FormSearchableMultiselectDropdown
                  label={getMessage('users.user.accessGroup.buildings')}
                  id="buildingsIds"
                  query={useGetAccessGroupsQuery}
                  queryOptions={{ accessGroup: 'buildings' }}
                  defaultValue={user?.accessGroups?.buildings}
                  ariaLabel={getMessage('form.ariaLabel.buildings')}
                />
                <FormSearchableMultiselectDropdown
                  label={getMessage('users.user.accessGroup.journals')}
                  id="journalsIds"
                  query={useGetJournalsQuery}
                  defaultValue={user?.accessGroups?.journals}
                  ariaLabel="Wyszukaj dzienniki"
                />
              </section>
              <section className="mt-8 flex flex-wrap justify-between">
                <Button type="submit">{getMessage('save')}</Button>
              </section>
            </>
          )}
        </form>
      </FormProvider>
    </Card>
  );
};

export default UserForm;
