const { createThemes } = require('tw-colors');

module.exports = {
  content: [
    './app/views/**/*.html.erb',
    './app/helpers/**/*.rb',
    './app/assets/stylesheets/**/*.css',
    './app/javascript/**/*[.tsx|.ts|.js|.jsx]',
    './node_modules/tailwind-datepicker-react/dist/**/*.js',
  ],
  future: {
    hoverOnlyWhenSupported: true,
  },
  theme: {
    screens: {
      sm: '376px',
      md: '769px',
      lg: '1024px',
      mobile: { raw: '(max-width: 768px)' },
      tablet: { raw: '(min-width: 769px) and (max-width: 1023px)' },
      desktop: { raw: '(min-width: 1024px)' },
    },
    fontFamily: {
      sans: 'inter, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
    },

    extend: {
      colors: {
        background: {
          icon: '#C3C3D2',
          light: '#F2F3F5',
          white: '#FFFFFF',
        },
        typography: {
          black: '#2B2B31',
          dark: '#5C5C67',
          white: '#FFFFFF',
        },
        stroke: {
          'grey-300': '#CED2D6',
          'grey-200': '#DBDFE4',
          'grey-100': '#E4E7EB',
        },
        current: 'currentColor',
      },
      fontSize: {
        'header-lg': ['2.25rem', {
          lineHeight: '2.25rem',
          letterSpacing: '-3%',
          fontWeight: '600',
        }],
        'header-md': ['1.625rem', {
          lineHeight: '1.875rem',
          fontWeight: '600',
        }],
        'header-sm': ['1.25rem', {
          lineHeight: '1.5rem',
          fontWeight: '600',
        }],
        'header-xs': ['1.125rem', {
          lineHeight: '1.25rem',
          fontWeight: '600',
        }],
        'title-semibold': ['0.9375rem', {
          lineHeight: '1.25rem',
          fontWeight: '600',
        }],
        'title-medium': ['0.9375rem', {
          lineHeight: '1.25rem',
          fontWeight: '500',
        }],
        'text-lg-bold': ['0.875rem', {
          lineHeight: '1.375rem',
          fontWeight: '700',
        }],
        'text-lg-semibold': ['0.875rem', {
          lineHeight: '1.375rem',
          fontWeight: '600',
        }],
        'text-lg-medium': ['0.875rem', {
          lineHeight: '1.375rem',
          fontWeight: '500',
        }],
        'text-md-semibold': ['0.75rem', {
          lineHeight: '1.375rem',
          fontWeight: '600',
        }],
        'text-md-medium': ['0.75rem', {
          lineHeight: '1.375rem',
          fontWeight: '500',
        }],
        'text-sm-semibold': ['0.625rem', {
          lineHeight: '0.75rem',
          fontWeight: '600',
        }],
        'text-sm-medium': ['0.625rem', {
          lineHeight: '0.75rem',
          fontWeight: '500',
        }],
      },
      height: {
        '100svh': '100svh',
      },
      maxWidth: {
        '1/2': '50%',
        '2/3': '66%',
        '3/4': '75%',
        100: '100px',
      },
      minWidth: {
        90: '90px',
      },
      minHeight: {
        headerMobile: '50px',
        headerTablet: '80px',
        headerDesktop: '96px',
        headerDesktopWithSubheader: '128px',
      },
      transitionProperty: {
        height: 'height',
      },
      animation: {
        'spin-slow': 'spin 3s linear infinite',
      },
    },
  },
  plugins: [
    createThemes({
      violet: {
        main: '#705ACB',
        secondary: '#2B2B31',
        additional: '#BCBCD2',
        'light-300': '#E1DDEE',
        'light-200': '#F1EFFA',
        'light-100': '#FCFBFF',
        stroke: '#D5CEEA',
        hover: '#674FC3',
        interactions: {
          'success-text': '#167B6E',
          'success-bg': '#E8F3F2',
          'error-text': '#CF2C30',
          'error-bg': '#FBECEC',
          'input-error': '#CF2C30',
        },
      },
      red: {
        main: '#D43538',
        secondary: '#2B2B31',
        additional: '#BCBCD2',
        'light-300': '#EED8D8',
        'light-200': '#FBECEC',
        'light-100': '#FCF7F7',
        stroke: '#F7D9D9',
        hover: '#C22529',
        interactions: {
          'success-text': '#167B6E',
          'success-bg': '#E8F3F2',
          'error-text': '#663c00',
          'error-bg': '#fff4e5',
          'input-error': '#B75E1F',
        },
      },
      green: {
        main: '#178173',
        secondary: '#2B2B31',
        additional: '#BCBCD2',
        'light-300': '#deeedd',
        'light-200': '#ecfdeb',
        'light-100': '#f7fff7',
        stroke: '#E8F3F2',
        hover: '#197c6f',
        interactions: {
          'success-text': '#167B6E',
          'success-bg': '#E8F3F2',
          'error-text': '#CF2C30',
          'error-bg': '#FBECEC',
          'input-error': '#CF2C30',
        },
      },
    }),
  ],
};
