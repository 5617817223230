/* eslint-disable react/jsx-props-no-spreading */
import { RegisterOptions, useFormContext } from 'react-hook-form';
import classnames from 'classnames';
import * as React from 'react';
import { useMemo } from 'react';
import { InputError, InputLabel } from '../InputLabel';
import Dropdown from '../../Dropdown/Dropdown';

const inputDefaultStyles = `inline-block px-2 py-1 font-normal text-typography-black bg-white bg-clip-padding
border border-solid border-stroke-grey-300 rounded transition ease-in-out my-1 text-text-md-semibold
focus:text-typography-black focus:bg-white focus:border-blue-600 focus:outline-none`;

interface SelectOption {
  value: string,
  optionLabel: string,
}
interface FormSelectProps {
  id: string,
  label?: string,
  className?: string,
  inputClassName?: string,
  inline?: boolean,
  selectOptions: SelectOption[],
  showReset?: boolean,
  options?: RegisterOptions,
}

const FormMultiSelect = ({
  id, label, className, inputClassName, inline, selectOptions, showReset, options,
}: FormSelectProps) => {
  const {
    formState, register, setValue, watch,
  } = useFormContext();
  const values = watch(id) || [];

  const selectedOptionsLabels = useMemo(
    () => values.map((value: string) => selectOptions.find((option) => option.value === value)?.optionLabel).join(', '),
    [values, selectOptions],
  );

  return (
    <div className={classnames('flex-col items-start', className)}>
      {label && (
        <InputLabel
          label={label}
          inline={inline}
          showReset={showReset && values.length > 0}
          onReset={() => setValue(id, [])}
        />
      )}
      <Dropdown
        label={selectedOptionsLabels || ''}
        labelClassName={classnames(inputDefaultStyles, inputClassName, 'w-full inline-flex justify-between', { 'border-500 border-2': formState.errors[id] })}
        listClassName="bg-white rounded w-full border p-2"
        ariaLabel={label}
      >
        {selectOptions.map((option) => (
          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events
          <label
            key={`${id}-${option.value}`}
            htmlFor={`${id}-${option.value}`}
            className="text-md-semibold p-1 rounded hover:bg-light-200 hover:cursor-pointer hover:text-hover block flex-grow-0"
            onClick={(event) => event?.stopPropagation()}
          >
            <input
              type="checkbox"
              {...register(id, options)}
              value={option.value}
              id={`${id}-${option.value}`}
              className="mr-2"
            />
            {option.optionLabel}
          </label>
        ))}
      </Dropdown>
      {formState.errors[id]?.message && <InputError message={formState.errors[id]?.message as string} />}
    </div>
  );
};

export default FormMultiSelect;
