/* eslint-disable react/jsx-props-no-spreading */

import { RegisterOptions, useFormContext } from 'react-hook-form';
import classnames from 'classnames';
import { ErrorMessage } from '@hookform/error-message';
import * as React from 'react';
import useMessages from '../../../hooks/useMessages';
import { InputError, InputLabel } from '../InputLabel';
import PaperClipIcon from '../../../icons/paper-clip.svg';

interface FormFileInputProps {
  id: string,
  className?: string,
  inputClassName?: string,
  disabled?: boolean,
  options?: RegisterOptions,
  multiple?: boolean,
  labelText?: string,
  withIcon?: boolean,
  labelClassName?: string,
}

const FormFileInput = ({
  id, className, inputClassName, disabled, options, multiple, labelText, withIcon, labelClassName,
}: React.PropsWithChildren<FormFileInputProps>) => {
  const { register, formState } = useFormContext();
  const getMessage = useMessages();

  const isFileSizeLessThan5MB = (fileList: FileList) => {
    if (fileList === null) {
      return true;
    }
    if (fileList.length > 0 && fileList[0]?.size >= 5000000) return getMessage('tickets.ticket.error.attachment.biggerThan5mb');
    return true;
  };

  const validateFileType = (fileList: FileList) => {
    if (fileList === null) {
      return true;
    }
    const validFileTypes = ['image/png', 'image/jpeg', 'application/pdf'];
    if (fileList.length === 0 || validFileTypes.includes(fileList[0]?.type)) {
      return true;
    }
    return getMessage('tickets.ticket.error.attachment.wrongFileType');
  };

  return (
    <div className={className}>
      {withIcon ? (
        <InputLabel id={id}>
          <div className={classnames(labelClassName, 'flex text-typography-dark text-text-lg-medium my-3 items-center')}>
            <p>{labelText}</p>
            <PaperClipIcon className="mx-2 text-transparent" />
          </div>
        </InputLabel>
      ) : (
        <InputLabel id={id} label={labelText} className={labelClassName} />
      )}
      <input
        className={classnames(
          'mt-2 border-none text-xs',
          inputClassName,
          { 'bg-gray-100': disabled, 'border-interactions-input-error border-2': formState.errors[id] },
        )}
        type="file"
        id={id}
        disabled={disabled}
        multiple={multiple}
        accept="image/png, image/jpeg, application/pdf"
        data-testid="file"
        {...register(id, {
          ...options,
          validate: {
            isFileSizeLessThan5MB,
            validateFileType,
          },
        })}
      />
      <ErrorMessage errors={formState.errors} name={id} render={({ message }) => <InputError message={message} />} />
    </div>
  );
};

export default FormFileInput;
