import * as React from 'react';
import { useMemo } from 'react';
import useMessages from '../../../hooks/useMessages';
import Filters from '../../../components/Filters/Filters';
import { FilterConfig } from '../../../components/FilterInput/FilterInput';
import useHomeownerAssociationsMode from '../../../hooks/useHomeownerAssociationsMode';

interface Props {
  tab: 'all' | 'active',
}

const SettlementsVenueFilters = ({ tab }:Props) => {
  const getMessage = useMessages();
  const hoaMode = useHomeownerAssociationsMode();

  const statusOptionValues = [
    {
      value: 'true',
      optionLabel: getMessage('settlements.venuesList.filters.status.resident'),
    },
    {
      value: 'false',
      optionLabel: getMessage('settlements.venuesList.filters.status.notResident'),
    },
  ];

  const membershipOptionValues = [
    {
      value: 'true',
      optionLabel: getMessage('settlements.venuesList.filters.membership.member'),
    },
    {
      value: 'false',
      optionLabel: getMessage('settlements.venuesList.filters.membership.notMember'),
    },
  ];

  const filterFields: FilterConfig[] = useMemo(() => {
    const statusFilter: FilterConfig[] = [{
      type: 'select',
      id: 'residency_active',
      label: getMessage('settlements.venuesList.filters.status'),
      selectOptions: statusOptionValues,
    }];

    const fields: FilterConfig[] = [
      {
        type: 'text',
        id: 'tenant_search',
        label: getMessage('settlements.venuesList.filters.tenant'),
        ariaLabel: 'Szukaj po imieniu i nazwisku lub kodzie lokatora',
      },
      {
        type: 'text',
        id: 'venue_search',
        label: getMessage('settlements.venuesList.filters.venue'),
        ariaLabel: 'Szukaj po adresie budynku lub kodzie lokalu',

      },
      {
        type: 'dateRange',
        id: 'from_residency_date',
        toDateId: 'to_residency_date',
        label: getMessage('settlements.venuesList.filters.residencyDate'),
      },
    ];
    if (hoaMode) {
      fields.push({
        type: 'text',
        id: 'homeowner_association_search',
        label: getMessage('settlements.venuesList.filters.homeownerAssociation'),
        ariaLabel: 'Szukaj po nazwie wspólnoty',
      });
    } else {
      fields.push({
        type: 'select',
        id: 'membership',
        label: getMessage('settlements.venuesList.filters.membership'),
        selectOptions: membershipOptionValues,
      });
    }

    return tab === 'active' ? fields : statusFilter.concat(fields);
  }, [hoaMode, tab]);

  return (
    <Filters
      filterId="settlementsVenuesList"
      filterFields={filterFields}
    />
  );
};

export default SettlementsVenueFilters;
