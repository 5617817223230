import { useEffect } from 'react';
import { useGetCompanySettingsQuery, useGetFullUserAccountQuery } from '../api/appApi';

export const useColorPalette = () => {
  const { data: companySettings, isError: companySettingsNotFetched, error: companySettingsError } = useGetCompanySettingsQuery();
  const { data: userMe } = useGetFullUserAccountQuery();

  const changeColorPalette = () => {
    if (companySettings && userMe) {
      const selectedColor = userMe.colorPalette || companySettings.colorPalette || 'red';
      document.body.classList.remove(...document.body.classList);
      document.body.classList.add(selectedColor);
      localStorage.setItem('colorPalette', selectedColor);
    }
  };

  useEffect(() => changeColorPalette(), [companySettings?.colorPalette, userMe?.colorPalette]);

  return [companySettingsNotFetched, companySettingsError];
};
