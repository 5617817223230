import * as React from 'react';
import { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useGetTicketsQuery } from '../../../../api/appApi';
import { EmployeeTicketsListItemData } from '../../../../api/modules/tickets/ticketsTypes';
import usePagination from '../../../../hooks/usePagination';
import PaginationControls from '../../../../components/Table/PaginationControls';
import useFilters from '../../../../hooks/useFilters';
import useSortParams from '../../../../hooks/useSortParams';
import { FilterValues } from '../../../../store/appSlice';
import { md } from '../../../../../utils/breakpoints';
import TicketsTableMobile from './TicketsTableMobile';
import TicketsTableDesktop from './TicketsTableDesktop';

interface TicketsPageProps {
  prefilter?: 'me' | 'none',
}

const EmployeeTicketsList = ({ prefilter }: TicketsPageProps) => {
  const [selectedPage, setSelectedPage] = usePagination('employeeTicketsTable');
  const { filterValues } = useFilters('employeeTicketsTable');
  const { sortParamsValues } = useSortParams('employeeTicketsTable');
  const filters: FilterValues = { ...filterValues };
  if (prefilter) filters.assigned = prefilter;

  useEffect(() => {
    setSelectedPage(1);
  }, [prefilter]);

  const { data } = useGetTicketsQuery({
    ...sortParamsValues,
    ...filters,
    page: selectedPage.toString(),
  }, { refetchOnMountOrArgChange: true });
  const isTabletOrDesktop = useMediaQuery({ query: `(min-width: ${md})` });

  const ticketsData = data?.tickets as EmployeeTicketsListItemData[] || [] as EmployeeTicketsListItemData[];

  return (
    <>
      {isTabletOrDesktop ? (
        <TicketsTableDesktop tickets={ticketsData} />
      ) : <TicketsTableMobile tickets={ticketsData} />}
      {data?.metadata && (
        <PaginationControls
          className="col-span-full"
          selectedPage={selectedPage}
          setSelectedPage={setSelectedPage}
          paginationMetadata={data.metadata}
        />
      )}
    </>
  );
};

export default EmployeeTicketsList;
