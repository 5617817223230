import * as React from 'react';

interface Props {
  children: React.ReactNode,
  className?: string,
}

const Card = ({ children, className = '' }: Props) => (
  <div className={`bg-background-white w-full p-5 md:p-8 rounded border border-stroke-grey-100 ${className}`}>
    {children}
  </div>
);

export const ArticleCard = ({ children, className = '' }: Props) => (
  <article className={`bg-background-white w-full p-5 md:p-8 rounded border border-stroke-grey-100 ${className}`}>
    {children}
  </article>
);

export default Card;
