import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import queryString from 'query-string';
import { ApiBaseQuery, ApiTagTypes } from '../../baseQuery';
import {
  ApiDocumentsResponse, ApiBMPaymentResponse,
  ApiMonthsSettlementsResponse,
  ApiPaymentsResponse,
  ApiSettlementResponse,
  ApiSettlementTitlesResponse,
  ApiYearsResponse,
  BalanceQuery,
  MonthBalanceQuery,
  MonthsTitle,
  Payment, BMPayment, PaymentRequest,
  SettlementTitle,
  SettlementTitleBalance,
  TitleBalanceQuery,
  YearsBalance,
  ApiSettlementVenuesResponse,
  SettlementsVenues,
  PaymentsHistory,
  ApiPaymentsHistoryResponse,
  PaymentsBalances,
} from './settlementsTypes';
import {
  translateApiPayments,
  translateMonthsTitles,
  translateBMPayment,
  translateSettlementTitles,
  translateTitleDocuments,
  translateYears,
  translateYearsBalance,
  translateVenues,
  translatePaymentHistory,
  translateVenuesPaymentsBalances,
} from './settlementsTranslator';

export const settlementsQueries = (builder: EndpointBuilder<ApiBaseQuery, ApiTagTypes, 'api'>) => ({
  getPayments: builder.query<Payment[], { venue: string, tenantId?: string }>({
    query: ({ venue, tenantId }) => `/settlements/venue/${venue}/list`.concat(tenantId ? `?tenant_id=${tenantId}` : ''),
    transformResponse(baseQueryReturnValue: ApiPaymentsResponse): Payment[] {
      return translateApiPayments(baseQueryReturnValue);
    },
    providesTags: () => ['SettlementsBalance', 'Payments'],
  }),
  getPaymentsHistory: builder.query<PaymentsHistory, Record<string, string>>({
    query: (params) => {
      const { venue, ...rest } = params;
      const stringifiedParams = queryString.stringify(rest, { arrayFormat: 'bracket' });
      return `/settlements/payments/venue/${venue}/list_history?${stringifiedParams}`;
    },
    transformResponse(baseQueryReturnValue: ApiPaymentsHistoryResponse): PaymentsHistory {
      return translatePaymentHistory(baseQueryReturnValue);
    },
    providesTags: () => ['PaymentsHistory'],
  }),
  getSettlementsBalance: builder.query<YearsBalance, BalanceQuery>({
    query: (params) => {
      const { venue, ...rest } = params;
      const stringifiedParams = queryString.stringify(rest, { arrayFormat: 'bracket' });
      return `/settlements/venue/${venue}/year_balance/list?${stringifiedParams}`;
    },
    transformResponse(baseQueryReturnValue: ApiSettlementResponse): Promise<YearsBalance> | YearsBalance {
      return translateYearsBalance(baseQueryReturnValue);
    },
    providesTags: () => ['SettlementsBalance'],
  }),
  getSettlementsYears: builder.query({
    query: ({ venue: venueId, tenantId }) => `/settlements/venue/${venueId}/years/list?tenant_id=${tenantId || ''}`,
    transformResponse(baseQueryReturnValue: ApiYearsResponse): string[] {
      return translateYears(baseQueryReturnValue);
    },
  }),
  getSettlementsTitles: builder.query({
    query: ({ venue, tenantId }) => `/settlements/venue/${venue}/titles/list?tenant_id=${tenantId || ''}`,
    transformResponse(baseQueryReturnValue: ApiSettlementTitlesResponse): SettlementTitle[] {
      return translateSettlementTitles(baseQueryReturnValue);
    },
  }),
  getMonthsSettlementsBalance: builder.query<MonthsTitle[], MonthBalanceQuery>({
    query: (params) => {
      const { venue, ...rest } = params;
      const stringifiedParams = queryString.stringify(rest, { arrayFormat: 'bracket' });
      return `/settlements/venue/${venue}/month_balance/list?${stringifiedParams}`;
    },
    transformResponse(baseQueryReturnValue: ApiMonthsSettlementsResponse): MonthsTitle[] {
      return translateMonthsTitles(baseQueryReturnValue);
    },
    providesTags: () => ['MonthSettlementsBalance'],
  }),
  getTitleSettlementsBalance: builder.query<SettlementTitleBalance, TitleBalanceQuery>({
    query: (params) => {
      const { venue, ...rest } = params;
      const stringifiedParams = queryString.stringify(rest, { arrayFormat: 'bracket' });
      return `/settlements/venue/${venue}/documents/list?${stringifiedParams}`;
    },
    transformResponse(baseQueryReturnValue: ApiDocumentsResponse): SettlementTitleBalance {
      return translateTitleDocuments(baseQueryReturnValue);
    },
    providesTags: () => ['SettlementsTitleBalance'],
  }),
  patchDocumentRead: builder.mutation<any, { documentId: string }>({
    query: (queryArg) => ({
      url: `/settlements/document/${queryArg.documentId}/read`,
      method: 'PATCH',
      body: {},
    }),
    invalidatesTags: ['SettlementsTitleBalance', 'ActivityLog'],
  }),
  postMakePayment: builder.mutation<BMPayment, PaymentRequest>({
    query: (paymentInfo) => ({
      url: '/settlements/payments/make',
      method: 'POST',
      body: paymentInfo,
    }),
    invalidatesTags: ['Payments', 'PaymentsHistory', 'ActivityLog'],
    transformResponse(baseQueryReturnValue: ApiBMPaymentResponse): BMPayment {
      return translateBMPayment(baseQueryReturnValue);
    },
  }),
  getBMPayment: builder.query<BMPayment, string>({
    query: (paymentId) => `/settlements/payments/${paymentId}/show`,
    transformResponse(baseQueryReturnValue: ApiBMPaymentResponse): BMPayment {
      return translateBMPayment(baseQueryReturnValue);
    },
    providesTags: ['Payments'],
  }),
  getPaymentsBalance: builder.query<PaymentsBalances, { venueId: string }>({
    query: ({ venueId }) => '/settlements/payments/balance'.concat(venueId ? `?venue_id=${venueId}` : ''),
    transformResponse(baseQueryReturnValue: PaymentsBalances) {
      return translateVenuesPaymentsBalances(baseQueryReturnValue);
    },
    providesTags: ['PaymentsBalances'],
  }),
  getSettlementsVenues: builder.query<SettlementsVenues, null | Record<string, string>>({
    query: (params) => {
      const stringifiedParams = params ? queryString.stringify(params, { arrayFormat: 'bracket' }) : '';
      return `/settlements/venues/list?${stringifiedParams}`;
    },
    transformResponse(baseQueryReturnValue: ApiSettlementVenuesResponse): SettlementsVenues {
      return translateVenues(baseQueryReturnValue);
    },
  }),
});
