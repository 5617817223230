import { rest } from 'msw';
import queryString from 'query-string';

let selectedColumns = [
  'task_number',
  'title',
  'status',
  'created_at',
  'last_comment_at',
  'venue_address',
  'homeowner_association_code',
  'venue_code',
  'homeowner_association_name',
  'category',
  'creator_external_id',
  'creator',
  'realizer_external_id',
  'realizer',
  'estimated_start_time',
  'started_at',
  'estimated_finish_time',
  'finished_at',
];

const taskCategories = [
  {
    id: '1',
    name: 'garbage',
  },
  {
    id: '2',
    name: 'malfunction',
  },
  {
    id: '3',
    name: 'complain',
  },
];
const taskStatuses = [
  {
    id: '1',
    external_name: 'open',
    name: 'open',
  },
  {
    id: '2',
    external_name: 'ongoing',
    name: 'custom_1',
  },
  {
    id: '3',
    external_name: 'completed',
    name: 'completed',
  },
];

const tasks = [
  {
    id: '3329d1cb-9818-4c73-97cb-378be369c996',
    task_number: 23,
    title: 'zada1',
    status: 'open',
    category: 'fixing',
    creator: 'Jane Doe',
    creator_external_id: null,
    realizer: null,
    realizer_external_id: null,
    venue_address: ' /',
    venue_code: null,
    homeowner_association_name: null,
    homeowner_association_code: null,
    created_at: '2023-06-26T13:57:08Z',
    last_comment_at: null,
    estimated_start_time: null,
    estimated_finish_time: null,
    started_at: null,
    finished_at: null,
  },
  {
    id: 'df9db1c4-ea12-4bc1-8723-7b1e8db63111',
    task_number: 22,
    title: 'zada2',
    status: 'open',
    category: 'cleaning',
    creator: 'Jane Doe',
    creator_external_id: null,
    realizer: null,
    realizer_external_id: null,
    venue_address: ' /',
    venue_code: null,
    homeowner_association_name: null,
    homeowner_association_code: null,
    created_at: '2023-06-26T13:56:58Z',
    last_comment_at: null,
    estimated_start_time: null,
    estimated_finish_time: null,
    started_at: null,
    finished_at: null,
  },
  {
    id: '08462bbf-9cb0-4c0f-8969-8cf4c8a3a4ee',
    task_number: 21,
    title: 'To jest testowe Zadanie ',
    status: 'completed',
    category: 'fixing',
    creator: 'Jane Doe',
    creator_external_id: null,
    realizer: 'Anna Nowak',
    realizer_external_id: 'prac1',
    venue_address: 'Odolańska 56/2',
    venue_code: '01-001-002',
    homeowner_association_name: null,
    homeowner_association_code: null,
    created_at: '2023-06-26T10:52:28Z',
    last_comment_at: null,
    estimated_start_time: '2023-06-26T00:00:00Z',
    estimated_finish_time: '2023-06-30T00:00:00Z',
    started_at: '2023-06-26T00:00:00Z',
    finished_at: '2023-06-30T00:00:00Z',
  },
  {
    id: 'b5adcb75-a4de-4b28-a3e8-e35e7d45e1ab',
    task_number: 20,
    title: 'kjhklhn',
    status: 'open',
    category: 'cleaning',
    creator: 'Jane Doe',
    creator_external_id: null,
    realizer: null,
    realizer_external_id: null,
    venue_address: 'Odolańska 56/1',
    venue_code: '01-001-001',
    homeowner_association_name: null,
    homeowner_association_code: null,
    created_at: '2023-06-23T13:18:14Z',
    last_comment_at: null,
    estimated_start_time: null,
    estimated_finish_time: null,
    started_at: null,
    finished_at: null,
  },
  {
    id: 'e39fca2a-c629-45b4-962b-949ec4e3e62c',
    task_number: 19,
    title: 'aaa',
    status: 'completed',
    category: 'cleaning',
    creator: 'Jane Doe',
    creator_external_id: null,
    realizer: null,
    realizer_external_id: null,
    venue_address: 'Odolańska 56/1',
    venue_code: '01-001-001',
    homeowner_association_name: null,
    homeowner_association_code: null,
    created_at: '2023-06-23T12:21:40Z',
    last_comment_at: null,
    estimated_start_time: '2023-06-06T00:00:00Z',
    estimated_finish_time: '2023-06-13T00:00:00Z',
    started_at: '2023-06-13T00:00:00Z',
    finished_at: null,
  },
  {
    id: '20fa5683-4e6c-4ab8-9ca6-023e9c4d1b19',
    task_number: 18,
    title: 'Clining',
    status: 'open',
    category: 'cleaning',
    creator: 'Jane Doe',
    creator_external_id: null,
    realizer: 'Anna Nowak',
    realizer_external_id: 'prac1',
    venue_address: 'Odolańska 56/2',
    venue_code: '01-001-002',
    homeowner_association_name: null,
    homeowner_association_code: null,
    created_at: '2023-06-23T12:14:16Z',
    last_comment_at: null,
    estimated_start_time: null,
    estimated_finish_time: null,
    started_at: null,
    finished_at: null,
    document: {
      id: '230a9e64-ad11',
      title: 'Voluptas commodi corporis perspiciatis.',
      document_number: 'dz2/1/2023',
      sender: 'Hilarion Gajda',
      receiver: 'Hanna Śliwa',
      description: 'Impedit hic rerum. Fuga est dolorum. Eligendi similique modi.',
      journal_id: '099e49ff',
      status_id: '2',
      category_id: '1',
      tenants_tenant_id: null,
      creator_id: '51ed4cf2',
      realizer_id: '51ed4cf2',
      created_at: '2023-12-15T19:09:01Z',
      revoked: false,
    },
  },
  {
    id: 'a9879039-18c1-461e-be26-e84c67d47963',
    task_number: 17,
    title: 'wyk',
    status: 'open',
    category: 'fixing',
    creator: 'Jane Doe',
    creator_external_id: null,
    realizer: null,
    realizer_external_id: null,
    venue_address: 'Odolańska 56/1',
    venue_code: '01-001-001',
    homeowner_association_name: null,
    homeowner_association_code: null,
    created_at: '2023-06-23T12:07:40Z',
    last_comment_at: null,
    estimated_start_time: null,
    estimated_finish_time: null,
    started_at: null,
    finished_at: null,
  },
  {
    id: '89f34df6-900a-4584-a3ec-78c331b9e619',
    task_number: 16,
    title: 'aaa',
    status: 'open',
    category: 'cleaning',
    creator: 'Jane Doe',
    creator_external_id: null,
    realizer: null,
    realizer_external_id: null,
    venue_address: 'Odolańska 56/1',
    venue_code: '01-001-001',
    homeowner_association_name: null,
    homeowner_association_code: null,
    created_at: '2023-06-23T12:06:58Z',
    last_comment_at: null,
    estimated_start_time: null,
    estimated_finish_time: null,
    started_at: null,
    finished_at: '2023-06-21T00:00:00Z',
  },
  {
    id: '5d0ea6ee-c12d-4bf3-a1f6-1a511dc29d32',
    task_number: 15,
    title: 'Tatarata',
    status: 'open',
    category: 'cleaning',
    creator: 'Jane Doe',
    creator_external_id: null,
    realizer: null,
    realizer_external_id: null,
    venue_address: 'Odolańska 56/1',
    venue_code: '01-001-001',
    homeowner_association_name: null,
    homeowner_association_code: null,
    created_at: '2023-06-23T12:03:59Z',
    last_comment_at: null,
    estimated_start_time: '2023-06-13T00:00:00Z',
    estimated_finish_time: '2023-06-08T00:00:00Z',
    started_at: '2023-06-26T00:00:00Z',
    finished_at: null,
  },
  {
    id: 'a7c3e6f7-20e9-42e2-b84c-f2caaa01d7e4',
    task_number: 14,
    title: 'Tatarata',
    status: 'open',
    category: 'cleaning',
    creator: 'Kreator',
    creator_external_id: null,
    realizer: null,
    realizer_external_id: null,
    venue_address: 'Odolańska 56/1',
    venue_code: '01-001-001',
    homeowner_association_name: null,
    homeowner_association_code: null,
    created_at: '2023-06-23T12:03:51Z',
    last_comment_at: null,
    estimated_start_time: '2023-06-13T00:00:00Z',
    estimated_finish_time: '2023-06-08T00:00:00Z',
    started_at: '2023-06-26T00:00:00Z',
    finished_at: null,
  },
];

let taskForShow = {
  id: 'a7c3e6f7-20e9-42e2-b84c-f2caaa01d7e4',
  description: 'OOOO',
  ticket: {
    id: 'b4e53836-2454-4f43-a6ab-3fd397a3c031',
  },
  task_number: 14,
  title: 'Tatarata',
  status: {
    id: '1',
    external_name: 'open',
  },
  category: {
    id: '3',
    name: 'complain',
  },
  created_at: '2023-06-23T12:03:51',
  last_comment_at: null,
  estimated_start_time: '2023-06-08T00:00:00Z',
  estimated_finish_time: '2023-06-13T00:00:00Z',
  started_at: '2023-06-26T00:00:00Z',
  finished_at: null,
  creator: 'Jane Doe',
  creator_external_id: null,
  realizer: null,
  realizer_external_id: null,
  venue_address: 'Odolańska 56/1',
  venue_code: '01-001-001',
  homeowner_association_name: null,
  homeowner_association_code: null,
  attachments: [
    {
      description: '',
      filename: 'file.jpg',
      content_type: 'image/jpeg',
      path: '/rails/active_storage/blobs/redirect/file.jpg',
    },
  ],
  comments: [
    {
      content: 'Officia fugit incidunt voluptas.',
      author: 'John Locke',
      created_at: '2023-07-07T07:53:01',
      attachments: [],
    },
    {
      content: 'Eligendi et consequatur id.',
      author: 'Jack Shepard',
      created_at: '2023-07-10T07:13:01',
      attachments: [],
    },
  ],
};

const filterTasks = ({
  from_created_at, to_created_at, from_last_comment_at, to_last_comment_at, unassigned,
  number_title_search, venue_search, homeowner_association_search, creator_search,
  realizer_search, from_estimated_start_time, to_estimated_start_time, from_started_at,
  to_started_at, from_finished_at, to_finished_at, from_estimated_finish_time, to_estimated_finish_time, ...rest
}) => {
  let filteredTasks = tasks;
  if (number_title_search) {
    filteredTasks = filteredTasks.filter(
      (task) => task.task_number.toString().includes(number_title_search) || task.title.includes(number_title_search),
    );
  }
  if (rest['status_ids[]']) {
    const statuses = !Array.isArray(rest['status_ids[]']) ? [rest['status_ids[]']] : rest['status_ids[]'];
    filteredTasks = filteredTasks.filter((task) => statuses.find((statusId) => task.status === taskStatuses[statusId - 1].external_name));
  }
  if (from_created_at && to_created_at) {
    filteredTasks = filteredTasks.filter((task) => new Date(task.created_at) >= new Date(from_created_at))
      .filter((task) => new Date(task.created_at) <= new Date(to_created_at));
  }
  if (unassigned) {
    filteredTasks = filteredTasks.filter((task) => !task.realizer);
  }
  if (from_last_comment_at && to_last_comment_at) {
    filteredTasks = filteredTasks.filter((task) => new Date(task.last_comment_at) >= new Date(from_last_comment_at))
      .filter((task) => new Date(task.last_comment_at) <= new Date(to_last_comment_at));
  }
  if (venue_search) {
    filteredTasks = filteredTasks.filter(
      (task) => task.venue_address.includes(venue_search) || task.venue_code.includes(venue_search),
    );
  }
  if (homeowner_association_search) {
    filteredTasks = filteredTasks.filter(
      (task) => task.homeowner_association_name.includes(homeowner_association_search)
        || task.homeowner_association_code.includes(homeowner_association_search),
    );
  }
  if (creator_search) {
    filteredTasks = filteredTasks.filter(
      (task) => (task.creator || '').includes(creator_search) || (task.creator_external_id || '').includes(creator_search),
    );
  }
  if (realizer_search) {
    filteredTasks = filteredTasks.filter(
      (task) => (task.realizer || '').includes(realizer_search) || (task.realizer_external_id || '').includes(realizer_search),
    );
  }
  if (from_estimated_start_time && to_estimated_start_time) {
    filteredTasks = filteredTasks.filter((task) => new Date(task.estimated_start_time) >= new Date(from_estimated_start_time))
      .filter((task) => new Date(task.estimated_start_time) <= new Date(to_estimated_start_time));
  }
  if (from_started_at && to_started_at) {
    filteredTasks = filteredTasks.filter((task) => new Date(task.started_at) >= new Date(from_started_at))
      .filter((task) => new Date(task.started_at) <= new Date(to_started_at));
  }

  if (from_finished_at && to_finished_at) {
    filteredTasks = filteredTasks.filter((task) => new Date(task.finished_at) >= new Date(from_finished_at))
      .filter((task) => new Date(task.finished_at) <= new Date(to_finished_at));
  }
  if (from_estimated_finish_time && to_estimated_finish_time) {
    filteredTasks = filteredTasks.filter((task) => new Date(task.estimated_finish_time) >= new Date(from_estimated_finish_time))
      .filter((task) => new Date(task.estimated_finish_time) <= new Date(to_estimated_finish_time));
  }
  return filteredTasks;
};

const mockSeries = (page) => {
  switch (page) {
    case '1':
      return ['1', 2, 3, 'gap', 6];
    case '2':
      return [1, '2', 3, 4, 5, 6];
    case '3':
      return [1, 2, '3', 4, 5, 6];
    case '4':
      return [1, 2, 3, '4', 5, 6];
    case '5':
      return [1, 2, 3, 4, '5', 6];
    case '6':
      return [1, 'gap', 4, 5, '6'];
    default:
      return null;
  }
};

const metadata = (page) => ({
  count: 58,
  first_url: '/tasks/list?page=1&items=10',
  from: page * 10 - 9,
  in: 10,
  items: 10,
  last: 6,
  last_url: '/tasks/list?page=6&items=10',
  next: page === 6 ? null : page + 1,
  page,
  pages: 6,
  prev: page === 1 ? null : page - 1,
  series: mockSeries(page),
  to: page * 10,
});

const updateTasks = (tasksChanges) => {
  Object.keys(tasksChanges).forEach((id) => {
    const i = tasks.findIndex((task) => task.id === id);
    tasks[i] = {
      ...tasks[i],
      status: Object.keys(tasksChanges[id]).includes('status_id') ? 'mocked status' : tasks[i].status,
      realizer: Object.keys(tasksChanges[id]).includes('realizer_id') ? 'mocked realizer' : tasks[i].realizer,
      category: Object.keys(tasksChanges[id]).includes('category_id') ? 'mocked category' : tasks[i].category,
    };
  });
};

export const tasksMocks = [
  rest.get('/tasks/list', (req, res, ctx) => {
    const { query: params } = queryString.parseUrl(req.url.href);
    return res(
      ctx.status(200),
      ctx.json({
        metadata: metadata(params.page),
        tasks: filterTasks(params),
      }),
    );
  }),
  rest.get('/tasks/:id/show', (req, res, ctx) => res(
    ctx.status(200),
    ctx.json({
      task: taskForShow,
    }),
  )),
  rest.get('/task_categories/list', (req, res, ctx) => res(
    ctx.status(200),
    ctx.json({ task_categories: taskCategories }),
  )),
  rest.get('/tickets/statuses/task/list', (req, res, ctx) => res(
    ctx.status(200),
    ctx.json({ statuses: taskStatuses }),
  )),
  rest.get('/tasks/list/columns_settings', (req, res, ctx) => res(
    ctx.status(200),
    ctx.json({ selected_columns: selectedColumns }),
  )),

  rest.post('/tasks/list/change_columns_settings', (req, res, ctx) => {
    req.json().then((postData) => {
      selectedColumns = postData.columns;
    });
    return res(
      ctx.status(200),
    );
  }),
  rest.post('/tasks/change', (req, res, ctx) => {
    req.json().then((postData) => {
      updateTasks(postData.tasks);
      taskForShow = {
        ...taskForShow,
        status: {
          id: 0,
          external_name: 'mocked status',
        },
        ...postData.tasks['a7c3e6f7-20e9-42e2-b84c-f2caaa01d7e4'],
      };
    });
    return res(
      ctx.status(200),
    );
  }),
];
