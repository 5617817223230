import * as React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useEffect, useMemo } from 'react';
import useMessages from '../../../hooks/useMessages';
import { ColorPaletteFormData } from '../../../api/modules/user/userTypes';
import { useGetFullUserAccountQuery, usePatchColorPaletteMutation } from '../../../api/appApi';
import Card from '../../../components/Card/Card';
import { FormRadioButtons } from '../../../components/FormInputs';
import Chip from '../../../components/Chip/Chip';
import Button from '../../../components/Button/Button';
import { FormNotice } from '../../../components/FormNotice/FormNotice';

const SettingsForm = () => {
  const getMessage = useMessages();
  const { data: user } = useGetFullUserAccountQuery();
  const [changeColor, result] = usePatchColorPaletteMutation();

  const methods = useForm<ColorPaletteFormData>({
    values: {
      colorPalette: user?.colorPalette || 'default',
    },
  });

  useEffect(() => {
    methods.reset();
  }, [user]);

  const onSubmit = (data: ColorPaletteFormData) => {
    changeColor(data.colorPalette !== 'default' ? data.colorPalette : null);
  };

  const colorPaletteOptions = useMemo(() => [
    { value: 'default', element: <Chip className="w-full" chipStyle="ghost">{getMessage('company.settings.colorPalette.default')}</Chip> },
    { value: 'red', element: <Chip className="red w-full bg-light-200" chipStyle="primary">{getMessage('company.settings.colorPalette.red')}</Chip> },
    { value: 'green', element: <Chip className="green w-full bg-light-200" chipStyle="primary">{getMessage('company.settings.colorPalette.green')}</Chip> },
    { value: 'violet', element: <Chip className="violet w-full bg-light-200" chipStyle="primary">{getMessage('company.settings.colorPalette.violet')}</Chip> },
  ], []);

  return (
    <Card className="col-span-full">
      {result.status === 'fulfilled' && <FormNotice type="success" message={getMessage('form.success')} />}
      {/* @ts-ignore */}
      {result.isError && <FormNotice type="error" message={result.error?.data?.errors || getMessage('form.error')} />}
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="w-full text-title-semibold mb-4">{getMessage('profile.settings.color')}</div>
          <FormRadioButtons id="colorPalette" inputOptions={colorPaletteOptions} label="profile.settings.color" className="w-52" />
          <section className="mt-8 flex flex-wrap justify-end">
            <Button type="submit">{getMessage('save')}</Button>
          </section>
        </form>
      </FormProvider>
    </Card>
  );
};

export default SettingsForm;
