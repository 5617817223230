import * as React from 'react';
import { format } from 'date-fns';
import useMessages from '../../../../../hooks/useMessages';
import { VenueDetails } from '../../../../../api/modules/venues/venuesTypes';
import DetailItem from './DetailItem';
import useHomeownerAssociationsMode from '../../../../../hooks/useHomeownerAssociationsMode';

const DetailsItemsList = ({ data }: { data: VenueDetails }) => {
  const getMessage = useMessages();
  const hoaMode = useHomeownerAssociationsMode();

  if (!data) return null;

  const residencyFromString = format(new Date(data.residencyFrom), 'dd-MM-yyyy');
  const residencyUntilString = data.residencyUntil !== null ? ` - ${format(new Date(data.residencyUntil), 'dd-MM-yyyy')}` : '';
  const residencyDatesString = `${residencyFromString}${residencyUntilString}`;

  return (
    <>
      <DetailItem label={getMessage('meters.metersList.fullname')} value={data?.tenant} />
      <DetailItem label={getMessage('meters.metersList.id')} value={data?.tenantId} />
      <DetailItem label={getMessage('meters.metersList.building')} value={data?.building} />
      <DetailItem label={getMessage('meters.metersList.venueCode')} value={data?.code} />
      <DetailItem label={getMessage('meters.metersList.residencyDates')} value={residencyDatesString} />
      {hoaMode && <DetailItem label={getMessage('meters.metersList.homeownerAssociation')} value={data?.homeownerAssociation} />}
    </>
  );
};

export default DetailsItemsList;
