import * as React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import Card from '../Card/Card';
import SortArrows from './SortArrows';
import useSortParams from '../../hooks/useSortParams';
import { TableId } from '../../store/appSlice';

export interface TableProps {
  children: React.ReactNode,
  header?: React.ReactNode,
  className?: string
  tableClassName?: string,
  tabIndex?: number
}
const Table = ({
  children, className, tableClassName, header, tabIndex,
}: TableProps) => (
  <Card className={classnames(className)}>
    {header}
    <table className={classnames('w-full text-left flex-row', tableClassName)} tabIndex={tabIndex}>
      {children}
    </table>
  </Card>
);

interface TableHeaderProps {
  children: React.ReactNode,
  className?: string,
}
const TableHeader = ({ className, children }: TableHeaderProps) => (
  <thead className={`h-11 ${className}`}>
    {children}
  </thead>
);

export interface TableHeaderRowProps {
  children: React.ReactNode,
  className?: string,
}
const TableHeaderRow = ({
  children, className,
}: TableHeaderRowProps) => (
  <tr className={`border-b ${className}`}>
    {children}
  </tr>
);

export interface TableHeaderCellProps {
  children?: React.ReactNode,
  id?: string,
  className?: string,
  divClassName?: string,
  tableName?: TableId,
  colspan?: number,
}

const TableHeaderCell = ({
  children, id, className, divClassName, tableName, colspan,
}: TableHeaderCellProps) => {
  const { sortParamsValues, handleSaveSortParams } = useSortParams(tableName as TableId);
  const { order: sortOrder, sort: sortBy } = sortParamsValues;
  const sortable = !!id;
  const handleClick = () => {
    if (sortable) handleSaveSortParams(id);
  };

  return (
    <th
      key={id}
      className={classnames('h-10 pr-8 md:pr-3 last:pr-0 text-text-lg-semibold text-typography-dark', { 'hover:cursor-pointer': sortable }, className)}
      onClick={handleClick}
      colSpan={colspan}
      scope="col"
    >
      <div className={classnames('flex items-center', divClassName)}>
        {children}
        {sortable && <SortArrows id={id} sortBy={sortBy} sortOrder={sortOrder} />}
      </div>
    </th>
  );
};

export interface TableBodyProps {
  children?: React.ReactNode,
}
const TableBody = ({ children }: TableBodyProps) => (
  <tbody className="text-typography-dark">
    {children}
  </tbody>
);

export interface TableBodyRowProps {
  children: React.ReactNode,
  id?: string,
  className?: string,
  tabIndex?: number,
  clickable?: {
    href : string,
    linkState?: {},
    onClick?: () => void,
    ariaLabel?: string,
  }
}

const TableBodyRow = ({
  children, id, className, tabIndex, clickable,
}: TableBodyRowProps) => (
  <tr
    key={id}
    className={classnames('grid-col border-b last:border-b-0', className, { 'cursor-pointer hover:bg-gray-50 focus-within:border focus-within:border-hover relative': clickable })}
    tabIndex={tabIndex}
  >
    {children}
    {clickable && (
      <Link
        to={clickable.href}
        state={clickable.linkState}
        aria-label={clickable.ariaLabel}
        onClick={clickable.onClick}
        className='after:absolute after:content-[""] after:w-full after:h-full after:top-0 after:left-0'
      />
    )}
  </tr>
);

interface TableBodyCellProps {
  children?: React.ReactNode,
  id?: string,
  className?: string,
  colspan?: number,
  title?: string,
  cellPadding?: string | boolean
  bold?: boolean
  clickable?: {
    href : string,
    linkState?: {},
    onClick?: () => void,
    ariaLabel?: string,
  }
}
const TableBodyCell = ({
  children, id, className, colspan, title, cellPadding, bold, clickable,
}: TableBodyCellProps) => (
  // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
  <td
    colSpan={colspan}
    key={id}
    title={title}
    className={classnames(
      'h-10 md:h-16 mobile:truncate mobile:overflow-hidden pr-0 md:pr-8 mb-2 md:mb-0',
      { 'hover:underline hover:cursor-pointer hover:text-hover mobile:select-none relative': clickable },
      bold ? 'text-text-md-semibold md:text-text-lg-semibold' : 'text-text-md-medium md:text-text-lg-medium',
      cellPadding || 'last:pr-0',
      className,
    )}
  >
    {clickable ? (
      <Link
        to={clickable.href}
        state={clickable.linkState}
        onClick={clickable.onClick}
        className="inline-block py-2 hover:text-hover hover:underline after:absolute after:content-[''] after:w-full after:h-full after:top-0 after:left-0"
        aria-label={clickable.ariaLabel}
      >
        {children}
      </Link>
    ) : children }
  </td>
);

export default Table;
export {
  TableHeader, TableHeaderRow, TableHeaderCell, TableBody, TableBodyRow, TableBodyCell,
};
