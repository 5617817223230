import { useCallback, useEffect, useState } from 'react';

type ToggleArrayType = [(toggleId: string) => boolean, (toggleId: string) => void, string[]];

const useToggleArray = (defaultToggledIds: string[]): ToggleArrayType => {
  const [toggledIds, setToggledIds] = useState(defaultToggledIds);

  useEffect(() => {
    setToggledIds(defaultToggledIds);
  }, [JSON.stringify(defaultToggledIds)]);

  const toggle = useCallback((toggleId: string) => {
    if (toggledIds.includes(toggleId)) {
      setToggledIds((ids) => ids.filter((id) => id !== toggleId));
    } else {
      setToggledIds((ids) => ids.concat([toggleId]));
    }
  }, [toggledIds]);

  const isToggled = useCallback(
    (toggleId: string): boolean => toggledIds.includes(toggleId),
    [toggledIds],
  );

  return [isToggled, toggle, toggledIds];
};

export default useToggleArray;
