import * as React from 'react';
import { useState } from 'react';
import { TableBodyCell } from '../../../components/Table/Table';
import ResponsiveTable, {
  ResponsiveTableBody,
  ResponsiveTableBodyRow,
} from '../../../components/Table/ResponsiveTable';
import useMessages from '../../../hooks/useMessages';
import Button from '../../../components/Button/Button';
import AddCategoryPopup from './AddCategoryPopup';
import ChangeCategoryPopup from './ChangeCategoryPopup';
import DeleteCategoryPopup from './DeleteCategoryPopup';
import { TaskCategory } from '../../../api/modules/tasks/tasksTypes';
import { TicketCategory } from '../../../api/modules/tickets/ticketsTypes';
import { DocumentCategory } from '../../../api/modules/documents/documentsTypes';

interface Props {
  categoryType: 'ticket' | 'task' | 'document',
  categories: TaskCategory[] | TicketCategory[] | DocumentCategory[],
}

const CategoriesTable = ({ categoryType, categories }: Props) => {
  const getMessage = useMessages();
  const [addCategoryOpen, setAddCategoryOpen] = useState(false);
  const [changeCategoryPopupCategory, setChangeCategoryPopupCategory] = useState(null as { id: string, name: string } | null);
  const [deleteCategoryPopupId, setDeleteCategoryPopupId] = useState('');

  const TableHeader = (
    <div className="flex justify-between w-full items-center mb-2">
      <div className="text-header-sm">{getMessage('parameters.categories')}</div>
      <Button size="small" onClick={() => setAddCategoryOpen(true)}>
        {getMessage('parameters.categories.add')}
      </Button>
    </div>
  );

  return (
    <>
      <AddCategoryPopup isOpen={addCategoryOpen} onClose={() => setAddCategoryOpen(false)} categoryType={categoryType} />
      <ChangeCategoryPopup
        isOpen={!!changeCategoryPopupCategory}
        onClose={() => setChangeCategoryPopupCategory(null)}
        categoryType={categoryType}
        category={changeCategoryPopupCategory}
      />
      <DeleteCategoryPopup
        isOpen={!!deleteCategoryPopupId}
        onClose={() => setDeleteCategoryPopupId('')}
        categoryType={categoryType}
        categoryId={deleteCategoryPopupId}
      />
      <ResponsiveTable className="col-span-full" header={TableHeader}>
        <ResponsiveTableBody>
          {categories && categories.map((category) => (
            <ResponsiveTableBodyRow key={category.id} className="border-b last:border-b-0 border-separate font-medium">
              <TableBodyCell>
                {category.name}
              </TableBodyCell>
              <TableBodyCell className="text-end">
                <Button size="small" styleType="ghost" onClick={() => setChangeCategoryPopupCategory(category)}>
                  {getMessage('parameters.categories.change')}
                </Button>
                <Button size="small" styleType="ghost" onClick={() => setDeleteCategoryPopupId(category.id)}>
                  {getMessage('parameters.categories.remove')}
                </Button>
              </TableBodyCell>
            </ResponsiveTableBodyRow>
          ))}

        </ResponsiveTableBody>
      </ResponsiveTable>
    </>
  );
};

export default CategoriesTable;
