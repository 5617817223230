import { VenueDetails, VenueDetailsApiResponse } from './venuesTypes';

export const translateVenue = ({ venue }: VenueDetailsApiResponse): VenueDetails => ({
  building: venue.building,
  code: venue.code,
  homeownerAssociation: venue.homeowner_association,
  id: venue.id,
  residencyFrom: venue.residency_from,
  residencyUntil: venue.residency_until,
  tenant: venue.tenant,
  tenantId: venue.tenant_external_id,
});
