/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */
import * as React from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useGetMetersQuery } from '../../../api/appApi';
import Card from '../../../components/Card/Card';
import useMessages from '../../../hooks/useMessages';
import SpinnerItem from '../../../components/Spinner/SpinnerItem';
import Button from '../../../components/Button/Button';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { ApiMeterRequestParams } from '../../../api/modules/meters/metersTypes';

const MetersWidget = () => {
  const venueId = useAppSelector((state) => state.app.selectedVenueId);
  const queryParams: ApiMeterRequestParams = { measurement_required: true, venues_ids: venueId || undefined };
  const { data, isLoading, isSuccess } = useGetMetersQuery(queryParams);
  const getMessage = useMessages();
  const navigate = useNavigate();
  const intl = useIntl();

  return (
    <div className="w-full tablet:basis-1/2">
      <p className="text-header-xs mb-2">{getMessage('local.meters.readings')}</p>
      <Card className="md:!p-6">
        {isLoading && <SpinnerItem containerStyles="flex items-center justify-center" spinnerStyles="h-6" />}
        {isSuccess && (
          (!data || data?.meters?.length === 0) ? (<div className="text-xs">{getMessage('local.meters.noMeters')}</div>) : (
            <div className="text-typography-black text-title-semibold">
              {intl.formatMessage({ id: 'dashboard.widget.meters.measurementRequired' }, { metersNumber: data.meters.length })}
              <Button onClick={() => navigate('/liczniki')} className="!w-fit !p-0 hover:underline" styleType="ghost">
                {getMessage('dashboard.widget.meters.display')}
              </Button>
            </div>
          )
        )}
      </Card>
    </div>
  );
};

export default MetersWidget;
