import * as React from 'react';
import { useEffect, PropsWithChildren } from 'react';

import { useNavigate } from 'react-router-dom';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import Sidebar from '../Navigation/Sidebar';
import { useColorPalette } from '../../hooks/useColorPallete';

export type NavigationRoutes = 'dashboard' | 'local' | 'local.meters' | 'local.peopleCount' | 'profile' | 'tickets' | 'users' | 'permissions' | 'roles' |
'resolutions' | 'announcements' | 'payments' | 'settlements' | 'tasks' | 'parameters' | 'settings' | 'documents';

type PasswordChangeRequiredError = {
  password_change_required: boolean
};

interface Props {
  currentPage?: NavigationRoutes,
}

const Layout = ({ currentPage, children }: PropsWithChildren<Props>) => {
  const [compananySettingsNotFetched, companySettingsError] = useColorPalette();
  const navigate = useNavigate();

  useEffect(() => {
    if (compananySettingsNotFetched && 'password_change_required' in ((companySettingsError as FetchBaseQueryError).data as PasswordChangeRequiredError)) {
      navigate('/profil/zmien_haslo');
    }
  }, [compananySettingsNotFetched, companySettingsError]);

  return (
    <div className="flex flex-col w-screen lg:flex-row lg:w-auto">
      <Sidebar currentPage={currentPage} />
      <main className="flex flex-col flex-grow items-stretch bg-background-light h-100svh" id="main">{children}</main>
    </div>
  );
};

export default Layout;
