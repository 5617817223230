/* eslint-disable react/jsx-props-no-spreading */

import * as React from 'react';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { useGetResolutionVotingsListQuery, useGetResolutionQuery } from '../../../../api/appApi';
import useMessages from '../../../../hooks/useMessages';
import Chip from '../../../../components/Chip/Chip';
import PaginationControls from '../../../../components/Table/PaginationControls';
import usePagination from '../../../../hooks/usePagination';
import ResponsiveTable, {
  ResponsiveTableHeader, ResponsiveTableBodyRow, ResponsiveTableBody,
} from '../../../../components/Table/ResponsiveTable';
import { TableId, FilterValues } from '../../../../store/appSlice';
import { TableBodyCell } from '../../../../components/Table/Table';
import useFilters from '../../../../hooks/useFilters';
import VotingsListFiltersBar from './VotingsListFiltersBar';
import TableContentPlaceholder from '../../../../components/Table/TableContentPlaceholder';

const VotingsList = () => {
  const { id } = useParams();
  const { data: resolutionData } = useGetResolutionQuery(id, { skip: !id });
  const [selectedPage, setSelectedPage] = usePagination(`resolutionVotes${id}`);
  const getMessage = useMessages();
  const { filterValues } = useFilters(`resolutionVotes${id}`);
  const filters: FilterValues = { ...filterValues };

  const { data } = useGetResolutionVotingsListQuery({
    ...filters,
    id,
    page: selectedPage.toString(),
  });

  const columnLabels = [
    { label: 'resolutions.votings.column.tenantExternalId' },
    { label: 'resolutions.votings.column.tenantName' },
    { label: 'resolutions.votings.column.dataSource' },
    { label: 'resolutions.votings.column.votedAt' },
    { label: 'resolutions.votings.column.votedValue' },
  ];

  if (!data || !id) return null;
  const ids = data.votings.map(({ id }) => id) || [];

  return (
    <>
      {resolutionData
      && <VotingsListFiltersBar voteOptions={resolutionData?.voteOptions} pwiVotes={resolutionData?.pwiVotes} id={id} />}
      <ResponsiveTable className="row-span-1 row-start-3 col-span-full">
        {data?.votings && data?.votings.length === 0 ? <TableContentPlaceholder /> : (
          <>
            <ResponsiveTableHeader ids={ids} columnLabels={columnLabels} tableName={`resolutionVotes${id}` as TableId} />
            <ResponsiveTableBody>
              {data.votings.map(({
                tenantExternalId, tenantName, dataSource, votedAt, voteValue,
              }) => (
                <ResponsiveTableBodyRow key={id} className="border-b last:border-b-0">
                  <TableBodyCell>{tenantExternalId}</TableBodyCell>
                  <TableBodyCell>{tenantName}</TableBodyCell>
                  <TableBodyCell>{dataSource}</TableBodyCell>
                  <TableBodyCell>
                    {votedAt && (
                    <Chip chipStyle="ghost" className="w-fit">
                      {format(new Date(votedAt), 'dd-MM-yyyy')}
                    </Chip>
                    )}
                  </TableBodyCell>
                  <TableBodyCell>
                    {voteValue !== null ? getMessage(`resolutions.votings.${voteValue}`) : getMessage('resolutions.votings.noVoteValue')}
                  </TableBodyCell>
                </ResponsiveTableBodyRow>
              ))}
            </ResponsiveTableBody>
          </>
        )}
      </ResponsiveTable>
      {data?.metadata && (
        <PaginationControls
          className="col-span-full"
          selectedPage={selectedPage}
          setSelectedPage={setSelectedPage}
          paginationMetadata={data.metadata}
        />
      )}
    </>
  );
};

export default VotingsList;
