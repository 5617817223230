import * as React from 'react';
import { useMemo } from 'react';
import { TableBodyCell } from '../../../../components/Table/Table';
import useMessages from '../../../../hooks/useMessages';
import { BalanceQuery, MonthBalance, months } from '../../../../api/modules/settlements/settlementsTypes';
import ResponsiveTable, {
  ResponsiveTableBody,
  ResponsiveTableBodyRow,
  ResponsiveTableHeader,
} from '../../../../components/Table/ResponsiveTable';
import { useGetSettlementsBalanceQuery } from '../../../../api/appApi';
import { useAppSelector, useAppDispatch } from '../../../../hooks/reduxHooks';
import Spinner from '../../../../components/Spinner/Spinner';
import { selectVenue } from '../../../../store/appSlice';
import { usePermissions } from '../../../../hooks/usePermissions';

interface Props {
  year?: string,
  venue: {
    venueId: string,
    venueAddress: string
  },
  user?: any,
}

const columnLabels = [
  { label: 'settlements.balance.month' },
  { label: 'settlements.balance.debits', divClassName: 'md:justify-end' },
  { label: 'settlements.balance.credits', divClassName: 'md:justify-end' },
  { label: 'settlements.balance.balance', divClassName: 'md:justify-end md:pr-8' },
];

const YearsBalanceTable = ({
  year, venue, user,
}: Props) => {
  const getMessage = useMessages();
  const dispatch = useAppDispatch();
  const titleId = useAppSelector((state) => state.app.selectedSettlementsTitle);
  const { hasRoleType } = usePermissions();
  const selectedSettlementsTenantId = localStorage.getItem(`selectedSettlementsTenantId-${user?.id}`);
  const { venueId, venueAddress } = venue;

  const queryParams = useMemo(() => {
    const params: BalanceQuery = {
      venue: venueId,
      year: year || '',
    };
    if (titleId) {
      params.title_id = titleId;
    }
    if (selectedSettlementsTenantId && hasRoleType('worker')) {
      params.tenant_id = selectedSettlementsTenantId;
    }
    return params;
  }, [venueId, year, titleId, selectedSettlementsTenantId]);

  const { data, isLoading, isSuccess } = useGetSettlementsBalanceQuery(queryParams, { skip: !year || !venueId || (hasRoleType('worker') && !selectedSettlementsTenantId) });

  const ids = useMemo(() => ['openingBalance'].concat(months).concat(['sum']), []);

  if (!year) return null;

  const handleOnClick = () => {
    dispatch(selectVenue({
      selectedVenueId: venueId,
      selectedVenueName: venueAddress,
      userId: user?.id,
    }));
  };

  return (
    <ResponsiveTable className="col-span-full md:px-0">
      {isLoading && <Spinner className="m-auto" />}
      {isSuccess && data && (
        <>
          <ResponsiveTableHeader ids={ids} columnLabels={columnLabels} tableName="yearsBalanceTable" firstItemClassName="md:pl-8" />
          <ResponsiveTableBody>
            <ResponsiveTableBodyRow className="border-b md:bg-light-200 last:border-b-0 border-separate font-medium md:m-2 rounded">
              <TableBodyCell className="pl-8">{getMessage('settlements.balance.openingBalance')}</TableBodyCell>
              <TableBodyCell className="md:text-right">{data.openingBalance.debits}</TableBodyCell>
              <TableBodyCell className="md:text-right">{data.openingBalance.credits}</TableBodyCell>
              <TableBodyCell className="md:text-right" cellPadding="!md:pr-8">{data.openingBalance.balance}</TableBodyCell>
            </ResponsiveTableBodyRow>
            {data.months.map(({
              month, debits, credits, balance,
            }: MonthBalance, index: number) => (
              <ResponsiveTableBodyRow
                key={month}
                clickable={({
                  href: `/rozrachunki/analiza_salda/${year}/${index + 1}?venueId=${venueId}`,
                  linkState: { fromYears: true },
                  onClick: handleOnClick,
                  ariaLabel: `Przejdź do analizy miesiąca ${getMessage(`datepicker.month.${month}`)} ${year} roku ${titleId ? 'dla wybranego tytułu' : ''}`,
                })}
                className="border-b last:border-b-0 border-separate font-medium"
              >
                <TableBodyCell className="md:pl-8">{getMessage(`datepicker.month.${month}`)}</TableBodyCell>
                <TableBodyCell className="md:text-right">{debits}</TableBodyCell>
                <TableBodyCell className="md:text-right">{credits}</TableBodyCell>
                <TableBodyCell className="md:text-right" cellPadding="!md:pr-8">{balance}</TableBodyCell>
              </ResponsiveTableBodyRow>
            ))}
            <ResponsiveTableBodyRow className="border-b md:bg-light-200 last:border-b-0 border-separate font-medium">
              <TableBodyCell className="pl-8">{getMessage('settlements.balance.sum')}</TableBodyCell>
              <TableBodyCell className="md:text-right">{data.sum.debits}</TableBodyCell>
              <TableBodyCell className="md:text-right">{data.sum.credits}</TableBodyCell>
              <TableBodyCell className="md:text-right" cellPadding="!md:pr-8">{data.sum.balance}</TableBodyCell>
            </ResponsiveTableBodyRow>
          </ResponsiveTableBody>
        </>
      )}
    </ResponsiveTable>
  );
};

export default YearsBalanceTable;
