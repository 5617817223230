import * as React from 'react';
import useMessages from '../../../../hooks/useMessages';
import Filters from '../../../../components/Filters/Filters';
import {
  useGetTicketCategoriesQuery, useGetTicketStatusesQuery,
} from '../../../../api/appApi';
import capitalize from '../../../../../utils/formatters';

const TicketsFilters = () => {
  const getMessage = useMessages();
  const { data: ticketCategories } = useGetTicketCategoriesQuery();
  const { data: ticketStatuses } = useGetTicketStatusesQuery();

  const categoryOptionsValues = ticketCategories?.map(({ id, name }) => ({
    value: id,
    optionLabel: capitalize(name),
  }));

  const statusOptionsValues = ticketStatuses?.map(({ id, name }) => ({
    value: id,
    optionLabel: capitalize(name),
  }));

  return (

    <Filters
      filterId="ticketsTable"
      filterFields={[
        {
          type: 'text',
          id: 'number_title_search',
          label: getMessage('tickets.ticket.titleOrNumber'),
          ariaLabel: 'Szukaj po tytule lub numerze zgłoszenia',
        },
        {
          type: 'dateRange',
          id: 'from_date',
          toDateId: 'to_date',
          label: getMessage('tickets.ticket.date'),
        },
        {
          type: 'multiselect',
          id: 'status_ids',
          label: getMessage('tickets.ticket.status'),
          selectOptions: statusOptionsValues || [],
        },
        {
          type: 'multiselect',
          id: 'category_ids',
          label: getMessage('tickets.ticket.category'),
          selectOptions: categoryOptionsValues || [],
        },
      ]}
    />
  );
};

export default TicketsFilters;
