import * as React from 'react';
import { PropsWithChildren } from 'react';
import ExpandableCard from '../../../components/ExpandableCard/ExpandableCard';

interface Props extends PropsWithChildren {
  title?: string,
  ariaLabel?: string
}

const MobileInfoCard = ({ title, children, ariaLabel }: Props) => (
  <ExpandableCard
    title={title || ''}
    className="bg-light-200 border border-light-300 col-span-full lg:hidden md:px-3 rounded-none"
    contentClassName="col-span-full md:flex md:flex-wrap md:justify-between pb-2"
    ariaLabel={ariaLabel}
  >
    {children}
  </ExpandableCard>
);

export default MobileInfoCard;
