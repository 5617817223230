/* eslint-disable react/jsx-props-no-spreading */

import * as React from 'react';
import { useEffect, useState } from 'react';
import { useForm, FormProvider, useFieldArray } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Card from '../../../components/Card/Card';
import useMessages from '../../../hooks/useMessages';
import Button from '../../../components/Button/Button';
import {
  FormInput, FormTextarea, FormDatePicker, FormFileInput,
} from '../../../components/FormInputs';
import { useChangeAnnouncementMutation, useGetAnnouncementRecipientsQuery } from '../../../api/appApi';
import { translateChangeAnnouncementToApi } from '../../../api/modules/announcements/announcementsTranslator';
import { AnnouncementData, AnnouncementChangeInputs, AnnouncementAttachment } from '../../../api/modules/announcements/announcementsTypes';
import { usePermissions } from '../../../hooks/usePermissions';
import useHomeownerAssociationsMode from '../../../hooks/useHomeownerAssociationsMode';
import FileUploadPreview from '../../../components/AttachmentPreview/FileUploadPreview/FileUploadPreview';
import AttachmentPreview from '../../../components/AttachmentPreview/AttachmentPreview';
import FormSearchableMultiselectDropdown from '../../../components/FormInputs/inputs/FormSearchableMultiselectDropdown';
import { FormNotice } from '../../../components/FormNotice/FormNotice';

const AnnouncementChangeForm = ({ announcement, id }: { announcement: AnnouncementData, id: string }) => {
  const getMessage = useMessages();
  const hoaMode = useHomeownerAssociationsMode();
  const [changeAnnouncement, result] = useChangeAnnouncementMutation();
  const { checkPermission } = usePermissions();
  const navigate = useNavigate();
  const [oldAttachmentsToDisplay, setOldAttachmentsToDisplay] = useState<AnnouncementAttachment[]>([]);

  const {
    title, description, expiresAt, attachments: oldAttachments, recipients,
  } = announcement;

  const methods = useForm<AnnouncementChangeInputs>({
    defaultValues: {
      title,
      description,
      expiresAt: expiresAt || undefined,
      attachments: [{ description: '', file: undefined }],
      employees: recipients?.employees?.map(({ id }) => id) || [],
      homeownerAssociations: recipients?.homeownerAssociations?.map(({ id }) => id) || [],
      estates: recipients?.estates?.map(({ id }) => id) || [],
      buildings: recipients?.buildings?.map(({ id }) => id) || [],
      tenants: recipients?.tenants?.map(({ id }) => id) || [],
      roles: recipients?.roles?.map(({ id }) => id) || [],
    },
  });

  const { fields, append, remove } = useFieldArray({ name: 'attachments', control: methods.control });

  const attachmentsInputs = methods.watch('attachments');

  const allAttachmentsSelected = attachmentsInputs?.every((attachment) => attachment.file?.length);

  useEffect(() => {
    if (allAttachmentsSelected) {
      append({ file: undefined, description: '' });
    }
  }, [allAttachmentsSelected]);

  useEffect(() => {
    setOldAttachmentsToDisplay(oldAttachments);
  }, [oldAttachments]);

  useEffect(() => {
    if (result.status === 'fulfilled') navigate('/aktualnosci');
  }, [result.status]);

  const onSubmit = (data: AnnouncementChangeInputs) => {
    translateChangeAnnouncementToApi({
      ...data,
      oldAttachments: oldAttachmentsToDisplay || [],
      id,
    }).then((newData) => changeAnnouncement({ newData }));
  };

  checkPermission('announcements_notice_add');

  const removeAttachments = (indexToRemove: number) => {
    const updatedOldAttachmentsList = [...oldAttachmentsToDisplay].filter((_, index) => index !== indexToRemove);
    setOldAttachmentsToDisplay(updatedOldAttachmentsList);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} className="col-span-full">
        <Card className="grid grid-cols-12">
          { /* @ts-ignore */}
          {result.isError && <FormNotice noticeClassName="col-span-full" type="error" message={result.error.data?.errors || getMessage('form.error')} />}
          <section className="col-span-full md:col-span-6">
            <FormInput
              className="w-full"
              inputClassName="w-full"
              id="title"
              type="text"
              label={`${getMessage('announcements.notice.form.title')}*`}
              options={{
                required: getMessage('announcements.notice.form.error.title.required'),
                maxLength: { value: 150, message: getMessage('announcements.notice.form.error.title.maxLength') },
              }}
            />
            <FormTextarea
              className="mt-6 w-full"
              inputClassName="w-full"
              id="description"
              label={`${getMessage('announcements.notice.form.description')}*`}
              options={{ required: getMessage('announcements.notice.form.error.description.required') }}
            />
          </section>
          <section className="col-span-full">
            <div className="w-full mb-4">
              {oldAttachmentsToDisplay.length > 0 && (
                <>
                  <div className="flex text-typography-dark text-text-lg-medium mt-12 mb-3 items-center">
                    <p>Edytuj istniejące załączniki</p>
                  </div>
                  <ol className="flex flex-wrap gap-x-8 gap-y-2">
                    {oldAttachmentsToDisplay.map(({
                      filename, path, contentType,
                    }, index) => (
                      <div className="flex items-start" key={path}>
                        <AttachmentPreview key={path} filename={filename} path={path} contentType={contentType} />
                        <Button styleType="ghost" className="hover:underline !w-auto !px-0" onClick={() => removeAttachments(index)}>
                          {getMessage('remove')}
                        </Button>
                      </div>
                    ))}
                  </ol>
                </>
              )}
            </div>
            <div className="flex flex-wrap w-full mb-4">
              {fields.map(({ file, id }, index) => {
                const fileSelected = file && file.length > 0;
                return (
                  <div key={id} id={id} className="mb-4">
                    {fileSelected && (
                    <FileUploadPreview attachment={attachmentsInputs[index]} onDelete={() => remove(index)} />
                    )}
                    <FormFileInput
                      inputClassName={fileSelected ? 'hidden' : ''}
                      id={`attachments.${index}.file`}
                      labelText={getMessage('announcements.notice.edit.addAdditionalAttachments')}
                      labelClassName={fileSelected ? 'hidden' : ''}
                      withIcon
                    />
                  </div>
                );
              })}
            </div>
          </section>
          <FormDatePicker
            id="expiresAt"
            label={getMessage('announcements.notice.form.expirationDate')}
            className="col-span-full"
          />
        </Card>
        <p className="text-header-sm my-4">{getMessage('announcements.notice.form.receiversGroups')}</p>
        <Card className="grid">
          <section className="col-span-12">
            <FormSearchableMultiselectDropdown
              label={getMessage('announcements.notice.form.employees')}
              id="employees"
              query={useGetAnnouncementRecipientsQuery}
              queryOptions={{ group: 'employees' }}
              defaultValue={recipients?.employees}
              ariaLabel={getMessage('form.ariaLabel.employees')}
            />
            {hoaMode && (
              <FormSearchableMultiselectDropdown
                label={getMessage('announcements.notice.form.homeownerAssociations')}
                id="homeownerAssociations"
                query={useGetAnnouncementRecipientsQuery}
                queryOptions={{ group: 'homeowner_associations' }}
                defaultValue={recipients?.homeownerAssociations}
                ariaLabel={getMessage('form.ariaLabel.hoa')}
              />
            )}
            <FormSearchableMultiselectDropdown
              label={getMessage('announcements.notice.form.estates')}
              id="estates"
              query={useGetAnnouncementRecipientsQuery}
              queryOptions={{ group: 'estates' }}
              defaultValue={recipients?.estates}
              ariaLabel={getMessage('form.ariaLabel.estates')}
            />
            <FormSearchableMultiselectDropdown
              label={getMessage('announcements.notice.form.buildings')}
              id="buildings"
              query={useGetAnnouncementRecipientsQuery}
              queryOptions={{ group: 'buildings' }}
              defaultValue={recipients?.buildings}
              ariaLabel={getMessage('form.ariaLabel.buildings')}
            />
            <FormSearchableMultiselectDropdown
              label={getMessage('announcements.notice.form.tenants')}
              id="tenants"
              query={useGetAnnouncementRecipientsQuery}
              queryOptions={{ group: 'tenants' }}
              defaultValue={recipients?.tenants}
              ariaLabel={getMessage('form.ariaLabel.tenants')}
            />
            <FormSearchableMultiselectDropdown
              label={getMessage('announcements.notice.form.roles')}
              id="roles"
              query={useGetAnnouncementRecipientsQuery}
              queryOptions={{ group: 'roles' }}
              defaultValue={recipients?.roles}
              ariaLabel={getMessage('form.ariaLabel.roles')}
            />
          </section>
        </Card>
        <div className="grid grid-cols-3">
          <Button type="submit" className="mt-6 col-start-4">{getMessage('save')}</Button>
        </div>
      </form>
    </FormProvider>
  );
};

export default AnnouncementChangeForm;
