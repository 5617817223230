/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */
import * as React from 'react';
import { useState } from 'react';
import format from 'date-fns/format';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { useGetTicketStatusesQuery, useGetTicketsQuery } from '../../../api/appApi';
import Chip from '../../../components/Chip/Chip';
import TableContentPlaceholder from '../../../components/Table/TableContentPlaceholder';
import TypeBadge from '../../../components/TypeBadge/TypeBadge';
import TicketsIcon from '../../../icons/tickets.svg';
import useMessages from '../../../hooks/useMessages';
import capitalize from '../../../../utils/formatters';
import WidgetTabs from '../../../components/Tabs/WidgetTabs';
import SpinnerItem from '../../../components/Spinner/SpinnerItem';

const TicketsWidget = () => {
  const { data: ticketsStatuses } = useGetTicketStatusesQuery();
  const [activeTab, setActiveTab] = useState('all');
  const getMessage = useMessages();

  const queryParameters: Record<string, string> = {};
  if (activeTab === 'open' && ticketsStatuses) {
    queryParameters['status_ids[]'] = ticketsStatuses[0].id;
  }
  const { data, isSuccess, isLoading } = useGetTicketsQuery(queryParameters);
  const tickets = data ? data.tickets.slice(0, 3) : [];

  const tabElements = [{
    label: getMessage('tabs.tickets.all'),
    onClick: () => setActiveTab('all'),
    isActive: activeTab === 'all',
  },
  {
    label: getMessage('tabs.tickets.open'),
    onClick: () => setActiveTab('open'),
    isActive: activeTab === 'open',
  }];

  return (
    <div className="mt-6">
      <div className="col-span-12 flex justify-between">
        <p className="text-header-xs mb-2 inline-block">{getMessage('navigation.tickets')}</p>
        <WidgetTabs tabElements={tabElements} />
      </div>
      <div className="col-span-12 flex mobile:overflow-x-auto mobile:no-scrollbar md:justify-between gap-1">
        {isLoading && [...Array(3)].map((_, index) => (
          <SpinnerItem
            key={index}
            containerStyles="h-[198px] list-none p-6 bg-background-white border rounded border-stroke-grey-100 flex-shrink-0 mobile:w-[15rem] md:basis-1/3 flex items-center"
            spinnerStyles="m-auto"
          />
        ))}
        {isSuccess && ((tickets.length === 0 || !data)
          ? <TableContentPlaceholder />
          : tickets?.map(({
            id, reportedAt, title, status, ticketNumber,
          }) => (
            <li
              className="list-none p-5 bg-background-white border rounded border-stroke-grey-100
                flex-shrink-0 mobile:w-[15rem] md:basis-1/3 md:truncate
                hover:border-hover hover:cursor-pointer focus-within:border-hover relative"
              key={id}
            >
              <TypeBadge className="flex-none w-fit mb-5" badgeStyle={status === 'otwarte' ? 'red' : 'grey'} title={`status: ${status}`}>
                <TicketsIcon />
              </TypeBadge>
              <p className="text-typography-gray text-text-md-medium">
                {format(new Date(reportedAt), 'dd-MM-yyyy')}
              </p>
              <p className={classNames(
                'text-typography-black mb-2 truncate',
                status === 'completed' ? 'text-title-medium' : 'text-title-semibold',
              )}
              >
                {title}
              </p>
              <Chip chipStyle={status === 'zakończone' ? 'gray' : 'primary'} className="mt-4 mb-0 md:flex-none w-fit">
                {status && capitalize(status)}
              </Chip>
              <Link
                to={`/zgloszenia/${id}`}
                state={{ returnTo: '/' }}
                aria-label={`Przejdź do szczegółów zgłoszenia nr ${ticketNumber} ${title}`}
                className='after:absolute after:content-[""] after:h-full after:w-full after:left-0 after:top-0'
              />
            </li>
          )))}
      </div>
    </div>
  );
};

export default TicketsWidget;
