import * as React from 'react';
import { useMemo } from 'react';
import useMessages from '../../../../hooks/useMessages';
import Filters from '../../../../components/Filters/Filters';

const resolutionsStates = [
  'voted', 'notVoted',
] as const;

const TenantResolutionsFilters = () => {
  const getMessage = useMessages();

  const stateOptionValues = useMemo(() => resolutionsStates.map((state) => ({
    value: state,
    optionLabel: getMessage(`resolutions.filters.state.${state}`),
  })), []);

  return (
    <Filters
      filterId="resolutionsList"
      filterFields={[
        {
          type: 'text',
          id: 'title_search',
          label: getMessage('resolutions.filters.title'),
          ariaLabel: 'Szukaj po tytule uchwały',
        },
        {
          type: 'select',
          id: 'voted',
          label: getMessage('resolutions.filters.state'),
          selectOptions: stateOptionValues,
        },
        {
          type: 'dateRange',
          id: 'from_created_at',
          toDateId: 'to_created_at',
          label: getMessage('resolutions.filters.creationDate'),
        },
        {
          type: 'dateRange',
          id: 'from_voting_deadline',
          toDateId: 'to_voting_deadline',
          label: getMessage('resolutions.filters.voteDate'),
        },
      ]}
    />
  );
};

export default TenantResolutionsFilters;
