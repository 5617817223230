import * as React from 'react';
import { useMemo } from 'react';
import {
  Permission,
  PermissionCategory,
  SimplifiedPermissionTree,
} from '../../../utils/generated/permissionsTypes';
import { permissionTree } from '../../../utils/permissions';
import ArrowUp from '../../icons/dropdown-arrow-up.svg';
import ArrowDown from '../../icons/dropdown-arrow-down.svg';
import { usePermissionMessages } from '../../hooks/useMessages';
import useToggleArray from '../../hooks/useToggleArray';

const shakePermissionsTree = (permissions: Permission[]) => {
  const shakenTree: Partial<SimplifiedPermissionTree> = {};
  const allCategories = Object.keys(permissionTree) as PermissionCategory[];
  allCategories.forEach((category: PermissionCategory) => {
    const permissionsInCategory = (permissionTree[category] as Permission[]).filter((p) => permissions.includes(p));
    if (permissionsInCategory.length > 0) {
      shakenTree[category] = permissionsInCategory;
    }
  });
  return shakenTree;
};

const PermissionTreeDisplay = ({ permissions }: { permissions: Permission[] }) => {
  const tree: Partial<SimplifiedPermissionTree> = useMemo(() => shakePermissionsTree(permissions), [permissions]);
  const categories = Object.keys(tree) as PermissionCategory[];
  const [isOpen, toggleOpen] = useToggleArray(categories);
  const getPermissionMessage = usePermissionMessages();

  return (
    <div>
      {categories.map((category) => (
        <React.Fragment key={category}>
          <div
            key={`${category}-header`}
            className="w-full border-b-2 border-black hover:bg-gray-50 py-3 pt-4"
            tabIndex={0}
            role="button"
            onClick={() => toggleOpen(category)}
            onKeyPress={() => toggleOpen(category)}
          >
            <span
              className="font-bold"
            >
              {getPermissionMessage(category, null)}
            </span>
            {isOpen(category) ? <ArrowUp className="inline ml-1 float-right m-2" /> : <ArrowDown className="inline ml-1 float-right m-2" />}
          </div>
          {isOpen(category) && (
            <div role="list" key={`${category}-content`}>
              {(tree[category] as Permission[]).map((permission) => (
                <div role="listitem" key={category + permission} className="border-b py-3 pl-6 text-sm">
                  <span>{getPermissionMessage(category, permission)}</span>
                </div>
              ))}
            </div>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default PermissionTreeDisplay;
