import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { FormCheckboxInput, FormInput, FormTextarea } from '../../../components/FormInputs';
import Button from '../../../components/Button/Button';
import useMessages from '../../../hooks/useMessages';
import { FullJournal } from '../../../api/modules/documents/documentsTypes';

type Props = {
  journal?: FullJournal
  onSubmit: (data: FullJournal) => void,
};

const JournalForm = ({ journal, onSubmit }: Props) => {
  const getMessage = useMessages();
  const intl = useIntl();

  const methods = useForm<FullJournal>({
    values: {
      autonumbering: journal?.autonumbering || false,
      prefix: journal?.prefix || '',
      name: journal?.name || '',
      description: journal?.description || '',
    },
    mode: 'onChange',
  });

  return (
    <div className="col-span-12">
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} className="flex flex-col">
          <FormCheckboxInput id="autonumbering" label={getMessage('parameters.statuses.form.autonumbering')} />
          <FormInput
            id="prefix"
            type="text"
            label={getMessage('parameters.statuses.form.prefix')}
            options={{ required: getMessage('parameters.statuses.form.prefix.required') }}
          />
          <FormInput
            id="name"
            type="text"
            label={getMessage('parameters.statuses.form.name')}
            options={{
              required: getMessage('parameters.statuses.form.name.required'),
              maxLength: { value: 64, message: intl.formatMessage({ id: 'form.name.error.tooLong' }, { maxLength: 64 }) },
            }}
          />
          <FormTextarea id="description" label={getMessage('parameters.statuses.form.description')} />
          <div className="col-span-12 self-end">
            <Button type="submit" className="mt-6">{getMessage('save')}</Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default JournalForm;
