/* eslint-disable react/jsx-props-no-spreading */

import * as React from 'react';
import { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { SelectOption } from '../FormInputs/inputs/FormSelect';
import TableCustomisationIcon from '../../icons/table-customisation.svg';
import useClickOutside from '../../hooks/useClickOutside';
import useMessages from '../../hooks/useMessages';

interface Props {
  selectedColumns: string[],
  setSelectedColumns: (columns: string[]) => void,
  selectOptions: SelectOption[],
  tableId: string,
}

interface Form {
  [key: string]: string[],
}

const TableCustomisationMenu = ({
  selectedColumns, setSelectedColumns, selectOptions, tableId,
}: Props) => {
  const getMessage = useMessages();
  const [open, setOpen] = useState(false);
  const listRef = useRef(null);
  const iconRef = useRef(null);
  const { register, handleSubmit, getValues } = useForm<Form>({
    defaultValues: {
      [tableId]: selectedColumns,
    },
  });

  const toggleOpen = () => {
    setOpen((open) => !open);
  };

  const onSubmit = (values: Form) => {
    if (values) {
      setSelectedColumns(values[tableId]);
    }
  };

  const onOutside = () => {
    setOpen(false);
    onSubmit(getValues());
  };

  useClickOutside(listRef, onOutside, [iconRef]);

  const handleOnKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' || e.key === ' ') toggleOpen();
    if (e.key === 'Escape') setOpen(false);
  };

  return (
    <div className="pl-2 flex align-middle">
      <div ref={iconRef} className="flex items-center" title={getMessage('table.customize')}>
        <TableCustomisationIcon
          aria-roledescription="application"
          onClick={toggleOpen}
          onKeyDown={(e) => handleOnKeyDown(e)}
          className="border rounded border-typography-dark  text-typography-dark hover:cursor-pointer hover:text-hover hover:border-hover hover:bg-light-200 p-2"
          tabIndex={0}
          aria-label={getMessage('table.customize')}
        />
      </div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="relative"
      >
        {open && (
          <div
            ref={listRef}
            className="absolute top-4 z-30 right-0 bg-white rounded border p-2 w-max"
          >
            {selectOptions.map((option) => (
              // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events
              <label
                key={`${tableId}-${option.value}`}
                htmlFor={`${tableId}-${option.value}`}
                className="text-md-semibold p-1 rounded hover:bg-light-200 hover:cursor-pointer hover:text-main block flex-grow-0
                font-normal text-gray-700 bg-clip-padding text-text-md-semibold focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                onClick={(event) => event?.stopPropagation()}
              >
                <input
                  type="checkbox"
                  {...register(tableId)}
                  value={option.value}
                  id={`${tableId}-${option.value}`}
                  onKeyDown={(e) => (e.key === 'Escape' || e.key === 'Enter') && onOutside()}
                  className="mr-2"
                />
                {option.optionLabel}
              </label>
            ))}
          </div>
        )}
      </form>
    </div>
  );
};

export default TableCustomisationMenu;
