import {
  ApiAccountResponse, ApiAccountsResponse, ApiRole, ApiRoles, Role, User, Users,
} from './accessTypes';

const translateApiToRole = (apiRole: ApiRole): Role => ({
  id: apiRole.id,
  name: apiRole.name,
  permissions: apiRole.permissions,
  isDefault: apiRole.default,
  roleType: apiRole.role_type,
});

export const translateApiToRoles = (apiRoles: ApiRoles): Role[] => apiRoles.roles.map((role) => translateApiToRole(role));

export const translateApiToUser = (apiAccount: ApiAccountResponse): User => ({
  id: apiAccount.account.id,
  name: apiAccount.account.name,
  permissions: apiAccount.account.permissions,
  roles: apiAccount.account.roles.map((role) => translateApiToRole(role)),
});

export const translateApiToUsers = (apiAccounts: ApiAccountsResponse): Users => ({
  metadata: apiAccounts.metadata,
  users: apiAccounts.accounts.map((apiAccount) => ({
    id: apiAccount.id,
    name: apiAccount.name,
    permissions: apiAccount.permissions,
    roles: apiAccount.roles.map((role) => translateApiToRole(role)),
  })),
});
