import * as React from 'react';
import { useNavigate } from 'react-router';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import PageHeader from '../../../../components/Layout/PageHeader';
import useMessages from '../../../../hooks/useMessages';
import { Select, SelectOption } from '../../../../components/FormInputs/inputs/FormSelect';
import { InputLabel } from '../../../../components/FormInputs/InputLabel';
import { useSettlementsYears } from '../../../../hooks/settlements/useSettlementsYears';
import { useSettlementsTitles } from '../../../../hooks/settlements/useSettlementsTitles';
import { selectSettlementsTitle } from '../../../../store/appSlice';
import { useAppSelector } from '../../../../hooks/reduxHooks';
import { useSettlementsVenue } from '../../../../hooks/settlements/useSettlementsVenue';
import { usePermissions } from '../../../../hooks/usePermissions';

interface Props {
  year?: string,
}

const YearsBalanceHeader = ({ year }: Props) => {
  const getMessage = useMessages();
  const { hasRoleType } = usePermissions();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selectedTitleId = useAppSelector((state) => state.app.selectedSettlementsTitle);
  const { venueId, venueAddress } = useSettlementsVenue();

  const years = useSettlementsYears();
  const titles = useSettlementsTitles();

  const selectedTitle = useMemo(() => titles.find((title) => title.id === selectedTitleId), [selectedTitleId, titles]);

  const yearsSelectOptions: SelectOption[] = useMemo(() => years?.map((year) => ({
    value: year,
    optionLabel: year,
  })) || [], [years]);

  const titlesSelectOptions: SelectOption[] = [{ value: '', optionLabel: 'Wszystkie' }].concat(titles?.map((title) => ({
    value: title.id,
    optionLabel: title.name,
  })));

  const selectTitle = (titleId: string) => {
    dispatch(selectSettlementsTitle(titleId));
  };

  const selectYear = (newYear: string) => navigate(
    hasRoleType('worker') ? `/rozrachunki/analiza_salda/${newYear}?venueId=${venueId}` : `/rozrachunki/analiza_salda/${newYear}`,
  );

  const subheader = useMemo(() => {
    const venue = venueId ? `${venueAddress} ` : '';
    const title = selectedTitle ? `${selectedTitle?.name}${selectedTitle?.bankAccountNumber ? `: ${selectedTitle?.bankAccountNumber}` : ''}` : '';
    return venue + title;
  }, [venueAddress, selectedTitle]);

  return (
    <PageHeader
      pageTitle={getMessage('navigation.settlements.balance')}
      currentPage="settlements"
      subheader={subheader}
      path={hasRoleType('worker') ? '/rozrachunki/lokale/aktywne' : undefined}
    >
      {year && (
        <div className="flex items-center text-typography-dark">
          <InputLabel label={getMessage('settlements.balance.filters.year')} inline />
          <Select value={year} onChange={(newYear) => selectYear(newYear)} selectOptions={yearsSelectOptions} />
        </div>
      )}
      <div className="flex items-center text-typography-dark ml-4">
        <InputLabel label={getMessage('settlements.balance.filters.title')} inline />
        <Select
          value={selectedTitleId || ''}
          onChange={(newTitle) => selectTitle(newTitle)}
          selectOptions={titlesSelectOptions}
          inputClassName="w-60"
          label={getMessage('settlements.balance.filters.title')}
        />
      </div>
    </PageHeader>
  );
};

export default YearsBalanceHeader;
