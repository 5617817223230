/* eslint-disable jsx-a11y/no-static-element-interactions */
import * as React from 'react';
import {
  useState, useCallback, useRef, useEffect,
} from 'react';
import classnames from 'classnames';
import { QueryDefinition } from '@reduxjs/toolkit/dist/query';
import { UseQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { baseQuery } from '../../api/baseQuery';
import useMessages from '../../hooks/useMessages';

interface Option {
  id: string,
  name: string,
}

const inputDefaultStyles = `inline-block px-2 py-1 font-normal text-gray-700 bg-white bg-clip-padding
border border-solid border-stroke-grey-300 rounded transition ease-in-out my-1 text-text-md-semibold
focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none`;

interface Props {
  query: UseQuery<QueryDefinition<Record<string, string | null>, typeof baseQuery, string, Option[], string>>,
  queryOptions?: Record<string, string | undefined>,
  className?: string,
  onSelect: (id: string) => void,
  initialState: string | null,
  ariaLabel?: string,
}

const SearchableDropdown = ({
  onSelect,
  query,
  queryOptions,
  className,
  initialState,
  ariaLabel,
}: Props) => {
  const [focusedIndex, setFocusedIndex] = useState(-1);
  const [showResults, setShowResults] = useState(false);
  const [typedValue, setTypedValue] = useState(initialState);
  const { data } = query({ search: typedValue, ...queryOptions });
  const getMessage = useMessages();
  const listRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setTypedValue(initialState);
  }, [initialState]);

  const resetSearch = useCallback(() => {
    setFocusedIndex(-1);
    setShowResults(false);
  }, []);

  const handleSelection = (item: Option) => {
    setTypedValue(item.name);
    onSelect(item.id);
    resetSearch();
  };

  const handleKeyDown: React.KeyboardEventHandler<HTMLDivElement> = (e) => {
    if (data) {
      const { key } = e;
      let nextIndexCount = 0;
      if (key === 'ArrowDown') { nextIndexCount = (focusedIndex + 1) % data.length; }
      if (key === 'ArrowUp') { nextIndexCount = (focusedIndex + data.length - 1) % data.length; }
      if (key === 'Escape') {
        resetSearch();
      }
      if (key === 'Enter') {
        e.preventDefault();
        const item = data[focusedIndex];
        handleSelection(item);
      }

      setFocusedIndex(nextIndexCount);
      setTimeout(() => {
        listRef.current?.querySelector('.active')?.scrollIntoView({ block: 'nearest' });
      }, 300);
    }
  };

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setTypedValue(e.target.value);
  };

  const showValues = useCallback(() => {
    setShowResults(true);
  }, [setShowResults]);

  return (
    <div
      onBlur={resetSearch}
      onKeyDown={handleKeyDown}
      className={`relative ${className}`}
    >
      <input
        value={typedValue || ''}
        // eslint-disable-next-line jsx-a11y/tabindex-no-positive
        tabIndex={0}
        onChange={handleChange}
        onFocus={showValues}
        type="search"
        placeholder={getMessage('filters.search')}
        className={classnames('transition w-full', inputDefaultStyles, { 'bg-background-light': !!initialState })}
        autoComplete="off"
        disabled={!!initialState}
        aria-label={ariaLabel}
      />
      <input
        type="select"
        value={typedValue || ''}
        style={{ display: 'none' }}
      />
      {showResults && data && data.length > 0 && (
        <div
          className="absolute w-full bg-white shadow-lg rounded-bl rounded-br max-h-56 overflow-y-auto z-50"
          ref={listRef}
        >
          {data?.map((item: {
            id: string,
            name: string,
          }, index) => (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events
            <div
              key={item.id}
              onMouseDown={() => handleSelection(item)}
              className={classnames(
                inputDefaultStyles,
                'w-full hover:text-main hover:bg-light-200 border-none',
                { 'bg-light-200 active': index === focusedIndex },
              )}
            >
              {item.name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SearchableDropdown;
