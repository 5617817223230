import * as React from 'react';
import Layout from '../../../components/Layout/Layout';
import ParametersSidebar from '../ParametersSidebar';
import PageHeader from '../../../components/Layout/PageHeader';
import ContentWrapper from '../../../components/ContentWrapper/ContentWrapper';
import useMessages from '../../../hooks/useMessages';
import StatusesList from './StatusesList';
import { usePermissions } from '../../../hooks/usePermissions';
import {
  useGetFullTicketStatusesQuery,
  useGetFullDocumentStatusesQuery,
  useGetFullTaskStatusesQuery,
} from '../../../api/appApi';

const StatusesPage = () => {
  const getMessage = useMessages();
  const { isModuleEnabled, checkModuleEnabled } = usePermissions();

  const { data: ticketStatuses } = useGetFullTicketStatusesQuery();
  const { data: taskStatuses } = useGetFullTaskStatusesQuery();
  const { data: documentStatuses } = useGetFullDocumentStatusesQuery();

  if (!isModuleEnabled('tickets')) checkModuleEnabled('documents');
  if (!isModuleEnabled('documents')) checkModuleEnabled('tickets');

  return (
    <Layout currentPage="parameters">
      <div className="flex flex-grow h-full">
        <ParametersSidebar selectedPage="statuses" />
        <div className="flex flex-col flex-grow">
          <PageHeader currentPage="parameters" pageTitle={getMessage('navigation.parameters.statuses')} />
          <ContentWrapper>
            {isModuleEnabled('tickets') && ticketStatuses && (
              <StatusesList title={getMessage('parameters.statuses.tickets')} statuses={ticketStatuses} />)}
            {isModuleEnabled('tickets') && taskStatuses && (
              <StatusesList title={getMessage('parameters.statuses.tasks')} statuses={taskStatuses} />
            )}
            {isModuleEnabled('documents') && documentStatuses && (
              <StatusesList title={getMessage('parameters.statuses.documents')} statuses={documentStatuses} />
            )}
          </ContentWrapper>
        </div>
      </div>
    </Layout>
  );
};

export default StatusesPage;
