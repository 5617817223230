import * as React from 'react';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import BackLink from '../../../BackLink/BackLink';
import BurgerIcon from '../../../../icons/burger.svg';
import { useGetCompanyDataQuery } from '../../../../api/appApi';
import Spinner from '../../../Spinner/Spinner';
import DashboardIcon from '../../../../icons/dashboard.svg';
import useMessages from '../../../../hooks/useMessages';

interface Props {
  onClick: () => void;
  pageTitle: string | undefined,
  path?: string,
}

const TopBarHeader = React.forwardRef(({ onClick, pageTitle, path }: Props, ref: React.Ref<any>) => {
  const intl = useIntl();
  const getMessage = useMessages();
  const { data: companyDetails, isSuccess: companyFetched } = useGetCompanyDataQuery();

  return (
    <header
      className="lg:hidden min-h-headerMobile md:min-h-headerTablet z-50 flex justify-between items-center bg-background-white px-2 sm:px-3 md:px-8 text-typography-black"
    >
      <div className="text-title-semibold md:text-header-sm">
        {path && <BackLink path={path} />}
        {pageTitle}
      </div>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div className="flex items-center">
        <button
          className="flex justify-end cursor-pointer mr-2"
          aria-label={getMessage('menu.ariaLabel')}
          ref={ref}
          onClick={onClick}
          type="button"
        >
          <BurgerIcon />
        </button>
        <Link to="/" aria-label={intl.formatMessage({ id: 'navigation.dashboard' })}>
          <div className="max-h-9 max-w-100 flex items-center justify-center">
            {companyFetched && (
              companyDetails.logo
                ? (
                  <img
                    src={companyDetails.logo.path}
                    alt={companyDetails.name || intl.formatMessage({ id: 'navigation.header' })}
                    className="max-h-9"
                  />
                )
                : <DashboardIcon className="stroke-current" />
            )}
            {!companyFetched && <Spinner className="h-8 w-6" />}
          </div>
        </Link>
      </div>
    </header>
  );
});

export default TopBarHeader;
