/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Card from '../../../components/Card/Card';
import { FormInput, FormMultiSelect } from '../../../components/FormInputs';
import Button from '../../../components/Button/Button';
import useMessages from '../../../hooks/useMessages';
import { CreateUserFormData } from '../../../api/modules/user/userTypes';
import {
  useGetAccessGroupsQuery,
  useGetCompanySettingsQuery,
  useGetRolesForUserQuery,
  usePostUserAccountMutation,
  useGetJournalsQuery,
} from '../../../api/appApi';
import { SelectOption } from '../../../components/FormInputs/inputs/FormSelect';
import { InputError } from '../../../components/FormInputs/InputLabel';
import FormSearchableMultiselectDropdown from '../../../components/FormInputs/inputs/FormSearchableMultiselectDropdown';
import { translateUserFormToPostRequest } from '../../../api/modules/user/userTranslator';
import { FormNotice } from '../../../components/FormNotice/FormNotice';
import { EMAIL_REGEX } from '../../../../utils/consts';

const NewUserForm = () => {
  const getMessage = useMessages();
  const navigate = useNavigate();
  const [postNewUser, result] = usePostUserAccountMutation();
  const { data: roles, isLoading } = useGetRolesForUserQuery();
  const { data: settings } = useGetCompanySettingsQuery();
  const [backendErrors, setBackendErrors] = useState([] as string[]);
  const methods = useForm<CreateUserFormData>({
    defaultValues: {
      email: '',
      fullName: '',
      externalId: '',
      login: '',
      phone: '',
      homeownerAssociationIds: [],
      buildingsIds: [],
      estatesIds: [],
      journalsIds: [],
    },
    mode: 'onTouched',
  });

  const onSubmit = (formData: CreateUserFormData) => {
    postNewUser(translateUserFormToPostRequest(formData));
  };

  const rolesSelectOptions = useMemo(() => (roles || []).reduce((rolesToSelect, role) => {
    if (role.roleType === 'worker' || role.roleType === 'admin') {
      return rolesToSelect.concat([{
        value: role.id,
        optionLabel: role.name,
      }]);
    }
    return rolesToSelect;
  }, [] as SelectOption[]), [roles]);

  useEffect(() => {
    if (!isLoading && roles) {
      const defaultRoleId = roles.find((role) => role.isDefault && role.roleType === 'worker')?.id || '';
      methods.setValue('roleIds', [defaultRoleId]);
    }
  }, [roles]);

  useEffect(() => {
    if (result.status === 'fulfilled') {
      navigate('/uzytkownicy');
    }
    // @ts-ignore
    if (result.status === 'rejected' && result.error?.data?.errors) {
      // @ts-ignore
      const errors = result.error.data.errors || [];
      setBackendErrors(Array.isArray(errors) ? errors : [errors]);
    }
  }, [result]);

  return (
    <Card className="col-span-full">
      {result.isError && <FormNotice type="error" message={getMessage('form.error')} />}
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} className="grid grid-cols-12 col-span-full">
          <section className="col-span-full md:col-span-4">
            <FormMultiSelect
              label={getMessage('users.newUser.roles')}
              id="roleIds"
              selectOptions={rolesSelectOptions}
              options={{
                validate: {
                  required: (val: any[]) => val.length > 0 || getMessage('users.newUser.roles.error.required'),
                },
              }}
            />
            <FormInput
              className="w-full mt-2"
              inputClassName="w-full"
              id="externalId"
              type="text"
              label={`${getMessage('users.newUser.externalId')}`}
              options={{ required: getMessage('users.newUser.id.error.required') }}
            />
            <FormInput
              className="w-full mt-2"
              inputClassName="w-full"
              id="login"
              type="text"
              label={`${getMessage('users.newUser.login')}`}
            />
            <FormInput
              className="w-full mt-2"
              inputClassName="w-full"
              id="fullName"
              type="text"
              label={`${getMessage('users.newUser.name')}`}
              options={{ required: getMessage('users.newUser.name.error.required') }}
            />
            <FormInput
              className="w-full mt-2"
              inputClassName="w-full"
              id="email"
              type="text"
              label={`${getMessage('users.newUser.email')}`}
              options={{
                required: getMessage('users.newUser.email.error.required'),
                pattern: {
                  value: EMAIL_REGEX,
                  message: getMessage('form.email.error.pattern'),
                },
              }}
            />
            <FormInput
              className="w-full mt-2 mb-2"
              inputClassName="w-full"
              id="phone"
              type="text"
              label={`${getMessage('users.newUser.phone')}`}
              options={{
                pattern: {
                  value: /^[0-9- +]+$/,
                  message: getMessage('form.phone.error.pattern'),
                },
              }}
            />
            {backendErrors && backendErrors.map((error) => (
              <InputError key={error} message={error} />
            ))}
          </section>
          <section className="col-span-12 border-t mt-4">
            <p className="text-header-sm my-4">{getMessage('users.user.accessGroups')}</p>
            {settings?.homeownerAssociationsMode && (
              <FormSearchableMultiselectDropdown
                label={getMessage('users.user.accessGroup.homeownerAssociations')}
                id="homeownerAssociationIds"
                query={useGetAccessGroupsQuery}
                queryOptions={{ accessGroup: 'homeowner_associations' }}
                ariaLabel={getMessage('form.ariaLabel.hoa')}
              />
            )}
            <FormSearchableMultiselectDropdown
              label={getMessage('users.user.accessGroup.estates')}
              id="estatesIds"
              query={useGetAccessGroupsQuery}
              queryOptions={{ accessGroup: 'estates' }}
              ariaLabel={getMessage('form.ariaLabel.estates')}
            />
            <FormSearchableMultiselectDropdown
              label={getMessage('users.user.accessGroup.buildings')}
              id="buildingsIds"
              query={useGetAccessGroupsQuery}
              queryOptions={{ accessGroup: 'buildings' }}
              ariaLabel={getMessage('form.ariaLabel.buildings')}
            />
            <FormSearchableMultiselectDropdown
              label={getMessage('users.user.accessGroup.journals')}
              id="journalsIds"
              query={useGetJournalsQuery}
              ariaLabel="Wyszukaj dzienniki"
            />
          </section>
          <div className="grid grid-cols-3 col-span-full">
            <Button type="submit" className="mt-6 col-start-4">{getMessage('save')}</Button>
          </div>
        </form>
      </FormProvider>
    </Card>
  );
};

export default NewUserForm;
