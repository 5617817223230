import * as React from 'react';
import useMessages from '../../hooks/useMessages';

const FallbackRender = ({ status }: { status?: number }) => {
  const getMessage = useMessages();

  return (
    <div className="text-center">
      <div>
        <h4 className="mb-2 text-title-semibold">{status === 404 ? getMessage(`fallbackRender.error.${status}.header`) : getMessage('fallbackRender.header')}</h4>
      </div>
      <p className="text-text-lg-medium text-typography-dark">
        {status === 404 && getMessage(`fallbackRender.error.${status}.paragraph`)}
        <a href="/" className="text-main hover:underline">{getMessage('fallbackRender.backToHomepage')}</a>
      </p>
    </div>
  );
};

export default FallbackRender;
