import * as React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import PageHeader from '../../components/Layout/PageHeader';
import PermissionTreeDisplay from '../../components/PermissionTree/PermissionTreeDisplay';
import { useGetAccountQuery, usePostAssignPermissionsMutation } from '../../api/appApi';
import ExpandableCard from '../../components/ExpandableCard/ExpandableCard';
import PermissionEditTree from '../../components/PermissionTree/PermissionEditTree';
import { Permission } from '../../../utils/generated/permissionsTypes';
import Popup from '../../components/Popup/Popup';
import Button from '../../components/Button/Button';
import useMessages from '../../hooks/useMessages';
import { usePermissions } from '../../hooks/usePermissions';
import ContentWrapper from '../../components/ContentWrapper/ContentWrapper';
import Layout from '../../components/Layout/Layout';
import { FormNotice } from '../../components/FormNotice/FormNotice';

const UserPage = () => {
  const { id } = useParams();
  const getMessage = useMessages();
  const userQuery = useGetAccountQuery(id as string, { refetchOnMountOrArgChange: true });
  const [postPermissions, postPermissionsStatus] = usePostAssignPermissionsMutation();
  const [popupOpened, setPopupOpened] = useState(false);
  const user = useMemo(() => userQuery.data, [userQuery]);
  const intl = useIntl();
  const { checkRoleType } = usePermissions();
  const navigate = useNavigate();
  const hasWorkerRoleType = useMemo(() => user?.roles?.find((role) => role.roleType === 'worker') || false, [user?.roles]);

  useEffect(() => {
    if (postPermissionsStatus.status === 'fulfilled') {
      setPopupOpened(true);
    }
    if (user && !hasWorkerRoleType) {
      navigate(`/uzytkownicy/${id}`);
    }
  }, [postPermissionsStatus, user, hasWorkerRoleType]);

  checkRoleType('admin');

  return (
    <Layout currentPage="users">
      <Popup isOpen={popupOpened}>
        <div className="h-1/2 flex flex-col justify-around items-center">
          <p className="mb-8">
            {intl.formatMessage({ id: 'user.permissionsChanged' })}
          </p>
          <Button
            styleType="primary"
            onClick={() => setPopupOpened(false)}
          >
            {getMessage('close')}
          </Button>
        </div>
      </Popup>
      <PageHeader pageTitle={user?.name} currentPage="users" path={`/uzytkownicy/${id}`} />
      {hasWorkerRoleType && (
      <ContentWrapper>
        {postPermissionsStatus.isError && (
          /* @ts-ignore */
          <FormNotice type="error" noticeClassName="col-span-full" message={postPermissionsStatus.error.data?.errors || getMessage('form.error')} />
        )}
        {user?.roles?.map((role) => (
          <ExpandableCard
            title={role.name}
            key={role.id}
            className="mt-2 col-span-full"
            additionalInfo={role.name === 'admin' ? '' : intl.formatMessage(
              { id: 'user.role.permissionCount' },
              { count: role.permissions.length },
            )}
          >
            <PermissionTreeDisplay permissions={role.permissions} />
          </ExpandableCard>
        ))}
        {user?.roles && user.roles.length > 0 && <div className="h-1 border-b w-full mt-2 col-span-full" />}
        <ExpandableCard
          title={intl.formatMessage({ id: 'user.additionalPermissions' })}
          className="mt-2 col-span-full"
          defaultOpen
        >
          <PermissionEditTree
            startingPermissions={user?.permissions || []}
            onSubmit={(permissions: Permission[]) => postPermissions({
              id,
              payload: {
                permissions_names: permissions,
              },
            })}
          />
        </ExpandableCard>
      </ContentWrapper>
      )}
    </Layout>
  );
};

export default UserPage;
