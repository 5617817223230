import * as React from 'react';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import useMessages from '../../../../hooks/useMessages';
import VotingsListFilters from './VotingsListFilters';
import Button from '../../../../components/Button/Button';
import FiltersIcon from '../../../../icons/filters.svg';
import { useAppSelector } from '../../../../hooks/reduxHooks';

interface Props {
  pwiVotes: string,
  voteOptions: string[],
  id: string,
}

const VotingsListFiltersBar = ({ pwiVotes, voteOptions, id }: Props) => {
  const getMessage = useMessages();
  const intl = useIntl();
  const hasFilterSet = useAppSelector((state) => !!state.app.filters.find((filter) => (filter.id === `resolutionVotes${id}`)));
  const [filtersOpen, setFiltersOpen] = useState(hasFilterSet);

  return (
    <div className="row-span-1 row-start-2 col-span-full">
      <div className="flex flex-wrap justify-between mb-2">
        { intl.formatMessage({ id: 'resolutions.votings.onlineVotesGiven' }, { pwiVotes }) }
        <Button
          Icon={FiltersIcon}
          styleType="secondary"
          size="small"
          onClick={() => setFiltersOpen((filtersOpen) => !filtersOpen)}
          className="h-8 py-1 bg-background-white"
        >
          {getMessage('filters')}
        </Button>
      </div>

      {filtersOpen && <VotingsListFilters voteOptions={voteOptions} id={id} />}
    </div>
  );
};

export default VotingsListFiltersBar;
