import * as React from 'react';
import { useMediaQuery } from 'react-responsive';
import useMessages from '../../hooks/useMessages';
import { TableBodyRow, TableHeaderCell, TableBodyCell } from './Table';
import { mobile } from '../../../utils/breakpoints';
import TableContentPlaceholder from './TableContentPlaceholder';

const TableBodyPlaceholder = ({ text }: { text?: string }) => {
  const getMessage = useMessages();
  const isMobile = useMediaQuery({ query: mobile.raw });

  return (
    isMobile ? (
      <TableContentPlaceholder />
    ) : (
      <TableBodyRow>
        <TableHeaderCell />
        <TableBodyCell colspan={4}>{text || getMessage('filters.noResults')}</TableBodyCell>
      </TableBodyRow>
    )
  );
};

export default TableBodyPlaceholder;
