import * as React from 'react';
import format from 'date-fns/format';
import { FullTask } from '../../../api/modules/tasks/tasksTypes';
import Card from '../../../components/Card/Card';
import Chip from '../../../components/Chip/Chip';
import capitalize from '../../../../utils/formatters';
import DocumentIcon from '../../../icons/document.svg';
import AttachmentPreview from '../../../components/AttachmentPreview/AttachmentPreview';
import useMessages from '../../../hooks/useMessages';
import CommentsList from '../../../components/CommentsList/CommentsList';

interface Props {
  task: FullTask,
}

const MainTaskCard = ({ task }: Props) => {
  const getMessage = useMessages();
  const {
    title, createdAt, estimatedStartTime, estimatedFinishTime, category, description, attachments, status,
  } = task;

  return (
    <Card className="col-span-full lg:col-span-9">
      <div className="border-b py-3">
        <div className="flex justify-between">
          <h4 className="text-header-xs md:text-header-sm inline mr-6">{title}</h4>
          <div className="flex flex-wrap justify-end">
            <Chip chipStyle="ghost" className="mb-1 md:mb-0" tooltipText={getMessage('tasks.task.createdAt')} screenReaderOnlyText="Data utworzenia">
              {createdAt && format(new Date(createdAt), 'dd-MM-yyyy')}
            </Chip>
            {(estimatedStartTime || estimatedFinishTime) && (
              <Chip chipStyle="ghost" className="ml-3 mb-1 md:mb-0" tooltipText={getMessage('tasks.task.estimatedStart.estimatedEnd')}>
                  {estimatedStartTime && `${getMessage('tasks.date.from')} ${format(new Date(estimatedStartTime), 'dd-MM-yyyy')}`}
                  {' '}
                  {estimatedFinishTime && `${getMessage('tasks.date.until')} ${format(new Date(estimatedFinishTime), 'dd-MM-yyyy')}`}
              </Chip>
            )}
            <Chip chipStyle="primary" className="ml-3 mb-1 md:mb-0" screenReaderOnlyText="Status">
              {status?.name && capitalize(status.name)}
            </Chip>
          </div>
        </div>
        <div className="flex mt-2 text-text-lg-medium">
          <div className="flex mr-4 text-main">
            <DocumentIcon className="stroke-current" />
            {category && (
              <p className="inline-block ml-1">
                <span className="visually-hidden">Kategoria</span>
                {capitalize(category.name)}
              </p>
            )}
          </div>
        </div>
        <p className="text-typography-dark text-text-lg-medium my-7 whitespace-pre-line">{description}</p>
        <div className="my-9">
          {attachments?.length > 0 && (
            <ol className="flex flex-wrap">
              {attachments?.map(({
                filename, path, contentType, description,
              }) => (
                <AttachmentPreview filename={filename} path={path} contentType={contentType} description={description} key={path} />))}
            </ol>
          )}
        </div>
      </div>
      <h3 className="inline-block my-5 text-text-lg-semibold md:text-title-semibold">{getMessage('tasks.task.comments.discussion')}</h3>
      <CommentsList taskId={task.id} comments={task.comments} publicComment={false} />
    </Card>
  );
};

export default MainTaskCard;
