import * as React from 'react';
import { useParams } from 'react-router-dom';
import format from 'date-fns/format';
import useMessages from '../../hooks/useMessages';
import PageHeader from '../../components/Layout/PageHeader';
import ContentWrapper from '../../components/ContentWrapper/ContentWrapper';
import Layout from '../../components/Layout/Layout';
import { usePermissions } from '../../hooks/usePermissions';
import { useGetDocumentQuery } from '../../api/appApi';
import MobileDocumentInfoCard from './EmployeeDocumentPage/MobileDocumentInfoCard';
import Card from '../../components/Card/Card';
import Chip from '../../components/Chip/Chip';
import AttachmentPreview from '../../components/AttachmentPreview/AttachmentPreview';
import CommentsList from '../../components/CommentsList/CommentsList';
import DocumentDesktopInfoCard from './EmployeeDocumentPage/DocumentDesktopInfoCard';
import { FullDocument } from '../../api/modules/documents/documentsTypes';
import InfoIcon from '../../icons/info.svg';

const DocumentPage = () => {
  const { checkModuleEnabled } = usePermissions();
  const { id } = useParams();
  const { data: document, isSuccess } = useGetDocumentQuery(id, { skip: !id });
  const { hasRoleType } = usePermissions();
  const getMessage = useMessages();

  checkModuleEnabled('documents');
  if (!id) return null;

  const {
    title, journal, creationDate, documentNumber, description,
    attachments, comments, id: documentId, revoked,
  } = isSuccess ? document : {} as FullDocument;

  return (
    <Layout currentPage="documents">
      <PageHeader
        pageTitle={`${getMessage('documents.document.header')} ${document ? document.documentNumber : ''}`}
        currentPage="documents"
        path="/dokumenty"
      />
      {hasRoleType('worker') && (
        <MobileDocumentInfoCard />
      )}
      <ContentWrapper>
        <Card className={`col-span-full row-span-1 ${hasRoleType('worker') ? 'lg:col-span-9' : 'lg:col-span-12'}`}>
          <div className="flex justify-between">
            <h4 className="text-header-xs md:text-header-sm inline mr-6">{title}</h4>
            <div className="flex flex-wrap justify-end">
              <Chip chipStyle="ghost" className="mb-1 md:mb-0" screenReaderOnlyText="Data utworzenia">
                {creationDate && format(new Date(creationDate), 'dd-MM-yyyy')}
              </Chip>
            </div>
          </div>
          {hasRoleType('worker') && (
            <div className="flex mt-2 text-text-lg-medium border-b">
              <p className="text-main my-5">
                <span className="visually-hidden">Nazwa dziennika</span>
                {`${getMessage('documents.document.journal')} ${journal?.name}: `}
                <span className="visually-hidden">Numer dokumentu</span>
                {documentNumber}
              </p>
            </div>
          )}
          {revoked ? (
            <p className="flex align-center text-typography-dark text-text-lg-medium my-7 whitespace-pre-line text-interactions-error-text">
              <InfoIcon className="mr-2" />
              {getMessage('documents.document.revoked')}
            </p>
          ) : (
            <p className="text-typography-dark text-text-lg-medium my-7 whitespace-pre-line">
              {description}
            </p>
          )}
          {attachments && attachments?.length > 0 && !revoked && (
            <ol className="flex flex-wrap">
              {attachments?.map(({
                filename, path, contentType, description,
              }) => (
                <AttachmentPreview filename={filename} path={path} contentType={contentType} description={description} key={path} />))}
            </ol>
          )}
          {hasRoleType('worker') && (
            <>
              <h3 className="inline-block my-5 text-text-lg-semibold md:text-title-semibold">{getMessage('documents.document.discussion')}</h3>
              <CommentsList documentId={documentId} comments={comments} publicComment={false} />
            </>
          )}
        </Card>
        {document && hasRoleType('worker') && <DocumentDesktopInfoCard />}
      </ContentWrapper>
    </Layout>
  );
};

export default DocumentPage;
