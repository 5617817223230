import { rest } from 'msw';
import queryString from 'query-string';

const users = [
  {
    id: '13d8afb6-e051-48dd-823c-d3781ff5a85b',
    external_id: 'prac1',
    email: 'pracownik1@example.org',
    login: 'login 1',
    full_name: 'Anna Nowak',
    roles: [
      'pracownik',
    ],
    correspondence_email: null,
    email_agreement: null,
  },
  {
    id: '62bd6076-3913-4304-b988-66a66cd81164',
    external_id: 'prac4',
    email: 'pracownik4@example.org',
    login: 'login 2',
    full_name: 'Barbara Strzelecka',
    roles: [
      'pracownik',
    ],
    correspondence_email: null,
    email_agreement: null,
  },
  {
    id: '3633060a-ead7-4442-b563-374f79710ce1',
    external_id: 'prac7',
    email: 'pracownik7@example.org',
    login: 'pracownikk',
    full_name: 'Grzegorz Brzęczyszczykiewicz',
    roles: [
      'admin',
      'pracownik',
    ],
    correspondence_email: null,
    email_agreement: null,
  },
  {
    id: '2a2142df-fefe-4e0e-8a4f-18dd64649b26',
    external_id: 'prac10',
    email: 'pracownik10@example.org',
    login: null,
    full_name: 'Ireneusz Motylanoga',
    roles: [
      'admin',
    ],
    correspondence_email: null,
    email_agreement: null,
  },
  {
    id: 'b234d23d-91f3-4eae-9a6d-39ea7284dd49',
    external_id: 'prac5',
    email: 'pracownik5@example.org',
    login: null,
    full_name: 'Jadwiga Kuna',
    roles: [
      'lokator',
    ],
    correspondence_email: null,
    email_agreement: null,
  },
  {
    id: '806a8d70-8f6d-4d62-9dd6-0238d8699bf1',
    external_id: 'prac2',
    email: 'pracownik2@example.org',
    login: null,
    full_name: 'Jan Kowalski',
    roles: [
      'członek',
    ],
    correspondence_email: null,
    email_agreement: null,
  },
  {
    id: '11eaddc1-efec-4228-85ce-df02db3d08a3',
    external_id: null,
    email: 'pracownik@example.org',
    login: null,
    full_name: 'Jane Doe',
    roles: [
      'lokator',
    ],
    correspondence_email: null,
    email_agreement: null,
  },
  {
    id: '1ce9eabc-e064-4681-a9ed-34178efa31b3',
    external_id: 'prac8',
    email: 'pracownik8@example.org',
    login: null,
    full_name: 'Marek Kanarek',
    roles: [
      'lokator',
    ],
    correspondence_email: null,
    email_agreement: null,
  },
  {
    id: 'ab68ede6-b837-4aeb-8fb4-4f911b79ba51',
    external_id: 'prac6',
    email: 'pracownik6@example.org',
    login: null,
    full_name: 'Michał Owczarek',
    roles: [
      'lokator',
    ],
    correspondence_email: null,
    email_agreement: null,
  },
  {
    id: '54ceb840-1cec-45d3-b8df-6aa0e9972a46',
    external_id: 'prac3',
    email: 'pracownik3@example.org',
    login: null,
    full_name: 'Roman Wiśniewski',
    roles: [
      'lokator',
    ],
    correspondence_email: null,
    email_agreement: null,
  },
];

const filterUsers = ({ full_name, login_option_search, ...rest }) => {
  let filteredUsers = users;
  if (full_name) {
    filteredUsers = users.filter((user) => user.full_name.includes(full_name));
  }
  if (login_option_search) {
    filteredUsers = users.filter((user) => user.login && user.login.includes(login_option_search));
  }
  if (rest['role_type[]'] || rest.role_type) {
    // no point in replicating serverside logic
    filteredUsers = [users[0], users[1], users[2]];
  }
  return filteredUsers;
};

export const usersMocks = [
  rest.get('/users/accounts/list', (req, res, ctx) => {
    const { query: params } = queryString.parseUrl(req.url.href);
    return res(
      ctx.status(200),
      ctx.json({
        accounts: filterUsers(params),
      }),
    );
  }),
];
