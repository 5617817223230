import * as React from 'react';
import TaskAssignEmployeeAction from './TaskAssignEmployeeAction';
import TaskChangeCategoryAction from './TaskChangeCategoryAction';
import TaskChangeStatusAction from './TaskChangeStatusAction';

interface Props {
  selectedTasks: string[];
}

const TasksBulkActions = ({ selectedTasks }: Props) => (
  <div className="flex items-center border-r border-stroke-grey-300 pr-1">
    <TaskAssignEmployeeAction selectedTasksIds={selectedTasks} />
    <TaskChangeCategoryAction selectedTasksIds={selectedTasks} />
    <TaskChangeStatusAction selectedTasksIds={selectedTasks} />
  </div>
);

export default TasksBulkActions;
