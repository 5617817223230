import * as React from 'react';
import { useState, useEffect } from 'react';
import AttachmentTypeIcon from '../AttachmentTypeIcon/AttachmentTypeIcon';
import PdfPreviewer from '../../PdfPreviewer/PdfPreviewer';
import DeleteIcon from '../../../icons/delete.svg';
import PreviewReplacement from '../PreviewReplacement/PreviewReplacement';
import useMessages from '../../../hooks/useMessages';

type Props = {
  attachment: {
    file: FileList | undefined,
    description?: string
  },
  onDelete: () => void;
};

const readFile = async (file: File) => new Promise<string>((resolve, reject) => {
  const reader = new FileReader();
  // FileReader.readAsDataURL() returns a string - base64-encoded representation of the img
  reader.onload = () => resolve(reader.result as string);
  reader.onerror = () => reject(new Error('Error reading file'));
  reader.readAsDataURL(file);
});

const FileUploadPreview = ({ attachment, onDelete }: Props) => {
  const [pdfFile, setPdfFile] = useState<File>();
  const [imageData, setImageData] = useState<{ data: string; file: File }>();
  const [otherFile, setOtherFile] = useState<File>();
  const getMessage = useMessages();

  useEffect(() => {
    if (attachment.file && attachment.file.length > 0) {
      const file = attachment.file[0];

      if (file.type === 'application/pdf') {
        setPdfFile(file);
      }
      if (file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/png') {
        readFile(file).then((data) => setImageData({ data, file }));
      }
      setOtherFile(file);
    }
  }, [attachment]);

  return (
    <div className="border border-gray rounded shadow-md shadow-gray-300 mr-6 relative">
      <button
        type="button"
        onClick={onDelete}
        className="h-fit absolute z-20 -right-2 -top-2"
        aria-label={getMessage('remove')}
      >
        <DeleteIcon className="hover:text-gray-400" />
      </button>
      <div className="overflow-hidden h-[100px] w-[140px]">
        {pdfFile && <PdfPreviewer source={pdfFile} width={140} />}
        {imageData && (
          <img
            src={imageData.data}
            alt={imageData.file.name}
            className="object-cover h-[100px] w-[140px]"
          />
        )}
        <PreviewReplacement widthInPx="140" heightInPx="100" />
      </div>
      <div className="p-1 bg-gray-100 w-full flex self-end absolute bottom-0 index-5 text-typography-dark text-xs text-center">
        <AttachmentTypeIcon contentType={imageData?.file.type || pdfFile?.type || otherFile?.type} />
        <div className="truncate grow-0">{imageData?.file.name || pdfFile?.name || otherFile?.name}</div>
      </div>
    </div>
  );
};

export default FileUploadPreview;
