import * as React from 'react';
import classNames from 'classnames';
import useMessages from '../../../hooks/useMessages';
import { FormInput } from '../../../components/FormInputs';

interface Props {
  meterId: string, measurementUnit: string, divClassName?: string, meterType: string,
}

const MeterReadingInput = ({
  meterId, measurementUnit, divClassName, meterType,
}: Props) => {
  const getMessage = useMessages();

  return (
    <div className={classNames('flex items-center w-52 mobile:mt-6', divClassName)}>
      <label htmlFor={meterId} className="text-text-md-semibold mr-2">{getMessage('local.meters.reading')}</label>
      <FormInput
        className="w-full mr-2"
        inputClassName="w-full"
        id={meterId}
        type="number"
        options={{ required: true, min: { value: 0, message: getMessage('local.meters.error.minValue') } }}
        ariaLabel={`Wprowadź odczyt dla licznika ${meterType}`}
      />
      <span className="text-text-md-semibold w-6">{measurementUnit}</span>
    </div>
  );
};

export default MeterReadingInput;
