import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { appApi, useGetFullUserAccountQuery } from '../../api/appApi';
import { useSettlementsVenue } from './useSettlementsVenue';
import { usePermissions } from '../usePermissions';

export const useSettlementsYears = () => {
  const { venueId } = useSettlementsVenue();
  const { data: user } = useGetFullUserAccountQuery();
  const { hasRoleType } = usePermissions();
  const multiVenuesIds = useMemo(() => (venueId ? [venueId] : user?.tenant?.venues.map((venue) => venue.id)), [user]);
  const [fetchYears] = appApi.endpoints.getSettlementsYears.useLazyQuery();
  const [years, setYears] = useState([] as string[]);
  const navigate = useNavigate();
  const selectedSettlementsTenantId = hasRoleType('worker') ? localStorage.getItem(`selectedSettlementsTenantId-${user?.id}`) : '';

  if (hasRoleType('worker') && !selectedSettlementsTenantId) navigate('/rozrachunki/lokale/aktywne');

  useEffect(() => {
    (multiVenuesIds || []).forEach((venueId) => {
      fetchYears({ venue: venueId, tenantId: hasRoleType('worker') ? selectedSettlementsTenantId : '' }).then((res) => {
        setYears((state) => [...new Set(state.concat(res?.data || []))]);
      });
    });
  }, [multiVenuesIds, selectedSettlementsTenantId]);

  return years;
};
