import { rest } from 'msw';

let details = {
  name: 'SM Zielone Zacisze',
  street_address: 'Zielona 1/2',
  postcode: '54-321',
  city: 'Warszawa',
  phone: '987-654-321',
  website: 'www.smzacisze.pl',
};

const attachments = {
  background_photo: undefined,
  logo: undefined,
  regulations: undefined,
};

const settings = {
  color_palette: 'violet',
  hoa_mode: true,
  meters_enabled: true,
  resolutions_enabled: true,
  settlements_enabled: true,
  payments_enabled: true,
  tickets_enabled: true,
  documents_enabled: true,
};

const updateCompanyDetails = (data) => {
  details = data;
};

const mailServerConfigToShow = {
  address: 'smtp.gmail.com',
  authentication: 'PLAIN',
  communication_encryption: 'none',
  domain: 'example.co',
  from_email: 'noreply@example.com',
  port: 8080,
  user_name: '',
};

export const companyMocks = [
  rest.get('/company/details/show', (req, res, ctx) => res(
    ctx.status(200),
    ctx.json({ details, attachments }),
  )),
  rest.put('/company/details/change', (req, res, ctx) => {
    req.json().then((postData) => {
      updateCompanyDetails(postData);
    });
    return res(
      ctx.status(200),
    );
  }),
  rest.get('/company/settings', (req, res, ctx) => res(
    ctx.status(200),
    ctx.json({ settings }),
  )),
  rest.put('/company/settings/change', (req, res, ctx) => res(
    ctx.status(200),
    ctx.json({ settings }),
  )),
  rest.get('/company/mail_server_config', (req, res, ctx) => res(
    ctx.status(200),
    ctx.json({ mail_server_config: {} }),
  )),
  rest.put('/company/mail_server_config/change', (req, res, ctx) => res(
    ctx.status(200),
    ctx.json({ mail_server_config: mailServerConfigToShow }),
  )),
  rest.post('/company/mail_server_config/test', (req, res, ctx) => res(
    ctx.status(200),
  )),
];
