/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useMemo } from 'react';
import { useGetFullUserAccountQuery, useGetPaymentsBalanceQuery } from '../../../api/appApi';
import { useSettlementsVenue } from '../../../hooks/settlements/useSettlementsVenue';
import Button from '../../../components/Button/Button';
import useMessages from '../../../hooks/useMessages';
import Spinner from '../../../components/Spinner/Spinner';
import { VenuePaymentsBalance } from '../../../api/modules/settlements/settlementsTypes';
import { usePermissions } from '../../../hooks/usePermissions';

type VenueItemProps = {
  id: string,
  address: string,
  settlements: VenuePaymentsBalance,
  details?: boolean
};

const VenueItem = ({ id, address, settlements, details = true }: VenueItemProps) => {
  const getMessage = useMessages();

  return (
    <div className="w-full last:mb-0" key={id}>
      <div className="flex justify-between text-title-semibold text-main pb-2 border-b">
        {`${settlements.balance} PLN`}
        <span className="text-typography-dark">
          {address}
        </span>
      </div>
      {details && 
      <div className="text-text-md-medium py-4 h-24">
        <div className="mb-2 flex justify-between">
          {getMessage('dashboard.widget.settlements.debit')}
          <span className="text-typography-dark">{settlements?.liability}</span>
        </div>
          <div className="flex justify-between">
            {getMessage('dashboard.widget.settlements.credit')}
            <span className="text-typography-dark">
              {settlements.overpayment}
            </span>
          </div>
      </div>}
    </div>
  );
};

const SettlementsWidget = () => {
  const { data: user } = useGetFullUserAccountQuery();
  const getMessage = useMessages();
  const navigate = useNavigate();
  const handleNavigate = () => navigate('/rozrachunki/platnosci');
  const { isModuleEnabled } = usePermissions();

  const { venueId } = useSettlementsVenue();
  const usersVenues = useMemo(() => user?.tenant?.venues || [], [user, venueId]);
  const filteredVenues = venueId ? usersVenues.filter((venue) => venue.id === venueId) : usersVenues;
  const filteredVenuesIds = filteredVenues.map(({ id }) => id);
  const { data: paymentsBalances } = useGetPaymentsBalanceQuery({ venueId });


  const isButtonVisible = useMemo(
    () => (paymentsBalances
      ? !!filteredVenuesIds.find((id: string) => (paymentsBalances[id] && paymentsBalances[id]?.balance !== '0,00'))
      : false),
    [paymentsBalances, filteredVenuesIds],
  );

  return (
    <div className="w-full">
      <p className="text-header-xs mb-2 inline-block">{getMessage('dashboard.widget.settlements.balance')}</p>
      {paymentsBalances ? (
        <div className="p-6 bg-background-white border rounded">
          {filteredVenues.map((venue) => (
            paymentsBalances[venue.id]
              ? <VenueItem key={venue.id} id={venue.id} address={venue.address} settlements={paymentsBalances[venue.id]} />
              : <Spinner className="h-24" />))}
          {filteredVenues.length > 1 &&
            <VenueItem
              key='all-venue-balance'
              id='all-venue-balance'
              address={getMessage('dashboard.widget.settlements.allVenues')}
              settlements={paymentsBalances['all']}
              details={false}
            />}
          {isModuleEnabled('payments') && isButtonVisible && (
            <Button onClick={handleNavigate} className="!w-full">
              {getMessage('settlements.payments.pay')}
            </Button>
          )}
        </div>
      ) : <Spinner className="h-24" />}
    </div>
  );
};

export default SettlementsWidget;
