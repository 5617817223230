import * as React from 'react';
import { useIntl } from 'react-intl';
import { SettlementTitleBalance } from '../../../../../api/modules/settlements/settlementsTypes';
import Card from '../../../../../components/Card/Card';
import useMessages from '../../../../../hooks/useMessages';
import MobileDocumentItem from './MobileDocumentItem';
import Spinner from '../../../../../components/Spinner/Spinner';

interface Props {
  data?: SettlementTitleBalance
}

const MobileTitleBalanceTable = ({ data }: Props) => {
  const getMessage = useMessages();
  const intl = useIntl();

  return (
    data ? (
      <Card className="col-span-full">
        <div className="flex flex-row justify-between border-b leading-normal text-text-md-semibold text-typography-dark pb-4">
          <div className="flex flex-col justify-end text-typography-black">
            <span>{getMessage('settlements.balance.openingBalance')}</span>
          </div>
          <div className="flex flex-col justify-end items-end">
            <span>{getMessage('settlements.titleBalance.header.deposits')}</span>
            <span>{data?.openingBalance?.debits}</span>
          </div>
          <div className="flex flex-col justify-end items-end">
            <span>{getMessage('settlements.titleBalance.header.credits')}</span>
            <span>{data?.openingBalance?.credits}</span>
          </div>
          <div className="flex flex-col justify-end items-end">
            <span>
              {getMessage('settlements.titleBalance.header.balance')}
            </span>
            <span className="text-typography-black">{data?.openingBalance?.balance}</span>
          </div>
        </div>
        {(data.documents?.length > 0)
          ? data.documents.map((doc) => <MobileDocumentItem document={doc} />)
          : (
            <div className="py-4 leading-normal text-text-md-semibold text-typography-dark border-b">
              {intl.formatMessage({ id: 'settlements.titleBalance.noResults' }, { title: data.title })}
            </div>
          )}
        <div className="flex flex-row justify-between leading-normal text-text-md-semibold text-typography-black pb-4">
          <div className="flex flex-col justify-end">
            <span>{getMessage('settlements.balance.sum')}</span>
          </div>
          <div className="flex flex-col justify-end items-end">
            <span>{getMessage('settlements.titleBalance.header.deposits')}</span>
            <span>{data?.sum.debits}</span>
          </div>
          <div className="flex flex-col justify-end items-end">
            <span>{getMessage('settlements.titleBalance.header.credits')}</span>
            <span>{data?.sum.credits}</span>
          </div>
          <div className="flex flex-col justify-end items-end">
            <span>
              {getMessage('settlements.titleBalance.header.balance')}
            </span>
            <span className="">{data?.sum.balance}</span>
          </div>
        </div>
      </Card>
    ) : <Card><Spinner /></Card>
  );
};

export default MobileTitleBalanceTable;
