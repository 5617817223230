import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import RolesSidebar from './RolesSidebar';
import PageHeader from '../../components/Layout/PageHeader';
import { useGetRolesQuery } from '../../api/appApi';
import RoleEditCard from './RoleEditCard/RoleEditCard';
import { usePermissions } from '../../hooks/usePermissions';
import ContentWrapper from '../../components/ContentWrapper/ContentWrapper';
import Layout from '../../components/Layout/Layout';

interface Props {
  newRole?: boolean,
}

const RolesPage = ({ newRole = false }: Props) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const rolesQuery = useGetRolesQuery();
  const { checkRoleType } = usePermissions();

  useEffect(() => {
    if (!id && rolesQuery?.data && !newRole) {
      const adminRole = rolesQuery.data.find((role) => role.isDefault && role.roleType === 'admin');
      navigate(`/uprawnienia/role/${adminRole?.id}`);
    }
  }, [id, rolesQuery, newRole]);

  const selectedRole = useMemo(() => rolesQuery?.data?.find((role) => role.id === id), [rolesQuery, id]);

  checkRoleType('admin');

  return (
    <Layout currentPage="roles">
      <div className="flex flex-grow h-full">
        <RolesSidebar />
        <div className="flex flex-col flex-grow items-stretch">
          <PageHeader pageTitle="Role" currentPage="roles" />
          <ContentWrapper>
            <RoleEditCard role={selectedRole} />
          </ContentWrapper>
        </div>
      </div>
    </Layout>
  );
};

export default RolesPage;
