{
    "meters": [
        "meters_list",
        "meters_history_list",
        "meters_measurement_add"
    ],
    "tickets": [
        "tickets_list",
        "tickets_add"
    ],
    "tasks": [
        "tasks_list",
        "tasks_add"
    ],
    "documents": [
        "documents_list",
        "documents_add"
    ],
    "resolutions": [
        "resolutions_list",
        "resolutions_vote_add",
        "resolutions_votings_list"
    ],
    "announcements": [
        "announcements_list",
        "announcements_notice_add"
    ],
    "tenants": [
        "tenants_people_entries_manage"
    ],
    "activity_log": [
        "activity_log_register_entries_list"
    ],
    "settlements": [
        "settlements_balance_list",
        "settlements_document_read",
        "settlements_payments_list",
        "settlements_payment_make"
    ]
}
