import * as React from 'react';
import useMessages from '../../../hooks/useMessages';
import capitalize, { formatNumber } from '../../../../utils/formatters';

interface Props {
  meterType?: string,
  meterCode?: string,
  meterLocation?: string | null,
  measurementUnit?: string,
  lastValue?: number,
}

const MeterTitleLocationContainer = ({ meter }: { meter: Props }) => {
  const getMessage = useMessages();
  const {
    meterType, lastValue, measurementUnit, meterCode, meterLocation,
  } = meter;

  return (
    <div className="flex flex-wrap">
      <h4 className="inline text-header-sm mr-4 mobile:mb-3 mobile:mr-2 tablet:mb-2" aria-label={`Rodzaj licznika - ${meterType}`}>{meterType}</h4>
      <div className="flex flex-wrap text-text-md-medium text-typography-dark border rounded px-1 h-fit w-fit mr-4">
        <span className="inline-block mr-1">
          {`${meterLocation ? `${getMessage('local.meters.location')}: ${capitalize(meterLocation)} / ` : ''}`}
        </span>
        <span className="inline-block mr-1">{` nr: ${meterCode}`}</span>
        {lastValue && (
          <span className="inline-block">{` / ${getMessage('local.meters.previousReading')}: ${formatNumber(lastValue)} ${measurementUnit}`}</span>
        )}
      </div>
    </div>
  );
};
export default MeterTitleLocationContainer;
