import * as React from 'react';
import { useMemo } from 'react';
import { activityCategory } from '../../../api/modules/user/userTypes';
import useMessages from '../../../hooks/useMessages';
import { usePermissions } from '../../../hooks/usePermissions';
import { useGetFullUserAccountQuery } from '../../../api/appApi';
import Filters from '../../../components/Filters/Filters';
import { FilterConfig } from '../../../components/FilterInput/FilterInput';

const ActivityLogFilters = () => {
  const getMessage = useMessages();
  const { hasRoleType } = usePermissions();
  const { data: user } = useGetFullUserAccountQuery();
  const venues = useMemo(() => user?.tenant?.venues, [user]);

  const categoryOptionValues = useMemo(() => {
    let categories;
    if (hasRoleType('admin') && !hasRoleType('worker')) {
      categories = activityCategory.filter((activity) => activity === 'profiles' || activity === 'authentication');
    } else if (hasRoleType('tenant')) {
      categories = activityCategory.filter(
        (activity) => activity !== 'tasks' && activity !== 'tickets' && activity !== 'announcements',
      );
    } else if (hasRoleType('worker')) {
      categories = activityCategory.filter(
        (activity) => (activity !== 'settlements')
        && (activity !== 'resolutions')
        && (activity !== 'residency_amendments'),
      );
    } else {
      categories = activityCategory;
    }
    return categories.map((category) => ({
      value: category as string,
      optionLabel: getMessage(`profile.user.activityLog.menuOption.${category}`),
    }));
  }, [activityCategory]);

  const venueOptionValues = useMemo(() => venues?.map((venue) => ({
    value: venue.id as string,
    optionLabel: venue.address as string,
  })) || [], [user]);

  const filterFields: FilterConfig[] = [
    {
      type: 'dateRange',
      id: 'from_date',
      toDateId: 'to_date',
      label: getMessage('profile.user.activityLog.date'),
    },
    {
      type: 'multiselect',
      id: 'category',
      label: getMessage('profile.user.activityLog.menuOption'),
      selectOptions: categoryOptionValues,
    },
  ];

  if (hasRoleType('tenant')) {
    filterFields.push({
      type: 'multiselect',
      id: 'venues_ids',
      label: getMessage('profile.user.activityLog.venue'),
      selectOptions: venueOptionValues,
    });
  }

  return (
    <Filters
      filterId="myActivityLog"
      filterFields={filterFields}
    />
  );
};

export default ActivityLogFilters;
