import { useCallback } from 'react';
import { debounce } from 'debounce';
import { useAppDispatch, useAppSelector } from './reduxHooks';
import { FilterValues, setFilter, TableId } from '../store/appSlice';

const useFilters = <FilterInputsValues extends FilterValues>(filterFormId: TableId) => {
  const dispatch = useAppDispatch();
  const filterValues = useAppSelector((state) => state.app.filters.find((filter) => filter.id === filterFormId)?.values as FilterInputsValues);

  const saveFilterValues = useCallback(
    debounce(
      (filters: FilterInputsValues) => {
        dispatch(setFilter({ id: filterFormId, values: filters }));
      },
      500,
    ),
    [],
  );

  return { saveFilterValues, filterValues };
};

export default useFilters;
