import * as React from 'react';
import classNames from 'classnames';
import { MetersFormFields, ReadingRequest } from '../../../../../api/modules/meters/metersTypes';
import useMessages from '../../../../../hooks/useMessages';
import Dialog from '../../../../../components/Dialog/Dialog';
import { formatNumber } from '../../../../../../utils/formatters';
import calculateChange from '../../../../../../utils/calculateReadingChange';

interface Props {
  isOpen: boolean,
  meterGroup: ReadingRequest[],
  onAccept: () => void,
  onClose: () => void,
  values: MetersFormFields,
}

const MetersUpdatePopup = ({
  isOpen, meterGroup, onAccept, onClose, values,
}: Props) => {
  const getMessage = useMessages();

  return (
    <Dialog
      isOpen={isOpen}
      acceptButtonText={getMessage('confirm')}
      onClose={onClose}
      closeButtonText={getMessage('cancel')}
      onAccept={onAccept}
      labelledBy="confirmMetersReadingsPopUpTitle"
      focusOnBtn
    >
      <h3 className="visually-hidden" id="confirmMetersReadingsPopUpTitle">
        Potwierdź wprowadzoną wartość odczytu
      </h3>
      <table className={classNames('mobile:w-full mobile:table-fixed w-[75%] border-x-collapse border-y-separate mb-3 text-center p-4')}>
        <thead>
          <tr className={classNames('text-text-lg-medium text-typography-dark border-b border-stroke-grey-300')}>
            <th className={classNames('h-14 text-left')}>
              {getMessage('local.meters.meter')}
            </th>
            <th className={classNames('h-14 mobile:px-1 px-8')}>
              {`${getMessage('local.meters.newReading')} `}
            </th>
            <th className={classNames('h-14 text-right')}>
              {getMessage('local.meters.previousReading')}
            </th>
          </tr>
        </thead>
        <tbody className={classNames('mobile:overflow-y-auto')}>
          {meterGroup.map(({
            meterType, meterCode, meterId, lastValue, measurementUnit,
          }) => {
            const newValue = values[meterId];
            const { change, percentageChange } = calculateChange(lastValue, newValue);

            return (
              <tr key={meterId} className="border-b border-stroke-grey-300 last:border-b-0 last:pb-0 whitespace-pre-wrap">
                <td className="text-left py-3">
                  <p className="text-title-semibold text-typography-black">{meterType}</p>
                  <p className="text-text-md-medium text-typography-dark">{meterCode}</p>
                </td>
                <td className="py-3 mobile:px-1"><p className="text-title-semibold">{`${newValue && formatNumber(newValue)} ${measurementUnit}`}</p></td>
                <td className="text-right py-3">
                  <p className="text-title-semibold text-typography-black">{`${(typeof lastValue === 'number') ? formatNumber(lastValue) : '-'} ${measurementUnit}`}</p>
                  {lastValue !== undefined && (
                    <p className="text-text-md-medium text-typography-dark">
                      {!!percentageChange && `${formatNumber((percentageChange).toFixed(2))}%`}
                    </p>
                  )}
                  <p className="text-text-md-medium text-typography-dark">
                    {`(${formatNumber(change)} ${measurementUnit}) ${change > 0 ? '↑' : '↓'}`}
                  </p>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Dialog>
  );
};

export default MetersUpdatePopup;
