import * as React from 'react';
import Spinner from './Spinner';

interface Props {
  containerStyles?: string,
  spinnerStyles?: string
}

const SpinnerItem = ({ containerStyles, spinnerStyles }: Props) => (
  <li className={containerStyles}>
    <Spinner className={spinnerStyles} />
  </li>
);

export default SpinnerItem;
