import * as React from 'react';
import { useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { FormSelect } from '../../../../components/FormInputs';

import useMessages from '../../../../hooks/useMessages';
import { FullTask, postTaskCommissionsPayload } from '../../../../api/modules/tasks/tasksTypes';
import Button from '../../../../components/Button/Button';
import {
  usePostTaskCommissionMutation,
  useGetPortalProServicesQuery,
  usePutTaskCommissionsMutation,
} from '../../../../api/appApi';
import FormSearchableDropdown from '../../../../components/FormInputs/FormSearchableDropdown';
import { InputLabel } from '../../../../components/FormInputs/InputLabel';
import { usePermissions } from '../../../../hooks/usePermissions';

import PortalProPopup from './PortalProPopup';

interface Props {
  task: FullTask,
}

const ConnectedCommissionSection = ({ task }: Props) => {
  const methods = useForm()
  const getMessage = useMessages();
  const [updateTaskCommissionsData] = usePutTaskCommissionsMutation();
  const { isModuleEnabled } = usePermissions();
  const [popupOpen, setPopupOpen] = useState(false);


  const onStatusCheckSubmit = () => {
    updateTaskCommissionsData({
      id: task.id
    });
    methods.reset();
  };

  const onPopupOpen = () => {
    setPopupOpen(true);
  }

  if (!isModuleEnabled('portal_pro')) {
    return null;
  }

  return (
    <>
      {task.ticketId !== undefined &&
        <section>
          {task.commissions.length === 0 &&
            <div>
              <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onPopupOpen)} className="w-full pt-2 text-sm flex flex-col">
                  <Button type="submit" className="mobile:w-full mobile:mt-4">{getMessage('tasks.taskCommission.apiSubmit')}</Button>
                </form>
                <PortalProPopup
                  isOpen={popupOpen}
                  task={task}
                  onClose={() => setPopupOpen(false)}
                  setPopupOpen={setPopupOpen}
                  />
              </FormProvider>
            </div>
          }
          {task.commissions.length > 0 && task.commissions.map((commission) => (
            <div id={commission.id}>
              <p className='text-typography-dark mb-4 lg:mb-1 bg-green-200 py-2 px-4 rounded-md'>{commission.provider}</p>
              <div className='text-typography-dark mb-4 lg:mb-1 bg-background-light py-2 px-4 rounded-md whitespace-pre-line'>
                {getMessage(`tasks.taskCommission.status.${commission.internalCommissionStatus}`)}
              </div>
              {commission?.portalPro?.jobTrackingUrl && <div className='text-typography-dark mb-4 lg:mb-1 bg-green-200 py-2 px-4 rounded-md whitespace-pre-line'>
                <a href={commission?.portalPro?.jobTrackingUrl} target="_blank">{getMessage('tasks.taskCommission.jobTrackingUrl')}</a>
              </div>
              }
              <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onStatusCheckSubmit)} className="w-full pt-2 text-sm flex flex-col">
                  <Button type="submit" className="mobile:w-full mobile:mt-4">{getMessage('tasks.taskCommission.apiStatus')}</Button>
                </form>
              </FormProvider>
            </div>
          ))}
        </section>}
    </>
  );
};

export default ConnectedCommissionSection;
