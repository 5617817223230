/* eslint-disable react/jsx-props-no-spreading */
import { RegisterOptions, useFormContext } from 'react-hook-form';
import * as React from 'react';
import classnames from 'classnames';

interface FormInputProps {
  id: string,
  label?: string,
  className?: string,
  options?: RegisterOptions,
  disabled?: boolean,
}

const FormCheckboxInput = ({
  id, label, className, options, disabled,
}: FormInputProps) => {
  const { register } = useFormContext();

  return (
    <div className={classnames('group', className)}>
      <input
        className={classnames('m-2 group-hover:cursor-pointer', disabled ? 'group-hover:cursor-default' : 'group-hover:cursor-pointer')}
        type="checkbox"
        id={id}
        {...register(id, options)}
        disabled={disabled}
      />
      {label && <label htmlFor={id} className={classnames('text-text-lg-semibold', disabled ? 'text-typography-dark' : 'text-typography-black group-hover:cursor-pointer')}>{label}</label>}
    </div>
  );
};

export default FormCheckboxInput;
