import * as React from 'react';
import format from 'date-fns/format';
import classNames from 'classnames';
import { useMemo, useEffect } from 'react';
import { useGetMetersVenuesListQuery } from '../../../../api/appApi';
import ResponsiveTable, { ResponsiveTableHeader, ResponsiveTableBody, ResponsiveTableBodyRow } from '../../../../components/Table/ResponsiveTable';
import { TableBodyCell } from '../../../../components/Table/Table';
import Chip from '../../../../components/Chip/Chip';
import { TableId } from '../../../../store/appSlice';
import usePagination from '../../../../hooks/usePagination';
import PaginationControls from '../../../../components/Table/PaginationControls';
import useFilters from '../../../../hooks/useFilters';
import useHomeownerAssociationsMode from '../../../../hooks/useHomeownerAssociationsMode';
import TableContentPlaceholder from '../../../../components/Table/TableContentPlaceholder';

const MetersVenuesList = () => {
  const [selectedPage, setSelectedPage] = usePagination('metersVenuesList');
  const { filterValues } = useFilters('metersVenuesList');
  const filters = { ...filterValues };
  const hoaMode = useHomeownerAssociationsMode();

  useEffect(() => {
    setSelectedPage(1);
  }, []);

  const { data } = useGetMetersVenuesListQuery({
    ...filters,
    page: selectedPage.toString(),
  });

  const ids = data?.venues.map(({ venueId }) => venueId) || [];
  const columnLabels = useMemo(() => {
    const columns = [
      { label: 'meters.venuesList.columns.tenantExternalId' },
      { label: 'meters.venuesList.columns.name' },
      { label: 'meters.venuesList.columns.venueCode' },
      { label: 'meters.venuesList.columns.building' },
      { label: 'meters.venuesList.columns.residencyDates' },
    ];
    if (hoaMode) {
      columns.push({ label: 'meters.venuesList.columns.homeownerAssociation' });
    }
    return columns;
  }, [hoaMode]);
  return (
    <>
      <ResponsiveTable className="col-span-full">
        {data?.venues && data?.venues.length === 0 ? <TableContentPlaceholder /> : (
          <>
            <ResponsiveTableHeader ids={ids} columnLabels={columnLabels} tableName={'metersVenuesList' as TableId} />
            <ResponsiveTableBody>
              {data?.venues.map(({
                building, code, homeownerAssociation, venueId, measurementRequired, residencyFrom, residencyUntil, tenant, tenantExternalId,
              }) => (
                <ResponsiveTableBodyRow
                  key={venueId}
                  className="border-b last:border-b-0 border-separate"
                  clickable={({
                    href: measurementRequired
                      ? `/liczniki/lokale/${venueId}/do-odczytu`
                      : `/liczniki/lokale/${venueId}`,
                    ariaLabel: `Przejdź do ${measurementRequired ? 'podstrony wprowadzania odczytów liczników' : 'listy poprzednich odczytów liczników'} dla lokalu ${code} ${building} dla lokatora ${tenant}`,
                  })}
                >
                  <TableBodyCell
                    className={classNames(
                      { "before:content-[' '] before:-ml-4 before:bg-main before:w-[8px] before:h-[8px] before:rounded-full before:shrink-0 flex items-center": measurementRequired },
                    )}
                  >
                    <div className={`${measurementRequired && 'ml-2'}`}>{tenantExternalId}</div>
                  </TableBodyCell>
                  <TableBodyCell>{tenant}</TableBodyCell>
                  <TableBodyCell>{code}</TableBodyCell>
                  <TableBodyCell>{building}</TableBodyCell>
                  <TableBodyCell>
                    <Chip chipStyle="ghost" className="w-fit">
                      {residencyFrom && format(new Date(residencyFrom), 'dd-MM-yyyy')}
                      {residencyUntil && ` - ${format(new Date(residencyUntil), 'dd-MM-yyyy')}`}
                    </Chip>
                  </TableBodyCell>
                  {hoaMode && (
                  <TableBodyCell>
                    {homeownerAssociation}
                  </TableBodyCell>
                  )}
                </ResponsiveTableBodyRow>
              ))}
            </ResponsiveTableBody>
          </>
        )}
      </ResponsiveTable>
      {data?.metadata && (
      <PaginationControls
        className="col-span-full"
        selectedPage={selectedPage}
        setSelectedPage={setSelectedPage}
        paginationMetadata={data.metadata}
      />
      )}
    </>
  );
};

export default MetersVenuesList;
