import * as React from 'react';
import { useNavigate } from 'react-router';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import useMessages from '../../../../hooks/useMessages';
import PageHeader from '../../../../components/Layout/PageHeader';
import { InputLabel } from '../../../../components/FormInputs/InputLabel';
import { Select, SelectOption } from '../../../../components/FormInputs/inputs/FormSelect';
import { months } from '../../../../api/modules/settlements/settlementsTypes';
import {
  useGetSettlementsTitlesQuery,
  useGetSettlementsYearsQuery,
} from '../../../../api/appApi';
import { useSettlementsVenue } from '../../../../hooks/settlements/useSettlementsVenue';
import { useAppSelector } from '../../../../hooks/reduxHooks';
import { selectSettlementsTitle } from '../../../../store/appSlice';
import { usePermissions } from '../../../../hooks/usePermissions';

interface Props {
  year: string,
  month: string,
  path?: string,
  tenantId?: string,
}

const MonthsBalanceHeader = ({
  year, month, path, tenantId,
}: Props) => {
  const getMessage = useMessages();
  const navigate = useNavigate();
  const monthName = months[parseInt(month, 10) - 1];
  const { venueId, venueAddress } = useSettlementsVenue();
  const { hasRoleType } = usePermissions();
  const { data: years } = useGetSettlementsYearsQuery(
    { venue: venueId, tenantId: hasRoleType('worker') ? tenantId : '' },
    { skip: !venueId || (hasRoleType('worker') && !tenantId) },
  );
  const { data: titles } = useGetSettlementsTitlesQuery(
    { venue: venueId, tenantId: hasRoleType('worker') ? tenantId : '' },
    { skip: !venueId || (hasRoleType('worker') && !tenantId) },
  );

  const dispatch = useDispatch();
  const selectedTitleId = useAppSelector((state) => state.app.selectedSettlementsTitle);

  const yearsSelectOptions: SelectOption[] = useMemo(() => years?.map((year) => ({
    value: year,
    optionLabel: year,
  })) || [], [years]);

  const monthsSelectOptions: SelectOption[] = useMemo(() => months?.map((month, index) => ({
    value: (index + 1).toString(),
    optionLabel: getMessage(`datepicker.month.${month}`),
  })) || [], [years]);

  const titlesSelectOptions: SelectOption[] = useMemo(() => [{ value: '', optionLabel: 'Wszystkie' }].concat(titles?.map((title) => ({
    value: title.id,
    optionLabel: title.name,
  })) || []), [titles || 0]);

  const selectTitle = (titleId: string) => {
    dispatch(selectSettlementsTitle(titleId));
  };

  return (
    <PageHeader
      pageTitle={`${getMessage('navigation.settlements.balance')}: ${getMessage(`datepicker.month.${monthName}`)}`}
      currentPage="settlements"
      subheader={venueAddress}
      path={path}
    >
      <div className="flex items-center text-typography-dark">
        <InputLabel label={getMessage('settlements.balance.filters.year')} inline />
        <Select
          value={year}
          onChange={(newYear) => navigate(`/rozrachunki/analiza_salda/${newYear}/${month}?venueId=${venueId}`)}
          selectOptions={yearsSelectOptions}
          label="year"
        />
      </div>
      <div className="flex items-center text-typography-dark ml-4">
        <InputLabel label={getMessage('settlements.balance.filters.month')} inline />
        <Select
          value={month}
          onChange={(newMonth) => navigate(`/rozrachunki/analiza_salda/${year}/${newMonth}?venueId=${venueId}`)}
          selectOptions={monthsSelectOptions}
          inputClassName="w-32"
          label="month"
        />
      </div>
      <div className="flex items-center text-typography-dark ml-4">
        <InputLabel label={getMessage('settlements.balance.filters.title')} inline />
        <Select
          value={selectedTitleId || ''}
          onChange={(newTitle) => selectTitle(newTitle)}
          selectOptions={titlesSelectOptions}
          inputClassName="w-60"
          label={getMessage('settlements.balance.filters.title')}
        />
      </div>
    </PageHeader>
  );
};

export default MonthsBalanceHeader;
