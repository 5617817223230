import * as React from 'react';
import { useLocation } from 'react-router';
import queryString from 'query-string';
import { useState } from 'react';
import { useGetFullUserAccountQuery } from '../../../api/appApi';
import PaymentsTable from './PaymentsTable/PaymentsTable';
import ExpandableCard from '../../../components/ExpandableCard/ExpandableCard';
import Card from '../../../components/Card/Card';
import PaymentInProgressDialog from './PaymentInProgressDialog/PaymentInProgressDialog';
import { useSettlementsVenue } from '../../../hooks/settlements/useSettlementsVenue';
import { usePermissions } from '../../../hooks/usePermissions';

const PaymentsPage = () => {
  const [progressDialogOpen, setProgressDialogOpen] = useState(true);
  const { data: user } = useGetFullUserAccountQuery();
  const selectedSettlementsTenantId = localStorage.getItem(`selectedSettlementsTenantId-${user?.id}`);
  const { search } = useLocation();
  const { payment_id: paymentId } = queryString.parse(search);

  const { venueId } = useSettlementsVenue();

  const { checkModuleEnabled } = usePermissions();
  checkModuleEnabled('settlements');
  checkModuleEnabled('payments');

  if (venueId) {
    return (
      <Card className="col-span-12">
        <PaymentsTable venueId={venueId} tenantId={selectedSettlementsTenantId || ''} />
      </Card>
    );
  }
  return (
    <>
      {(user?.tenant?.venues || []).map((venue) => (
        <React.Fragment key={venue.id}>
          {paymentId && (
            <PaymentInProgressDialog
              paymentId={paymentId as string}
              isOpen={progressDialogOpen}
              close={() => setProgressDialogOpen(false)}
            />
          )}
          <ExpandableCard className="col-span-12" title={venue.address}>
            <PaymentsTable venueId={venue.id} />
          </ExpandableCard>
        </React.Fragment>
      ))}
    </>
  );
};

export default PaymentsPage;
