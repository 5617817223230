import { PaginationMetadata } from '../../paginationTypes';

export interface ApiMetersResponse {
  meters: ApiMeter[]
}

export interface ApiMeter {
  can_update: boolean,
  last_value?: string,
  meter_group?: string | null,
  meter_id: string,
  meter_type: ApiMeterType,
  meter_code: string,
  meter_location: string | null,
  valid_until: string,
}

export const apiMeterType = [
  'CW', 'ZW', 'task', 'GAS', 'PRAD', 'CO',
] as const;
export type ApiMeterType = typeof apiMeterType[number];

export type ApiMeasurements = {
  measurements: {
    meter_id: string,
    value: string | undefined,
  }[]
};

export interface ApiMeterHistoryResponse {
  history: ApiMeterHistory[],
}

export interface ApiMetersVenue {
  building: string,
  code: string,
  homeowner_association: string,
  id: string,
  measurement_required: true,
  residency_from: string,
  residency_until: string | null,
  tenant: string,
  tenant_external_id: string,
}
export interface ApiMetersVenuesListResponse {
  metadata: PaginationMetadata,
  venues: ApiMetersVenue[],
}

export interface ApiMeterRequestParams {
  venues_ids?: string | string[],
  measurement_required?: boolean,
  type?: ApiMeterType,
  location?: string,
}

export interface ApiMeterHistory {
  data_source: string,
  measurement_taken_at: string,
  measurement_value: string,
}
export interface Meter {
  meterId: string,
  meterType: string,
  meterCode: string,
  meterLocation: string | null,
  measurementUnit: string,
}
export interface ReadingRequest extends Meter {
  meterGroup?: string,
  validUntil: Date,
  lastValue?: number,
  canUpdate: boolean,
}

export interface MeterHistory {
  value: string,
  date: string,
  source: string,
}

export interface Measurement {
  meterId: string,
  value: string | undefined,
}

export interface MetersFormFields {
  [key: string]: number,
}

export interface MetersVenue {
  building: string,
  code: string,
  homeownerAssociation: string,
  venueId: string,
  measurementRequired: true,
  residencyFrom: string,
  residencyUntil: string | null,
  tenant: string,
  tenantExternalId: string,
}

export interface MetersVenuesList {
  metadata: PaginationMetadata,
  venues: MetersVenue[]
}
