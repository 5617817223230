import * as React from 'react';
import { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useGetDocumentsQuery } from '../../../api/appApi';
import usePagination from '../../../hooks/usePagination';
import useFilters from '../../../hooks/useFilters';
import useSortParams from '../../../hooks/useSortParams';
import { FilterValues } from '../../../store/appSlice';
import { md } from '../../../../utils/breakpoints';
import PaginationControls from '../../../components/Table/PaginationControls';
import EmployeeDocumentsTableDesktop from './EmployeeDocumentsTableDesktop';
import EmployeeDocumentsTableMobile from './EmployeeDocumentsTableMobile';

const EmployeeDocumentsList = () => {
  const [selectedPage, setSelectedPage] = usePagination('documentsList');
  const { filterValues } = useFilters('documentsList');
  const { sortParamsValues } = useSortParams('documentsList');
  const filters: FilterValues = { ...filterValues };

  useEffect(() => {
    setSelectedPage(1);
  }, []);

  const { data } = useGetDocumentsQuery({
    ...sortParamsValues,
    ...filters,
    page: selectedPage.toString(),
  }, { refetchOnMountOrArgChange: true });
  const isTabletOrDesktop = useMediaQuery({ query: `(min-width: ${md})` });
  const documentsData = data?.documents;

  return (
    <>
      {isTabletOrDesktop ? (
        <EmployeeDocumentsTableDesktop documents={documentsData || []} />
      ) : <EmployeeDocumentsTableMobile documents={documentsData || []} />}
      {data?.metadata && (
        <PaginationControls
          className="col-span-full"
          selectedPage={selectedPage}
          setSelectedPage={setSelectedPage}
          paginationMetadata={data.metadata}
        />
      )}
    </>
  );
};

export default EmployeeDocumentsList;
