import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import FilterInput, { FilterConfig } from '../FilterInput/FilterInput';
import useMessages from '../../hooks/useMessages';
import useFilters from '../../hooks/useFilters';
import { TableId } from '../../store/appSlice';
import Button from '../Button/Button';

interface Props {
  filterId: TableId,
  filterFields: FilterConfig[],
  showReset?: boolean,
}

interface DefaultValues {
  [key: string]: string | string[]
}

const Filters = ({ filterId, filterFields, showReset = true }: Props) => {
  const getMessage = useMessages();
  const { saveFilterValues, filterValues } = useFilters(filterId);

  const defaultValues: DefaultValues = useMemo(() => {
    const defaults: DefaultValues = {};
    filterFields.forEach((field) => {
      switch (field.type) {
        case 'multiselect':
          defaults[field.id] = filterValues?.[field.id] || field.defaultValue || [];
          break;
        case 'dateRange':
          defaults[field.id] = filterValues?.[field.id] || field.defaultValue || '';
          defaults[field.toDateId] = filterValues?.[field.toDateId] || field.defaultValue || '';
          break;
        default:
          defaults[field.id] = filterValues?.[field.id] || field.defaultValue || '';
      }
    });
    return defaults;
  }, [filterFields]);

  const resetValues: DefaultValues = useMemo(() => {
    const reset: DefaultValues = {};
    filterFields.forEach((field) => {
      switch (field.type) {
        case 'multiselect':
          reset[field.id] = [];
          break;
        case 'dateRange':
          reset[field.id] = '';
          reset[field.toDateId] = '';
          break;
        default:
          reset[field.id] = '';
      }
    });
    return reset;
  }, [filterFields]);

  const methods = useForm({
    defaultValues,
  });

  useEffect(() => {
    filterFields.forEach((field) => {
      if (field.defaultValue) {
        methods.setValue(field.id, field.defaultValue);
      }
    });
  }, [filterFields, defaultValues]);

  useEffect(() => {
    methods.watch(
      (filters) => {
        saveFilterValues(filters);
      },
    );
  }, [methods.watch]);

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <FormProvider {...methods}>
      <form
        className="grid grid-cols-12 items-end gap-2 md:gap-4 w-full bg-light-100 border-t px-2 sm:px-3 md:px-7 lg:px-8 xl:px-12 py-4 md:py-6 lg:py-8"
        onSubmit={methods.handleSubmit(() => 'submit')}
      >
        {filterFields.map((field) => (
          <FilterInput
            key={field.id}
            config={field}
          />
        ))}
        {showReset && (
          <div className="
            col-span-12 md:col-span-4 lg:col-span-2 md:col-end-13 lg:col-end-13 flex flex-row items-end justify-end mt-6 md:mt-0"
          >
            <Button
              onClick={() => methods.reset(() => (resetValues))}
              className="w-full md:w-auto mb-1 py-1 h-8"
              styleType="secondary"
            >
              {getMessage('filters.clean')}
            </Button>
          </div>
        )}
      </form>
    </FormProvider>
  );
};

export default Filters;
