import * as React from 'react';
import { PropsWithChildren } from 'react';
import Card from '../../../components/Card/Card';

interface Props extends PropsWithChildren {
  title?: string,
  className?: string,
  ariaLabel?: string,
}

const DesktopInfoCard = ({
  title, children, className, ariaLabel,
}: Props) => (
  <Card className={`bg-light-100 border border-light-300 hidden lg:block lg:col-span-3 h-fit p-6 ${className}`}>
    {title && (
      <div className="border-b border-b-light-300 pb-6 mb-5">
        <span>
          <h4 className="inline text-header-sm mr-4" aria-label={ariaLabel}>{title}</h4>
        </span>
      </div>
    )}
    {children}
  </Card>
);

export default DesktopInfoCard;
