import * as React from 'react';
import { useMemo } from 'react';
import useMessages from '../../../hooks/useMessages';
import MonthsBalanceHeader from './MonthsBalanceHeader/MonthsBalanceHeader';
import ContentWrapper from '../../../components/ContentWrapper/ContentWrapper';
import Card from '../../../components/Card/Card';
import MonthsBalanceTable from './MonthsBalanceTable/MonthsBalanceTable';
import { useGetMonthsSettlementsBalanceQuery, useGetFullUserAccountQuery } from '../../../api/appApi';
import { useSettlementsVenue } from '../../../hooks/settlements/useSettlementsVenue';
import { usePermissions } from '../../../hooks/usePermissions';
import { MonthBalanceQuery } from '../../../api/modules/settlements/settlementsTypes';

interface Props {
  year: string,
  month: string,
}
const MonthsBalancePage = ({ year, month }: Props) => {
  const getMessage = useMessages();
  const { hasRoleType } = usePermissions();
  const { venueId } = useSettlementsVenue();
  const { data: user } = useGetFullUserAccountQuery();
  const selectedSettlementsTenantId = user && localStorage.getItem(`selectedSettlementsTenantId-${user?.id}`);

  const queryParams = useMemo(() => {
    const params: MonthBalanceQuery = {
      venue: venueId,
      year,
      month,
    };
    if (hasRoleType('worker') && selectedSettlementsTenantId) {
      params.tenant_id = selectedSettlementsTenantId;
    }
    return params;
  }, [venueId, year, month, selectedSettlementsTenantId]);
  const { data, isLoading, isSuccess } = useGetMonthsSettlementsBalanceQuery(
    queryParams,
    { skip: !venueId || !year || !month || (hasRoleType('worker') && !selectedSettlementsTenantId) },
  );

  return (
    <>
      <MonthsBalanceHeader
        year={year}
        month={month}
        path={`/rozrachunki/analiza_salda/${year}${hasRoleType('worker') ? `?venueId=${venueId}` : ''}`}
        tenantId={selectedSettlementsTenantId || ''}
      />
      <ContentWrapper>
        {venueId ? (
          <MonthsBalanceTable
            data={data}
            year={year}
            month={month}
            isLoading={isLoading}
            isSuccess={isSuccess}
          />
        ) : (
          <Card className="col-span-12">
            {getMessage('settlements.balance.chooseVenue')}
          </Card>
        )}
      </ContentWrapper>
    </>
  );
};

export default MonthsBalancePage;
