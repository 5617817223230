import * as React from 'react';
import { usePermissions } from '../../hooks/usePermissions';
import EmployeeTicketsPage from './EmployeeTicketsPage';
import TenantTicketsPage from './TenantTicketsPage';

interface EmployeeTicketsPageProps {
  tab?: 'me' | 'none',
}

const TicketsPage = ({ tab }: EmployeeTicketsPageProps) => {
  const { checkPermission, hasRoleType, checkModuleEnabled } = usePermissions();

  checkPermission('tickets_list');
  checkModuleEnabled('tickets');

  return (
    hasRoleType('worker') ? <EmployeeTicketsPage tab={tab} /> : <TenantTicketsPage />
  );
};

export default TicketsPage;
