import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import queryString from 'query-string';
import {
  ApiBaseQuery, ApiTagTypes,
} from '../../baseQuery';
import {
  Venues, Venue, VenueDetailsApiResponse, VenueDetails,
} from './venuesTypes';
import { translateVenue } from './venuesTranslator';

export const venuesQueries = (builder: EndpointBuilder<ApiBaseQuery, ApiTagTypes, 'api'>) => ({
  getVenues: builder.query<Venue[], Record<string, string | null>>({
    query: (params) => {
      const stringifiedParams = queryString.stringify(params, { arrayFormat: 'bracket' });
      return `venues/list?${stringifiedParams}`;
    },
    transformResponse(baseQueryReturnValue: Venues): Venue[] {
      return baseQueryReturnValue.venues;
    },
  }),
  getVenueDetails: builder.query<VenueDetails, string>({
    query: (id) => `meters/venues/${id}/show`,
    transformResponse(baseQueryReturnValue: VenueDetailsApiResponse): VenueDetails {
      return translateVenue(baseQueryReturnValue);
    },
  }),
});
