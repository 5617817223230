import { useAppDispatch, useAppSelector } from './reduxHooks';
import { setPaginationPage, TableId } from '../store/appSlice';

const usePagination = (tableId: TableId): [number, (n: number) => void] => {
  const dispatch = useAppDispatch();
  const selectedPage = useAppSelector((state) => {
    const pag = state.app.pagination.find((pagination) => pagination.tableId === tableId);
    return pag?.selectedPage || 1;
  });

  const selectPage = (newPageNumber: number) => {
    dispatch(setPaginationPage({
      id: tableId,
      selectedPage: newPageNumber,
    }));
  };

  return [selectedPage, selectPage];
};

export default usePagination;
