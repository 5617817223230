import * as React from 'react';
import { useGetCompanySettingsQuery } from '../../api/appApi';
import useMessages from '../../hooks/useMessages';

const VersionDisplay = () => {
  const getMessage = useMessages();
  const { data: companySettings } = useGetCompanySettingsQuery();

  return (
    <div className="mx-2 lg:mx-7 border-t flex flex-col items-center text-typography-dark text-text-md-medium">
      <div className="mt-4">
        {`${getMessage('navigation.version')} ${companySettings?.version}`}
      </div>
      <div className="mb-2">
        {getMessage('navigation.probit')}
      </div>
    </div>
  );
};

export default VersionDisplay;
