/* eslint-disable react/jsx-props-no-spreading */

import * as React from 'react';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { useGetResolutionQuery } from '../../../../api/appApi';
import useMessages from '../../../../hooks/useMessages';
import AttachmentPreview from '../../../../components/AttachmentPreview/AttachmentPreview';
import Card from '../../../../components/Card/Card';
import Chip from '../../../../components/Chip/Chip';
import { ResolutionData } from '../../../../api/modules/resolutions/resolutionsTypes';
import LocationIcon from '../../../../icons/location.svg';

const EmployeeResolutionPage = () => {
  const { id } = useParams();
  const { data, isSuccess } = useGetResolutionQuery(id, { skip: !id });
  const getMessage = useMessages();

  if (!isSuccess) return null;

  const {
    active, title, text, createdAt, attachments,
    votingDeadline, status, votedAt, homeownerAssociationName,
  } = data as ResolutionData;

  return (
    <Card className="row-span-1 row-start-1 col-span-full">
      <div className="mb-8 pb-6 border-b">
        <h2 className="text-header-sm">{title}</h2>
        <div className="md:flex justify-between items-center">
          <div className="flex mt-2 mb-3 md:mb-0 text-main">
            <LocationIcon />
            <p className="ml-1 text-text-lg-medium">
              <span className="visually-hidden">Wspólnota</span>
              {homeownerAssociationName}
            </p>
          </div>
          <div className="flex">
            <Chip chipStyle="primary" className="min-w-fit h-fit">{active ? getMessage('resolutions.resolution.votingActive') : getMessage(`resolutions.status.${status}`)}</Chip>
            <Chip chipStyle="ghost" className="min-w-fit h-fit ml-2">
              {getMessage('resolutions.resolution.createdAt')}
              {createdAt && format(new Date(createdAt), 'dd-MM-yyyy')}
            </Chip>
            {votedAt && (
            <Chip chipStyle="ghost" className="min-w-fit h-fit ml-2">
              <span className="font-semibold">{getMessage('resolutions.voted')}</span>
              {format(new Date(votedAt), 'dd-MM-yyyy')}
            </Chip>
            )}
          </div>
        </div>
      </div>
      <section className="text-typography-dark text-text-lg-medium whitespace-pre-line">
        {text}
        <p className="text-text-md-semibold my-4">
          {getMessage('resolutions.resolution.voteUntil')}
          {votingDeadline && format(new Date(votingDeadline), 'dd-MM-yyyy')}
        </p>
      </section>
      {attachments?.length > 0 && (
        <section className="my-6 text-text-md-medium">
          <ol className="flex flex-wrap">
            {attachments.map(({ filename, path, contentType }) => (
              <AttachmentPreview key={path} filename={filename} path={path} contentType={contentType} />))}
          </ol>
        </section>
      )}
    </Card>
  );
};

export default EmployeeResolutionPage;
