import * as React from 'react';
import useMessages from '../../../hooks/useMessages';
import Pill from '../../../components/Pill/Pill';
import { AnnouncementsRecipient } from '../../../api/modules/announcements/announcementsTypes';

interface Props {
  label: string,
  recipientGroup: AnnouncementsRecipient[]
}

const RecipientGroupItem = ({ label, recipientGroup }: Props) => {
  const getMessage = useMessages();
  const recipients = [...new Set(recipientGroup.map(({ id, name }) => ({ id, name })))];

  return (
    <div className="mb-2 flex items-baseline">
      <div className="text-text-lg-medium mr-6">{getMessage(`announcements.notice.form.${label}`)}</div>
      <div className="flex flex-wrap">
        {recipients?.map(({ id, name }) => <Pill label={name} key={id} />)}
      </div>
    </div>
  );
};

export default RecipientGroupItem;
