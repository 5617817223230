import {
  ApiResolution,
  ApiResolutionsResponse,
  ApiResolutionResponse,
  Resolution,
  ResolutionData,
  ApiAttachment,
  Attachment,
  Resolutions,
  EmployeeApiResolution,
  EmployeeResolution,
  Votings,
  ApiVotingsResponse,
} from './resolutionsTypes';

const translateAttachments = (attachments: ApiAttachment[]): Attachment[] => attachments.map((attachments) => ({
  contentType: attachments.content_type,
  filename: attachments.filename,
  path: attachments.path,
  title: attachments.title,
}));

export const translateSingleResolution = ({ resolution }: ApiResolutionResponse): ResolutionData => ({
  active: resolution.active,
  attachments: translateAttachments(resolution.attachments),
  contentAcceptedAt: resolution.content_accepted_at,
  createdAt: resolution.created_at,
  homeownerAssociationName: resolution.homeowner_association_name,
  title: resolution.title,
  id: resolution.id,
  status: resolution.status,
  text: resolution.text,
  voteOptions: resolution.vote_options,
  voteValue: resolution.vote_value,
  votedAt: resolution.voted_at,
  votingDeadline: resolution.voting_deadline,
  pwiVotes: resolution.pwi_votes,
  resolutionNumber: resolution.resolution_number,
});

export const translateResolution = (resolution: ApiResolution | EmployeeApiResolution) => {
  if ('pwi_votes' in resolution) {
    return ({
      active: resolution.active,
      createdAt: resolution.created_at,
      homeownerAssociationCode: resolution.homeowner_association_code,
      id: resolution.id,
      pwiVotes: resolution.pwi_votes,
      resolutionNumber: resolution.resolution_number,
      status: resolution.status,
      title: resolution.title,
      votingDeadline: resolution.voting_deadline,
    } as EmployeeResolution);
  }
  return ({
    active: resolution.active,
    contentAcceptedAt: resolution.content_accepted_at,
    createdAt: resolution.created_at,
    homeownerAssociationName: resolution.homeowner_association_name,
    title: resolution.title,
    id: resolution.id,
    status: resolution.status,
    text: resolution.text,
    voteValue: resolution.vote_value,
    votedAt: resolution.voted_at,
    votingDeadline: resolution.voting_deadline,
    resolutionNumber: resolution.resolution_number,
  } as Resolution);
};

export const translateApiToResolutions = ({ resolutions, metadata }: ApiResolutionsResponse) : Resolutions => ({
  metadata,
  resolutions: resolutions.map((resolution) => translateResolution(resolution)),
});

export const translateVotings = ({ votings, metadata }: ApiVotingsResponse): Votings => ({
  votings: votings.map((vote) => ({
    dataSource: vote.data_source,
    id: vote.id,
    externalId: vote.external_id,
    tenantName: vote.tenant_name,
    tenantExternalId: vote.tenant_external_id,
    voteValue: vote.vote_value,
    votedAt: vote.voted_at,
  })),
  metadata,
});

export const translateResolutionVoteValueToApi = ({ voteValue }: { voteValue: string }) => ({ vote_value: voteValue });
