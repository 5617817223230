import * as React from 'react';
import useMessages from '../../../hooks/useMessages';
import Layout from '../../../components/Layout/Layout';
import PageHeader from '../../../components/Layout/PageHeader';
import ContentWrapper from '../../../components/ContentWrapper/ContentWrapper';
import ParametersSidebar from '../ParametersSidebar';
import CategoriesTable from '../CategoryTable/CategoriesTable';
import { useGetTicketCategoriesQuery } from '../../../api/appApi';
import { usePermissions } from '../../../hooks/usePermissions';

const TicketsParametersPage = () => {
  const getMessage = useMessages();
  const { checkModuleEnabled } = usePermissions();
  const { data: ticketCategories } = useGetTicketCategoriesQuery();

  checkModuleEnabled('tickets');

  return (
    <Layout currentPage="parameters">
      <div className="flex flex-grow h-full">
        <ParametersSidebar selectedPage="ticketsParameters" />
        <div className="flex flex-col flex-grow">
          <PageHeader currentPage="parameters" pageTitle={getMessage('navigation.parameters.tickets')} />
          <ContentWrapper>
            <CategoriesTable categoryType="ticket" categories={ticketCategories || []} />
          </ContentWrapper>
        </div>
      </div>
    </Layout>
  );
};

export default TicketsParametersPage;
