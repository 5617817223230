import * as React from 'react';
import { useController } from 'react-hook-form';
import classnames from 'classnames';
import DatePicker, { DateObject } from 'react-multi-date-picker';
import { useMonthsAndWeekDays } from '../../../hooks/useMessages';
import { InputLabel } from '../InputLabel';

const inputDefaultStyles = `inline-block px-2 py-1 font-normal text-typography-black bg-white bg-clip-padding
border border-solid border-stroke-grey-300 rounded transition ease-in-out my-1 text-text-md-semibold
mobile:text-base focus:text-typography-black focus:bg-white focus:border-blue-600 focus:outline-none`;

interface FormRangeDatePickerProps {
  idFrom: string,
  idTo: string,
  className?: string,
  inputClassName?: string,
  label?: string,
  showReset?: boolean,
}

const FormRangeDatePicker = ({
  idFrom, idTo, className, label, inputClassName, showReset,
} : FormRangeDatePickerProps) => {
  const { months, weekDays } = useMonthsAndWeekDays();
  const { field: fieldFrom, fieldState: fieldStateFrom } = useController({ name: idFrom });
  const { field: fieldTo, fieldState: fieldStateTo } = useController({ name: idTo });

  return (
    <div className={classnames('flex-col items-start inline-block', className)}>
      {label && (
        <InputLabel
          id={idFrom}
          label={label}
          showReset={showReset && fieldFrom.value && fieldTo.value}
          onReset={() => {
            fieldFrom.onChange('');
            fieldTo.onChange('');
          }}
        />
      )}
      <DatePicker
        range
        value={[new Date(fieldFrom.value) || '', new Date(fieldTo.value) || '']}
        onChange={(dates: DateObject[]) => {
          fieldFrom.onChange(dates[0].format('YYYY-MM-DD'));
          if (dates.length === 2) {
            fieldTo.onChange(dates[1].format('YYYY-MM-DD'));
          } else {
            fieldTo.onChange('');
          }
        }}
        name={idFrom}
        format="DD-MM-YYYY"
        inputClass={classnames(inputDefaultStyles, inputClassName)}
        className="red"
        containerClassName="w-full"
        months={months}
        weekDays={weekDays}
        weekStartDayIndex={1}
        id={idFrom}
      />
      {fieldStateFrom.error && <p>{fieldStateFrom.error.message}</p>}
      {fieldStateTo.error && <p>{fieldStateTo.error.message}</p>}
    </div>
  );
};

export default FormRangeDatePicker;
