import * as React from 'react';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import useMessages from '../../hooks/useMessages';
import PageHeader from '../../components/Layout/PageHeader';
import { usePermissions } from '../../hooks/usePermissions';
import Tabs, { TabElement } from '../../components/Tabs/Tabs';
import Button from '../../components/Button/Button';
import { useAppSelector } from '../../hooks/reduxHooks';
import AnnouncementsFilters from './AnnouncementsFilters/AnnouncementsFilters';
import ContentWrapper from '../../components/ContentWrapper/ContentWrapper';
import AnnouncementsList from './AnnouncementsList/AnnouncementsList';
import Layout from '../../components/Layout/Layout';
import FiltersIcon from '../../icons/filters.svg';
import PlusIcon from '../../icons/plus.svg';

type AnnouncementsPageProps = {
  archived?: true;
};

const AnnouncementsPage = ({ archived }: AnnouncementsPageProps) => {
  const getMessage = useMessages();
  const navigate = useNavigate();
  const { checkPermission, hasPermission, hasRoleType } = usePermissions();
  const hasFilterSet = useAppSelector((state) => !!state.app.filters.find((filter) => filter.id === 'announcementsList'));
  const [filtersOpen, setFiltersOpen] = useState(hasFilterSet);

  const tabs: TabElement[] = useMemo(() => {
    if (hasPermission('announcements_list')) {
      return [
        {
          label: getMessage('tabs.announcements.all'),
          path: '/aktualnosci',
        }, {
          label: getMessage('tabs.announcements.archived'),
          path: '/aktualnosci/archiwum',
        },
      ];
    }

    return [];
  }, [hasPermission]);

  checkPermission('announcements_list');

  return (
    <Layout currentPage="announcements">
      <PageHeader pageTitle={getMessage('navigation.announcements')} currentPage="announcements">
        <Button
          styleType="secondary"
          size="small"
          Icon={FiltersIcon}
          onClick={() => setFiltersOpen((filtersOpen) => !filtersOpen)}
          className="mx-3 h-8 py-1"
        >
          {getMessage('filters')}
        </Button>
        {hasRoleType('worker') && hasPermission('announcements_notice_add') && (
          <Button
            onClick={() => navigate('/aktualnosci/nowe')}
            className="py-1"
            size="small"
            Icon={PlusIcon}
          >
            {getMessage('announcements.newAnnouncement')}
          </Button>
        )}
      </PageHeader>
      {hasRoleType('tenant') && <Tabs tabElements={tabs} />}
      {filtersOpen && <AnnouncementsFilters />}
      <ContentWrapper>
        <AnnouncementsList archived={archived} />
      </ContentWrapper>
    </Layout>
  );
};

export default AnnouncementsPage;
