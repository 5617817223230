/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */
import * as React from 'react';
import format from 'date-fns/format';
import { useNavigate } from 'react-router-dom';
import { useGetResolutionsQuery } from '../../../api/appApi';
import TypeBadge from '../../../components/TypeBadge/TypeBadge';
import Card from '../../../components/Card/Card';
import ResolutionsIcon from '../../../icons/book-open.svg';
import useMessages from '../../../hooks/useMessages';
import SpinnerItem from '../../../components/Spinner/SpinnerItem';
import Button from '../../../components/Button/Button';

const ResolutionsWidget = () => {
  const { data, isLoading, isSuccess } = useGetResolutionsQuery({
    active: true,
  });
  const getMessage = useMessages();
  const navigate = useNavigate();

  const resolution = data?.resolutions[0];

  return (
    <div className="w-full tablet:basis-1/2">
      <p className="text-header-xs mb-2">{getMessage('dashboard.widget.resolutions.activeVotings')}</p>
      <Card className="h-[216px] md:!p-6">
        {isLoading && (<SpinnerItem containerStyles="flex items-center justify-center" />)}
        {isSuccess && (
          resolution ? (
            <>
              <div className="flex justify-between gap-3 mb-4">
                <div>
                  <p className="text-typography-black mb-2 text-title-semibold">
                    <span className="text-typography-dark font-normal">
                      <span className="visually-hidden">Uchwała numer</span>
                      {`${resolution.resolutionNumber} - `}
                    </span>
                    {resolution.title}
                  </p>
                  <p className="text-text-md-medium text-typography-dark">
                    <span className="visually-hidden">Na zagłosowanie masz czas</span>
                    {`do ${resolution && format(new Date(resolution.votingDeadline), 'dd-MM-yyyy')}`}
                  </p>
                </div>
                <TypeBadge badgeStyle={resolution.active ? 'red' : 'grey'} title={getMessage(`resolutions.status.${resolution.status}`)} ariaLabel={`status ${getMessage(`resolutions.status.${resolution.status}`)}`}>
                  <ResolutionsIcon />
                </TypeBadge>
              </div>
              <Button onClick={() => navigate(`/uchwaly/${resolution.id}`, { state: { returnTo: '/' } })} className="!w-full mb-4" ariaLabel="Zapoznaj się z treścią uchwały">
                {getMessage('resolutions.display')}
              </Button>
              <Button onClick={() => navigate('/uchwaly')} className="!w-full !py-0" styleType="ghost" ariaLabel="Przejdź do listy uchwał">
                {getMessage('dashboard.widget.resolutions.allResolutions')}
              </Button>
            </>
          ) : <div className="text-xs">{getMessage('dashboard.widget.resolutions.noActiveVotings')}</div>
        )}
      </Card>
    </div>
  );
};

export default ResolutionsWidget;
