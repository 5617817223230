import * as React from 'react';
import classnames from 'classnames';

interface Props {
  className?: string,
  title?: string,
  badgeStyle: 'red' | 'grey',
  ariaLabel?: string,
}

const TypeBadge = ({
  className, title, badgeStyle, children, ariaLabel,
}: React.PropsWithChildren<Props>) => {
  const colorOption = {
    red: 'bg-light-200 fill-bg-light-200 text-main',
    grey: 'border border-stroke-grey-100 text-typography-dark ',
  };
  return (
    <div
      className={classnames(`min-w-[2.5rem] h-10 rounded font-semibold ${badgeStyle === 'red' ? colorOption.red : colorOption.grey} flex justify-center align-self-start items-center`, className)}
      title={title}
    >
      <span className="visually-hidden">
        {ariaLabel}
      </span>
      {children}
    </div>
  );
};

export default TypeBadge;
