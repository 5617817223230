import * as React from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import Layout from '../../../components/Layout/Layout';
import ParametersSidebar from '../ParametersSidebar';
import ContentWrapper from '../../../components/ContentWrapper/ContentWrapper';
import PageHeader from '../../../components/Layout/PageHeader';
import useMessages from '../../../hooks/useMessages';
import Card from '../../../components/Card/Card';
import { usePostJournalMutation } from '../../../api/appApi';
import { FormNotice } from '../../../components/FormNotice/FormNotice';
import JournalForm from './JournalForm';
import { FullJournal } from '../../../api/modules/documents/documentsTypes';

const JournalFormPage = () => {
  const getMessage = useMessages();
  const [postJournal, { error, status }] = usePostJournalMutation();
  const navigate = useNavigate();

  const onSubmit = (data: FullJournal) => postJournal(data);

  useEffect(() => {
    if (status === 'fulfilled') navigate('/parametry/dzienniki');
  }, [status]);

  return (
    <Layout currentPage="parameters">
      <div className="flex flex-grow h-full">
        <ParametersSidebar selectedPage="journals" />
        <div className="flex flex-col flex-grow">
          <PageHeader
            currentPage="parameters"
            pageTitle={getMessage('parameters.journals.newJournal')}
            path="/parametry/dzienniki"
          />
          <ContentWrapper>
            <Card className="col-span-12">
              {/* @ts-ignore */}
              {error && <FormNotice type="error" message={error.data.errors || getMessage('form.error')} />}
              <JournalForm onSubmit={onSubmit} />
            </Card>
          </ContentWrapper>
        </div>
      </div>
    </Layout>
  );
};
export default JournalFormPage;
