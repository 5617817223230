import * as React from 'react';
import format from 'date-fns/format';
import useMessages from '../../hooks/useMessages';
import Table, {
  TableHeader, TableHeaderRow, TableHeaderCell, TableBody, TableBodyRow, TableBodyCell,
} from '../../components/Table/Table';

import Chip from '../../components/Chip/Chip';
import { Residency } from '../../api/modules/residency/residencyTypes';

interface Props {
  data: Residency[]
}

const ResidencyUpdateHistory = ({ data }: Props) => {
  const getMessage = useMessages();

  return (
    (
      <Table className="max-h-64 overflow-y-auto !pt-0" tabIndex={0}>
        <TableHeader className="sticky top-0 z-20 bg-background-white ">
          <TableHeaderRow className="text-typography-black">
            <TableHeaderCell className="pt-8 pb-4">
              {getMessage('local.tenants.residency.createdAt')}
            </TableHeaderCell>
            <TableHeaderCell divClassName="flex justify-center pt-8 pb-4">
              {getMessage('local.tenants.residency.number')}
            </TableHeaderCell>
            <TableHeaderCell divClassName="flex items-center justify-end pt-8 pb-4">
              {getMessage('local.tenants.residency.fromWhen')}
            </TableHeaderCell>
          </TableHeaderRow>
        </TableHeader>
        <TableBody>
          {data?.map(({
            createdAt, validFrom, tenantsNumber,
          }) => (
            <TableBodyRow key={createdAt + validFrom + tenantsNumber} className="relative">
              <TableBodyCell>
                <Chip chipStyle="ghost" className="w-fit">
                  {format(new Date(createdAt), 'dd-MM-yyyy')}
                </Chip>
              </TableBodyCell>
              <TableBodyCell className="text-typography-black text-center">{tenantsNumber}</TableBodyCell>
              <TableBodyCell className="flex items-center justify-end">
                <Chip chipStyle="ghost" className="w-fit">
                  {format(new Date(validFrom), 'dd-MM-yyyy')}
                </Chip>
              </TableBodyCell>
            </TableBodyRow>
          ))}
        </TableBody>
      </Table>
    )
  );
};

export default ResidencyUpdateHistory;
