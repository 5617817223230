import React, { useEffect } from 'react';

const useClickOutside = (ref: React.MutableRefObject<any>, onClickedOutside: () => void, ignoreRefs: React.MutableRefObject<any>[] = []) => {
  useEffect(() => {
    const clickedOutsideFunction = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target) && !ignoreRefs.find((r) => r.current.contains(event.target))) {
        onClickedOutside();
      }
    };
    document.addEventListener('mousedown', clickedOutsideFunction);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', clickedOutsideFunction);
    };
  }, [ref]);
};

export default useClickOutside;
