/* eslint-disable react/jsx-props-no-spreading */
import { RegisterOptions, useFormContext } from 'react-hook-form';
import * as React from 'react';
import useMessages from '../../../hooks/useMessages';
import { InputError } from '../InputLabel';

export interface RadioButtonOption {
  value: string,
  label?: string,
  element?: JSX.Element,
}

interface FormRadioProps {
  id: string,
  inputOptions: RadioButtonOption[],
  label: string,
  disabled?: boolean,
  options?: RegisterOptions,
  className?: string,
}

const FormRadioButtons = ({
  id, label, inputOptions, disabled, options, className,
}: FormRadioProps) => {
  const { register, formState } = useFormContext();
  const getMessage = useMessages();

  return (
    <>
      {inputOptions.map((option: RadioButtonOption) => (
        <label
          key={option.value}
          htmlFor={option.value}
          className={
            `flex border rounded mb-2 py-3 px-3 w-full
            ${disabled ? 'text-gray-500' : 'hover:border-hover hover:cursor-pointer'}
            ${formState.errors[id] ? 'border-interactions-input-error' : 'border-gray-100'}
            ${className}`
          }
        >
          <input
            className="mr-3"
            type="radio"
            id={option.value}
            disabled={disabled}
            value={option.value}
            {...register(id, options)}
          />
          {option.element || option.label || getMessage(`${label}.${option.value}`)}
        </label>
      ))}
      {formState.errors[id]?.message && <InputError message={formState.errors[id]?.message as string} />}
    </>
  );
};

export default FormRadioButtons;
