/* eslint-disable react/jsx-props-no-spreading */

import * as React from 'react';
import { useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import useMessages from '../../../hooks/useMessages';
import Card from '../../../components/Card/Card';
import Button from '../../../components/Button/Button';
import { useGetProfileQuery, usePostProfileMutation, useGetFullUserAccountQuery } from '../../../api/appApi';
import { translateApiToProfile, translateProfileToApi } from '../../../api/modules/user/userTranslator';
import { FormCheckboxInput, FormInput } from '../../../components/FormInputs';
import { UserProfileData } from '../../../api/modules/user/userTypes';
import ChangeMyPasswordPopUp from './ChangeMyPasswordPopUp';
import { FormNotice } from '../../../components/FormNotice/FormNotice';
import capitalize from '../../../../utils/formatters';
import { usePermissions } from '../../../hooks/usePermissions';
import Chip from '../../../components/Chip/Chip';
import { EMAIL_REGEX } from '../../../../utils/consts';

const ProfileForm = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isPasswordChanged, setIsPasswordChanged] = useState(false);
  const getMessage = useMessages();
  const { data } = useGetProfileQuery();
  const { data: user } = useGetFullUserAccountQuery();
  const [postUserData, result] = usePostProfileMutation();
  const methods = useForm<UserProfileData>({
    values: data ? translateApiToProfile(data) : undefined,
    mode: 'onTouched',
  });
  const { hasRoleType } = usePermissions();

  const onSubmit = (data: UserProfileData) => {
    postUserData(translateProfileToApi(data));
  };

  const onPasswordChanged = () => {
    setIsPasswordChanged(true);
  };

  return (
    <>
      <Card className="col-span-full">
        {isPasswordChanged && <FormNotice type="success" message={getMessage('profile.user.changePassword.success')} />}
        {result.status === 'fulfilled' && <FormNotice type="success" message={getMessage('profile.user.success')} />}
        {/* @ts-ignore */}
        {result.isError && <FormNotice type="error" message={result.error.data.errors || getMessage('form.error')} />}
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="w-full text-title-semibold mb-4">Edytuj profil</div>
            <section className="grid grid-cols-1 md:grid-cols-2 border-b pb-8">
              <div className="w-full md:w-2/3 grid-span-2 md:grid-span-1 flex flex-col">
                <FormInput
                  className="w-full"
                  inputClassName="w-full"
                  id="profileId"
                  type="text"
                  label={getMessage('profile.user.id')}
                  disabled
                  options={{ required: getMessage('profile.user.error.required') }}
                />
                <FormInput
                  className="mt-2 w-full"
                  inputClassName="w-full"
                  id="profileName"
                  type="text"
                  label={getMessage('profile.user.name')}
                  disabled
                  options={{ required: getMessage('profile.user.error.required') }}
                />
                <FormInput
                  className="mt-2 w-full"
                  inputClassName="w-full"
                  id="profileEmail"
                  type="email"
                  label={getMessage('profile.user.email')}
                  options={{
                    pattern: {
                      value: EMAIL_REGEX,
                      message: getMessage('form.email.error.pattern'),
                    },
                  }}
                />
                <FormInput
                  className="mt-2 w-full"
                  inputClassName="w-full"
                  id="profileLogin"
                  type="text"
                  label={getMessage('profile.user.login')}
                />
              </div>
              <div className="grid-span-2 md:grid-span-1 flex flex-col">
                <FormInput
                  type="tel"
                  className="mt-2 md:mt-0 w-full md:w-2/3 "
                  inputClassName="w-full"
                  id="profilePhoneNumber"
                  label={getMessage('profile.user.phone')}
                  options={{
                    pattern: {
                      value: /^[0-9- +]+$/,
                      message: getMessage('form.phone.error.pattern'),
                    },
                  }}
                />
                {hasRoleType('tenant') && (
                <div className="mt-2 grid grid-cols-1 grid-rows-3 md:grid-cols-3 md:grid-rows-2">
                  <FormInput
                    className="w-full md:w-2/3 md:col-span-3 md:row-span-1"
                    inputClassName="w-full"
                    id="profileAddressStreet"
                    type="text"
                    label={getMessage('profile.user.address')}
                  />
                  <FormInput
                    className="mt-2 md:mr-1 md:row-span-2 md:col-span-1"
                    id="profileAddressPostalCode"
                    inputClassName="w-full"
                    type="text"
                    placeholder={getMessage('profile.user.postalCode')}
                    options={{
                      pattern: {
                        value: /^[0-9]{2}[-][0-9]{3}/,
                        message: getMessage('form.postalCode.error.pattern'),
                      },
                    }}
                  />
                  <FormInput
                    className="mt-2 md:ml-1 md:row-span-2"
                    inputClassName="w-full"
                    id="profileAddressCity"
                    type="text"
                    placeholder={getMessage('profile.user.city')}
                  />
                </div>
                )}
                <div className="mt-6">
                  <div className="my-2 text-text-lg-semibold">{getMessage('profile.user.group')}</div>
                  <div className="flex">
                    {user && user?.roles.map((role) => <Chip chipStyle="gray" key={role.name} className="mr-1 px-6 py-1 text-sm rounded w-fit">{capitalize(role.name)}</Chip>)}
                  </div>
                </div>
              </div>
            </section>
            <section className="border-b py-8">
              <div className="w-full mb-4 text-title-semibold">{getMessage('profile.user.changePassword')}</div>
              <Button styleType="primary" onClick={() => setIsDialogOpen(true)}>{getMessage('profile.user.changePassword')}</Button>
            </section>
            <section className="mt-8 flex flex-wrap justify-between">
              <FormCheckboxInput
                id="profileEmailAgreement"
                label={getMessage('profile.user.agreement')}
                className="pb-8 md:pb-0 text-title-medium text-typography-dark"
              />
              <Button type="submit">{getMessage('save')}</Button>
            </section>
          </form>
        </FormProvider>
      </Card>
      <ChangeMyPasswordPopUp
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        onPasswordChanged={onPasswordChanged}
      />
    </>
  );
};

export default ProfileForm;
