import * as React from 'react';
import classNames from 'classnames';
import { useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useGetRolesQuery } from '../../api/appApi';
import { Role } from '../../api/modules/access/accessTypes';
import useMessages from '../../hooks/useMessages';
import AddNewIcon from '../../icons/add_new.svg';

type GroupedRoles = {
  defaultRoles: Role[],
  customRoles: Role[],
};

const RolesSidebar = () => {
  const getMessage = useMessages();
  const navigate = useNavigate();
  const { data: rolesArray } = useGetRolesQuery();
  const { id } = useParams();

  const sortedRoles = useMemo(() => {
    if (rolesArray && rolesArray.length > 0) {
      return [...rolesArray].sort((r1, r2) => (r1.name.toLowerCase() > r2.name.toLowerCase() ? 1 : -1));
    }
    return [];
  }, [rolesArray]);

  const roles: GroupedRoles = sortedRoles.reduce(
    (acc: GroupedRoles, curr: Role) => {
      if (curr.isDefault) {
        return ({
          ...acc,
          defaultRoles: acc.defaultRoles.concat([curr]),
        });
      }
      return ({
        ...acc,
        customRoles: acc.customRoles.concat([curr]),
      });
    },
    { defaultRoles: [], customRoles: [] },
  );

  const renderRoleOption = (role: Role) => (
    <div
      key={role.id}
      className={classNames('m-2 pl-3 py-1 rounded hover:bg-gray-200 cursor-pointer', { 'bg-gray-300': role.id === id })}
      role="button"
      tabIndex={0}
      onClick={() => { navigate(`/uprawnienia/role/${role.id}`); }}
      onKeyPress={() => { navigate(`/uprawnienia/role/${role.id}`); }}
    >
      {role.name}
    </div>
  );

  return (
    <div className="flex flex-col w-56 border-r text-gray-800 pt-20 bg-gray-100 capitalize justify-between">
      <div className="flex flex-col w-full overflow-y-auto">
        {roles.defaultRoles?.map((role) => renderRoleOption(role))}
        <div className="border-b w-auto mb-4 pb-4 mx-2" />
        {roles.customRoles?.map((role) => renderRoleOption(role))}
      </div>
      <div
        className={classNames('mt-2 py-4 px-2 pl-4 bg-background-white text-title-semibold border rounded group hover:text-hover cursor-pointer', { 'bg-gray-300': !id })}
        role="button"
        tabIndex={0}
        onClick={() => { navigate('/uprawnienia/role/nowa_rola'); }}
        onKeyPress={() => { navigate('/uprawnienia/role/nowa_rola'); }}
      >
        <span className="group-hover:text-main">
          {getMessage('user.roles.createRole')}
        </span>
        <AddNewIcon className="float-right mr-2 my-1 text-typography-dark group-hover:text-main" />
      </div>
    </div>
  );
};

export default RolesSidebar;
