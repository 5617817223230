import { rest } from 'msw';

export const metersMocks = [
  rest.post('/meters/add_measurements', (req, res, ctx) => res(
    ctx.status(200),
  )),

  rest.get('/meters/list', (req, res, ctx) => res(
    ctx.status(200),
    ctx.json({
      meters: [
        {
          meter_id: '9c84c9a6-2fc2-4071-95dc-b95b7a09981d',
          meter_group: 'water',
          meter_type: 'CW',
          meter_location: 'w szafie',
          last_value: '20.5',
          valid_until: '2023-02-14T14:08:28.128Z',
          can_update: true,
        },
        {
          meter_id: 'f8206fe9-206f-4403-8980-c7c0c547d3fa',
          meter_group: null,
          meter_type: 'ZW',
          meter_location: 'w szafie',
          last_value: '55.0',
          valid_until: '2023-01-17T14:08:28.236Z',
          can_update: false,
        },
        {
          meter_id: '3231bca5-92d9-4f6b-b728-94074fb3bd00',
          meter_group: 'water',
          meter_type: 'ZW',
          meter_location: 'w szafie',
          last_value: '10.1',
          valid_until: '2023-02-14T14:08:28.175Z',
          can_update: true,
        },
        {
          meter_id: '7ae3759d-123e-407f-a0e4-d83aa84e21d4',
          meter_group: 'gas',
          meter_type: 'GAS',
          meter_location: 'w szafie',
          last_value: '15.0',
          valid_until: '2023-02-14T14:08:28.206Z',
          can_update: true,
        },
        {
          meter_id: '855b26f4-73d2-455b-847d-dde03066c598',
          meter_group: null,
          meter_type: 'CW',
          meter_location: 'w szafie',
          last_value: '45.0',
          valid_until: '2023-02-14T14:08:28.224Z',
          can_update: true,
        },
        {
          meter_id: '1ac788b4-2b09-4759-a086-0ee4a53dcae0',
          meter_group: 'gas',
          meter_type: 'GAS',
          meter_location: 'w szafie',
          last_value: '35.0',
          valid_until: '2023-01-17T14:08:28.215Z',
          can_update: false,
        },
        {
          meter_id: 'd5017cf2-8196-4741-a587-cc9185e63f33',
          meter_group: null,
          meter_type: 'GAS',
          meter_location: 'w szafie',
          last_value: '15.0',
          valid_until: '2023-02-21T14:08:28.184Z',
          can_update: true,
        },
      ],
    }),
  )),
];
