import { rest } from 'msw';
import queryString from 'query-string';

const venues = [
  {
    homeowner_association: {
      id: 'a6ab-3fd397a3c031',
      name: 'SM Tęcza',
    },
    id: '2454-4f43',
    name: 'Agrestowa 1/1',
    tenants: [
      {
        id: 'b4e53836',
        name: 'Adam Banialuka',
      },
    ],
  },
  {
    homeowner_association: {
      id: 'a6ab-3fd397a3c031',
      name: 'SM Tęcza',
    },
    id: '7539-4282',
    name: 'Agrestowa 1/2',
    tenants: [
      {
        id: 'b4e53836',
        name: 'Adam Banialuka',
      },
    ],
  },
  {
    homeowner_association: {
      id: 'a6ab-3fd397a3c031',
      name: 'SM Tęcza',
    },
    id: '432rs-54g54h',
    name: 'Agrestowa 1/3',
    tenants: [
      {
        id: '36b4e538',
        name: 'Beata Cytrynowicz',
      },
    ],
  },
  {
    homeowner_association: {
      id: 'a6ab-3fd397a3c031',
      name: 'SM Tęcza',
    },
    id: 'f8f7s-8f9af6',
    name: 'Porzeczkowa 1/9',
    tenants: [
      {
        id: '432g435',
        name: 'Dariusz Eliasz',
      },
    ],
  },
  {
    homeowner_association: {
      id: '464-664',
      name: 'SM Jarząbek',
    },
    id: '32r2f-42f434',
    name: 'Ziębia 2/7',
    tenants: [
      {
        id: 'jd97gd8',
        name: 'Felicja Hiacynt',
      },
    ],
  },
  {
    homeowner_association: {
      id: '464-664',
      name: 'SM Jarząbek',
    },
    id: '534g43-4g345g',
    name: 'Ziębia 1/3',
    tenants: [
      {
        id: 'jd97gd8',
        name: 'Felicja Hiacynt',
      },
    ],
  },
];

const filteredVenues = ({
  search, venue_id, tenant_id, homeowner_association_id,
}) => {
  let filteredVenues = venues;
  if (search) {
    filteredVenues = filteredVenues.filter((venue) => venue.name.toLowerCase().includes(search.toLowerCase()));
  }
  if (venue_id) {
    filteredVenues = filteredVenues.filter(
      (venue) => venue.id === venue_id,
    );
  }
  if (homeowner_association_id) {
    filteredVenues = filteredVenues.filter(
      (venue) => venue.homeowner_association.id === homeowner_association_id,
    );
  }
  if (tenant_id) {
    filteredVenues = filteredVenues.filter(
      (venue) => ((venue.tenants.find((tenant) => tenant.id === tenant_id))),
    );
  }
  return filteredVenues;
};

export const venuesMocks = [
  rest.get('/venues/list', (req, res, ctx) => {
    const { query: params } = queryString.parseUrl(req.url.href);
    return res(
      ctx.status(200),
      ctx.json({
        venues: filteredVenues(params),
      }),
    );
  }),
];
