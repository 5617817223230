import * as React from 'react';
import { format } from 'date-fns';
import { useCallback, useEffect } from 'react';
import { Task, TaskColumnId } from '../../../api/modules/tasks/tasksTypes';
import TableActionsBar from '../../../components/Table/TableActionsBar';
import TasksBulkActions from './TasksBulkActions/TasksBulkActions';
import TasksTableCustomisation from './TasksTableCustomisation';
import Table, {
  TableBody, TableBodyCell,
  TableBodyRow,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow,
} from '../../../components/Table/Table';
import {
  resetSelectedTaskIds,
  saveSelectedTaskId,
  TableId,
} from '../../../store/appSlice';
import TableBodyPlaceholder from '../../../components/Table/TableBodyPlaceholder';
import Chip from '../../../components/Chip/Chip';
import capitalize from '../../../../utils/formatters';
import useMessages from '../../../hooks/useMessages';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { useGetSelectedTasksColumnsQuery } from '../../../api/appApi';
import useHomeownerAssociationsMode from '../../../hooks/useHomeownerAssociationsMode';

interface Props {
  tasks: Task[]
}

const TasksListDesktop = ({ tasks }: Props) => {
  const getMessage = useMessages();
  const hoaMode = useHomeownerAssociationsMode();
  const { data: selectedColumns } = useGetSelectedTasksColumnsQuery();
  const dispatch = useAppDispatch();

  const isSelected = useCallback((columnId: TaskColumnId): boolean => !!selectedColumns?.includes(columnId), [selectedColumns]);
  const selectedTasksIds = useAppSelector((state) => state.app.selectedTasksIds);

  const handleChecked = (id: string) => {
    dispatch(saveSelectedTaskId({ id }));
  };

  useEffect(() => {
    dispatch(resetSelectedTaskIds());
  }, []);

  return (
    <>
      <TableActionsBar>
        <div className="flex justify-center">
          <TasksBulkActions selectedTasks={selectedTasksIds} />
          <TasksTableCustomisation />
        </div>
      </TableActionsBar>
      <Table className="col-span-full px-0 py-0 table-fixed whitespace-nowrap overflow-auto !p-0">
        <TableHeader className="sticky top-0 z-20 bg-background-white border-none shadow-main shadow-sm">
          <TableHeaderRow className="[&>*:nth-child(2)]:pl-8 [&>*:last-child]:pr-8">
            <TableHeaderCell className="sticky top-0 left-0 pt-8 bg-main " />
            <TableHeaderCell className="pt-8 pb-4 pl-8">{getMessage('tasks.column.status')}</TableHeaderCell>
            {isSelected('created_at') && (
              <TableHeaderCell className="pt-8 pb-4" id="created_at" tableName={'tasksTable' as TableId}>
                {getMessage('tasks.column.creationDate')}
              </TableHeaderCell>
            )}
            {isSelected('last_comment_at') && (
              <TableHeaderCell className="pt-8 pb-4" id="last_comment_at" tableName={'tasksTable' as TableId}>
                {getMessage('tasks.column.lastCommentDate')}
              </TableHeaderCell>
            )}
            {isSelected('task_number') && (
              <TableHeaderCell className="pt-8 pb-4" id="task_number" tableName={'tasksTable' as TableId}>
                {getMessage('tasks.column.number')}
              </TableHeaderCell>
            )}
            {isSelected('title') && <TableHeaderCell className="pt-8 pb-4">{getMessage('tasks.column.title')}</TableHeaderCell>}
            {isSelected('venue_address') && <TableHeaderCell className="pt-8 pb-4">{getMessage('tasks.column.venueAddress')}</TableHeaderCell>}
            {isSelected('venue_code') && <TableHeaderCell className="pt-8 pb-4">{getMessage('tasks.column.venueCode')}</TableHeaderCell>}
            {hoaMode && isSelected('homeowner_association_code') && <TableHeaderCell className="pt-8 pb-4">{getMessage('tasks.column.homeownersAssociationCode')}</TableHeaderCell>}
            {hoaMode && isSelected('homeowner_association_name') && <TableHeaderCell className="pt-8 pb-4">{getMessage('tasks.column.homeownerAssociation')}</TableHeaderCell>}
            {isSelected('category') && <TableHeaderCell className="pt-8 pb-4">{getMessage('tasks.column.category')}</TableHeaderCell>}
            {isSelected('creator_external_id') && <TableHeaderCell className="pt-8 pb-4">{getMessage('tasks.column.reporterCode')}</TableHeaderCell>}
            {isSelected('creator') && <TableHeaderCell className="pt-8 pb-4">{getMessage('tasks.column.reporterName')}</TableHeaderCell>}
            {isSelected('realizer_external_id') && <TableHeaderCell className="pt-8 pb-4">{getMessage('tasks.column.realizerCode')}</TableHeaderCell>}
            {isSelected('realizer') && <TableHeaderCell className="pt-8 pb-4">{getMessage('tasks.column.realizer')}</TableHeaderCell>}
            {isSelected('estimated_start_time') && <TableHeaderCell className="pt-8 pb-4">{getMessage('tasks.column.plannedStartDate')}</TableHeaderCell>}
            {isSelected('started_at') && <TableHeaderCell className="pt-8 pb-4">{getMessage('tasks.column.startDate')}</TableHeaderCell>}
            {isSelected('estimated_finish_time') && <TableHeaderCell className="pt-8 pb-4">{getMessage('tasks.column.plannedEndDate')}</TableHeaderCell>}
            {isSelected('finished_at') && <TableHeaderCell className="pt-8 pb-4">{getMessage('tasks.column.endDate')}</TableHeaderCell>}
          </TableHeaderRow>
        </TableHeader>
        <TableBody>
          {tasks && tasks.length === 0 && (<TableBodyPlaceholder />)}
          {selectedColumns && selectedColumns.length > 0 && tasks && tasks.map(({
            id, status, createdAt, lastCommentAt, taskNumber, title, venueAddress, venueCode, homeownerAssociationCode,
            homeownerAssociationName, category, creator, creatorCode, realizerCode, realizer,
            estimatedStartTime, estimatedFinishTime, startedAt, finishedAt,
          }) => (
            <TableBodyRow
              key={id}
              className="relative border-none odd:bg-light-100 text-typography-black [&>*:nth-child(2)]:pl-8 [&>*:last-child]:pr-8"
            >
              <TableHeaderCell className="sticky left-0 z-15 m-10 pl-0 !pr-0 pt-0 bg-main border-main w-1 mr-16">
                <label htmlFor={id} className="hover:cursor-pointer">
                  <div className="text-[0px] h-0">{getMessage('tasks.checkbox.label')}</div>
                  <input
                    className="m-4"
                    type="checkbox"
                    checked={selectedTasksIds.includes(id)}
                    onChange={() => handleChecked(id)}
                    id={id}
                  />
                </label>
              </TableHeaderCell>
              <TableBodyCell>
                <Chip chipStyle={status === 'completed' ? 'gray' : 'primary'} className="w-fit">
                  {status && capitalize(status)}
                </Chip>
              </TableBodyCell>
              {isSelected('created_at') && (
              <TableBodyCell>
                {createdAt && (
                <Chip chipStyle="ghost" className="w-fit">
                  {format(new Date(createdAt), 'dd-MM-yyyy')}
                </Chip>
                )}
              </TableBodyCell>
              )}
              {isSelected('last_comment_at') && (
              <TableBodyCell>
                {lastCommentAt && (
                <Chip chipStyle="ghost" className="w-fit">
                  {format(new Date(lastCommentAt), 'dd-MM-yyyy')}
                </Chip>
                )}
              </TableBodyCell>
              )}
              {isSelected('task_number') && <TableBodyCell>{taskNumber}</TableBodyCell>}
              {isSelected('title') && (
                <TableBodyCell clickable={({ href: `/zadania/${id}`, ariaLabel: `Przejdź do szczegółów zadania ${taskNumber} ${title}` })}>
                  {title}
                </TableBodyCell>
              )}
              {isSelected('venue_address') && <TableBodyCell>{venueAddress}</TableBodyCell>}
              {isSelected('venue_code') && <TableBodyCell>{venueCode}</TableBodyCell>}
              {hoaMode && isSelected('homeowner_association_code') && (
              <TableBodyCell>
                {homeownerAssociationCode}
              </TableBodyCell>
              ) }
              {hoaMode && isSelected('homeowner_association_name') && <TableBodyCell>{homeownerAssociationName}</TableBodyCell>}
              {isSelected('category') && <TableBodyCell>{category}</TableBodyCell>}
              {isSelected('creator_external_id') && <TableBodyCell>{creatorCode}</TableBodyCell>}
              {isSelected('creator') && <TableBodyCell>{creator}</TableBodyCell>}
              {isSelected('realizer_external_id') && <TableBodyCell>{realizerCode}</TableBodyCell>}
              {isSelected('realizer') && <TableBodyCell>{realizer}</TableBodyCell>}
              {isSelected('estimated_start_time') && (
              <TableBodyCell>
                {estimatedStartTime && (
                <Chip chipStyle="ghost" className="w-fit">
                  {format(new Date(estimatedStartTime), 'dd-MM-yyyy')}
                </Chip>
                )}
              </TableBodyCell>
              )}
              {isSelected('started_at') && (
              <TableBodyCell>
                {startedAt && (
                <Chip chipStyle="ghost" className="w-fit">
                  {format(new Date(startedAt), 'dd-MM-yyyy')}
                </Chip>
                )}
              </TableBodyCell>
              )}
              {isSelected('estimated_finish_time') && (
              <TableBodyCell>
                {estimatedFinishTime && (
                <Chip chipStyle="ghost" className="w-fit">
                  {format(new Date(estimatedFinishTime), 'dd-MM-yyyy')}
                </Chip>
                )}
              </TableBodyCell>
              )}
              {isSelected('finished_at') && (
              <TableBodyCell>
                {finishedAt && (
                <Chip chipStyle="ghost" className="w-fit">
                  {format(new Date(finishedAt), 'dd-MM-yyyy')}
                </Chip>
                )}
              </TableBodyCell>
              )}
            </TableBodyRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

export default TasksListDesktop;
