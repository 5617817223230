import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { useMediaQuery } from 'react-responsive';
import { useGetTitleSettlementsBalanceQuery, useGetFullUserAccountQuery } from '../../../api/appApi';
import TitleBalanceHeader from './TitleBalanceHeader/TitleBalanceHeader';
import ContentWrapper from '../../../components/ContentWrapper/ContentWrapper';
import DesktopTitleBalanceTable from './TitleBalanceTable/DesktopTitleBalanceTable';
import { useSettlementsVenue } from '../../../hooks/settlements/useSettlementsVenue';
import { mobile } from '../../../../utils/breakpoints';
import MobileTitleBalanceTable from './TitleBalanceTable/MobileTitleBalanceTable/MobileTitleBalanceTable';
import { usePermissions } from '../../../hooks/usePermissions';
import { TitleBalanceQuery } from '../../../api/modules/settlements/settlementsTypes';
import Spinner from '../../../components/Spinner/Spinner';

interface Props {
  year: string,
  month: string,
  titleId: string,
}

const TitleBalancePage = ({ year, month, titleId }: Props) => {
  const { venueId } = useSettlementsVenue();
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: mobile.raw });
  const { hasRoleType } = usePermissions();
  const { data: user } = useGetFullUserAccountQuery();

  const selectedSettlementsTenantId = user && localStorage.getItem(`selectedSettlementsTenantId-${user?.id}`);
  const queryParams = useMemo(() => {
    const params: TitleBalanceQuery = {
      year,
      month,
      title_id: titleId,
      venue: venueId,
    };
    if (selectedSettlementsTenantId && hasRoleType('worker')) {
      params.tenant_id = selectedSettlementsTenantId;
    }
    return params;
  }, [year, month, titleId, venueId, selectedSettlementsTenantId]);
  const { data, isLoading } = useGetTitleSettlementsBalanceQuery(
    queryParams,
    { skip: !venueId || !year || !month || !titleId || (hasRoleType('worker') && !selectedSettlementsTenantId) },
  );

  const venueOnEnter = useMemo(() => venueId, []);

  useEffect(() => {
    if (venueOnEnter !== venueId) {
      navigate(`/rozrachunki/analiza_salda/${year}/${month}?venueId=${venueId}`);
    }
  }, [venueId]);

  return (
    <>
      <TitleBalanceHeader
        year={year}
        month={month}
        titleId={titleId}
        path={`/rozrachunki/analiza_salda/${year}/${month}?venueId=${venueId}`}
        tenantId={selectedSettlementsTenantId || ''}
      />
      <ContentWrapper>
        {isLoading ? <Spinner /> : (
          <>
            {isMobile && <MobileTitleBalanceTable data={data} />}
            {!isMobile && <DesktopTitleBalanceTable data={data} />}
          </>
        )}
      </ContentWrapper>
    </>
  );
};

export default TitleBalancePage;
