import * as React from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { useMemo } from 'react';
import Layout from '../../../components/Layout/Layout';
import ParametersSidebar from '../ParametersSidebar';
import PageHeader from '../../../components/Layout/PageHeader';
import ContentWrapper from '../../../components/ContentWrapper/ContentWrapper';
import {
  useGetFullDocumentStatusesQuery,
  useGetFullTaskStatusesQuery,
  useGetFullTicketStatusesQuery,
} from '../../../api/appApi';
import StatusForm from './StatusForm';

const StatusEditPage = () => {
  const intl = useIntl();
  const { id: statusId } = useParams();

  const { data: ticketStatuses } = useGetFullTicketStatusesQuery();
  const { data: taskStatuses } = useGetFullTaskStatusesQuery();
  const { data: documentStatuses } = useGetFullDocumentStatusesQuery();

  const status = useMemo(() => {
    if (ticketStatuses && taskStatuses && documentStatuses) {
      return ticketStatuses.concat(taskStatuses).concat(documentStatuses).find((status) => status.id === statusId);
    }
    return null;
  }, [statusId, ticketStatuses, taskStatuses, documentStatuses]);

  return (
    <Layout currentPage="parameters">
      <div className="flex flex-grow h-full">
        <ParametersSidebar selectedPage="statuses" />
        <div className="flex flex-col flex-grow">
          <PageHeader
            currentPage="parameters"
            pageTitle={intl.formatMessage({ id: 'parameters.statuses.status' }, { statusName: status?.externalName })}
            path="/parametry/statusy"
          />
          <ContentWrapper>
            {status && (
              <StatusForm status={status} />
            )}
          </ContentWrapper>
        </div>
      </div>
    </Layout>
  );
};

export default StatusEditPage;
