import { IntlShape } from 'react-intl';
import {
  ApiMeasurements,
  ApiMeter,
  ApiMeterHistoryResponse,
  ApiMetersResponse,
  ApiMeterType,
  Measurement,
  Meter, MeterHistory,
  MetersFormFields,
  ReadingRequest,
  ApiMetersVenuesListResponse,
  ApiMetersVenue,
  MetersVenue,
  MetersVenuesList,
} from './metersTypes';

export const translateMeterType = (apiType: ApiMeterType, intl: IntlShape) => {
  switch (apiType) {
    case 'CW':
      return {
        type: intl.formatMessage({ id: 'local.meters.type.cw' }),
        unit: 'm3',
      };
    case 'ZW':
      return {
        type: intl.formatMessage({ id: 'local.meters.type.zw' }),
        unit: 'm3',
      };
    case 'GAS':
      return {
        type: intl.formatMessage({ id: 'local.meters.type.gas' }),
        unit: 'm3',
      };
    case 'PRAD':
      return {
        type: intl.formatMessage({ id: 'local.meters.type.prad' }),
        unit: 'kWh',
      };
    case 'CO':
      return {
        type: intl.formatMessage({ id: 'local.meters.type.co' }),
        unit: 'GJ',
      };
    default:
      return {
        type: intl.formatMessage({ id: 'local.meters.type.unknown' }),
        unit: '',
      };
  }
};

const translateMeter = (apiMeter: ApiMeter, intl: IntlShape): ReadingRequest => {
  const { type, unit } = translateMeterType(apiMeter.meter_type, intl);

  return ({
    meterId: apiMeter.meter_id,
    meterGroup: apiMeter.meter_group || undefined,
    meterType: type,
    meterCode: apiMeter.meter_code,
    meterLocation: apiMeter.meter_location,
    measurementUnit: unit,
    validUntil: new Date(apiMeter.valid_until),
    lastValue: apiMeter.last_value ? parseFloat(apiMeter.last_value) : undefined,
    canUpdate: apiMeter.can_update,
  });
};

const groupByMeterGroup = (meters: ReadingRequest[]) => meters.reduce(
  (groups:ReadingRequest[][], meter) => {
    const { meterGroup } = meter;
    let foundGroup: (ReadingRequest[] | undefined);

    if (meterGroup) {
      foundGroup = groups.find((g) => g[0].meterGroup === meterGroup);
    }
    if (foundGroup) {
      foundGroup.push(meter);
    } else {
      groups.push([meter]);
    }
    return groups;
  },
  [],
);

export const translateApiToMeterGroups = (apiObject: ApiMetersResponse, intl: IntlShape) : ReadingRequest[][] => groupByMeterGroup(
  apiObject.meters.map((apiObject) => translateMeter(apiObject, intl)),
);

export const translateApiToMetersList = (apiObject: ApiMetersResponse, intl: IntlShape) : Meter[] => apiObject.meters.map((apiMeter) => {
  const { type, unit } = translateMeterType(apiMeter.meter_type, intl);
  return {
    meterId: apiMeter.meter_id,
    meterType: type,
    meterCode: apiMeter.meter_code,
    meterLocation: apiMeter.meter_location,
    measurementUnit: unit,
  };
});

export const translateMetersHistory = (apiResponse: ApiMeterHistoryResponse): MeterHistory[] => apiResponse.history.map((measuremeent) => ({
  value: measuremeent.measurement_value,
  date: measuremeent.measurement_taken_at,
  source: measuremeent.data_source,
}));

export const translateMeasurementsToApi = (measurements: Measurement[]): ApiMeasurements => ({
  measurements: measurements.map((m) => ({
    meter_id: m.meterId,
    value: m.value,
  })),
});

export const translateFormFieldsToApi = (fields: MetersFormFields): ApiMeasurements => ({
  measurements: Object.keys(fields).map((key) => ({
    meter_id: key,
    value: `${fields[key]}`,
  })),
});
const translateMetersVenuesList = (apiVenue: ApiMetersVenue): MetersVenue => ({
  building: apiVenue.building,
  code: apiVenue.code,
  homeownerAssociation: apiVenue.homeowner_association,
  venueId: apiVenue.id,
  measurementRequired: apiVenue.measurement_required,
  residencyFrom: apiVenue.residency_from,
  residencyUntil: apiVenue.residency_until,
  tenant: apiVenue.tenant,
  tenantExternalId: apiVenue.tenant_external_id,
});

export const translateApiMetersVenuesList = ({ venues, metadata }: ApiMetersVenuesListResponse) : MetersVenuesList => ({
  metadata,
  venues: venues.map((ApiMetersVenue) => translateMetersVenuesList(ApiMetersVenue)),
});

export { ApiMetersResponse };
