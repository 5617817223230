import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useForm, FormProvider, FieldValues } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Card from '../../../components/Card/Card';
import PermissionEditTree from '../../../components/PermissionTree/PermissionEditTree';
import {
  useGetAccountsQuery, useGetRolesQuery,
  usePostCreateRoleMutation,
} from '../../../api/appApi';
import RoleEditPopup from '../RolePopup/RoleEditPopup';
import { FormInput } from '../../../components/FormInputs';
import useMessages from '../../../hooks/useMessages';
import RoleDeletePopup from '../RolePopup/RoleDeletePopup';
import ToggleableInput from '../../../components/FormInputs/ToggleableInput';
import { Role } from '../../../api/modules/access/accessTypes';
import { FormNotice } from '../../../components/FormNotice/FormNotice';

interface Props {
  role?: Role,
}

const RoleEditCard = ({ role }: Props) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const getMessage = useMessages();

  const methods = useForm({
    values: {
      roleName: role?.name || '',
    },
  });

  const usersQuery = useGetAccountsQuery({ page: 1, filters: { role: role?.name } });
  const rolesQuery = useGetRolesQuery();
  const [postNewRole, newRoleQuery] = usePostCreateRoleMutation();

  const [editPopupOpen, setEditPopupOpen] = useState(false);
  const [deletePopupOpen, setDeletePopupOpen] = useState(false);

  const [selectedPermissions, setSelectedPermissions] = useState(role?.permissions || []);
  const [roleName, setRoleName] = useState(role?.name || '');

  const affectedUsersCount = useMemo(
    () => usersQuery?.data?.metadata.count,
    [usersQuery],
  );

  useEffect(() => {
    if (newRoleQuery.status === 'fulfilled') {
      navigate(`/uprawnienia/role/${newRoleQuery.data.role.id}`);
    }
  }, [newRoleQuery]);

  const onSubmit = (values: FieldValues) => {
    if (!role) {
      postNewRole({
        name: values.roleName,
        permissions_names: selectedPermissions,
      });
    } else {
      setRoleName(values.roleName);
      setEditPopupOpen(true);
    }
  };

  return (
    <Card className="col-span-full">
      { /* @ts-ignore */ }
      {newRoleQuery.isError && <FormNotice type="error" message={newRoleQuery.error.data?.errors || getMessage('form.error')} />}
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          {role && (
            <>
              <RoleEditPopup
                isOpen={editPopupOpen}
                role={role}
                onClose={() => setEditPopupOpen(false)}
                affectedUsersCount={affectedUsersCount}
                newRoleName={roleName}
                selectedPermissions={selectedPermissions}
              />
              <RoleDeletePopup
                isOpen={deletePopupOpen}
                role={role}
                onClose={() => setDeletePopupOpen(false)}
                affectedUsersCount={affectedUsersCount}
              />
            </>
          )}
          <h3 className="font-bold text-xl">
            {role ? (
              <ToggleableInput
                key={role.id}
                id="roleName"
                startingValue={role.name}
                options={{
                  required: getMessage('user.roles.validation.nameRequired'),
                  validate: (v) => {
                    const unique = !rolesQuery?.data?.map((role) => role.name.toLowerCase())?.includes(v.toLowerCase());
                    const nameNotChanged = role && role.name.toLowerCase() === v.toLowerCase();
                    return unique || nameNotChanged || getMessage('user.roles.validation.nameUnique');
                  },
                }}
              />
            ) : (
              <FormInput
                className="w-1/3 mt-4"
                inputClassName="w-3/4"
                id="roleName"
                type="text"
                placeholder={getMessage('user.roles.fillName')}
                options={{
                  required: getMessage('user.roles.validation.nameRequired'),
                  validate: (v) => {
                    const unique = !rolesQuery?.data?.map((role) => role.name.toLowerCase())?.includes(v.toLowerCase());
                    return unique || getMessage('user.roles.validation.nameUnique');
                  },
                }}
              />
            )}
          </h3>
          <PermissionEditTree
            startingPermissions={role?.permissions || []}
            onSubmit={(selectedPermissions) => {
              setSelectedPermissions(selectedPermissions);
            }}
            secondButtonText={role?.isDefault || !role ? undefined : intl.formatMessage({ id: 'user.roles.deleteRole' }, { roleName: role?.name })}
            secondButtonCallback={role?.isDefault || !role ? undefined : () => { setDeletePopupOpen(true); }}
          />
        </form>
      </FormProvider>
    </Card>
  );
};

export default RoleEditCard;
