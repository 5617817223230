import {
  Residency, PostResidencyUpdate, ApiPostResidencyUpdate, ApiResidencyResponse,
} from './residencyTypes';

export const translateApitoResidency = ({ residency_amendments }: ApiResidencyResponse): Residency[] => residency_amendments.map((residency) => ({
  accepted: residency.accepted,
  tenantsNumber: residency.tenants_number,
  createdAt: residency.created_at,
  validFrom: residency.valid_from,
}));

export const translateResidencyUpdateToApi = (id: string, residency: PostResidencyUpdate): ApiPostResidencyUpdate => ({
  venue_id: id,
  tenants_number: residency.tenantsNumber,
  valid_from: residency.validFrom,
});
