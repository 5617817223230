import { rest } from 'msw';
import queryString from 'query-string';

const tenants = [
  {
    id: 'b4e53836',
    name: 'Adam Banialuka',
    venues: [
      {
        id: '2454-4f43',
        name: 'Agrestowa 1/1',
        homeowner_association: {
          id: 'a6ab-3fd397a3c031',
          name: 'SM Tęcza',
        },
      },
      {
        id: '7539-4282',
        name: 'Agrestowa 1/2',
        homeowner_association: {
          id: 'a6ab-3fd397a3c031',
          name: 'SM Tęcza',
        },
      },
    ],
  },
  {
    id: '36b4e538',
    name: 'Beata Cytrynowicz',
    venues: [
      {
        id: '432rs-54g54h',
        name: 'Agrestowa 1/3',
        homeowner_association: {
          id: 'a6ab-3fd397a3c031',
          name: 'SM Tęcza',
        },
      },
    ],
  },
  {
    id: '432g435',
    name: 'Dariusz Eliasz',
    venues: [
      {
        id: 'f8f7s-8f9af6',
        name: 'Porzeczkowa 1/9',
        homeowner_association: {
          id: 'a6ab-3fd397a3c031',
          name: 'SM Tęcza',
        },
      },
    ],
  },
  {
    id: 'jd97gd8',
    name: 'Felicja Hiacynt',
    venues: [
      {
        id: '32r2f-42f434',
        name: 'Ziębia 2/7',
        homeowner_association: {
          id: '464-664',
          name: 'SM Jarząbek',
        },
      },
      {
        id: '534g43-4g345g',
        name: 'Ziębia 1/3',
        homeowner_association: {
          id: '464-664',
          name: 'SM Jarząbek',
        },
      },
    ],
  },
];

const filteredTenants = ({
  search, venue_id, tenant_id,
}) => {
  let filteredTenants = tenants;
  if (search) {
    filteredTenants = filteredTenants.filter((tenant) => tenant.name.toLowerCase().includes(search.toLowerCase()));
  }
  if (tenant_id) {
    filteredTenants = filteredTenants.filter(
      (tenant) => tenant.id === tenant_id,
    );
  }
  if (venue_id) {
    filteredTenants = filteredTenants.filter(
      (tenant) => tenant.venues.find((venue) => venue.id === venue_id),
    );
  }
  return filteredTenants;
};

export const tenantsMocks = [
  rest.get('/tenants/list', (req, res, ctx) => {
    const { query: params } = queryString.parseUrl(req.url.href);
    return res(
      ctx.status(200),
      ctx.json({
        tenants: filteredTenants(params),
      }),
    );
  }),
];
