import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import queryString from 'query-string';
import {
  ApiBaseQuery, ApiTagTypes,
} from '../../baseQuery';
import { ApiEmployees, Employee } from './employeesTypes';
import { translateApiToEmployees } from './employeesTranslator';

export const employeesQueries = (builder: EndpointBuilder<ApiBaseQuery, ApiTagTypes, 'api'>) => ({
  getEmployees: builder.query<Employee[], Record<string, string | null>>({
    query: (params) => {
      const stringifiedParams = queryString.stringify(params, { arrayFormat: 'bracket' });
      return `employees/list?${stringifiedParams}`;
    },
    transformResponse(baseQueryReturnValue: ApiEmployees): Employee[] {
      return translateApiToEmployees(baseQueryReturnValue).employees;
    },
    providesTags: () => ['Employees'],
  }),
});
