import * as React from 'react';
import { RegisterOptions, useFormContext } from 'react-hook-form';
import { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import EditPencilIcon from '../../icons/edit-pencil.svg';
import useClickOutside from '../../hooks/useClickOutside';
import FormInput from './inputs/FormInput';
import { InputError } from './InputLabel';

interface Props {
  id: string,
  startingValue?: string,
  className?: string,
  inputClassName?: string,
  options?: RegisterOptions,
  disabled?: boolean,
}

const ToggleableInput = ({
  id, className, inputClassName, options, startingValue, disabled,
}: Props) => {
  const [editOpen, setEditOpen] = useState(false);
  const [value, setValue] = useState(startingValue);
  const { formState } = useFormContext();

  useEffect(() => {
    setValue(startingValue);
  }, [startingValue]);

  const inputRef = useRef(null);
  const handleOpen = () => {
    if (!disabled) {
      setEditOpen(true);
    }
  };

  useClickOutside(inputRef, () => setEditOpen(false));

  return (
    <div ref={inputRef} className={`w-fit ${className}`}>
      {!editOpen && (
        <>
          <div
            role="button"
            tabIndex={0}
            onClick={handleOpen}
            onKeyPress={handleOpen}
            className="flex justify-start items-center"
          >
            <h3 className="inline-block capitalize">{value}</h3>
            {!disabled && (<EditPencilIcon className="inline-block ml-2 stroke-gray-500 text-gray-500 h-3" />)}
          </div>
          {formState.errors[id]?.message && <InputError message={formState.errors[id]?.message as string} />}
        </>
      )}
      <FormInput
        id={id}
        type="text"
        className={classnames(inputClassName, { hidden: !editOpen })}
        options={{
          ...options,
          onChange: ((e) => {
            setValue(e.target.value);
          }),
        }}
      />
    </div>
  );
};

export default ToggleableInput;
