import * as React from 'react';
import { useRef, useState } from 'react';
import classnames from 'classnames';
import ArrowUp from '../../icons/dropdown-arrow-up.svg';
import ArrowDown from '../../icons/dropdown-arrow-down.svg';
import useClickOutside from '../../hooks/useClickOutside';

interface Props {
  label: string,
  labelClassName?: string,
  children: JSX.Element | JSX.Element[],
  listClassName?: string,
  ariaLabel?: string,
  autofocus?: boolean,
}

const Dropdown = ({
  label, labelClassName, children, listClassName, ariaLabel, autofocus,
}: Props) => {
  const [open, setOpen] = useState(false);
  const listRef = useRef(null);
  const labelRef = useRef<HTMLSpanElement>(null);
  const toggleOpen = () => {
    setOpen((open) => !open);
  };

  useClickOutside(listRef, toggleOpen, [labelRef]);

  const handleKeyUp = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' || e.key === ' ') {
      toggleOpen();
    }
  };

  React.useEffect(() => {
    if (autofocus && labelRef.current) labelRef.current.focus();
  });

  return (
    <div className="relative">
      <span
        role="button"
        onKeyUp={(e) => handleKeyUp(e)}
        onClick={toggleOpen}
        tabIndex={0}
        className={`inline-block cursor-pointer ${labelClassName}`}
        ref={labelRef}
        aria-label={ariaLabel}
      >
        <span className="float-left h-[1.375rem] truncate text-text-md-semibold">{label}</span>
        {open ? <ArrowUp className="inline float-right ml-1 mt-2" /> : <ArrowDown className="inline float-right ml-1 mt-2" />}
        {open && (
          <div className={classnames('absolute top-8 z-30 left-0 rounded-b', listClassName)} ref={listRef}>
            {children}
          </div>
        )}
      </span>
    </div>
  );
};

export default Dropdown;
