export interface ApiCompanySettingsResponse {
  settings: {
    color_palette: ColorPalette,
    hoa_mode: boolean,
    meters_enabled: boolean,
    resolutions_enabled: boolean,
    settlements_enabled: boolean,
    payments_enabled: boolean,
    tickets_enabled: boolean,
    documents_enabled: boolean,
    portal_pro_enabled: boolean,
    version: string,
  }
}

export interface CompanySettings {
  colorPalette: ColorPalette,
  homeownerAssociationsMode: boolean,
  metersModuleEnabled: boolean,
  resolutionsModuleEnabled: boolean,
  settlementsModuleEnabled: boolean,
  paymentsModuleEnabled: boolean,
  ticketsModuleEnabled: boolean,
  documentsModuleEnabled: boolean,
  portalProModuleEnabled: boolean,
  version: string,
}

export interface ApiCompanyDetailsResponse {
  details: ApiCompanyDetails,
  attachments: ApiCompanyAttachments,
}

export interface ApiCompanyTaxDetailsResponse {
  tax_details: ApiCompanyTaxDetails,
}

export interface ApiCompanyDetails {
  name: string,
  street_address: string,
  postcode: string,
  city: string,
  phone: string,
  website: string
}

export interface ApiCompanyTaxDetails {
  vat_code: string,
  company_code: string,
  contact_email: string,
  contact_phone_number: string,
}


export interface ApiCompanyAttachments {
  background_photo: CompanyAttachment,
  logo: CompanyAttachment,
  regulations: CompanyAttachment,
}

export interface CompanyAttachment {
  filename: string,
  path: string,
  content_type: string,
}

export interface CompanyDetails {
  name: string,
  streetAddress: string,
  postalCode: string,
  city: string,
  phone: string,
  website: string,
}

export interface CompanyTaxDetails {
  vatCode: string,
  companyCode: string,
  contactEmail: string,
  contactPhoneNumber: string
}

export interface CompanyData extends CompanyDetails {
  backgroundImage?: CompanyAttachment,
  logo?: CompanyAttachment,
  regulations?: CompanyAttachment,
}

export interface CompanyFormInputs extends CompanyDetails {
  oldLogo?: CompanyAttachment,
  oldBackgroundImage?: CompanyAttachment,
  oldRegulations?: CompanyAttachment,
  backgroundImage?: FileList,
  logo?: FileList,
  regulations?: FileList,
  colorPalette?: ColorPalette,
}

export const colorPalettes = ['red', 'green', 'violet'] as const;
export type ColorPalette = typeof colorPalettes[number];

export interface FormCompanyDetails extends ApiCompanyDetails {}
export interface FormCompanyTaxDetails extends ApiCompanyTaxDetails {}

type ApiMailServerConfig = {
  address: string,
  authentication: Authentication,
  domain: string,
  port: string,
  user_name: string,
  from_email: string,
  communication_encryption: CommunicationEncryption,
};

export type ApiMailServerConfigResponse = {
  mail_server_config: ApiMailServerConfig
};

export const communicationEncryptions = ['none', 'starttls', 'ssl', 'tls'] as const;
export type CommunicationEncryption = typeof communicationEncryptions[number];

export const authentications = ['none', 'plain', 'login', 'cram_md5'] as const;
export type Authentication = typeof authentications[number];

export type MailServerConfig = Omit<ApiMailServerConfig, 'user_name' | 'from_email' | 'communication_encryption'> & {
  userName: string,
  fromEmail: string,
  communicationEncryption: CommunicationEncryption,
};

export type MailServerConfigFormInputs = MailServerConfig & {
  password: string,
};

export type ApiPutMailServerConfig = ApiMailServerConfig & {
  password?: string,
};

export type ApiTestMailServerConfig = {
  email: string
};
