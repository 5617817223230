import * as React from 'react';
import { useMemo } from 'react';
import useMessages from '../../../../hooks/useMessages';
import {
  useGetSelectedDocumentsColumnsQuery, usePostSelectedDocumentsColumnsMutation,
} from '../../../../api/appApi';
import { SelectOption } from '../../../../components/FormInputs/inputs/FormSelect';
import TableCustomisationMenu from '../../../../components/TableCustomisationMenu/TableCustomisationMenu';
import {
  documentCustomizableColumnIds,
  documentDefaultColumnIds,
} from '../../../../api/modules/documents/documentsTypes';

const DocumentTableCustomisation = () => {
  const getMessage = useMessages();
  const { data: selectedColumns } = useGetSelectedDocumentsColumnsQuery();
  const [postSelectedDocumentColumns] = usePostSelectedDocumentsColumnsMutation();

  const selectOptions: SelectOption[] = useMemo(() => documentCustomizableColumnIds.map((id) => ({
    value: id,
    optionLabel: getMessage(`documents.columnConfig.${id}`),
  })), []);

  if (!selectedColumns || !postSelectedDocumentColumns) return null;

  const handleSelectedColumns = (selectedColumns: string[]) => {
    const columns = documentDefaultColumnIds.concat(selectedColumns);
    postSelectedDocumentColumns({ columns });
  };

  return (
    <TableCustomisationMenu
      selectedColumns={selectedColumns}
      setSelectedColumns={handleSelectedColumns}
      selectOptions={selectOptions}
      tableId="documentsList"
    />
  );
};

export default DocumentTableCustomisation;
