import * as React from 'react';
import { PropsWithChildren } from 'react';

const TableActionsBar = ({ children } : PropsWithChildren) => (
  <div className="flex flex-row-reverse col-span-full">
    {children}
  </div>
);

export default TableActionsBar;
