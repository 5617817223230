import * as React from 'react';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import PageHeader from '../../components/Layout/PageHeader';
import Layout from '../../components/Layout/Layout';
import { useGetFullUserAccountQuery } from '../../api/appApi';
import { usePermissions } from '../../hooks/usePermissions';
import TenantDashboardPage from './TenantDashboardPage';
import WorkerDashboardPage from './WorkerDashboardPage';

const DashboardPage = () => {
  const { data: user } = useGetFullUserAccountQuery();
  const intl = useIntl();
  const { hasRoleType, checkPermission } = usePermissions();

  useEffect(() => {
    checkPermission('announcements_list'); // redirect for admin
  }, [user]);

  return (
    <Layout currentPage="dashboard">
      <PageHeader pageTitle={intl.formatMessage({ id: 'dashboard.header' }, { username: user?.fullName })} currentPage="dashboard" />
      {hasRoleType('tenant') && <TenantDashboardPage />}
      {hasRoleType('worker') && <WorkerDashboardPage />}
    </Layout>
  );
};

export default DashboardPage;
