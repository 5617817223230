import * as React from 'react';
import { useIntl } from 'react-intl';
import { useAppSelector } from '../../hooks/reduxHooks';
import Info from '../../icons/info.svg';
import { Residency } from '../../api/modules/residency/residencyTypes';

const UpcomingResidencyUpdate = ({ data: upcomingUpdate }: { data: Residency }) => {
  const intl = useIntl();
  const venueName = useAppSelector((state) => state.app.selectedVenueName);

  return (
    <div className={`p-4 mb-12 col-span-12 border border-main rounded bg-light-100 text-main text-text-lg-medium flex ${!upcomingUpdate && 'hidden'}`}>
      <div className="mr-4 w-12 h-12 bg-light-300 rounded flex justify-center items-center"><Info /></div>
      <div className="flex flex-col">
        <p className="pb-2">
          {intl.formatMessage({ id: 'local.tenants.residency.upcomingChange' }, { validFrom: upcomingUpdate?.validFrom, tenantsNumber: upcomingUpdate?.tenantsNumber })}
        </p>
        <p>{venueName}</p>
      </div>
    </div>
  );
};

export default UpcomingResidencyUpdate;
