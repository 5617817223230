import * as React from 'react';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import {
  closestIndexTo, isAfter, isBefore, isEqual,
} from 'date-fns';
import useMessages from '../../hooks/useMessages';
import { usePermissions } from '../../hooks/usePermissions';
import { useAppSelector } from '../../hooks/reduxHooks';
import PageHeader from '../../components/Layout/PageHeader';
import ContentWrapper from '../../components/ContentWrapper/ContentWrapper';
import ResidencyUpdateForm from './ResidencyUpdateForm';
import ResidencyUpdateHistory from './ResidencyUpdatesHistory';
import UpcomingResidencyUpdate from './UpcomingResidencyUpdate';
import Layout from '../../components/Layout/Layout';
import { useGetResidencyQuery } from '../../api/appApi';
import Card from '../../components/Card/Card';

const PeopleCountPage = () => {
  const getMessage = useMessages();
  const intl = useIntl();
  const { checkPermission, checkModuleEnabled } = usePermissions();
  const venueId = useAppSelector((state) => state.app.selectedVenueId);
  const { data } = useGetResidencyQuery(venueId, { skip: !venueId });

  checkPermission('tenants_people_entries_manage');
  checkModuleEnabled('tickets');

  const today = useMemo(() => new Date(), [data]);
  const closestUpdate = useMemo(() => data?.find((item) => isAfter(new Date(item.validFrom), today)), [data]); // validFrom are always in asc order
  let closestDateIndex: number | undefined;

  useMemo(() => {
    if (data) {
      const dates = data?.map((item) => new Date(item.validFrom));
      const pastAndTodayDates = dates?.filter((dateItem) => isBefore(dateItem, today) || isEqual(dateItem, today));
      closestDateIndex = closestIndexTo(today, pastAndTodayDates);
    }
  }, [data]);

  const currentTenantsNumber = useMemo(() => (data && closestDateIndex !== undefined ? data[closestDateIndex]?.tenantsNumber : 0), [data]);

  return (
    <Layout currentPage="local.peopleCount">
      <PageHeader pageTitle={getMessage('navigation.local.peopleCount')} currentPage="local" />
      <ContentWrapper>
        {!venueId
          ? <p className="col-span-12 text-title-sm">{getMessage('local.tenants.residency.selectVenueWarning')}</p>
          : (
            <>
              <p className="col-span-12 text-header-sm">
                {intl.formatMessage({ id: 'local.tenants.residency.currentTenantsNumber' }, { currentTenantsNumber })}
              </p>
              {closestUpdate && <UpcomingResidencyUpdate data={closestUpdate} />}
              <div className="col-span-12 md:col-span-6">
                <p className="text-title-semibold mb-5">
                  {getMessage('local.tenants.residency.addNew')}
                  <span className="visually-hidden">zmiany liczby osób</span>
                </p>
                <ResidencyUpdateForm />
              </div>
              <div className="col-span-12 md:col-span-6">
                <p className="text-title-semibold mb-5">
                  {getMessage('local.tenants.residency.history')}
                  <span className="visually-hidden">zmiany liczby osób</span>
                </p>
                {data && data?.length > 0
                  ? <ResidencyUpdateHistory data={data} />
                  : (<Card>{getMessage('local.tenants.residency.noHistory')}</Card>)}
              </div>
            </>
          )}
      </ContentWrapper>
    </Layout>
  );
};

export default PeopleCountPage;
