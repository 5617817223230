import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import queryString from 'query-string';
import {
  ApiAnnouncementsResponse,
  Announcements,
  AnnouncementData,
  AnnouncementsAuthor,
  ApiAnnouncementsAuthorsResponse,
  ApiAnnouncementsRecipientsResponse,
  AnnouncementsRecipient,
  ApiAnnouncementResponse,
  ApiAnnouncementsRecipientsForFilterResponse,
  AnnouncementsRecipientForFilter,
  ApiChangeAnnouncementData,
} from './announcementsTypes';
import {
  translateAnnouncements, translateSingleAnnouncement, translateAnnouncementsAuthors, translateAnnouncementsRecipients,
} from './announcementsTranslator';
import {
  ApiBaseQuery, ApiTagTypes,
} from '../../baseQuery';

export const announcementsQueries = (builder: EndpointBuilder<ApiBaseQuery, ApiTagTypes, 'api'>) => ({
  getAnnouncements: builder.query<Announcements, Record<string, string | boolean> | void>({
    query: (params) => {
      const stringifiedParams = params && queryString.stringify(params, { arrayFormat: 'bracket' });
      return `announcements/list?${stringifiedParams}`;
    },
    transformResponse(baseQueryReturnValue: ApiAnnouncementsResponse): Promise<Announcements> | Announcements {
      return translateAnnouncements(baseQueryReturnValue);
    },
    providesTags: () => ['Announcements'],
  }),
  getAnnouncement: builder.query<AnnouncementData, string | undefined>({
    query: (id) => (`announcements/${id}/show`),
    transformResponse(baseQueryReturnValue: ApiAnnouncementResponse): Promise<AnnouncementData> | AnnouncementData {
      return translateSingleAnnouncement(baseQueryReturnValue.announcement);
    },
    providesTags: () => ['Announcement'],
  }),
  postAnnouncement: builder.mutation<any, any>({
    query: (payload) => ({
      url: '/announcements/add',
      method: 'POST',
      body: payload,
    }),
    invalidatesTags: () => ['Announcements', 'AnnouncementsRecipientsForFilter'],
  }),
  changeAnnouncement: builder.mutation<any, ApiChangeAnnouncementData>({
    query: ({ newData }) => ({
      url: '/announcements/change',
      method: 'PUT',
      body: newData,
    }),
    invalidatesTags: () => ['Announcements', 'Announcement', 'AnnouncementsRecipientsForFilter'],
  }),
  postMarkReadAnnouncement: builder.mutation<any, string>({
    query: (id) => ({
      url: `announcements/${id}/read`,
      method: 'POST',
    }),
    invalidatesTags: () => ['Announcements', 'Announcement'],
  }),
  postArchiveAnnouncement: builder.mutation<any, string>({
    query: (id) => ({
      url: `announcements/${id}/archive`,
      method: 'POST',
    }),
    invalidatesTags: () => ['Announcements', 'Announcement'],
  }),
  getAnnouncementsAuthors: builder.query<AnnouncementsAuthor[], void>({
    query: () => 'announcements/authors',
    transformResponse(baseQueryReturnValue: ApiAnnouncementsAuthorsResponse): Promise<AnnouncementsAuthor[]> | AnnouncementsAuthor[] {
      return translateAnnouncementsAuthors(baseQueryReturnValue);
    },
    providesTags: () => ['AnnouncementsAuthors'],
  }),
  getAnnouncementRecipients: builder.query<AnnouncementsRecipient[], Record<string, string>>({
    query: ({ group, ...rest }) => {
      const stringifiedParams = queryString.stringify(rest, { arrayFormat: 'bracket' });
      return `announcements/recipients/${group}/list?${stringifiedParams}`;
    },
    transformResponse(baseQueryReturnValue: ApiAnnouncementsRecipientsResponse): Promise<AnnouncementsRecipient[]> | AnnouncementsRecipient[] {
      return baseQueryReturnValue.recipients;
    },
    providesTags: () => ['AnnouncementRecipients'],
  }),
  getRecipientsForFilter: builder.query<AnnouncementsRecipientForFilter[], void>({
    query: () => 'announcements/recipients/list?',
    transformResponse(baseQueryReturnValue: ApiAnnouncementsRecipientsForFilterResponse):
    Promise<AnnouncementsRecipientForFilter[]> | AnnouncementsRecipientForFilter[] {
      return translateAnnouncementsRecipients(baseQueryReturnValue);
    },
    providesTags: () => ['AnnouncementsRecipientsForFilter'],
  }),
});
