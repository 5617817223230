import * as React from 'react';
import MobileInfoCard from '../../../components/InfoCard/MobileInfoCard/MobileInfoCard';
import DocumentInfoCard from './DocumentInfoCard';
import useMessages from '../../../hooks/useMessages';

const MobileDocumentInfoCard = () => {
  const getMessage = useMessages();

  return (
    <MobileInfoCard title={getMessage('documents.document.info')}>
      <DocumentInfoCard />
    </MobileInfoCard>
  );
};

export default MobileDocumentInfoCard;
