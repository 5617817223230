import * as React from 'react';
import { useMemo } from 'react';
import useMessages from '../../../hooks/useMessages';
import Filters from '../../../components/Filters/Filters';
import { paymentStatuses } from '../../../api/modules/settlements/settlementsTypes';

const PaymentsHistoryFilters = () => {
  const getMessage = useMessages();

  const statusOptionsValues = useMemo(() => paymentStatuses.map((status) => ({
    value: status as string,
    optionLabel: getMessage(`settlements.history.column.status.${status}`),
  })), [paymentStatuses]) || [];

  return (
    <Filters
      filterId="paymentsHistoryTable"
      filterFields={[
        {
          type: 'text',
          id: 'description_search',
          label: getMessage('settlements.history.filters.title'),
          ariaLabel: 'Szukaj po tytule płatności',
        },
        {
          type: 'dateRange',
          id: 'from_payment_date',
          toDateId: 'to_payment_date',
          label: getMessage('settlements.history.filters.date'),
        },
        {
          type: 'number',
          id: 'from_amount',
          label: getMessage('settlements.history.filters.minValue'),
        },
        {
          type: 'number',
          id: 'to_amount',
          label: getMessage('settlements.history.filters.maxValue'),
        },
        {
          type: 'select',
          id: 'status',
          label: getMessage('settlements.history.filters.status'),
          selectOptions: statusOptionsValues,
        },
      ]}
    />
  );
};

export default PaymentsHistoryFilters;
