import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import queryString from 'query-string';
import {
  Accounts,
  ActivityLog,
  ApiActivityLogResponse,
  ApiMe,
  ApiPostUserAccount,
  ApiPostUserProfile,
  ApiUserAccountsResponse,
  ApiUserProfile,
  UserAccount,
  ApiRoles,
  Role,
  FullUserAccount,
  ApiAccountResponse,
  ApiPutUserAccount,
  ApiChangeUserActivity,
  AccessGroup,
  ApiAccessGroupResponse,
  ApiPostNewPassword,
} from './userTypes';
import { ApiBaseQuery, ApiTagTypes } from '../../baseQuery';
import {
  translateApiMeToUser,
  translateApiToActivityLog,
  translateUserAccounts,
  translateApiToRoles,
  translateFullUserAccount,
  translateAccessGroups,
} from './userTranslator';
import { ColorPalette } from '../company/companyTypes';

export const userQueries = (builder: EndpointBuilder<ApiBaseQuery, ApiTagTypes, 'api'>) => ({
  getFullUserAccount: builder.query<UserAccount, void>({
    query: () => 'users/accounts/me?with_access_info&with_tenant',
    providesTags: ['UserAccess'],
    transformResponse(baseQueryReturnValue: ApiMe): UserAccount {
      return translateApiMeToUser(baseQueryReturnValue);
    },
  }),
  getProfile: builder.query<ApiUserProfile, void>({
    query: () => 'users/accounts/profile',
    providesTags: () => ['Profile'],
  }),
  postProfile: builder.mutation<any, ApiPostUserProfile>({
    query: (profile) => ({
      url: 'users/accounts/profile',
      method: 'POST',
      body: profile,
    }),
    invalidatesTags: () => ['Profile', 'ActivityLog'],
  }),
  postChangePassword: builder.mutation({
    query: (payload) => ({
      url: 'users/accounts/change_my_password',
      method: 'PUT',
      body: payload,
    }),
  }),
  putChangeUserPassword: builder.mutation<any, { id: string, payload: ApiPostNewPassword }>({
    query: ({ id, payload }) => ({
      url: `users/accounts/${id}/change_password`,
      method: 'PUT',
      body: payload,
    }),
  }),
  getActivityLog: builder.query<ActivityLog, Record<string, string | boolean>>({
    query: (params) => {
      const stringifiedParams = queryString.stringify(params, { arrayFormat: 'bracket' });
      return `activity_log/list?${stringifiedParams}`;
    },
    transformResponse(baseQueryReturnValue: ApiActivityLogResponse): Promise<ActivityLog> | ActivityLog {
      return translateApiToActivityLog(baseQueryReturnValue);
    },
    providesTags: () => ['ActivityLog'],
  }),
  logout: builder.mutation<any, null>({
    query: () => ({
      url: 'accounts/sign_out',
      method: 'DELETE',
    }),
  }),
  getUsersAccounts: builder.query<Accounts, Record<string, string>>({
    query: (params) => {
      const stringifiedParams = queryString.stringify(params, { arrayFormat: 'bracket' });
      return `/users/accounts/list?${stringifiedParams}`;
    },
    transformResponse(baseQueryReturnValue: ApiUserAccountsResponse): Accounts {
      return translateUserAccounts(baseQueryReturnValue);
    },
    providesTags: () => ['Users'],
  }),
  getUserAccount: builder.query<FullUserAccount, string>({
    query: (id) => `/users/accounts/${id}/show`,
    transformResponse(baseQueryReturnValue: ApiAccountResponse): FullUserAccount {
      return translateFullUserAccount(baseQueryReturnValue);
    },
    providesTags: () => ['Users'],
  }),
  postUserAccount: builder.mutation<any, ApiPostUserAccount>({
    query: (account) => ({
      url: 'users/accounts/add',
      method: 'POST',
      body: account,
    }),
    invalidatesTags: () => ['Profile', 'Users', 'Roles'],
  }),
  putUserAccount: builder.mutation<any, ApiPutUserAccount>({
    query: ({ id, ...account }) => ({
      url: `users/accounts/${id}/change`,
      method: 'PUT',
      body: account,
    }),
    invalidatesTags: () => ['Profile', 'Users', 'Roles'],
  }),
  getRolesForUser: builder.query<Role[], void>({
    query: () => 'users/roles/list',
    transformResponse(baseQueryReturnValue: ApiRoles): Promise<Role[]> | Role[] {
      return translateApiToRoles(baseQueryReturnValue);
    },
    providesTags: ['Roles'],
  }),
  changeUserActivityStatus: builder.mutation<any, ApiChangeUserActivity>({
    query: (mutation) => ({
      url: `users/accounts/${mutation.id}/change_access`,
      method: 'PUT',
      body: {
        active: mutation.active,
      },
    }),
    invalidatesTags: () => ['Profile', 'Users'],
  }),
  getAccessGroups: builder.query<AccessGroup[], Record<string, string>>({
    query: ({ accessGroup, ...rest }) => {
      const stringifiedParams = queryString.stringify(rest, { arrayFormat: 'bracket' });
      return `/users/access_groups/${accessGroup}/list?${stringifiedParams}`;
    },
    transformResponse(baseQueryReturnValue: ApiAccessGroupResponse): AccessGroup[] {
      return translateAccessGroups(baseQueryReturnValue);
    },
  }),
  patchColorPalette: builder.mutation<any, ColorPalette | null | undefined>({
    query: (color) => ({
      url: '/users/profiles/change_color_palette',
      method: 'PATCH',
      body: {
        color_palette: color,
      },
    }),
    invalidatesTags: () => ['Profile', 'UserAccess'],
  }),
});
