import * as React from 'react';
import DesktopInfoCard from '../../../../components/InfoCard/DesktopInfoCard/DesktopInfoCard';
import TaskInfoCard from './TaskInfoCard';
import { FullTask } from '../../../../api/modules/tasks/tasksTypes';

interface Props {
  task: FullTask,
}

const TaskDesktopInfoCard = ({ task }: Props) => (
  <DesktopInfoCard>
    <TaskInfoCard task={task} />
  </DesktopInfoCard>
);

export default TaskDesktopInfoCard;
