import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import { pdfjs } from 'react-pdf';
import Cookies from 'js-cookie';
import AppRouter from './AppRouter';
import messages from './languages/pl';
import { store } from './store/store';

const rootElement = document.getElementById('react-app');

if (process.env.ENABLE_MOCKS === 'true') {
  // eslint-disable-next-line global-require
  require('../test/worker');
}

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

document.addEventListener('DOMContentLoaded', () => {
  const noticeElement = document.getElementById('notice');
  const alertElement = document.getElementById('alert');
  const cookiesConsentElement = document.getElementById('cookiesConsent');
  const cookiesAcceptButton = document.getElementById('cookiesAcceptButton');

  const consent = Cookies.get('cookiesAccepted');
  if (!consent && cookiesConsentElement) {
    cookiesConsentElement.classList.remove('hidden');
  }

  if (cookiesAcceptButton && cookiesConsentElement) {
    cookiesAcceptButton.onclick = () => {
      Cookies.set('cookiesAccepted', 'true');
      cookiesConsentElement.classList.add('hidden');
    };
  }

  const hideElement = () => {
    if (noticeElement) noticeElement.setAttribute('style', 'display: none');
    if (alertElement) alertElement.setAttribute('style', 'display: none');
  };
  setTimeout(hideElement, 5000);
});

if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <IntlProvider locale="pl" defaultLocale="pl" messages={messages}>
          <AppRouter />
        </IntlProvider>
      </Provider>
    </React.StrictMode>,
  );
}
