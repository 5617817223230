import * as React from 'react';
import { useMemo } from 'react';
import useMessages from '../../../hooks/useMessages';
import {
  useGetDocumentsCategoriesQuery,
  useGetDocumentStatusesQuery,
  useGetFullJournalsQuery,
} from '../../../api/appApi';
import capitalize from '../../../../utils/formatters';
import { FilterConfig } from '../../../components/FilterInput/FilterInput';
import Filters from '../../../components/Filters/Filters';

const EmployeeDocumentsFilters = () => {
  const getMessage = useMessages();
  const { data: documentCategories } = useGetDocumentsCategoriesQuery();
  const { data: documentStatuses } = useGetDocumentStatusesQuery();
  const { data: journals } = useGetFullJournalsQuery({ items: '200' });

  const categoryOptionsValues = useMemo(() => documentCategories?.map(({ id, name }) => ({
    value: id,
    optionLabel: capitalize(name),
  })), [documentCategories]);

  const statusOptionsValues = useMemo(() => documentStatuses?.map(({ id, name }) => ({
    value: id,
    optionLabel: capitalize(name),
  })), [documentStatuses]);

  const journalOptionsValues = useMemo(() => journals?.journals?.map(({ id, name }) => ({
    value: id,
    optionLabel: capitalize(name),
  })), [journals]);

  const filterFields: FilterConfig[] = useMemo(() => [
    {
      type: 'multiselect',
      id: 'status_ids',
      label: getMessage('documents.column.status'),
      selectOptions: statusOptionsValues || [],
    },
    {
      type: 'dateRange',
      id: 'from_created_at',
      toDateId: 'to_created_at',
      label: getMessage('documents.column.date'),
    },
    {
      type: 'text',
      id: 'number_title_search',
      label: getMessage('documents.filter.titleOrNumber'),
      ariaLabel: 'Szukaj po numerze lub tytule dokumentu',
    },
    {
      type: 'multiselect',
      id: 'category_ids',
      label: getMessage('documents.column.category'),
      selectOptions: categoryOptionsValues || [],
    },
    {
      type: 'multiselect',
      id: 'journal_ids',
      label: getMessage('documents.column.journal'),
      selectOptions: journalOptionsValues || [],
    },
    {
      type: 'text',
      id: 'creator_search',
      label: getMessage('documents.column.creator'),
      ariaLabel: 'Szukaj po imieniu i nazwisku autora',
    },
    {
      type: 'text',
      id: 'sender_search',
      label: getMessage('documents.column.sender'),
      ariaLabel: 'Szukaj po imieniu i nazwisku nadawcy',
    },
    {
      type: 'text',
      id: 'receiver_search',
      label: getMessage('documents.column.receiver'),
      ariaLabel: 'Szukaj po imieniu i nazwisku odbiorcy',
    },
    {
      type: 'text',
      id: 'tenant_search',
      label: getMessage('documents.column.tenant'),
      ariaLabel: 'Szukaj po imieniu i nazwisku lub kodzie lokatora',
    },
    {
      type: 'text',
      id: 'realizer_search',
      label: getMessage('documents.column.realizer'),
      ariaLabel: 'Szukaj po imieniu i nazwisku realizującego',
    },
  ], [statusOptionsValues, categoryOptionsValues, journalOptionsValues]);

  return (
    <Filters
      filterId="documentsList"
      filterFields={filterFields}
    />
  );
};

export default EmployeeDocumentsFilters;
