import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import Layout from '../../../components/Layout/Layout';
import PageHeader from '../../../components/Layout/PageHeader';
import ContentWrapper from '../../../components/ContentWrapper/ContentWrapper';
import { useChangeUserActivityStatusMutation, useGetUserAccountQuery } from '../../../api/appApi';
import UserForm from './UserForm';
import Dialog from '../../../components/Dialog/Dialog';
import Button from '../../../components/Button/Button';
import ChangeUserPasswordPopUp from './ChangeUserPasswordPopUp';
import { FormNotice } from '../../../components/FormNotice/FormNotice';
import useMessages from '../../../hooks/useMessages';

const UserPage = () => {
  const { id } = useParams();
  const intl = useIntl();
  const { data: user } = useGetUserAccountQuery(id as string);
  const [changeUserStatePopupOpen, setChangeUserStatePopupOpen] = useState(false);
  const [changeUserPasswordPopupOpen, setChangeUserPasswordPopupOpen] = useState(false);
  const [isPasswordChanged, setIsPasswordChanged] = useState(false);
  const [changeActivity] = useChangeUserActivityStatusMutation();
  const getMessage = useMessages();

  const userModifiable: boolean = useMemo(
    () => !!user?.roles.find((role) => role.roleType === 'admin' || role.roleType === 'worker'),
    [user],
  );

  const toggleState = () => {
    if (user) {
      changeActivity({
        id: user.id,
        active: !user.active,
      });
    }
  };

  return (
    <Layout currentPage="users">
      <Dialog
        isOpen={changeUserStatePopupOpen}
        onAccept={toggleState}
        onClose={() => setChangeUserStatePopupOpen(false)}
      >
        <p className="mb-12 mt-8 mobile:mx-0 mx-16 text-center text-typography-black text-text-lg-medium">
          {user && user.active
            ? intl.formatMessage({ id: 'users.user.deactivate.popup' }, { userName: user.fullName })
            : intl.formatMessage({ id: 'users.user.activate.popup' }, { userName: user?.fullName || '' })}
        </p>
      </Dialog>
      {user?.id && (
        <ChangeUserPasswordPopUp
          isOpen={changeUserPasswordPopupOpen}
          onClose={() => setChangeUserPasswordPopupOpen(false)}
          onPasswordChanged={() => setIsPasswordChanged(true)}
          id={user?.id}
        />
      )}
      <PageHeader
        currentPage="users"
        pageTitle={user?.fullName || ''}
        path="/uzytkownicy"
      >
        {user?.id && (
          <Button styleType="secondary" onClick={() => setChangeUserPasswordPopupOpen(true)}>
            {getMessage('users.user.resetUserPassword')}
          </Button>
        )}
        {user && userModifiable && (
          <Button onClick={() => setChangeUserStatePopupOpen(true)} className="!ml-4">
            {user.active
              ? intl.formatMessage({ id: 'users.user.deactivate' }, { userName: user.fullName })
              : intl.formatMessage({ id: 'users.user.activate' }, { userName: user.fullName })}
          </Button>
        )}
      </PageHeader>
      <ContentWrapper>
        {isPasswordChanged && <FormNotice type="success" noticeClassName="col-span-full" message={getMessage('profile.user.changePassword.success')} />}
        <UserForm userModifiable={userModifiable} />
      </ContentWrapper>
    </Layout>
  );
};

export default UserPage;
