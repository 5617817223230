/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useCallback, useEffect, useState } from 'react';
import Dialog from '../../../components/Dialog/Dialog';
import useMessages from '../../../hooks/useMessages';
import {
  usePostDocumentCategoryMutation,
  usePostTaskCategoryMutation,
  usePostTicketCategoryMutation,
} from '../../../api/appApi';
import { FormInput } from '../../../components/FormInputs';
import { InputError } from '../../../components/FormInputs/InputLabel';

interface Props {
  isOpen: boolean,
  onClose: () => void,
  categoryType: 'ticket' | 'task' | 'document',
}

const AddCategoryPopup = ({ isOpen, onClose, categoryType }: Props) => {
  const getMessage = useMessages();
  const [postTicketCategory, ticketResult] = usePostTicketCategoryMutation();
  const [postTaskCategory, taskResult] = usePostTaskCategoryMutation();
  const [postDocumentCategory, documentResult] = usePostDocumentCategoryMutation();
  const methods = useForm<{ name: string }>();
  const [backendErrors, setBackendErrors] = useState([] as string[]);

  const onSubmit = (data: { name: string }) => {
    if (categoryType === 'ticket') {
      postTicketCategory(data);
    } else if (categoryType === 'task') {
      postTaskCategory(data);
    } else if (categoryType === 'document') {
      postDocumentCategory(data);
    }
  };

  const handleErrorsFromResult = useCallback((result: any) => {
    if (result.status === 'fulfilled') {
      setBackendErrors([]);
      onClose();
    } else if (result.status === 'rejected') {
      const errors = result.error?.data?.errors || [];
      setBackendErrors(Array.isArray(errors) ? errors : [errors]);
    }
  }, []);

  useEffect(() => {
    handleErrorsFromResult(ticketResult);
  }, [ticketResult]);

  useEffect(() => {
    handleErrorsFromResult(taskResult);
  }, [taskResult]);

  useEffect(() => {
    handleErrorsFromResult(documentResult);
  }, [documentResult]);

  return (
    <FormProvider {...methods}>
      <Dialog
        isOpen={isOpen}
        onAccept={methods.handleSubmit(onSubmit)}
        onClose={onClose}
        acceptButtonText={getMessage('parameters.categories.addDialog.button')}
        submitButton
        preventCloseOnAccept
      >
        <div className="text-head-sm mb-8">
          {getMessage('parameters.categories.addDialog.header')}
        </div>
        <FormInput
          className="mb-8 flex !flex-row items-center justify-between w-full"
          inputClassName="w-3/4 mx-4"
          id="name"
          type="text"
          label={getMessage('parameters.categories.dialog.name')}
          options={{ required: getMessage('parameters.categories.dialog.name.error') }}
        />
        {backendErrors && backendErrors.map((error) => (
          <InputError key={error} message={error} />
        ))}
      </Dialog>
    </FormProvider>
  );
};

export default AddCategoryPopup;
