import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import PageHeader from '../../components/Layout/PageHeader';
import Layout from '../../components/Layout/Layout';
import useMessages from '../../hooks/useMessages';
import Tabs, { TabElement } from '../../components/Tabs/Tabs';
import ContentWrapper from '../../components/ContentWrapper/ContentWrapper';
import PaymentsPage from './PaymentsPage/PaymentsPage';
import YearsBalancePage from './YearsBalancePage/YearsBalancePage';
import MonthsBalancePage from './MonthsBalancePage/MonthsBalancePage';
import TitleBalancePage from './TitleBalancePage/TitleBalancePage';
import { useSettlementsYears } from '../../hooks/settlements/useSettlementsYears';
import { useSettlementsVenue } from '../../hooks/settlements/useSettlementsVenue';
import PaymentsHistoryPage from './PaymentsHistoryPage/PaymentsHistoryPage';
import { usePermissions } from '../../hooks/usePermissions';

interface Props {
  tab: 'payments' | 'balance' | 'history',
}

const SettlementsPage = ({ tab }: Props) => {
  const getMessage = useMessages();
  const { hasRoleType, checkModuleEnabled, isModuleEnabled } = usePermissions();
  const navigate = useNavigate();
  const { year, month, titleId } = useParams();
  const years = useSettlementsYears();
  const { venueId, venueAddress } = useSettlementsVenue();

  const mostRecentYear = useMemo(() => years[0], [years]); // years array is sorted on backend

  const tabs: TabElement[] = [{
    label: getMessage('settlements.balance'),
    path: `/rozrachunki/analiza_salda/${mostRecentYear || ''}${venueId ? `?venueId=${venueId}` : ''}`,
  }];

  const settlementsTabs = (isModuleEnabled('settlements') && isModuleEnabled('payments')) ? tabs.concat([{
    label: getMessage('settlements.payments'),
    path: `/rozrachunki/platnosci${venueId ? `?venueId=${venueId}` : ''}`,
  }, {
    label: getMessage('settlements.history'),
    path: `/rozrachunki/historia${venueId ? `?venueId=${venueId}` : ''}`,
  }]) : tabs;

  useEffect(() => {
    if (tab === 'balance' && !year && mostRecentYear) {
      navigate(`/rozrachunki/analiza_salda/${mostRecentYear}${venueId ? `?venueId=${venueId}` : ''}`);
    }
  }, [tab, mostRecentYear, venueId]);

  checkModuleEnabled('settlements');

  return (
    <Layout currentPage="settlements">
      {tab === 'payments' && (
        <>
          <PageHeader
            pageTitle={getMessage('navigation.settlements.payments')}
            currentPage="settlements"
            path={hasRoleType('worker') ? '/rozrachunki/lokale/aktywne' : undefined}
            subheader={venueAddress}
          />
          <Tabs tabElements={settlementsTabs} />
          <ContentWrapper>
            <PaymentsPage />
          </ContentWrapper>
        </>
      )}
      {tab === 'balance' && !month && (
        <YearsBalancePage tabs={settlementsTabs} year={year} />
      )}
      {tab === 'balance' && year && month && !titleId && (
        <MonthsBalancePage year={year} month={month} />
      )}
      {tab === 'balance' && year && month && titleId && (
        <TitleBalancePage year={year} month={month} titleId={titleId} />
      )}
      {tab === 'history' && (
        <PaymentsHistoryPage tabs={settlementsTabs} />
      )}
    </Layout>
  );
};

export default SettlementsPage;
