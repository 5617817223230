import { rest } from 'msw';

const journals = [
  {
    autonumbering: false,
    id: '786fe68b-24d2551b05c',
    name: 'dziennik 1',
    prefix: 'Enea',
  },
  {
    autonumbering: false,
    id: '68b535d-1b05c80k5',
    name: 'dziennik 2',
    prefix: 'PGE',
  },
  {
    autonumbering: true,
    id: '573d9bad-061376d0a913',
    name: 'dziennik auto',
    prefix: '2024',
  },
];

const journalToShow = {
  ...journals[1],
  prefix: 'PGE-2024',
  autonumbering: 'Autonumeracja',
  name: 'Dziennik 2 v. 2',
  description: 'Nie było opisu, jest opis',
};

export const journalsMocks = [
  rest.get('/journals/list', (req, res, ctx) => res(
    ctx.status(200),
    ctx.json({ journals }),
  )),
  rest.post('/journals/add', (req, res, ctx) => res(
    ctx.status(200),
  )),
  rest.get('/journals/:id/show', (req, res, ctx) => res(
    ctx.status(200),
    ctx.json({
      journal: {
        ...journals[1], description: '',
      },
    }),
  )),
  rest.patch('/journals/:id/change', (req, res, ctx) => {
    req.json().then(() => ({
      journal: journalToShow,
    }));
    return res(
      ctx.status(200),
    );
  }),
  rest.delete('/journals/:id/remove', (req, res, ctx) => res(
    ctx.status(200),
  )),
];
