import * as React from 'react';
import { useGetBMPaymentQuery } from '../../../../api/appApi';
import Button from '../../../../components/Button/Button';
import Popup from '../../../../components/Popup/Popup';
import useMessages from '../../../../hooks/useMessages';
import Spinner from '../../../../components/Spinner/Spinner';
import SuccessIcon from '../../../../icons/payment-success.svg';
import FailureIcon from '../../../../icons/payment-error.svg';

interface Props {
  isOpen: boolean,
  close: () => void,
  paymentId: string,
}

const PaymentInProgressDialog = ({ paymentId, isOpen, close }: Props) => {
  const getMessage = useMessages();
  const { data: paymentData } = useGetBMPaymentQuery(
    paymentId,
    {
      pollingInterval: 5000,
      skip: !isOpen,
    },
  );

  return (
    <Popup isOpen={isOpen}>
      {paymentData?.status === 'pending' && (
        <>
          <div className="text-typography-black text-lg-bold">{getMessage('settlements.payment.inProgress')}</div>
          <Spinner className="m-6" />
        </>
      )}
      {paymentData?.status === 'success' && (
        <>
          <div className="text-interactions-success-text text-lg-bold">{getMessage('settlements.payment.success')}</div>
          <SuccessIcon className="m-6" />
        </>
      )}
      {paymentData?.status === 'failure' && (
        <>
          <div className="text-interactions-error-text text-lg-bold">{getMessage('settlements.payment.error')}</div>
          <FailureIcon className="m-6" />
        </>
      )}
      <Button onClick={close}>{getMessage('settlements.payment.close')}</Button>
    </Popup>
  );
};

export default PaymentInProgressDialog;
