import * as React from 'react';
import { useState } from 'react';
import { useAppSelector } from '../../../../hooks/reduxHooks';
import useMessages from '../../../../hooks/useMessages';
import { usePermissions } from '../../../../hooks/usePermissions';
import PageHeader from '../../../../components/Layout/PageHeader';
import ContentWrapper from '../../../../components/ContentWrapper/ContentWrapper';
import Layout from '../../../../components/Layout/Layout';
import Button from '../../../../components/Button/Button';
import MetersVenuesList from './MetersVenuesList';
import MetersVenuesFilters from './MetersVenuesFilters';
import FiltersIcon from '../../../../icons/filters.svg';

const MetersVenuesListPage = () => {
  const getMessage = useMessages();
  const { checkPermission, checkRoleType, checkModuleEnabled } = usePermissions();
  const hasFilterSet = useAppSelector((state) => !!state.app.filters.find((filter) => filter.id === 'metersVenuesList'));
  const [filtersOpen, setFiltersOpen] = useState(hasFilterSet);

  checkRoleType('worker');
  checkPermission('meters_list');
  checkModuleEnabled('meters');

  return (
    <Layout currentPage="local.meters">
      <PageHeader pageTitle={getMessage('meters.venuesList')} currentPage="local.meters">
        <Button
          Icon={FiltersIcon}
          size="small"
          styleType="secondary"
          onClick={() => setFiltersOpen((filtersOpen) => !filtersOpen)}
          className="h-8 py-1"
        >
          {getMessage('filters')}
        </Button>
      </PageHeader>
      {filtersOpen && <MetersVenuesFilters />}
      <ContentWrapper>
        <MetersVenuesList />
      </ContentWrapper>
    </Layout>
  );
};

export default MetersVenuesListPage;
