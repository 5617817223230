/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import Dialog from '../../../components/Dialog/Dialog';
import useMessages from '../../../hooks/useMessages';
import {
  usePatchDocumentCategoryMutation,
  usePatchTaskCategoryMutation,
  usePatchTicketCategoryMutation,
} from '../../../api/appApi';
import { FormInput } from '../../../components/FormInputs';
import { InputError } from '../../../components/FormInputs/InputLabel';

interface Props {
  isOpen: boolean,
  onClose: () => void,
  categoryType: 'ticket' | 'task' | 'document',
  category: {
    name: string,
    id: string,
  } | null
}

const ChangeCategoryPopup = ({
  isOpen, onClose, categoryType, category,
}: Props) => {
  const getMessage = useMessages();
  const intl = useIntl();
  const [patchTicketCategory, ticketResult] = usePatchTicketCategoryMutation();
  const [patchTaskCategory, taskResult] = usePatchTaskCategoryMutation();
  const [patchDocumentCategory, documentResult] = usePatchDocumentCategoryMutation();
  const [backendErrors, setBackendErrors] = useState([] as string[]);

  const methods = useForm<{ name: string, id: string }>({
    defaultValues: {
      id: category?.id || '',
      name: category?.name || '',
    },
    mode: 'onTouched',
  });

  const onSubmit = (data: { name: string, id: string }) => {
    if (categoryType === 'ticket') {
      patchTicketCategory(data);
    } else if (categoryType === 'task') {
      patchTaskCategory(data);
    } else if (categoryType === 'document') {
      patchDocumentCategory(data);
    }
  };

  const handleErrorsFromResult = useCallback((result: any) => {
    if (result.status === 'fulfilled') {
      setBackendErrors([]);
      onClose();
    } else if (result.status === 'rejected') {
      const errors = result.error?.data?.errors || [];
      setBackendErrors(Array.isArray(errors) ? errors : [errors]);
    }
  }, []);

  useEffect(() => {
    handleErrorsFromResult(ticketResult);
  }, [ticketResult]);

  useEffect(() => {
    handleErrorsFromResult(taskResult);
  }, [taskResult]);

  useEffect(() => {
    handleErrorsFromResult(documentResult);
  }, [documentResult]);

  useEffect(() => {
    methods.reset({
      id: category?.id || '',
      name: category?.name || '',
    });
    setBackendErrors([]);
  }, [category]);

  return (
    <FormProvider {...methods}>
      <Dialog
        isOpen={isOpen}
        onAccept={methods.handleSubmit(onSubmit)}
        onClose={onClose}
        acceptButtonText={getMessage('parameters.categories.changeDialog.button')}
        submitButton
        preventCloseOnAccept
      >
        <div className="text-head-sm mb-8">
          {getMessage('parameters.categories.changeDialog.header')}
        </div>
        <FormInput
          className="mb-8 flex !flex-row items-center justify-between w-full"
          inputClassName="w-3/4 mx-4"
          id="name"
          type="text"
          label={getMessage('parameters.categories.dialog.name')}
          options={{ required: getMessage('parameters.categories.dialog.name.error'), maxLength: { value: 64, message: intl.formatMessage({ id: 'form.name.error.tooLong' }, { maxLength: 64 }) } }}
        />
        {backendErrors && backendErrors.map((error) => (
          <InputError key={error} message={error} />
        ))}
      </Dialog>
    </FormProvider>
  );
};

export default ChangeCategoryPopup;
