import * as React from 'react';
import ReactDOM from 'react-dom';
import { PropsWithChildren } from 'react';

interface Props {
  isOpen: boolean,
  labelledBy?: string,
  describedBy?: string,
}

const Popup = ({
  isOpen, children, labelledBy, describedBy,
}: PropsWithChildren<Props>) => {
  if (!isOpen) return null;

  const modal = (
    <>
      <div role="dialog" aria-labelledby={labelledBy} aria-describedby={describedBy} className="fixed mobile:relative z-50 top-1/2 left-1/2 mobile:left-1/8 -translate-x-1/2 -translate-y-1/2 w-fit mobile:min-w-[90%] min-w-[500px] min-h-1/3 mobile:h-fit bg-white px-4 mobile:px-1 py-9 mobile:py-3 rounded flex flex-col items-center">
        {children}
      </div>
      <div className="fixed z-40 top-0 left-0 w-full h-full bg-gray-800 opacity-70" />
    </>
  );

  return ReactDOM.createPortal(modal, document.body);
};

export default Popup;
