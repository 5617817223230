import { PaginationMetadata } from '../../paginationTypes';

export const months = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'] as const;
export type Month = typeof months[number];

export interface BalanceQuery {
  venue: string,
  year: string,
  title_id?: string,
  tenant_id?: string,
}

export interface MonthBalanceQuery {
  venue: string,
  year: string,
  month: string,
  tenant_id?: string,
}

export interface TitleBalanceQuery {
  venue: string,
  year: string,
  month: string,
  title_id: string,
  tenant_id?: string,
}

export interface ApiPaymentsResponse {
  payments: ApiPayment[],
}

export interface ApiPayment {
  account_number: string,
  title_names: string,
  value: string,
  bm_payment?: ApiBMPayment,
}

export interface Payment {
  accountNumber: string,
  titleNames: string,
  value: string,
  bmPayment?: BMPayment,
}

export interface ApiBMPaymentResponse {
  payment: ApiBMPayment,
}

export const paymentStatuses = ['pending', 'success', 'failure'] as const;
export type PaymentStatus = typeof paymentStatuses[number];

export interface ApiBMPayment {
  id: string,
  remote_id: string
  order_id: string,
  status: PaymentStatus,
  description: string,
  bank_account: string,
  amount: string,
  redirect_url: string,
  payment_date?: string,
}

export interface APISettlementVenue {
  venue_id: string,
  tenant_id: string,
  address: string,
  building: string,
  code: string,
  homeowner_association: string,
  membership: boolean,
  residency_from: string,
  residency_until: string,
  tenant: string,
  tenant_external_id: string,
}

export interface ApiSettlementVenuesResponse {
  metadata: PaginationMetadata,
  venues: APISettlementVenue[],
}

export interface SettlementVenue {
  venueId: string,
  tenantId: string,
  address: string,
  building: string,
  code: string,
  homeownerAssociation: string,
  membership: boolean,
  residencyFrom: string,
  residencyUntil: string,
  tenant: string,
  tenantExternalId: string,
}

export interface SettlementsVenues {
  metadata: PaginationMetadata,
  venues: SettlementVenue[],
}

export interface BMPayment {
  id: string,
  remoteId: string
  orderId: string,
  status: PaymentStatus,
  description: string,
  bankAccount: string,
  amount: string,
  redirectUrl: string,
  paymentDate?: string,
}

export interface ApiSettlementResponse {
  months: {
    [key: number]: ApiMonthBalance
  },
  year_balance: ApiMonthBalance
}

export interface ApiYearsResponse {
  years: number[]
}

export interface ApiSettlementTitlesResponse {
  titles: ApiSettlementTitle[]
}

export interface ApiSettlementTitle {
  id: string,
  name: string,
  bank_account_number: string,
}

export interface ApiMonthsSettlementsResponse {
  titles: {
    [key: string]: ApiTitle,
  }
}

export interface ApiTitle {
  name: string,
  balance: string,
  bank_account_number: string,
}

export interface ApiDocumentsResponse {
  entry_month_balance: ApiMonthBalance,
  month_balance: ApiMonthBalance,
  documents: ApiDocumentWithItems[],
}

export interface ApiDocument {
  balance: string,
  credits: string,
  date: string,
  deadline: string,
  debits: string
  description: string
  open: boolean,
  operation: string,
  value: string,
  vat: string,
  id: string,
}

export interface ApiDocumentWithItems extends ApiDocument {
  items?: ApiDocumentItem[],
  read_at?: string | null,
  attachment?: ApiDocumentAttachment,
}

export interface ApiDocumentItem {
  amount: string,
  item_name: string,
  position: string,
  price: string,
  unit: string,
  value: string,
  vat: string,
}

export interface ApiDocumentAttachment {
  content_type: string,
  filename: string,
  path: string,
}

export interface SettlementTitleBalance {
  title?: string | null,
  openingBalance: Balance,
  sum: Balance,
  documents: SettlementDocument[]
}

export interface SettlementDocument extends ApiDocument {
  items: DocumentItem[],
  readAt?: string,
  attachment?: DocumentAttachment,
}

export interface DocumentItem {
  amount: string,
  itemName: string,
  position: string,
  price: string,
  unit: string,
  value: string,
  vat: string,
}

export interface DocumentAttachment {
  contentType: string,
  filename: string,
  path: string,
}

export interface MonthsTitle {
  id: string,
  name: string,
  balance: string,
  bankAccountNumber: string,
}

export interface SettlementTitle {
  id: string,
  name: string,
  bankAccountNumber: string,
}

export interface Balance {
  balance: string,
  credits: string,
  debits: string,
}

export interface ApiMonthBalance extends Balance {
  title?: string | null,
}

export interface YearsBalance {
  openingBalance: Balance,
  months: MonthBalance[],
  sum: Balance,
}

export type YearsBalances = Record<string, YearsBalance>;

export interface MonthBalance extends Balance {
  month: Month,
}

export interface PaymentRequest {
  payment: {
    amount: string
    description: string
    bank_account: string
    email: string
    tenants_venue_id: string
  }
}

export interface ApiPaymentsHistoryResponse {
  metadata: PaginationMetadata,
  payments: ApiHistoryPayment[],
}

export interface ApiHistoryPayment {
  status: PaymentStatus,
  description: string,
  bank_account: string,
  amount: string,
  payment_date: string,
}

export interface PaymentsHistory {
  metadata: PaginationMetadata,
  payments: HistoryPayment[],
}

export interface HistoryPayment {
  status: PaymentStatus,
  description: string,
  bankAccount: string
  amount: string,
  paymentDate: string,
}

export interface VenuePaymentsBalance {
  balance: string,
  liability: string,
  overpayment: string,
}

export interface PaymentsBalances {
  [key: string]: VenuePaymentsBalance,
}
