/* eslint-disable jsx-a11y/no-static-element-interactions */
import * as React from 'react';
import { useState, useCallback, useRef } from 'react';
import classnames from 'classnames';
import { QueryDefinition } from '@reduxjs/toolkit/dist/query';
import { UseQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { useFormContext } from 'react-hook-form';
import { InputError, InputLabel } from './InputLabel';
import { baseQuery } from '../../api/baseQuery';
import useMessages from '../../hooks/useMessages';
import useClickOutside from '../../hooks/useClickOutside';

interface Option {
  id: string,
  name: string,
}

const inputDefaultStyles = `inline-block px-2 py-1 font-normal text-typography-black bg-white bg-clip-padding
border border-solid border-stroke-grey-300 rounded transition ease-in-out my-1 text-text-md-semibold
focus:text-gray-black focus:bg-white focus:border-blue-600 focus:outline-none`;

interface Props {
  id: string,
  label?: string,
  query: UseQuery<QueryDefinition<Record<string, string | null>, typeof baseQuery, string, Option[], string>>,
  queryOptions?: Record<string, string | undefined>,
  className?: string,
  disabled?: boolean,
  ariaLabel?: string,
}

const FormSearchableDropdown = ({
  id,
  label,
  query,
  queryOptions,
  className,
  disabled,
  ariaLabel,
}: Props) => {
  const [focusedIndex, setFocusedIndex] = useState(-1);
  const [showResults, setShowResults] = useState(false);
  const [typedValue, setTypedValue] = useState('');
  const { data } = query({ search: typedValue, ...queryOptions });
  const getMessage = useMessages();
  const containterRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLDivElement>(null);

  const { setValue, watch } = useFormContext();
  const currentValue = watch(id);

  const resetSearch = useCallback(() => {
    setFocusedIndex(-1);
    setShowResults(false);
  }, []);

  const handleSelection = (item: Option) => {
    setTypedValue(item.name);
    setValue(id, item.id);
    resetSearch();
  };

  const handleKeyDown: React.KeyboardEventHandler<HTMLDivElement> = (e) => {
    if (data) {
      const { key } = e;
      let nextIndexCount = 0;

      if (key === 'ArrowDown') { nextIndexCount = (focusedIndex + 1) % data.length; }
      if (key === 'ArrowUp') { nextIndexCount = (focusedIndex + data.length - 1) % data.length; }
      if (key === 'Escape') { resetSearch(); }
      if (key === 'Enter') {
        e.preventDefault();
        const item = data[focusedIndex];
        if (item) handleSelection(item);
      }

      setFocusedIndex(nextIndexCount);
      setTimeout(() => {
        listRef.current?.querySelector('.active')?.scrollIntoView({ block: 'nearest' });
      }, 300);
    }
  };

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setTypedValue(e.target.value);
  };

  const resetValues = useCallback(() => {
    setTypedValue('');
    setValue(id, null);
    setShowResults(false);
  }, [id, setValue]);

  const showValues = useCallback(() => {
    setShowResults(true);
  }, [setShowResults]);

  useClickOutside(listRef, resetValues, [containterRef]);

  return (
    <div
      onKeyDown={handleKeyDown}
      className={`relative ${className}`}
      ref={containterRef}
    >
      {label && <InputLabel id={id} label={label} showReset={!!currentValue} onReset={resetValues} />}
      <input
        value={typedValue}
        tabIndex={0}
        onChange={handleChange}
        onFocus={showValues}
        type="text"
        placeholder={getMessage('filters.search')}
        className={classnames(
          'transition w-full',
          inputDefaultStyles,
          { 'bg-background-light': disabled || !!currentValue },
        )}
        autoComplete="off"
        disabled={disabled || !!currentValue}
        aria-label={ariaLabel}
      />
      <input
        id={id}
        type="select"
        value={typedValue}
        style={{ display: 'none' }}
      />
      {(typedValue && !currentValue) && data?.length === 0 && (
        <InputError message={getMessage('noSearchResults')} />
      )}
      {showResults && !currentValue && data && data.length > 0 && (
        <div
          className="absolute w-full bg-white shadow-lg rounded-bl rounded-br max-h-56 overflow-y-auto z-50"
          ref={listRef}
        >
          {data?.map((item: {
            id: string,
            name: string,
          }, index) => (
            <div
              key={item.id}
              onKeyDown={(e) => { if (e.key === 'Enter') handleSelection(item); }}
              onClick={() => handleSelection(item)}
              className={classnames(
                inputDefaultStyles,
                'w-full hover:text-hover hover:bg-light-200 border-none',
                { 'bg-light-200 active': index === focusedIndex },
              )}
            >
              {item.name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default FormSearchableDropdown;
