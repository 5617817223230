import * as React from 'react';
import { useIntl } from 'react-intl';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Dialog from '../../../components/Dialog/Dialog';
import { useDeleteRoleMutation } from '../../../api/appApi';
import { Role } from '../../../api/modules/access/accessTypes';
import Popup from '../../../components/Popup/Popup';
import Button from '../../../components/Button/Button';

interface Props {
  isOpen: boolean,
  role: Role,
  affectedUsersCount?: number,
  onClose: () => void,
}

const RoleDeletePopup = ({
  isOpen, role, affectedUsersCount, onClose,
}: Props) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [deleteRole, deleteRoleQuery] = useDeleteRoleMutation();

  useEffect(() => {
    if (deleteRoleQuery.status === 'fulfilled') {
      navigate('/uprawnienia/role');
    }
  });

  const handleDeleteRole = () => {
    deleteRole(role?.id as string);
  };

  if (affectedUsersCount === 0) {
    return (
      <Dialog isOpen={isOpen} onAccept={handleDeleteRole} onClose={onClose}>
        <h3 className="text-xl font-semibold">
          {intl.formatMessage({ id: 'user.roles.confirmation.deleteRole.header' }, { roleName: role.name })}
        </h3>
        <p className="mb-12 mt-8 mx-16 text-sm">
          {intl.formatMessage({ id: 'user.roles.confirmation.deleteRole.description' }, { roleName: role.name })}
        </p>
      </Dialog>
    );
  }
  return (
    <Popup isOpen={isOpen}>
      <div className="h-1/2 flex flex-col justify-around items-center">
        <p className="mb-8 text-center">
          {intl.formatMessage({ id: 'user.roles.confirmation.deleteRole.affectedUsers' }, { roleName: role.name, usersCount: affectedUsersCount })}
        </p>
        <Button styleType="primary" onClick={onClose}>
          {intl.formatMessage({ id: 'close' })}
        </Button>
      </div>
    </Popup>
  );
};

export default RoleDeletePopup;
