import * as React from 'react';
import { useParams } from 'react-router-dom';
import format from 'date-fns/format';
import { useGetTicketQuery } from '../../../../api/appApi';
import useMessages from '../../../../hooks/useMessages';
import Card from '../../../../components/Card/Card';
import Chip from '../../../../components/Chip/Chip';
import capitalize from '../../../../../utils/formatters';
import CommentsList from '../../../../components/CommentsList/CommentsList';
import AttachmentPreview from '../../../../components/AttachmentPreview/AttachmentPreview';
import { TenantShowTicketData } from '../../../../api/modules/tickets/ticketsTypes';
import DocumentIcon from '../../../../icons/document.svg';
import FallbackRender from '../../../FallbackRender/FallbackRender';
import Spinner from '../../../../components/Spinner/Spinner';

const TenantTicketPage = () => {
  const { id } = useParams();
  const {
    data, isSuccess, isLoading, error,
  } = useGetTicketQuery(id);
  const getMessage = useMessages();

  if (!id) return null;

  const {
    title, status, reportedAt, description, attachments, publicComments, category, venueAddress,
  } = isSuccess ? data : {} as TenantShowTicketData;

  if (isLoading) return (<Card className="col-span-full"><Spinner /></Card>);
  if (error && ('originalStatus' in error)) return (<Card className="col-span-full"><FallbackRender status={error.originalStatus} /></Card>);

  return (
    <Card className="col-span-full">
      <section className="border-b py-3">
        <div className="flex justify-between">
          <h4 className="text-header-sm inline mr-6">{title}</h4>
          <div className="flex">
            <Chip chipStyle="ghost" className="mx-3" screenReaderOnlyText="Data utworzenia zgłoszenia" tooltipText="Data utworzenia">
              {reportedAt && format(new Date(reportedAt), 'dd-MM-yyyy')}
            </Chip>
            <Chip chipStyle="primary" screenReaderOnlyText="Status">
              {status && capitalize(status.name)}
            </Chip>
          </div>
        </div>
        <div className="flex mt-2 text-text-lg-medium">
          <div className="flex mr-4 text-main">
            <DocumentIcon className="stroke-current" />
            {category && (
              <p className="inline-block ml-1 text-text-lg-medium">
                <span className="visually-hidden">Kategoria</span>
                {category.name}
              </p>
            )}
          </div>
          {venueAddress && (
            <p>
              <span className="visually-hidden">Adres lokalu</span>
              {venueAddress}
            </p>
          )}
        </div>
        <p className="text-typography-dark text-text-lg-medium my-7 whitespace-pre-line">{description}</p>
        <div className="my-9">
          {attachments && attachments.length > 0 && (
          <ol className="flex">
            {attachments?.map(({
              filename, path, contentType, description,
            }) => (
              <AttachmentPreview filename={filename} path={path} contentType={contentType} description={description} key={path} />))}
          </ol>
          )}
        </div>
      </section>
      <section>
        <h3 className="inline-block my-5 text-title-semibold">{getMessage('tickets.ticket.comments.discussion')}</h3>
        <CommentsList ticketId={id} comments={publicComments} publicComment />
      </section>
    </Card>
  );
};

export default TenantTicketPage;
