import { rest } from 'msw';
import queryString from 'query-string';

const payments = {
  payments: [
    {
      title_names: 'Lokale użytkowe',
      account_number: '15026236894102598584372021',
      value: '118.00',
      bm_payment: null,
    },
    {
      title_names: 'Zaliczka na eksploatacje, Fundusz remontowy, Media, Odsetki - Zaliczka',
      account_number: '46633100749263722374809894',
      value: '0.00',
      bm_payment: null,
    },
    {
      title_names: 'Odsetki - Fundusz remontowy, Odsetki media, Odsetki sadowe',
      account_number: '73738905867314294548680276',
      value: '0.00',
      bm_payment: null,
    },
  ],
};
const makePayment = {
  id: 'id',
  remote_id: 'id',
  order_id: 'id',
  status: 'pending',
  description: 'desc',
  bank_account: '123412312421421',
  amount: '1000',
  redirect_url: 'payment-url',
};
const titles = [
  {
    id: '2f8d51cb-1245-4f61-8990-01958ef13c3d',
    name: 'Lokale użytkowe',
    bank_account_number: null,
  },
  {
    id: 'ab69cdd1-be9a-45c8-a2d7-883f6b4c8221',
    name: 'Zaliczka na eksploatacje',
    bank_account_number: null,
  },
  {
    id: 'c3a197ad-f7f2-4278-acbc-bad88cd155de',
    name: 'Fundusz remontowy',
    bank_account_number: null,
  },
];
const settlements2023 = {
  0: {
    title: null,
    credits: '18.0',
    debits: '-38.0',
    balance: '56.0',
  },
  1: {
    title: null,
    credits: '155.0',
    debits: '184.0',
    balance: '-29.0',
  },
  2: {
    title: null,
    credits: '104.0',
    debits: '15.0',
    balance: '89.0',
  },
  3: {
    title: null,
    credits: '4.0',
    debits: '215.0',
    balance: '-211.0',
  },
  4: {
    title: null,
    credits: '52.0',
    debits: '121.0',
    balance: '-69.0',
  },
  5: {
    title: null,
    credits: '-70.0',
    debits: '-73.0',
    balance: '3.0',
  },
  6: {
    title: null,
    credits: '99.0',
    debits: '-110.0',
    balance: '209.0',
  },
  7: {
    title: null,
    credits: '468.0',
    debits: '23.0',
    balance: '445.0',
  },
  8: {
    title: null,
    credits: '636.0',
    debits: '-138.0',
    balance: '774.0',
  },
  9: {
    title: null,
    credits: '582.0',
    debits: '-181.0',
    balance: '763.0',
  },
  10: {
    title: null,
    credits: '445.0',
    debits: '-85.0',
    balance: '530.0',
  },
  11: {
    title: null,
    credits: '536.0',
    debits: '-4.0',
    balance: '540.0',
  },
  12: {
    title: null,
    credits: '651.0',
    debits: '-207.0',
    balance: '858.0',
  },
};
const settlements2023WithTitle = {
  0: {
    title: null,
    credits: '0',
    debits: '0',
    balance: '0',
  },
  1: {
    title: null,
    credits: '0',
    debits: '0',
    balance: '0',
  },
  2: {
    title: null,
    credits: '0',
    debits: '0',
    balance: '0',
  },
  3: {
    title: null,
    credits: '0',
    debits: '0',
    balance: '0',
  },
  4: {
    title: null,
    credits: '0',
    debits: '0',
    balance: '0',
  },
  5: {
    title: null,
    credits: '0',
    debits: '0',
    balance: '0',
  },
  6: {
    title: null,
    credits: '0',
    debits: '0',
    balance: '0',
  },
  7: {
    title: null,
    credits: '0',
    debits: '0',
    balance: '0',
  },
  8: {
    title: null,
    credits: '0',
    debits: '0',
    balance: '0',
  },
  9: {
    title: null,
    credits: '0',
    debits: '0',
    balance: '0',
  },
  10: {
    title: null,
    credits: '0',
    debits: '0',
    balance: '0',
  },
  11: {
    title: null,
    credits: '0',
    debits: '0',
    balance: '0',
  },
  12: {
    title: null,
    credits: '0',
    debits: '0',
    balance: '0',
  },
};
const settlements2023January = {
  titles: {
    'c3a197ad-f7f2-4278-acbc-bad88cd155de': {
      name: 'Fundusz remontowy',
      bank_account_number: '46633100749263722374809894',
      balance: '350.0',
    },
  },
};
const settlements2023February = {
  titles: {
    '68d79c57-00f9-4317-be52-04c878fbb0f8': {
      name: 'Media',
      bank_account_number: '46633100749263722374809894',
      balance: '83.0',
    },
    '0792fae9-8373-49c6-b472-8375147a1c59': {
      name: 'Odsetki - Zaliczka',
      bank_account_number: '46633100749263722374809894',
      balance: '107.0',
    },
    '315f4716-cc59-4804-bc1d-7598b39548fe': {
      name: 'Odsetki - Fundusz remontowy',
      bank_account_number: '73738905867314294548680276',
      balance: '115.0',
    },
    '78478a69-6413-46d0-8a43-a442bc2dd604': {
      name: 'Odsetki media',
      bank_account_number: '73738905867314294548680276',
      balance: '138.0',
    },
  },
};
const settlements2023JanuaryDocs = {
  entry_month_balance: {
    title: 'Odsetki - Fundusz remontowy',
    credits: '180.0',
    debits: '10.0',
    balance: '170.0',
  },
  documents: [
    {
      id: '172af386-c549-4277-ad7a-1f07cd3fbb07',
      description: '',
      date: '2023-02-04',
      deadline: '2023-02-28',
      open: true,
      operation: 'Korekta',
      debits: '55.0',
      credits: '0.0',
      balance: '115.0',
      value: '-55.0',
      vat: '-185.0',
      attachment: {
        filename: 'FA-1-02-2023.pdf',
        content_type: 'application/pdf',
        path: '/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaEpJaWt5WW1GaVpUTXlOQzFoTVRGaExUUXpaall0WVRkbE5DMHdNamt5TkdZM09XTTRNVGtHT2daRlZBPT0iLCJleHAiOm51bGwsInB1ciI6ImJsb2JfaWQifX0=--8f33420f9d2640de1a7215eb7546c66950f6acfc/FA-1-02-2023.pdf',
      },
      read_at: null,
      items: [
        {
          position: '01',
          item_name: 'składnik 1',
          amount: '3.0',
          unit: 'm3',
          price: '10.0',
          value: '30.0',
        },
        {
          position: '02',
          item_name: 'składnik 2',
          amount: '4.0',
          unit: 'm3',
          price: '10.0',
          value: '40.0',
        },
        {
          position: '03',
          item_name: 'składnik 3',
          amount: '6.0',
          unit: 'm3',
          price: '10.0',
          value: '60.0',
        },
      ],
    },
  ],
  month_balance: {
    title: 'Odsetki - Fundusz remontowy',
    credits: '180.0',
    debits: '65.0',
    balance: '115.0',
  },
};
const paymentsHistory = {
  payments: [
    {
      status: 'pending',
      description: 'Lokale użytkowe',
      bank_account: '15026236894102598584372021',
      amount: '118.0',
      payment_date: '2023-07-07T07:53:01',
    },
    {
      status: 'success',
      description: 'Lokale użytkowe',
      bank_account: '15026236894102598584372021',
      amount: '300.0',
      payment_date: null,
    },
  ],
};
const paymentsHistoryFiltered = {
  payments: [
    {
      status: 'pending',
      description: 'Lokale użytkowe',
      bank_account: '15026236894102598584372021',
      amount: '118.0',
      payment_date: null,
    },
  ],
};

export const settlementsMocks = [
  rest.get('/settlements/venue/:venue/list', (req, res, ctx) => res(
    ctx.status(200),
    ctx.json(payments),
  )),
  rest.get('/settlements/venue/:venue/year_balance/list', (req, res, ctx) => {
    const { query: params } = queryString.parseUrl(req.url.href);
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { title_id } = params;
    return res(
      ctx.status(200),
      ctx.json({ months: title_id ? settlements2023WithTitle : settlements2023, year_balance: { debits: 122, credits: 123, balance: 124 } }),
    );
  }),
  rest.get('/settlements/venue/:venue/month_balance/list', (req, res, ctx) => {
    const { query: params } = queryString.parseUrl(req.url.href);
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { month } = params;
    return res(
      ctx.status(200),
      ctx.json(month === '1' ? settlements2023January : settlements2023February),
    );
  }),
  rest.get('/settlements/venue/:venue/years/list', (req, res, ctx) => res(
    ctx.status(200),
    ctx.json({
      years: [
        '2023',
      ],
    }),
  )),
  rest.get('/settlements/venue/:venue/titles/list', (req, res, ctx) => res(
    ctx.status(200),
    ctx.json({ titles }),
  )),
  rest.get('/settlements/payments/venue/:venue/list_history', (req, res, ctx) => {
    const { query: params } = queryString.parseUrl(req.url.href);
    const {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      description_search, from_amount, to_amount, status,
    } = params;

    if (description_search === 'test' && from_amount === '10' && to_amount === '100' && status === 'pending') {
      return res(
        ctx.status(200),
        ctx.json(paymentsHistoryFiltered),
      );
    }
    return res(
      ctx.status(200),
      ctx.json(paymentsHistory),
    );
  }),
  rest.get('/settlements/venue/:venue/documents/list', (req, res, ctx) => res(
    ctx.status(200),
    ctx.json(settlements2023JanuaryDocs),
  )),
  rest.post('/settlements/payments/make', (req, res, ctx) => res(
    ctx.status(200),
    ctx.json({ payment: makePayment }),
  )),
  rest.get('/settlements/payments/:payment/show', (req, res, ctx) => res(
    ctx.status(200),
    ctx.json({ payment: makePayment }),
  )),
];
