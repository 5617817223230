import * as React from 'react';
import { useParams } from 'react-router-dom';
import useMessages from '../../../hooks/useMessages';
import PageHeader from '../../../components/Layout/PageHeader';
import ContentWrapper from '../../../components/ContentWrapper/ContentWrapper';
import Layout from '../../../components/Layout/Layout';
import TenantResolutionPage from './TenantResolutionPage/TenantResolutionPage';
import EmployeeResolutionPage from './EmployeeResolutionPage/EmployeeResolutionPage';
import VotingsList from './EmployeeResolutionPage/VotingsList';
import { usePermissions } from '../../../hooks/usePermissions';
import { useGetResolutionQuery } from '../../../api/appApi';

const ResolutionPage = () => {
  const getMessage = useMessages();
  const { hasRoleType, checkModuleEnabled } = usePermissions();
  const { id } = useParams();
  const { data: resolution } = useGetResolutionQuery(id, { skip: !id });

  checkModuleEnabled('resolutions');

  return (
    <Layout currentPage="resolutions">
      <PageHeader pageTitle={`${getMessage('resolutions.resolution')} ${resolution ? resolution.resolutionNumber : ''}`} currentPage="resolutions" path="/uchwaly" />
      <ContentWrapper>
        {hasRoleType('worker')
          ? (
            <>
              <EmployeeResolutionPage />
              <VotingsList />
            </>
          )
          : (<TenantResolutionPage />) }
      </ContentWrapper>
    </Layout>
  );
};

export default ResolutionPage;
