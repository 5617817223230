/* eslint-disable react/jsx-props-no-spreading */

import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { format } from 'date-fns';
import { useGetResolutionQuery, usePostResolutionVoteValueMutation } from '../../../../api/appApi';
import useMessages from '../../../../hooks/useMessages';
import AttachmentPreview from '../../../../components/AttachmentPreview/AttachmentPreview';
import Card from '../../../../components/Card/Card';
import Chip from '../../../../components/Chip/Chip';
import { FormRadioButtons } from '../../../../components/FormInputs';
import { ResolutionData } from '../../../../api/modules/resolutions/resolutionsTypes';
import Button from '../../../../components/Button/Button';
import { translateResolutionVoteValueToApi } from '../../../../api/modules/resolutions/resolutionsTranslator';
import LocationIcon from '../../../../icons/location.svg';
import { FormNotice } from '../../../../components/FormNotice/FormNotice';

type ResolutionVoteInputs = { voteValue: string };

interface VotingFormProps {
  voteOptions: string[],
  voteValue: string | null,
  id: string,
  active: boolean,
}

const VotingForm = ({
  voteOptions, voteValue, active, id,
}: VotingFormProps) => {
  const getMessage = useMessages();
  const methods = useForm<ResolutionVoteInputs>({
    defaultValues: {
      voteValue: voteValue || '',
    },
  });

  const [postVoteValue, result] = usePostResolutionVoteValueMutation();

  const onSubmit = (data: any) => {
    postVoteValue({
      id,
      payload: translateResolutionVoteValueToApi(data),
    });
  };

  return (
    <FormProvider {...methods}>
      {/* @ts-ignore */}
      {result.error && <FormNotice type="error" noticeClassName="mt-2" message={result.error.data?.errors || getMessage('resolutions.resolutions.voting.error.internalError')} />}
      <form className="w-full md:w-1/3" onSubmit={methods.handleSubmit(onSubmit)}>
        <fieldset className="radio-container">
          <legend className="mb-1">{getMessage('resolutions.resolution.myVote')}</legend>
          <FormRadioButtons
            inputOptions={voteOptions.map((option) => ({ value: option })) || []}
            id="voteValue"
            label="resolutions.resolution.voteOptions"
            disabled={!!voteValue || !active}
            options={{ required: getMessage('resolutions.resolutions.voting.error.required') }}
          />
        </fieldset>
        <Button type="submit" className="mt-4 w-full" disabled={!!voteValue || !active}>
          {voteValue ? getMessage('resolutions.resolution.voted') : getMessage('resolutions.resolution.vote')}
        </Button>
      </form>
    </FormProvider>
  );
};

const TenantResolutionPage = () => {
  const { id } = useParams();
  if (!id) return null;
  const { data, isSuccess } = useGetResolutionQuery(id);
  const getMessage = useMessages();
  if (!isSuccess) return null;
  const {
    active, title, text, createdAt, contentAcceptedAt, attachments,
    votingDeadline, voteOptions, voteValue, status, votedAt, homeownerAssociationName,
  } = data as ResolutionData;

  return (
    <Card className="col-span-full">
      <div className="mb-8 pb-6 border-b">
        <h2 className="text-header-sm">{title}</h2>
        <div className="md:flex justify-between items-center">
          <div className="flex mt-2 mb-3 md:mb-0 text-main">
            <LocationIcon />
            <p className="ml-1 text-text-lg-medium">
              <span className="visually-hidden">Wspólnota</span>
              {homeownerAssociationName}
            </p>
          </div>
          <div className="flex">
            <Chip chipStyle="primary" className="min-w-fit h-fit">{active ? getMessage('resolutions.resolution.votingActive') : getMessage(`resolutions.status.${status}`)}</Chip>
            <Chip chipStyle="ghost" className="min-w-fit h-fit ml-2">
              {getMessage('resolutions.resolution.createdAt')}
              {createdAt && format(new Date(createdAt), 'dd-MM-yyyy')}
            </Chip>
            {votedAt && (
            <Chip chipStyle="ghost" className="min-w-fit h-fit ml-2">
              <span className="font-semibold">{getMessage('resolutions.voted')}</span>
              {format(new Date(votedAt), 'dd-MM-yyyy')}
            </Chip>
            )}
          </div>
        </div>

      </div>
      <section className="text-typography-dark text-text-lg-medium whitespace-pre-line">
        {text}
        <p className="text-text-md-semibold my-4">
          {getMessage('resolutions.resolution.voteUntil')}
          {votingDeadline && format(new Date(votingDeadline), 'dd-MM-yyyy')}
        </p>
      </section>
      {attachments?.length > 0 && (
      <section className="my-6 text-text-md-medium w-full">
        <ol className="flex flex-wrap">
          {attachments.map(({ filename, path, contentType }, index) => (
            <AttachmentPreview key={path} filename={filename} path={path} href={`${id}/akceptuj/${index}`} contentType={contentType} />))}
        </ol>
        {active && (
          !contentAcceptedAt
            ? (
              <a href={`${id}/akceptuj/${0}`}>
                <div className="mt-8 px-8 py-4 text-typography-dark bg-background-light rounded">
                  {getMessage('resolutions.resolution.attachmentsNotice')}
                </div>
              </a>
            )
            : (
              <div className="mt-8 px-8 py-4 text-typography-dark bg-background-light rounded">
                {getMessage('resolutions.resolution.attachmentsAccepted')}
              </div>
            )
        )}
      </section>
      )}
      {(contentAcceptedAt || attachments.length === 0 || !active) && (
      <section className="pt-6 border-t">
        {voteValue && <p className="text-title-semibold mb-4" aria-live="polite">{getMessage('resolutions.resolution.voteSubmitted')}</p>}
        <VotingForm id={id} voteValue={voteValue} voteOptions={voteOptions} active={active} />
      </section>
      )}
    </Card>
  );
};

export default TenantResolutionPage;
