import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { appApi, useGetFullUserAccountQuery } from '../../api/appApi';
import { SettlementTitle } from '../../api/modules/settlements/settlementsTypes';
import { useSettlementsVenue } from './useSettlementsVenue';
import { usePermissions } from '../usePermissions';

export const useSettlementsTitles = () => {
  const { venueId } = useSettlementsVenue();
  const { hasRoleType } = usePermissions();
  const { data: user } = useGetFullUserAccountQuery();

  const multiVenuesIds = useMemo(() => (venueId ? [venueId] : user?.tenant?.venues.map((venue) => venue.id)), [user, venueId]);
  const [fetchTitles] = appApi.endpoints.getSettlementsTitles.useLazyQuery();
  const navigate = useNavigate();

  const [titles, setTitles] = useState([] as SettlementTitle[]);
  const selectedSettlementsTenantId = hasRoleType('worker') ? localStorage.getItem(`selectedSettlementsTenantId-${user?.id}`) : '';
  if (hasRoleType('worker') && !selectedSettlementsTenantId) navigate('/rozrachunki/lokale/aktywne');

  useEffect(() => {
    (multiVenuesIds || []).forEach((venueId) => {
      fetchTitles({ venue: venueId, tenantId: hasRoleType('worker') ? selectedSettlementsTenantId : '' }).then((res) => {
        setTitles((state) => {
          const fetchedTitles = res?.data || [];
          const titlesToAdd = fetchedTitles.filter((fetchedTitle) => !state.find((stateTitle) => stateTitle.id === fetchedTitle.id));

          return [...state, ...titlesToAdd];
        });
      });
    });
  }, [multiVenuesIds]);

  return titles;
};
