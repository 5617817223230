import * as React from 'react';
import { SelectOption } from '../FormInputs/inputs/FormSelect';
import {
  FormInput, FormMultiSelect, FormRangeDatePicker, FormSelect,
} from '../FormInputs';
import useMessages from '../../hooks/useMessages';

type CommonInputConfig = {
  id: string,
  label: string,
  defaultValue?: string | string[],
  ariaLabel?: string,
};

interface InputConfig extends CommonInputConfig {
  type: 'text' | 'number',
  selectOptions?: never,
  toDateId?: never,
}

interface SelectConfig extends CommonInputConfig {
  type: 'select' | 'multiselect',
  selectOptions: SelectOption[],
  toDateId?: never,
  showReset?: boolean,
}

interface RangeConfig extends CommonInputConfig {
  type: 'dateRange',
  toDateId: string,
  selectOptions?: never,
  showReset?: boolean,
}

export type FilterConfig = InputConfig | SelectConfig | RangeConfig;

interface Props {
  config: FilterConfig,
}

const FilterInput = ({ config }: Props) => {
  const getMessage = useMessages();
  const {
    type, id, label, selectOptions, toDateId, ariaLabel,
  } = config;

  const classname = 'col-span-12 md:col-span-4 lg:col-span-2';

  switch (type) {
    case 'text':
      return (
        <FormInput
          className={classname}
          id={id}
          label={label}
          type="text"
          ariaLabel={ariaLabel}
          inputClassName="w-full"
          placeholder={getMessage('filters.search')}
        />
      );
    case 'number':
      return (
        <FormInput
          className={classname}
          id={id}
          label={label}
          type="number"
          inputClassName="w-full"
        />
      );
    case 'select':
      return (
        <FormSelect
          className={classname}
          id={id}
          label={label}
          selectOptions={selectOptions}
          showReset={typeof config.showReset === 'boolean' ? config.showReset : true}
        />
      );
    case 'multiselect':
      return (
        <FormMultiSelect
          className={classname}
          id={id}
          label={label}
          selectOptions={selectOptions}
          showReset={typeof config.showReset === 'boolean' ? config.showReset : true}
        />
      );
    case 'dateRange':
      return (
        <FormRangeDatePicker
          className={classname}
          idFrom={id}
          idTo={toDateId}
          inputClassName="w-full"
          label={label}
          showReset={typeof config.showReset === 'boolean' ? config.showReset : true}
        />
      );
    default:
      return null;
  }
};

export default FilterInput;
