import * as React from 'react';
import { useMemo } from 'react';
import useMessages from '../../../hooks/useMessages';
import Filters from '../../../components/Filters/Filters';
import { roleTypes } from '../../../../utils/permissions';
import { FilterConfig } from '../../../components/FilterInput/FilterInput';

interface Props {
  includeRoleType: boolean
}

const UsersFilters = ({ includeRoleType }: Props) => {
  const getMessage = useMessages();

  const roleTypeOptionsValues = roleTypes?.map((roleType) => ({
    value: roleType,
    optionLabel: getMessage(`permissions.roleType.${roleType}`),
  }));

  const filterConfig = useMemo(() => {
    const filters: FilterConfig[] = [
      {
        type: 'text',
        id: 'full_name',
        label: getMessage('users.filter.fullName'),
        ariaLabel: 'Szukaj po użytkowniku',
      },
      {
        type: 'text',
        id: 'login_option_search',
        label: getMessage('users.filter.login'),
        ariaLabel: 'Szukaj po loginie',
      },
    ];
    if (includeRoleType) {
      filters.push({
        type: 'multiselect',
        id: 'role_type',
        label: getMessage('users.filter.roleType'),
        selectOptions: roleTypeOptionsValues,
      });
    }
    return filters;
  }, [includeRoleType]);

  return (
    <Filters
      filterId="usersTable"
      filterFields={filterConfig}
    />
  );
};

export default UsersFilters;
