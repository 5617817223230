import * as React from 'react';
import format from 'date-fns/format';
import { Meter } from '../../../../../api/modules/meters/metersTypes';
import ExpandableCard from '../../../../../components/ExpandableCard/ExpandableCard';
import { appApi } from '../../../../../api/appApi';
import HistoryCardHeader from './HistoryCardHeader';
import Table, {
  TableBody, TableBodyCell,
  TableBodyRow,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow,
} from '../../../../../components/Table/Table';
import Chip from '../../../../../components/Chip/Chip';
import useMessages from '../../../../../hooks/useMessages';
import { formatNumber } from '../../../../../../utils/formatters';

interface Props {
  meter: Meter,
}

const MetersHistoryCard = ({ meter }: Props) => {
  const getMessage = useMessages();
  const [trigger, { data }] = appApi.endpoints.getMeterHistory.useLazyQuery();

  return (
    <ExpandableCard
      className="mb-4 border"
      title={<HistoryCardHeader meterLocation={meter.meterLocation} meterCode={meter.meterCode} meterType={meter.meterType} />}
      onOpen={() => trigger(meter.meterId, true)}
    >
      {data && data.find((measurement) => !!measurement.value) ? (
        <Table className="col-span-full py-0">
          <TableHeader>
            <TableHeaderRow>
              <TableHeaderCell>{getMessage('local.meters.history.date')}</TableHeaderCell>
              <TableHeaderCell>{getMessage('local.meters.history.value')}</TableHeaderCell>
              <TableHeaderCell>{getMessage('local.meters.history.source')}</TableHeaderCell>
            </TableHeaderRow>
          </TableHeader>
          <TableBody>
            {data.filter((measurement) => !!measurement.value).map((measurement) => (
              <TableBodyRow key={`${measurement.value}-${measurement.date}`}>
                <TableBodyCell>
                  <Chip chipStyle="ghost" className="w-fit">
                    {format(new Date(measurement.date), 'dd-MM-yyyy')}
                  </Chip>
                </TableBodyCell>
                <TableBodyCell>{`${formatNumber(measurement.value)} ${meter.measurementUnit}`}</TableBodyCell>
                <TableBodyCell>{measurement.source}</TableBodyCell>
              </TableBodyRow>
            ))}
          </TableBody>
        </Table>
      ) : getMessage('local.meters.history.noData')}
    </ExpandableCard>
  );
};

export default MetersHistoryCard;
