import * as React from 'react';
import AnnouncementsWidget from './Widgets/AnnouncementsWidget';
import { usePermissions } from '../../hooks/usePermissions';
import ContentWrapper from '../../components/ContentWrapper/ContentWrapper';
import TasksWidget from './Widgets/TasksWidget';
import TicketsWidget from './Widgets/TicketsWidget';
import DocumentsWidget from './Widgets/DocumentsWidget';

const WorkerDashboardPage = () => {
  const { hasPermission, isModuleEnabled } = usePermissions();

  return (
    <ContentWrapper>
      <div className="col-span-12 lg:col-span-4 lg:col-start-9 lg:row-start-1 tablet:grid tablet:grid-cols-2 tablet:gap-6">
        {isModuleEnabled('tickets') && <TasksWidget />}
        {isModuleEnabled('documents') && <DocumentsWidget />}
      </div>
      <div className="col-span-12 col-start-1 lg:col-span-8 lg:col-start-1 lg:row-start-1">
        {hasPermission('announcements_list') && <AnnouncementsWidget />}
        {isModuleEnabled('tickets') && <TicketsWidget />}
      </div>
    </ContentWrapper>
  );
};

export default WorkerDashboardPage;
