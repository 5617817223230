import * as React from 'react';
import { useState } from 'react';
import PageHeader from '../../../components/Layout/PageHeader';
import useMessages from '../../../hooks/useMessages';
import Tabs, { TabElement } from '../../../components/Tabs/Tabs';
import { usePermissions } from '../../../hooks/usePermissions';
import ContentWrapper from '../../../components/ContentWrapper/ContentWrapper';
import MetersList from './MetersListPage/MetersList';
import MetersHistory from './MetersHistoryPage/MetersHistory';
import Button from '../../../components/Button/Button';
import { useAppSelector } from '../../../hooks/reduxHooks';
import MetersHistoryFilters from './MetersHistoryPage/MetersHistoryFilters';
import Layout from '../../../components/Layout/Layout';
import FiltersIcon from '../../../icons/filters.svg';
import { useGetFullUserAccountQuery } from '../../../api/appApi';
import ExpandableCard from '../../../components/ExpandableCard/ExpandableCard';

interface Props {
  tab: 'list' | 'history',
}

const TenantMetersPage = ({ tab }: Props) => {
  const getMessage = useMessages();
  const { data: user } = useGetFullUserAccountQuery();
  const { checkPermission, checkModuleEnabled } = usePermissions();
  const hasFilterSet = useAppSelector((state) => !!state.app.filters.find((filter) => filter.id === 'metersHistory'));
  const [filtersOpen, setFiltersOpen] = useState(hasFilterSet);
  const venueId = useAppSelector((state) => state.app.selectedVenueId);

  const tabs: TabElement[] = [
    {
      label: getMessage('local.meters.readings'),
      path: '/liczniki',
    },
    {
      label: getMessage('local.meters.readingsHistory'),
      path: '/liczniki/historia',
    },
  ];

  checkPermission('meters_list');
  checkModuleEnabled('meters');

  return (
    <Layout currentPage="local.meters">
      <PageHeader
        pageTitle={getMessage('navigation.local.meters')}
        currentPage="local.meters"
      >
        {tab === 'history' && (
          <Button
            Icon={FiltersIcon}
            size="small"
            styleType="secondary"
            onClick={() => setFiltersOpen((filtersOpen) => !filtersOpen)}
            className="h-8 py-1"
          >
            {getMessage('filters')}
          </Button>
        )}
      </PageHeader>
      <Tabs tabElements={tabs} />
      {tab === 'history' && filtersOpen && <MetersHistoryFilters />}
      <ContentWrapper>
        {tab === 'list'
          && (venueId ? <MetersList venueId={venueId} /> : (
            <>
              {(user?.tenant?.venues || []).map((venue) => (
                <ExpandableCard className="col-span-full" title={venue.address} key={venue.id}>
                  <MetersList venueId={venue.id} />
                </ExpandableCard>
              ))}
            </>
          ))}
        {tab === 'history' && (venueId ? <MetersHistory venueId={venueId} /> : (
          <>
            {(user?.tenant?.venues || []).map((venue) => (
              <ExpandableCard className="col-span-full" title={venue.address} key={venue.id}>
                <MetersHistory venueId={venue.id} />
              </ExpandableCard>
            ))}
          </>
        ))}
      </ContentWrapper>
    </Layout>
  );
};

export default TenantMetersPage;
