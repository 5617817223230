import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import useMessages from '../../hooks/useMessages';
import { usePermissions } from '../../hooks/usePermissions';

interface Props {
  selectedPage: 'tasksParameters' | 'ticketsParameters' | 'documentsParameters' | 'statuses' | 'journals'
}

const ParametersSidebar = ({ selectedPage }: Props) => {
  const getMessage = useMessages();
  const navigate = useNavigate();
  const { checkRoleType, isModuleEnabled, checkModuleEnabled } = usePermissions();

  checkRoleType('admin');
  if (!isModuleEnabled('tickets')) checkModuleEnabled('documents');
  if (!isModuleEnabled('documents')) checkModuleEnabled('tickets');

  return (
    <div className="flex flex-col w-52 border-r text-gray-800 pt-20 bg-gray-100 capitalize justify-between">
      <div className="flex flex-col w-full">
        <div
          className={classNames('m-2 pl-3 py-2 rounded hover:bg-gray-200 cursor-pointer', { 'bg-gray-300': selectedPage === 'statuses' })}
          role="button"
          tabIndex={0}
          onClick={() => { navigate('/parametry/statusy'); }}
          onKeyPress={() => { navigate('/parametry/statusy'); }}
        >
          {getMessage('navigation.parameters.statuses')}
        </div>
        {isModuleEnabled('documents') && (
          <div
            className={classNames('m-2 pl-3 py-2 rounded hover:bg-gray-200 cursor-pointer', { 'bg-gray-300': selectedPage === 'journals' })}
            role="button"
            tabIndex={0}
            onClick={() => { navigate('/parametry/dzienniki'); }}
            onKeyPress={() => { navigate('/parametry/dzienniki'); }}
          >
            {getMessage('navigation.parameters.journals')}
          </div>
        )}
        {isModuleEnabled('tickets') && (
          <>
            <div
              className={classNames('m-2 pl-3 py-2 rounded hover:bg-gray-200 cursor-pointer', { 'bg-gray-300': selectedPage === 'ticketsParameters' })}
              role="button"
              tabIndex={0}
              onClick={() => { navigate('/parametry/zgloszenia'); }}
              onKeyPress={() => { navigate('/parametry/zgloszenia'); }}
            >
              {getMessage('navigation.parameters.tickets')}
            </div>
            <div
              className={classNames('m-2 pl-3 py-2 rounded hover:bg-gray-200 cursor-pointer', { 'bg-gray-300': selectedPage === 'tasksParameters' })}
              role="button"
              tabIndex={0}
              onClick={() => { navigate('/parametry/zadania'); }}
              onKeyPress={() => { navigate('/parametry/zadania'); }}
            >
              {getMessage('navigation.parameters.tasks')}
            </div>
          </>
        )}
        {isModuleEnabled('documents') && (
          <div
            className={classNames('m-2 pl-3 py-2 rounded hover:bg-gray-200 cursor-pointer', { 'bg-gray-300': selectedPage === 'documentsParameters' })}
            role="button"
            tabIndex={0}
            onClick={() => { navigate('/parametry/dokumenty'); }}
            onKeyPress={() => { navigate('/parametry/dokumenty'); }}
          >
            {getMessage('navigation.parameters.documents')}
          </div>
        )}
      </div>
    </div>
  );
};

export default ParametersSidebar;
