import * as React from 'react';
import { useMemo } from 'react';
import { useGetSelectedTicketsColumnsQuery, usePostSelectedTicketsColumnsMutation } from '../../../../api/appApi';
import { SelectOption } from '../../../../components/FormInputs/inputs/FormSelect';
import { ticketCustomizableColumnIds, ticketDefaultColumnIds } from '../../../../api/modules/tickets/ticketsTypes';
import useMessages from '../../../../hooks/useMessages';
import TableCustomisationMenu from '../../../../components/TableCustomisationMenu/TableCustomisationMenu';
import useHomeownerAssociationsMode from '../../../../hooks/useHomeownerAssociationsMode';

const TicketsTableCustomisation = () => {
  const getMessage = useMessages();
  const hoaMode = useHomeownerAssociationsMode();

  const { data: selectedColumns } = useGetSelectedTicketsColumnsQuery();
  const [postSelectedTicketsColumns] = usePostSelectedTicketsColumnsMutation();

  const selectOptions: SelectOption[] = useMemo(() => {
    const customizableColumns = ticketCustomizableColumnIds.map((id) => ({
      value: id,
      optionLabel: getMessage(`tickets.column.${id}`),
    }));

    return hoaMode ? customizableColumns : customizableColumns.filter((option) => option.value !== 'homeowner_association_name');
  }, [hoaMode]);

  if (!selectedColumns || !postSelectedTicketsColumns) return null;

  const handleSelectedColumns = (selectedColumns: string[]) => {
    const columns = ticketDefaultColumnIds.concat(selectedColumns);
    postSelectedTicketsColumns({ columns });
  };

  return (
    <TableCustomisationMenu
      selectedColumns={selectedColumns}
      setSelectedColumns={handleSelectedColumns}
      selectOptions={selectOptions}
      tableId="TicketsTable"
    />
  );
};

export default TicketsTableCustomisation;
