import * as React from 'react';

interface Props {
  className?: string,
}

const ContentWrapper = ({ children, className }: React.PropsWithChildren<Props>) => (
  <div className={`overflow-y-auto px-2 sm:px-3 md:px-7 lg:px-8 xl:px-12 py-7 xl:py-8 border-t grid-cols-12 grid gap-y-6 md:gap-6 ${className}`}>
    {children}
  </div>
);

export default ContentWrapper;
