import * as React from 'react';
import { Link, useLocation } from 'react-router-dom';
import useMessages from '../../hooks/useMessages';

const BackLink = ({ path }: { path: string }) => {
  const getMessage = useMessages();
  const { state } = useLocation();

  return (
    <Link
      to={state?.returnTo || path}
      className="inline-block pr-5 align-text-top"
      aria-label={getMessage('goBack')}
    >
      &#60;
    </Link>
  );
};

export default BackLink;
