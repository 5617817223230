/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable react/jsx-props-no-spreading */

import * as React from 'react';
import { useState, useCallback } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import classNames from 'classnames';
import { useGetEmployeesQuery, usePostChangeTicketMutation } from '../../../../../api/appApi';
import { useAppDispatch } from '../../../../../hooks/reduxHooks';
import { resetSelectedTicketIds } from '../../../../../store/appSlice';
import useMessages from '../../../../../hooks/useMessages';
import RealizerIcon from '../../../../../icons/user.svg';
import Dialog from '../../../../../components/Dialog/Dialog';
import FormSearchableDropdown from '../../../../../components/FormInputs/FormSearchableDropdown';
import { InputLabel } from '../../../../../components/FormInputs/InputLabel';

const TicketAssignEmployeeAction = ({ selectedTicketsIds }: { selectedTicketsIds: string[] }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [postChangeTicket] = usePostChangeTicketMutation();
  const getMessage = useMessages();
  const methods = useForm();
  const realizerId = methods.watch('realizer_id');
  const dispatch = useAppDispatch();

  const handleClick = () => {
    if (selectedTicketsIds.length > 0) setIsPopupOpen(true);
  };

  const onAccept = useCallback(() => {
    const tickets: {
      [key: string]: {
        realizer_id: string
      }
    } = {};
    function populatePayload(id: string) {
      tickets[id] = { realizer_id: realizerId };
    }
    selectedTicketsIds.forEach((item) => populatePayload(item));
    postChangeTicket({ tickets });
    methods.setValue('realizer_id', '');
  }, [realizerId]);

  const onClose = () => {
    setIsPopupOpen(false);
    dispatch(resetSelectedTicketIds());
  };

  const dynamicStyles = (selectedTicketsIds.length > 0) ? 'border-typography-dark hover:border-hover hover:cursor-pointer hover:text-hover hover:bg-light-200' : 'text-stroke-grey-300 border-stroke-grey-300 hover:cursor-default';

  return (
    <div title={getMessage('tickets.bulkAction.changeRealizer')}>
      <RealizerIcon
        role="button"
        className={classNames(dynamicStyles, 'border rounded text-typography-dark p-2 mr-1')}
        onClick={handleClick}
        tabIndex={(selectedTicketsIds.length > 0) ? 0 : undefined}
        onKeyDown={(e) => e.key === 'Enter' && setIsPopupOpen(true)}
        aria-label={getMessage('tickets.bulkAction.changeRealizer')}
      />
      {isPopupOpen
        && (
        <Dialog
          isOpen={isPopupOpen}
          acceptButtonText={getMessage('confirm')}
          onClose={onClose}
          closeButtonText={getMessage('cancel')}
          onAccept={onAccept}
        >
          <div className="mb-10">
            <InputLabel label={getMessage('tickets.ticket.changeTicketsRealizer')} autofocus />
            <FormProvider {...methods}>
              <FormSearchableDropdown
                label=" "
                className="mt-4 w-full"
                id="realizer_id"
                query={useGetEmployeesQuery}
                ariaLabel={getMessage('form.ariaLabel.realizer')}
              />
            </FormProvider>
          </div>
        </Dialog>
        ) }
    </div>
  );
};

export default TicketAssignEmployeeAction;
