import * as React from 'react';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { apiMeterType } from '../../../../api/modules/meters/metersTypes';
import { translateMeterType } from '../../../../api/modules/meters/metersTranslator';
import Filters from '../../../../components/Filters/Filters';

const MetersHistoryFilters = () => {
  const intl = useIntl();

  const typeOptionValues = useMemo(() => apiMeterType.map((type) => ({
    value: type as string,
    optionLabel: translateMeterType(type, intl).type,
  })), []);

  return (
    <Filters
      filterId="metersHistory"
      filterFields={[
        {
          type: 'multiselect',
          id: 'type',
          label: intl.formatMessage({ id: 'local.meters.filters.type' }),
          selectOptions: typeOptionValues,
        },
        {
          type: 'text',
          id: 'location',
          label: intl.formatMessage({ id: 'local.meters.filters.location' }),
          ariaLabel: 'Szukaj po lokalizacji licznika',
        },
      ]}
    />
  );
};

export default MetersHistoryFilters;
