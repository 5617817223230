import * as React from 'react';
import { useEffect, PropsWithChildren } from 'react';
import classnames from 'classnames';
import BackLink from '../BackLink/BackLink';
import MobileTopBar from '../Navigation/TopBar/TopBar';
import { NavigationRoutes } from './Layout';
import { PAGE_TITLE } from '../../../utils/consts';
import useMessages from '../../hooks/useMessages';

interface Props {
  pageTitle: string | undefined,
  currentPage?: NavigationRoutes,
  subheader?: string,
  path?: string,
}

const PageHeader = ({
  pageTitle, children, currentPage, subheader, path,
}: PropsWithChildren<Props>) => {
  const getMessage = useMessages();

  const currentNavigationItem = getMessage(`navigation.${currentPage}`);

  const pageTitleToSet = currentNavigationItem.toLocaleLowerCase() === pageTitle?.toLocaleLowerCase()
    ? `${pageTitle} - ${PAGE_TITLE}`
    : `${currentNavigationItem} - ${pageTitle} - ${PAGE_TITLE}`;

  useEffect(() => () => {
    document.title = pageTitle ? pageTitleToSet : PAGE_TITLE;
  }, []);

  return (
    <>
      <MobileTopBar currentPage={currentPage} pageTitle={pageTitle} path={path} />
      <header
        className={classnames(
          'px-2 sm:px-3 md:px-7 lg:px-8 xl:px-12 flex justify-between flex-row-reverse lg:flex-row items-center bg-background-white text-typography-black',
          { 'lg:min-h-headerDesktop': !subheader, 'lg:min-h-headerDesktopWithSubheader': subheader, 'min-h-[70px] md:min-h-headerTablet border-t': children },
        )}
      >
        <div className="hidden lg:flex flex-col justify-between h-8 text-header-md">
          <div className="text-header-md" id="pageTitle">
            {path && <BackLink path={path} />}
            {pageTitle}
          </div>
          {subheader && (
          <span className="text-typography-dark text-text-lg-medium">
            {subheader}
          </span>
          )}
        </div>
        {children && (
        <div className="flex justify-end">
          {children}
        </div>
        )}
      </header>
    </>
  );
};

export default PageHeader;
