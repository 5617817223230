import * as React from 'react';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import useMessages from '../../hooks/useMessages';
import { FormDatePicker, FormInput } from '../../components/FormInputs';
import Button from '../../components/Button/Button';
import { usePostResidencyUpdateMutation } from '../../api/appApi';
import { useAppSelector } from '../../hooks/reduxHooks';
import { translateResidencyUpdateToApi } from '../../api/modules/residency/residencyTranslator';
import Card from '../../components/Card/Card';
import { FormNotice } from '../../components/FormNotice/FormNotice';

const ResidencyUpdateForm = () => {
  const getMessage = useMessages();
  const venueId = useAppSelector((state) => state.app.selectedVenueId);
  const [showSuccessNotice, setShowSuccessNotice] = useState(false);
  const [showErrorNotice, setShowErrorNotice] = useState(false);
  const methods = useForm();
  const [postResidencyUpdate, { error, status }] = usePostResidencyUpdateMutation();

  useEffect(() => {
    methods.reset();
    setShowErrorNotice(false);
    setShowSuccessNotice(false);
  }, [venueId]);

  useEffect(() => {
    if (status === 'fulfilled') {
      methods.reset();
      setShowSuccessNotice(true);
    }
  }, [status]);

  useEffect(() => setShowErrorNotice(true), [error]);

  const onSubmit = (data: any) => {
    postResidencyUpdate(translateResidencyUpdateToApi(venueId, data));
  };

  return (
    <Card>
      {showSuccessNotice && <FormNotice type="success" message={getMessage('local.tenants.residency.success')} />}
      {/* @ts-ignore */}
      {error && showErrorNotice && <FormNotice type="error" message={error.data?.errors || getMessage('form.error')} />}
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} className="flex flex-col">
          <FormInput
            id="tenantsNumber"
            type="number"
            inputClassName="smallRange:w-full"
            label={getMessage('local.tenants.residency.tenantsNumber')}
            options={{
              required: getMessage('local.tenants.residency.error.required'),
              min: { value: 0, message: getMessage('local.tenants.residency.error.minZero') },
            }}
          />
          <FormDatePicker
            id="validFrom"
            label={getMessage('local.tenants.residency.validFrom')}
            required
            containerClassName="smallRange:w-full"
            inputClassName="smallRange:w-full"
          />
          <Button type="submit" className="mt-4 smallRange:w-full">{getMessage('local.tenants.residency.save')}</Button>
        </form>
      </FormProvider>
    </Card>
  );
};

export default ResidencyUpdateForm;
