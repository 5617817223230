import * as React from 'react';
import format from 'date-fns/format';
import classNames from 'classnames';
import { useState, useEffect } from 'react';
import { useGetAnnouncementsQuery, usePostArchiveAnnouncementMutation, usePostMarkReadAnnouncementMutation } from '../../../api/appApi';
import { TableBodyCell } from '../../../components/Table/Table';
import ResponsiveTable, { ResponsiveTableHeader, ResponsiveTableBody, ResponsiveTableBodyRow } from '../../../components/Table/ResponsiveTable';
import Chip from '../../../components/Chip/Chip';
import PaginationControls from '../../../components/Table/PaginationControls';
import usePagination from '../../../hooks/usePagination';
import useFilters from '../../../hooks/useFilters';
import useSortParams from '../../../hooks/useSortParams';
import { FilterValues, TableId } from '../../../store/appSlice';
import TableContentPlaceholder from '../../../components/Table/TableContentPlaceholder';
import ArchiveIcon from '../../../icons/archiveBox.svg';
import useMessages from '../../../hooks/useMessages';
import ArchiveAnnouncementPopup from './ArchiveAnnouncementPopup';
import { usePermissions } from '../../../hooks/usePermissions';

type AnnouncementsListProps = {
  archived?: true;
};

type AnnouncementToArchive = {
  id: string
  number: string | number
};

const initialState: AnnouncementToArchive = {
  id: '',
  number: '',
};

const AnnouncementsList = ({ archived }: AnnouncementsListProps) => {
  const [selectedPage, setSelectedPage] = usePagination('announcementsList');
  const { sortParamsValues } = useSortParams('announcementsList');
  const { filterValues } = useFilters('announcementsList');
  const [postArchiveAnnouncement] = usePostArchiveAnnouncementMutation();
  const [markReadAnnouncement] = usePostMarkReadAnnouncementMutation();
  const getMessage = useMessages();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [announcementToArchive, setAnnouncementToArchive] = useState<AnnouncementToArchive>(initialState);
  const { hasRoleType } = usePermissions();

  const filters: FilterValues = { ...filterValues };
  if (hasRoleType('tenant')) filters.archived = archived || false;

  useEffect(() => {
    setSelectedPage(1);
  }, [archived]);

  const { data } = useGetAnnouncementsQuery({
    ...sortParamsValues,
    ...filters,
    page: selectedPage.toString(),
  });

  const handleClick = (id: string, status: string) => {
    if (hasRoleType('tenant') && status === 'unread') markReadAnnouncement(id);
  };

  const toggleOpen = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const handleClickArchiveButton = (id: string, announcementNumber: string | number) => {
    toggleOpen();
    setAnnouncementToArchive({ id, number: announcementNumber });
  };

  const onConfirm = () => {
    toggleOpen();
    postArchiveAnnouncement(announcementToArchive.id);
  };

  const onCancel = () => {
    toggleOpen();
    setAnnouncementToArchive(initialState);
  };

  const ids = data?.announcements.map(({ id }) => id) || [];

  const columnLabels = [
    {
      label: 'announcements.announcement.id',
      id: 'announcement_number',
    },
    {
      label: 'announcements.announcement.date',
      id: 'created_at',
    },
    { label: 'announcements.announcement.title' },
    { label: 'announcements.announcement.description' },
    { label: 'announcements.announcement.author' },
  ];

  return (
    <>
      <ResponsiveTable className="col-span-full">
        {data?.announcements && data?.announcements.length === 0 ? <TableContentPlaceholder /> : (
          <>
            <ResponsiveTableHeader
              ids={ids}
              columnLabels={hasRoleType('tenant')
                ? columnLabels.concat({ label: 'announcements.announcement.placeholder' })
                : columnLabels}
              tableName={'announcementsList' as TableId}
            />
            <ResponsiveTableBody>
              {data?.announcements.map(({
                id, announcementNumber, createdAt, description, title, author, status,
              }) => (
                <ResponsiveTableBodyRow
                  key={id}
                  className="border-b last:border-b-0 border-separate font-medium"
                >
                  {hasRoleType('tenant') ? (
                    <TableBodyCell
                      className={classNames(
                        'max-w-[4rem]',
                        { "before:content-[' '] before:-ml-4 before:bg-main before:w-[8px] before:h-[8px] before:rounded-full before:shrink-0 flex items-center": status === 'unread' },
                      )}
                    >
                      <div className={`${status === 'unread' && 'ml-2'}`}>{announcementNumber}</div>
                    </TableBodyCell>
                  )
                    : (
                      <TableBodyCell className="max-w-[4rem]">
                        {announcementNumber}
                      </TableBodyCell>
                    )}
                  <TableBodyCell>
                    <Chip chipStyle={hasRoleType('tenant') && status === 'unread' ? 'gray' : 'ghost'} className="w-fit">
                      {format(new Date(createdAt), 'dd-MM-yyyy')}
                    </Chip>
                  </TableBodyCell>
                  <TableBodyCell
                    className="w-fit text-typography-black underline"
                    bold={status === 'unread' && hasRoleType('tenant')}
                    clickable={({
                      href: `/aktualnosci/${id}`,
                      linkState: { returnTo: archived ? '/aktualnosci/archiwum' : '/aktualnosci' },
                      onClick: () => handleClick(id, status),
                      ariaLabel: `Przejdź do szczegółów aktualności ${announcementNumber} ${title}`,
                    })}
                  >
                    {title}
                  </TableBodyCell>
                  <TableBodyCell className="max-w-[13rem] truncate">{description}</TableBodyCell>
                  <TableBodyCell>
                    <Chip chipStyle="primary" className="w-fit">
                      {author}
                    </Chip>
                  </TableBodyCell>
                  {(hasRoleType('tenant') && !archived) && (
                  <TableBodyCell>
                    <button
                      type="button"
                      title={getMessage('announcements.announcement.archived')}
                      onClick={() => handleClickArchiveButton(id, announcementNumber)}
                      className={classNames('py-2 pl-2 text-typography-black hover:cursor-pointer hover:text-hover')}
                    >
                      <ArchiveIcon />
                    </button>
                  </TableBodyCell>
                  )}
                </ResponsiveTableBodyRow>
              ))}
            </ResponsiveTableBody>
          </>
        )}
      </ResponsiveTable>
      {data?.metadata && (
        <PaginationControls
          className="col-span-full"
          selectedPage={selectedPage}
          setSelectedPage={setSelectedPage}
          paginationMetadata={data.metadata}
        />
      )}
      <ArchiveAnnouncementPopup
        isOpen={isPopupOpen}
        onConfirm={onConfirm}
        onCancel={onCancel}
        number={announcementToArchive.number}
      />
    </>
  );
};

export default AnnouncementsList;
