import * as React from 'react';
import { useEffect, useMemo } from 'react';
import useMessages from '../../../../hooks/useMessages';
import { useGetFullUserAccountQuery, useGetPaymentsQuery, usePostMakePaymentMutation } from '../../../../api/appApi';
import ResponsiveTable, {
  ResponsiveTableBody, ResponsiveTableBodyRow,
  ResponsiveTableHeader,
} from '../../../../components/Table/ResponsiveTable';
import { Payment } from '../../../../api/modules/settlements/settlementsTypes';
import { TableBodyCell } from '../../../../components/Table/Table';
import { formatNumber, formatBankAccount } from '../../../../../utils/formatters';
import Button from '../../../../components/Button/Button';
import { usePermissions } from '../../../../hooks/usePermissions';
import Spinner from '../../../../components/Spinner/Spinner';
import { FormNotice } from '../../../../components/FormNotice/FormNotice';

interface Props {
  venueId: string,
  tenantId?: string,
}

const PaymentsTable = ({ venueId, tenantId }: Props) => {
  const getMessage = useMessages();
  const { hasRoleType, isModuleEnabled } = usePermissions();
  const queryParams: { venue: string, tenantId?: string } = { venue: venueId };
  if (hasRoleType('worker')) queryParams.tenantId = tenantId;
  const { data, isLoading, isSuccess } = useGetPaymentsQuery(queryParams, { skip: hasRoleType('worker') && !tenantId });
  const [makePayment, result] = usePostMakePaymentMutation();
  const { data: user } = useGetFullUserAccountQuery();

  const columnLabels = useMemo(() => ([
    { label: 'settlements.payments.header.title' },
    { label: 'settlements.payments.header.bankAccount' },
    { label: 'settlements.payments.header.value', divClassName: 'md:text-right md:justify-end' },
    ...isModuleEnabled('payments') ? [{ label: 'settlements.payments.header.makePayment' }] : [],
  ]), [isModuleEnabled('payments')]);

  useEffect(() => {
    if (result?.status === 'fulfilled' && result?.data?.redirectUrl) {
      window.location.href = result.data.redirectUrl;
    }
  }, [result]);

  const handlePayment = (payment: Payment) => {
    makePayment({
      payment: {
        amount: parseFloat(payment.value.toString()).toFixed(2),
        description: payment.titleNames,
        bank_account: payment.accountNumber,
        email: user?.tenant?.email || '',
        tenants_venue_id: venueId,
      },
    });
  };

  const ids = useMemo(() => (data || []).map((payment) => payment.titleNames), [data]);

  return (
    <>
      {result.error && <FormNotice type="error" message={getMessage('settlements.payments.make.error')} />}
      <ResponsiveTable className="col-span-12 border-none !p-0">
        {isLoading && <Spinner className="m-auto" />}
        {isSuccess && data && (
          <>
            <ResponsiveTableHeader columnLabels={columnLabels} ids={ids} />
            <ResponsiveTableBody>
              {data.map((payment: Payment, index) => (
                <ResponsiveTableBodyRow key={`payment_${payment.titleNames}_${index + 1}`}>
                  <TableBodyCell>{payment.titleNames}</TableBodyCell>
                  <TableBodyCell className="select-all">{payment.accountNumber ? formatBankAccount(payment.accountNumber) : ''}</TableBodyCell>
                  <TableBodyCell className="md:text-right">{formatNumber(payment.value)}</TableBodyCell>
                  {isModuleEnabled('payments') && (
                  <TableBodyCell>
                    {parseFloat(payment.value) > 0 && (
                    <>
                      {(!payment.bmPayment || payment.bmPayment.status === 'failure') && (
                      <Button
                        disabled={!hasRoleType('tenant') || !payment.accountNumber}
                        onClick={() => handlePayment(payment)}
                        ariaLabel={'Status płatności: nie zapłacono'.concat(hasRoleType('tenant') ? `Kliknij aby zapłacić za ${payment.titleNames} w kwocie ${payment.value} zł` : '')}
                      >
                        {getMessage('settlements.payments.pay')}
                      </Button>
                      )}
                      {(payment.bmPayment && payment.bmPayment.status === 'success') && (
                      <Button disabled styleType="secondary" className="text-main" ariaLabel="Status płatności - zapłacono">
                        {getMessage('settlements.payments.paid')}
                      </Button>
                      )}
                      {(payment.bmPayment && payment.bmPayment.status === 'pending') && (
                      <Button disabled styleType="secondary" className="text-main" ariaLabel="Status płatności - w toku">
                        {getMessage('settlements.payments.paymentInProgress')}
                      </Button>
                      )}
                    </>
                    )}
                  </TableBodyCell>
                  )}
                </ResponsiveTableBodyRow>
              ))}
            </ResponsiveTableBody>
          </>
        )}
      </ResponsiveTable>
    </>
  );
};

export default PaymentsTable;
