import * as React from 'react';
import Card from '../../../../../components/Card/Card';
import Button from '../../../../../components/Button/Button';
import useMessages from '../../../../../hooks/useMessages';
import { ReadingRequest } from '../../../../../api/modules/meters/metersTypes';
import MeterTitleLocationContainer from '../../../MeterTitleLocationContainer/MeterTitleLocationContainer';

interface Props {
  meterGroup: ReadingRequest[],
  label?: string,
}

const DisabledCard = ({ meterGroup, label }: Props) => {
  const getMessage = useMessages();

  return (
    <Card className="mb-4">
      <div>
        {meterGroup.map((meter) => (
          <div key={meter.meterId} className="flex flex-wrap border-b w-full last:border-b-0 py-6 first:pt-0 last:mb-6">
            <MeterTitleLocationContainer meter={meter} />
          </div>
        ))}
      </div>
      <div className="flex flex-wrap justify-between items-center mr-2">
        <div className="text-text-md-semibold mobile:mb-3">
          {label}
        </div>
        <Button
          className="mr-4 mobile:w-full mobile:mr-0"
          styleType="primary"
          disabled
          onClick={() => {}}
          ariaLabel="Nie przesłano odczytu w wymaganym terminie"
        >
          {getMessage('local.meters.unsent')}
        </Button>
      </div>
    </Card>
  );
};

export default DisabledCard;
