import * as React from 'react';
import DocumentChangeCategoryAction from './DocumentChangeCategoryAction';
import DocumentAssignEmployeeAction from './DocumentAssignEmployeeAction';
import DocumentChangeStatusAction from './DocumentChangeStatusAction';

interface Props {
  selectedTasks: string[];
}

const DocumentBulkActions = ({ selectedTasks }: Props) => (
  <div className="flex items-center border-r border-stroke-grey-300 pr-1">
    <DocumentAssignEmployeeAction selectedDocumentsIds={selectedTasks} />
    <DocumentChangeCategoryAction selectedDocumentsIds={selectedTasks} />
    <DocumentChangeStatusAction selectedDocumentsIds={selectedTasks} />
  </div>
);

export default DocumentBulkActions;
