import * as React from 'react';
import format from 'date-fns/format';
import { useNavigate } from 'react-router';
import { useIntl } from 'react-intl';
import useMessages from '../../../hooks/useMessages';
import Chip from '../../../components/Chip/Chip';
import TypeBadge from '../../../components/TypeBadge/TypeBadge';
import Button from '../../../components/Button/Button';
import { Resolution } from '../../../api/modules/resolutions/resolutionsTypes';
import OngoingIcon from '../../../icons/book-open.svg';
import OutvotedIcon from '../../../icons/outvoted.svg';
import RejectedIcon from '../../../icons/rejected.svg';

interface Props {
  item: Resolution
}

const statusIcon = {
  ongoing: <OngoingIcon />,
  outvoted: <OutvotedIcon />,
  rejected: <RejectedIcon />,
};

const ResolutionItem = ({ item }: Props) => {
  const navigate = useNavigate();
  const getMessage = useMessages();
  const {
    id, active, createdAt, title, text, votedAt, votingDeadline, status, resolutionNumber,
  } = item;
  const intl = useIntl();

  return (
    <li className="w-full border-b py-6 first:pt-0 flex md:justify-between gap-4">
      <div className="flex flex-none mobile:items-center">
        <TypeBadge
          badgeStyle={active ? 'red' : 'grey'}
          title={getMessage(`resolutions.status.${status}`)}
          ariaLabel={`Status ${getMessage(`resolutions.status.${status}`)}`}
        >
          {statusIcon[status]}
        </TypeBadge>
      </div>
      <div className="mobile:flex-grow md:w-[95%] md:flex md:justify-between mobile:truncate">
        <div className="flex flex-col md:w-[50%] mobile:truncate">
          <p className="text-title-semibold text-typography-black inline-block mb-2 mobile:truncate">
            <span className="text-typography-dark font-medium">{`Nr ${resolutionNumber} - `}</span>
            {title}
          </p>
          <p className="text-text-md-medium text-typography-dark truncate">{text}</p>
        </div>
        <div className="mobile:mt-4 md:flex md:flex-col">
          <p className="text-typography-dark text-text-sm-medium my-2 md:mt-1 md:text-right md:mb-0 md:order-2">
            {`${getMessage('resolutions.createdAt')} ${format(new Date(createdAt), 'dd-MM-yyyy')}`}
          </p>
          <div className="mobile:mt-2 md:flex md:justify-between md:order-1 ">
            <Chip className="w-fit md:order-2 md:ml-3 my-0" chipStyle={votedAt ? 'gray' : 'ghost'}>
              {votedAt ? (
                <>
                  <span className="font-semibold">{getMessage('resolutions.voted')}</span>
                  {format(new Date(votedAt), 'dd-MM-yyyy')}
                </>
              ) : (`${getMessage('resolutions.voteUntil')} ${format(new Date(votingDeadline), 'dd-MM-yyyy')}`)}
            </Chip>
            <Button
              className="text-text-sm-semibold !h-7 !w-fit mobile:mt-4"
              styleType={active
                ? 'primary' : 'secondary'}
              onClick={() => navigate(`/uchwaly/${id}`)}
              ariaLabel={intl.formatMessage(
                { id: 'resolutions.displayAriaLabel' },
                { title },
              )}
            >
              {getMessage('resolutions.display')}
            </Button>
          </div>
        </div>
      </div>
    </li>
  );
};

export default ResolutionItem;
