import * as React from 'react';
import { useMemo } from 'react';
import useMessages from '../../../../hooks/useMessages';
import Filters from '../../../../components/Filters/Filters';
import useHomeownerAssociationsMode from '../../../../hooks/useHomeownerAssociationsMode';
import { FilterConfig } from '../../../../components/FilterInput/FilterInput';

const MetersVenuesFilters = () => {
  const getMessage = useMessages();
  const hoaMode = useHomeownerAssociationsMode();

  const statusOptionValues = [
    {
      value: 'true',
      optionLabel: getMessage('meters.venuesList.filters.status.measurementRequired'),
    },
    {
      value: 'false',
      optionLabel: getMessage('meters.venuesList.filters.status.measurementProvided'),
    },
  ];

  const filtersFields: FilterConfig[] = useMemo(() => {
    const filters: FilterConfig[] = [
      {
        type: 'select',
        id: 'measurement_required',
        label: getMessage('meters.venuesList.filters.status'),
        selectOptions: statusOptionValues,
      },
      {
        type: 'text',
        id: 'tenant_search',
        label: getMessage('meters.venuesList.filters.tenantNameID'),
        ariaLabel: 'Szukaj po imieniu i nazwisku lub ID lokatora',
      },
      {
        type: 'text',
        id: 'venue_search',
        label: getMessage('meters.venuesList.filters.venueAddressCode'),
        ariaLabel: 'Szukaj po adresie budynku lub kodzie lokalu',
      },
    ];

    if (hoaMode) {
      filters.push({
        type: 'text',
        id: 'homeowner_association_search',
        label: getMessage('meters.venuesList.filters.homeownerAssoctiation'),
        ariaLabel: 'Szukaj po nazwie wspólnoty',
      });
    }

    return filters;
  }, [hoaMode]);

  return (
    <Filters
      filterId="metersVenuesList"
      filterFields={filtersFields}
    />
  );
};

export default MetersVenuesFilters;
