import * as React from 'react';
import { Link } from 'react-router-dom';
import { FunctionComponent, SVGProps } from 'react';
import { Permission } from '../../../../utils/generated/permissionsTypes';
import { ModuleName, usePermissions } from '../../../hooks/usePermissions';

interface Props {
  to: string,
  label: string,
  selected: boolean,
  Icon: FunctionComponent<SVGProps<SVGSVGElement>>,
  permissionNeeded?: Permission[],
  module?: ModuleName,
}

const NavigationItem = ({
  to, label, selected, Icon, permissionNeeded, module,
}: Props) => {
  const { hasAnyPermission, isModuleEnabled } = usePermissions();
  if (permissionNeeded && !hasAnyPermission(permissionNeeded)) {
    return null;
  }
  if (module && !isModuleEnabled(module)) {
    return null;
  }

  const styleVariants = {
    hover: 'hover:cursor-pointer hover:font-semibold hover:bg-background-light',
    selected: 'border-main text-main font-semibold',
  };

  return (
    <li>
      <Link
        to={to}
        className={`${selected ? styleVariants.selected : 'text-typography-dark border-transparent'} 
        relative text-text-lg-medium flex flex-row items-center h-11 border-l-4 pr-6 group 
        ${styleVariants.hover}`}
      >
        <span className="inline-flex w-8 justify-center items-center ml-2 sm:ml-2 md:ml-5 group-hover:text-main ">
          <Icon className="w-6 stroke-current group-hover:stroke-current" />
        </span>
        <span className="ml-2 tracking-wide truncate group-hover:text-main">{label}</span>
      </Link>
    </li>
  );
};

export default NavigationItem;
