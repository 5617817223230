import { PaginationMetadata } from '../../paginationTypes';
import { ApiTask, Task } from '../tasks/tasksTypes';

export type ApiTicketsListItem = {
  category: string,
  id: string,
  important: boolean,
  reported_at: string,
  reporter: string
  status: string
  ticket_number: string
  title: string
  venue_address: string
};

export type EmployeeApiTicketsListItem = ApiTicketsListItem & {
  homeowner_association_code: string,
  homeowner_association_name: string,
  last_comment_at: string,
  realizer: string,
  realizer_external_id: string,
  reporter_external_id: string,
  venue_code: string
};

export type TenantApiSingleTicket = Omit<ApiTicketsListItem, 'status' | 'category'> & {
  attachments: ApiAttachment[],
  description: string,
  public_comments: ApiTicketComment[],
  status: ApiTicketStatus,
  category: TicketCategory,
};

export type EmployeeApiSingleTicket = TenantApiSingleTicket & Pick<EmployeeApiTicketsListItem, 'realizer' | 'homeowner_association_name'> & {
  private_comments: ApiTicketComment[],
  tasks: ApiTask[],
  tenant_id: string,
  tenant_name: string,
  venue_id: string,
  homeowner_association_id: string,
  homeowner_association_name: string,
};

export interface ApiTicketsResponse {
  metadata: PaginationMetadata,
  tickets: ApiTicketsListItem[] | EmployeeApiTicketsListItem[],
}

export interface ApiTicketResponse {
  ticket: TenantApiSingleTicket | EmployeeApiSingleTicket;
}

export interface ApiPostTicket {
  ticket: FormData
}

export interface TicketCategory {
  id: string,
  name: string,
}

export interface ApiTicketCategories {
  ticket_categories: TicketCategory[]
}

export interface ApiTicketStatus {
  id: string,
  object_type: string,
  name: string,
  active: boolean,
  internal_name: string,
  external_name: string,
  description: string,
}

export interface FullTicketStatus {
  id: string,
  objectType: string,
  name: 'open' | 'completed' | string,
  active: boolean,
  internalName: string,
  externalName: string,
  description: string,
}

export interface ApiTicketStatuses {
  statuses: ApiTicketStatus[]
}

export interface TicketStatus extends TicketCategory {}

export interface TicketStatuses {
  statuses: TicketStatus[]
}

export interface ApiAttachment {
  description: string,
  filename: string,
  path: string,
  content_type: string,
}

export interface ApiTicketComment {
  attachments: ApiAttachment[]
  author: string,
  content: string,
  created_at: string,
  public: boolean,
}

export interface ApiPostTicketComment {
  id: string,
  payload: FormData
}

export type TenantTicketsListItemData = {
  id: string,
  ticketNumber: string,
  reportedAt: string,
  title: string,
  category: string,
  status: string,
};

export type EmployeeTicketsListItemData = TenantTicketsListItemData & {
  homeownerAssociationCode: string,
  lastCommentAt: string,
  realizer: string,
  realizerCode: string,
  reporter: string,
  reporterCode: string,
  venueAddress: string,
  venueCode: string,
  homeownerAssociationName: string,
};

export type Tickets = {
  metadata: PaginationMetadata,
  tickets: TenantTicketsListItemData[] | EmployeeTicketsListItemData[],
};

export type TenantShowTicketData = Omit<TenantTicketsListItemData, 'status' | 'category'> & {
  attachments?: TicketAttachment[],
  description: string
  publicComments: TicketComment[],
  realizer: string,
  venueAddress: string,
  status: TicketStatus,
  category: TicketCategory,
};

export type EmployeeShowTicketData = Omit<TenantShowTicketData, 'status'> & Pick<EmployeeTicketsListItemData, 'reporter'> & {
  homeownerAssociationName: string,
  homeownerAssociationId: string,
  privateComments?: TicketComment[],
  tasks: Task[],
  venueAddress: string,
  status: TicketStatus,
  tenantName: string,
  tenantId: string,
  venueId: string,
  homeowner_associationId: string,
  homeowner_associationName: string,
};

export interface TicketAttachment {
  description: string,
  filename: string,
  path: string,
  contentType: string,
}

export interface TicketComment {
  author: string,
  content: string,
  createdAt: string,
  attachments: TicketAttachment[]
}

export type CommentAttachment = {
  file: FileList | undefined,
  description: string
};

export const ticketDefaultColumnIds = [
  'ticket_number', 'title', 'status', 'category', 'reported_at', 'last_comment_at',
  'venue_address', 'homeowner_association_code', 'realizer',
];
export const ticketCustomizableColumnIds = [
  'venue_code', 'homeowner_association_name', 'reporter_external_id',
  'reporter', 'realizer_external_id',
];
export type TicketColumnId = typeof ticketCustomizableColumnIds[number] | typeof ticketCustomizableColumnIds[number];

export interface ApiColumnsSettings {
  selected_columns: TicketColumnId[]
}

export interface ApiPostColumnsSettings {
  columns?: (TicketColumnId | undefined)[]
}

export interface ApiPostChangeTicketData {
  tickets: {
    [id: string]: {
      status_id?: string,
      category_id?: string
      realizer_id?: string,
    }
  }
}
