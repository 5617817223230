import * as React from 'react';
import classNames from 'classnames';

const AttachmentTypeIcon = ({ contentType }: { contentType?: string }) => {
  const fileType = contentType?.split('/').pop();
  if (!fileType) return null;

  let icon: {
    bgColor: string,
    textColor: string,
    text: string,
  };

  switch (contentType) {
    case 'application/pdf':
      icon = {
        bgColor: 'bg-black',
        text: fileType?.toUpperCase(),
        textColor: 'text-typography-white',
      };
      break;
    case 'image/png':
    case 'image/jpg':
    case 'image/jpeg':
      icon = {
        bgColor: ' bg-red-700',
        text: fileType?.toUpperCase(),
        textColor: 'text-typography-white',
      };
      break;
    default:
      icon = {
        bgColor: 'bg-gray-300',
        text: '?',
        textColor: 'text-typography-black',
      };
      break;
  }

  return (
    <div className={classNames('w-[17px] h-[17px] rounded-sm mr-2 shrink-0', icon.bgColor)}>
      <p className={classNames('inline-block align-middle text-[6px]', icon.textColor)}>{icon.text}</p>
    </div>
  );
};

export default AttachmentTypeIcon;
