import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import slice from './appSlice';
import { appApi } from '../api/appApi';
import { errorHandlingMiddleware } from './errorHandlingMiddleware';

export const store = configureStore({
  reducer: {
    app: slice,
    [appApi.reducerPath]: appApi.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([appApi.middleware, errorHandlingMiddleware]),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

setupListeners(store.dispatch);
