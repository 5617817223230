import { useLocation } from 'react-router';
import queryString from 'query-string';
import { useMemo } from 'react';
import { useAppSelector } from '../reduxHooks';
import { useGetFullUserAccountQuery, useGetSettlementsVenuesQuery } from '../../api/appApi';
import { usePermissions } from '../usePermissions';

export const useSettlementsVenue = () => {
  const { hasRoleType } = usePermissions();
  const { search } = useLocation();
  const { venueId: urlVenueId } = queryString.parse(search);
  const storeVenueId = useAppSelector((state) => state.app.selectedVenueId);
  const venueId = hasRoleType('worker') ? urlVenueId as string : storeVenueId;
  const { data: user } = useGetFullUserAccountQuery();
  const { data: workerVenueList } = useGetSettlementsVenuesQuery(null, { skip: !hasRoleType('worker') });
  const selectedSettlementsTenantId = hasRoleType('worker') ? localStorage.getItem(`selectedSettlementsTenantId-${user?.id}`) : '';

  const venueAddress = useMemo(() => {
    if (hasRoleType('worker') && selectedSettlementsTenantId && workerVenueList) {
      const selectedVenue = workerVenueList?.venues
        .filter((venue) => (venue.venueId === venueId) && (venue.tenantId === selectedSettlementsTenantId))[0];
      return selectedVenue ? `${selectedVenue?.address}, ${selectedVenue?.tenant}` : '';
    }
    return user?.tenant?.venues.filter((venue) => venue.id === venueId)[0]?.address || '';
  }, [user, workerVenueList, venueId, selectedSettlementsTenantId]);
  return { venueId, venueAddress };
};
