import * as React from 'react';
import TicketChangeStatusAction from './TicketChangeStatusAction';
import TicketAssignEmployeeAction from './TicketAssignEmployeeAction';
import TicketChangeCategoryAction from './TicketChangeCategoryAction';

const TicketsBulkActions = ({ selectedTicketsIds }: { selectedTicketsIds: string[] }) => (
  <div className="flex items-center border-r border-stroke-grey-300 pr-1">
    <TicketAssignEmployeeAction selectedTicketsIds={selectedTicketsIds} />
    <TicketChangeCategoryAction selectedTicketsIds={selectedTicketsIds} />
    <TicketChangeStatusAction selectedTicketsIds={selectedTicketsIds} />
  </div>
);

export default TicketsBulkActions;
