import * as React from 'react';
import { useIntl } from 'react-intl';
import { useGetMetersQuery } from '../../../../api/appApi';
import { usePermissions } from '../../../../hooks/usePermissions';
import { translateApiToMetersList } from '../../../../api/modules/meters/metersTranslator';
import MetersHistoryCard from './MetersHistoryCard/MetersHistoryCard';
import { ApiMeterRequestParams } from '../../../../api/modules/meters/metersTypes';
import useFilters from '../../../../hooks/useFilters';
import useMessages from '../../../../hooks/useMessages';
import Spinner from '../../../../components/Spinner/Spinner';

interface Props {
  venueId: string,
}

const MetersHistory = ({ venueId }: Props) => {
  const { filterValues } = useFilters('metersHistory');
  const queryParams: ApiMeterRequestParams = { ...filterValues, venues_ids: venueId };
  const { data, isLoading, isSuccess } = useGetMetersQuery(queryParams);
  const { checkPermission } = usePermissions();
  const intl = useIntl();
  const meters = data ? translateApiToMetersList(data, intl) : [];
  const getMessage = useMessages();

  checkPermission('meters_list');

  return (
    <div className="col-span-full">
      {isLoading && <Spinner className="m-auto mt-5" />}
      {isSuccess && meters && meters.length === 0
        ? (<div>{getMessage('filters.noResults')}</div>)
        : meters.map((meter) => <MetersHistoryCard meter={meter} key={meter.meterId} />)}
    </div>
  );
};

export default MetersHistory;
