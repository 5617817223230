/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import format from 'date-fns/format';
import classNames from 'classnames';
import { useGetTicketQuery } from '../../../../api/appApi';
import useMessages from '../../../../hooks/useMessages';
import Card from '../../../../components/Card/Card';
import Chip from '../../../../components/Chip/Chip';
import capitalize from '../../../../../utils/formatters';
import CommentsList from '../../../../components/CommentsList/CommentsList';
import AttachmentPreview from '../../../../components/AttachmentPreview/AttachmentPreview';
import { EmployeeShowTicketData } from '../../../../api/modules/tickets/ticketsTypes';
import DocumentIcon from '../../../../icons/document.svg';
import TicketDesktopInfoCard from './TicketInfoCard/TicketDesktopInfoCard';

const EmployeeTicketPage = () => {
  const { id } = useParams();
  const { data, isSuccess } = useGetTicketQuery(id);

  const {
    title, status, reportedAt, description, attachments, publicComments, privateComments, category, venueAddress,
  } = isSuccess ? data as EmployeeShowTicketData : {} as EmployeeShowTicketData;

  const [showPublicComments, setShowPublicComments] = useState(true);

  const getMessage = useMessages();

  const openPublicComments = () => {
    setShowPublicComments(true);
  };

  const openEmployeeComments = () => {
    setShowPublicComments(false);
  };

  if (!id) return null;

  return (
    <>
      <Card className="col-span-full lg:col-span-9 row-span-1">
        <div className="flex justify-between">
          <h4 className="text-header-xs md:text-header-sm inline mr-6">{title}</h4>
          <div className="flex flex-wrap justify-end">
            <Chip chipStyle="ghost" className="mb-1 md:mb-0" screenReaderOnlyText="Data utworzenia">
              {reportedAt && format(new Date(reportedAt), 'dd-MM-yyyy')}
            </Chip>
            <Chip chipStyle="primary" className="ml-3" screenReaderOnlyText="Status">
              {status && capitalize(status.name)}
            </Chip>
          </div>
        </div>
        <div className="flex mt-2 text-text-lg-medium">
          <div className="flex mr-4 text-main">
            <DocumentIcon className="stroke-current" />
            {category && (
              <p className="inline-block ml-1">
                <span className="visually-hidden">Kategoria</span>
                {category.name}
              </p>
            )}
          </div>
          {venueAddress && (
          <p className="text-typography-dark">
            <span className="visually-hidden">Adres lokalu</span>
            {venueAddress}
          </p>
          )}
        </div>
        <p className="text-typography-dark text-text-lg-medium my-7 whitespace-pre-line">{description}</p>
        {attachments && attachments?.length > 0 && (
        <ol className="flex flex-wrap">
          {attachments?.map(({
            filename, path, contentType, description,
          }) => (
            <AttachmentPreview filename={filename} path={path} contentType={contentType} description={description} key={path} />))}
        </ol>
        )}
      </Card>
      {data && <TicketDesktopInfoCard className="row-span-2" />}
      <div className="col-span-full lg:col-span-9">
        <div className="flex text-text-lg-semibold md:text-title-semibold">
          <button
            className={classNames(
              'border border-stroke-grey-100 rounded-tl-md w-[50%] px-5 md:px-8',
              { 'py-4 bg-white text-main': showPublicComments },
              { 'bg-stroke-grey-100 text-typography-dark': !showPublicComments },
            )}
            type="button"
            onClick={openPublicComments}
          >
            {getMessage('tickets.ticket.comments.discussionWithTenant')}
          </button>
          <button
            className={classNames(
              'border border-stroke-grey-100 rounded-tr-md w-[50%] px-5 md:px-8',
              { 'py-4 bg-white text-main': !showPublicComments },
              { 'bg-stroke-grey-100 text-typography-dark': showPublicComments },
            )}
            type="button"
            onClick={openEmployeeComments}
          >
            {getMessage('tickets.ticket.comments.discussionWithEmployee')}
          </button>
        </div>
        <Card className="col-span-full lg:col-span-9 row-span-1 rounded-t-none border-t-none !pt-0">
          {showPublicComments && <CommentsList ticketId={id} comments={publicComments} publicComment />}
          {!showPublicComments && <CommentsList ticketId={id} comments={privateComments} publicComment={false} />}
        </Card>
      </div>
    </>
  );
};

export default EmployeeTicketPage;
