import { rest } from 'msw';

const employees = [
  {
    id: '3e0b8eab-93bf-4495-a8f7-40d4411e9a89',
    full_name: 'Anna Mockowa',
  },
  {
    id: '14a19ecb-b5f6-4a76-85e2-143ca73fad46',
    full_name: 'Barbara Strzelecka',
  },
  {
    id: '72b1e9d0-0ed5-4deb-bd6c-a931dd3665e8',
    full_name: 'Grzegorz Brzęczyszczykiewicz',
  },
  {
    id: '379ea8fa-5864-4c8b-8cb4-cb79081b194e',
    full_name: 'Ireneusz Motylanoga',
  },
  {
    id: 'ff3f2886-85c8-48f0-85ca-5b5ba51b0d62',
    full_name: 'Jadwiga Kuna',
  },
  {
    id: '91a72ce4-b1bf-4f6d-a58e-08192f141ba3',
    full_name: 'Jan Kowalski',
  },
  {
    id: '760ffdfd-47ac-4010-b946-3621737dd009',
    full_name: 'Jane Doe',
  },
];

export const employeesMocks = [
  rest.get('employees/list', (req, res, ctx) => res(
    ctx.status(200),
    ctx.json({
      employees,
    }),
  )),
];
