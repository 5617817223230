import * as React from 'react';
import ArrowUp from '../../icons/table-arrow-up.svg';
import ArrowDown from '../../icons/table-arrow-down.svg';

interface SortArrowsProps {
  id?: string,
  sortBy?: string,
  sortOrder?: string
}

const SortArrows = ({ id, sortBy, sortOrder }: SortArrowsProps) => {
  let arrows: React.ReactNode;

  if (sortBy === id) {
    arrows = sortOrder === 'asc' ? <ArrowUp /> : <ArrowDown />;
  } else {
    arrows = (
      <>
        <ArrowUp />
        <ArrowDown className="mt-[0.15rem]" />
      </>
    );
  }

  return <div className="ml-2">{arrows}</div>;
};

export default SortArrows;
