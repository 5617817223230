import * as React from 'react';
import { useNavigate } from 'react-router';
import { useMemo } from 'react';
import useMessages from '../../../../hooks/useMessages';
import { MonthBalanceQuery, months } from '../../../../api/modules/settlements/settlementsTypes';
import {
  useGetMonthsSettlementsBalanceQuery,
  useGetSettlementsYearsQuery,
} from '../../../../api/appApi';
import { Select, SelectOption } from '../../../../components/FormInputs/inputs/FormSelect';
import PageHeader from '../../../../components/Layout/PageHeader';
import { InputLabel } from '../../../../components/FormInputs/InputLabel';
import { useSettlementsVenue } from '../../../../hooks/settlements/useSettlementsVenue';
import { usePermissions } from '../../../../hooks/usePermissions';

interface Props {
  year: string,
  month: string,
  titleId: string,
  path?: string,
  tenantId?: string,
}

const TitleBalanceHeader = ({
  year, month, titleId, path, tenantId,
}: Props) => {
  const getMessage = useMessages();
  const navigate = useNavigate();
  const { venueId, venueAddress } = useSettlementsVenue();
  const { hasRoleType } = usePermissions();

  const yearsQueryParams: { venue: string, tenantId?: string } = { venue: venueId };
  const monthsBalanceQueryParams: MonthBalanceQuery = {
    venue: venueId,
    year,
    month,
  };
  if (hasRoleType('worker') && tenantId) {
    yearsQueryParams.tenantId = tenantId;
    monthsBalanceQueryParams.tenant_id = tenantId;
  }
  const { data: years } = useGetSettlementsYearsQuery(
    yearsQueryParams,
    { skip: !venueId || (hasRoleType('worker') && !tenantId) },
  );
  const { data: thisMonthsTitles } = useGetMonthsSettlementsBalanceQuery(
    monthsBalanceQueryParams,
    { skip: !venueId || !year || !month || (hasRoleType('worker') && !tenantId) },
  );

  const yearsSelectOptions: SelectOption[] = useMemo(() => years?.map((year: string) => ({
    value: year,
    optionLabel: year,
  })) || [], [years]);

  const monthsSelectOptions: SelectOption[] = useMemo(() => months?.map((month, index) => ({
    value: (index + 1).toString(),
    optionLabel: getMessage(`datepicker.month.${month}`),
  })) || [], [months]);

  const titlesSelectOptions: SelectOption[] = useMemo(() => thisMonthsTitles?.map((title) => ({
    value: title.id,
    optionLabel: title.name,
  })) || [], [thisMonthsTitles]);

  return (
    <PageHeader
      pageTitle={`${getMessage('navigation.settlements.balance')}:`}
      subheader={`${venueAddress}: ${thisMonthsTitles?.find((title) => title.id === titleId)?.name || ''}`}
      currentPage="settlements"
      path={path}
    >
      <div className="flex items-center text-typography-dark">
        <InputLabel label={getMessage('settlements.balance.filters.year')} inline />
        <Select
          value={year}
          onChange={(newYear) => navigate(`/rozrachunki/analiza_salda/${newYear}/${month}?venueId=${venueId}`)}
          selectOptions={yearsSelectOptions}
        />
      </div>
      <div className="flex items-center text-typography-dark ml-4">
        <InputLabel label={getMessage('settlements.balance.filters.month')} inline />
        <Select
          value={month}
          onChange={(newMonth) => {
            navigate(`/rozrachunki/analiza_salda/${year}/${newMonth}/${titleId}/?venueId=${venueId}`);
          }}
          selectOptions={monthsSelectOptions}
          inputClassName="w-32"
        />
      </div>
      <div className="flex items-center text-typography-dark ml-4">
        <InputLabel label={getMessage('settlements.balance.filters.title')} inline />
        <Select
          value={titleId}
          onChange={(newTitle) => navigate(`/rozrachunki/analiza_salda/${year}/${month}/${newTitle}?venueId=${venueId}`)}
          selectOptions={titlesSelectOptions}
          inputClassName="w-40"
        />
      </div>
    </PageHeader>
  );
};

export default TitleBalanceHeader;
