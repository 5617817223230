import * as React from 'react';
import { useIntl } from 'react-intl';
import { PropsWithChildren } from 'react';
import classnames from 'classnames';
import { PaginationMetadata } from '../../api/paginationTypes';

interface Props {
  selectedPage: number,
  setSelectedPage: (n: number) => void,
  paginationMetadata: PaginationMetadata,
  className?: string,
}

const PaginationControlDiv = ({ children }: PropsWithChildren) => (
  <div className="bg-white rounded border border-gray-200 w-6 h-6 text-text-md-medium ml-1 flex justify-center items-center">{children}</div>
);

const PaginationControlButton = ({ children, onClick, ariaLabel }: PropsWithChildren<{ ariaLabel: string, onClick: () => void }>) => (
  <button
    className="bg-white rounded border border-gray-200 w-6 h-6 text-text-md-medium ml-1 hover:text-hover"
    type="button"
    onClick={onClick}
    aria-label={ariaLabel}
  >
    {children}
  </button>
);

const PaginationControls = ({
  selectedPage, setSelectedPage, paginationMetadata, className,
}: Props) => {
  const intl = useIntl();

  return (
    <div className={classnames(
      'bg-background-white flex flex-col items-center py-4 px-2 relative col-span-full w-full border rounded mt-4'
      + ' md:bg-transparent md:flex-row md:justify-between md:w-full md:px-5 md:p-0 md:mx-0 md:border-none md:mt-0',
      className,
    )}
    >
      <div className="text-text-md-medium mb-4">
        {intl.formatMessage(
          { id: 'pagination.info' },
          { start: paginationMetadata.from, end: paginationMetadata.to, all: paginationMetadata.count },
        )}
      </div>
      <div className="flex justify-end">
        {!!paginationMetadata.prev && (
          <PaginationControlButton
            onClick={() => setSelectedPage(selectedPage - 1)}
            ariaLabel={intl.formatMessage({ id: 'pagination.previousPage' })}
          >
            {'<'}
          </PaginationControlButton>
        )}
        {paginationMetadata.series.map((pageSelect, index) => {
          if (typeof pageSelect === 'string') {
            // eslint-disable-next-line react/no-array-index-key
            if (pageSelect === 'gap') return <PaginationControlDiv key={`gap-${index}`}>...</PaginationControlDiv>;
            return <PaginationControlDiv key={`current-${pageSelect}`}><b>{pageSelect}</b></PaginationControlDiv>;
          }
          return (
            <PaginationControlButton key={`control-${pageSelect}`} onClick={() => setSelectedPage(pageSelect)} ariaLabel={`${pageSelect}`}>
              {pageSelect}
            </PaginationControlButton>
          );
        })}
        {!!paginationMetadata.next && (
          <PaginationControlButton
            onClick={() => setSelectedPage(selectedPage + 1)}
            ariaLabel={intl.formatMessage({ id: 'pagination.nextPage' })}
          >
            {'>'}
          </PaginationControlButton>
        )}
      </div>
    </div>
  );
};

export default PaginationControls;
