import * as React from 'react';
import { useEffect, useMemo } from 'react';
import classNames from 'classnames';
import useMessages from '../../../hooks/useMessages';
import { useGetFullUserAccountQuery } from '../../../api/appApi';
import { selectVenue, VenueSelectPayload } from '../../../store/appSlice';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';

interface Props {
  isOpen: boolean,
  onSelect: () => void;
}

const UserNavigation = React.forwardRef(({ isOpen, onSelect }: Props, ref: React.Ref<any>) => {
  const getMessage = useMessages();
  const { data: user, isSuccess: userFetched } = useGetFullUserAccountQuery();
  const dispatch = useAppDispatch();
  const selectedVenueId = useAppSelector((state) => state.app.selectedVenueId);
  const venues = useMemo(() => user?.tenant?.venues, [user]);

  useEffect(() => {
    if (userFetched && venues) {
      let preselectedVenue: VenueSelectPayload;

      if (localStorage.getItem(`selectedVenueName-${user.id}`)) {
        preselectedVenue = {
          userId: user.id,
          selectedVenueId: localStorage.getItem(`selectedVenueId-${user.id}`) || '',
          selectedVenueName: localStorage.getItem(`selectedVenueName-${user.id}`) || '',
        };
      } else if (venues.length === 1) {
        preselectedVenue = {
          userId: user.id,
          selectedVenueId: venues[0].id,
          selectedVenueName: venues[0].address,
        };
      } else {
        preselectedVenue = {
          userId: user.id,
          selectedVenueId: '',
          selectedVenueName: getMessage('navigation.allVenues'),
        };
      }
      dispatch(selectVenue(preselectedVenue));
    }
  }, [userFetched]);

  const handleSelectVenue = (e: React.KeyboardEvent<HTMLDivElement> | React.MouseEvent, venuePayload: VenueSelectPayload) => {
    if (e.type === 'click' || ('key' in e && (e.key === 'Enter' || e.key === ' '))) {
      dispatch(selectVenue(venuePayload));
      onSelect();
    }
  };

  if (!userFetched) {
    return null;
  }

  return (
    <div
      ref={ref}
      className={classNames(
        'absolute transition-height duration-500 ease-in-out z-20 bg-background-light lg:bg-background-white'
        + ' w-full overflow-hidden flex flex-col justify-between text-text-lg-medium',
        { 'h-sidebar visible': isOpen, hidden: !isOpen },
      )}
    >
      <div className="w-full px-2 sm:px-3 md:px-7 flex flex-col py-4 space-y-1 overflow-y-auto">
        {venues?.map((venue) => (
          <div
            key={venue.id}
            className={
              `p-2 cursor-pointer hover:underline hover:text-hover rounded 
              ${selectedVenueId === venue.id ? 'text-hover bg-light-200' : 'text-typography-dark'}`
            }
            role="button"
            onClick={(e) => handleSelectVenue(e, {
              userId: user.id,
              selectedVenueId: venue.id,
              selectedVenueName: venue.address,
            })}
            tabIndex={0}
            onKeyPress={(e) => handleSelectVenue(e, {
              userId: user.id,
              selectedVenueId: venue.id,
              selectedVenueName: venue.address,
            })}
          >
            {venue.address}
          </div>
        ))}
        {venues?.length && venues?.length > 1
          && (
          <div
            className={
              `p-2 cursor-pointer hover:underline hover:text-hover rounded 
              ${selectedVenueId === '' ? 'text-hover bg-light-200' : 'text-typography-dark'}`
            }
            role="button"
            onClick={(e) => handleSelectVenue(e, {
              userId: user.id,
              selectedVenueId: '',
              selectedVenueName: getMessage('navigation.allVenues'),
            })}
            tabIndex={0}
            onKeyPress={(e) => handleSelectVenue(e, {
              userId: user.id,
              selectedVenueId: '',
              selectedVenueName: getMessage('navigation.allVenues'),
            })}
          >
            {getMessage('navigation.allVenues')}
          </div>
          )}
      </div>
    </div>
  );
});

export default UserNavigation;
