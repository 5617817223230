import * as React from 'react';
import { format } from 'date-fns';
import { useCallback, useEffect } from 'react';
import useMessages from '../../../../hooks/useMessages';
import { useGetSelectedTicketsColumnsQuery } from '../../../../api/appApi';
import Table, {
  TableHeader, TableHeaderRow, TableHeaderCell, TableBody, TableBodyRow, TableBodyCell,
} from '../../../../components/Table/Table';
import Chip from '../../../../components/Chip/Chip';
import capitalize from '../../../../../utils/formatters';
import { EmployeeTicketsListItemData, TicketColumnId } from '../../../../api/modules/tickets/ticketsTypes';
import TableActionsBar from '../../../../components/Table/TableActionsBar';
import TicketsTableCustomisation from './TicketsTableCustomisation';
import { TableId, saveSelectedTicketId, resetSelectedTicketIds } from '../../../../store/appSlice';
import TableBodyPlaceholder from '../../../../components/Table/TableBodyPlaceholder';
import TicketsBulkActions from './TicketsBulkActions/TicketsBulkActions';
import { useAppDispatch, useAppSelector } from '../../../../hooks/reduxHooks';
import useHomeownerAssociationsMode from '../../../../hooks/useHomeownerAssociationsMode';

interface Props {
  tickets: EmployeeTicketsListItemData[]
}

const TicketsTableDesktop = ({ tickets }: Props) => {
  const selectedTicketsIds = useAppSelector((state) => state.app.selectedTicketsIds);
  const dispatch = useAppDispatch();
  const getMessage = useMessages();
  const hoaMode = useHomeownerAssociationsMode();

  const { data: selectedColumns } = useGetSelectedTicketsColumnsQuery();
  const isSelected = useCallback((columnId: TicketColumnId): boolean => !!selectedColumns?.includes(columnId), [selectedColumns]);

  const handleChecked = (id: string) => {
    dispatch(saveSelectedTicketId({ id }));
  };

  useEffect(() => {
    dispatch(resetSelectedTicketIds());
  }, []);

  return (
    <>
      <TableActionsBar>
        <div className="flex justify-center">
          <TicketsBulkActions selectedTicketsIds={selectedTicketsIds} />
          <TicketsTableCustomisation />
        </div>
      </TableActionsBar>
      <Table className="col-span-full px-0 py-0 table-fixed whitespace-nowrap overflow-auto overscroll-none !p-0">
        <TableHeader className="sticky top-0 z-20 bg-background-white border-none shadow-main shadow-sm">
          <TableHeaderRow className="[&>*:nth-child(2)]:pl-8 [&>*:last-child]:pr-8">
            <TableHeaderCell className="sticky top-0 left-0 pt-8 bg-main " />
            <TableHeaderCell className="pt-8 pb-4 pl-8">{getMessage('tickets.ticket.status')}</TableHeaderCell>
            {isSelected('reported_at') && (
            <TableHeaderCell className="pt-8 pb-4" id="reported_at" tableName={'employeeTicketsTable' as TableId}>
              {getMessage('tickets.ticket.reportedAt')}
            </TableHeaderCell>
            )}
            {isSelected('last_comment_at') && (
            <TableHeaderCell className="pt-8 pb-4" id="last_comment_at" tableName={'employeeTicketsTable' as TableId}>
              {getMessage('tickets.ticket.lastCommentAt')}
            </TableHeaderCell>
            )}
            {isSelected('ticket_number') && (
            <TableHeaderCell className="pt-8 pb-4" id="ticket_number" tableName={'employeeTicketsTable' as TableId}>
              {getMessage('tickets.ticket.id')}
            </TableHeaderCell>
            )}
            {isSelected('title') && <TableHeaderCell className="pt-8 pb-4">{getMessage('tickets.ticket.title')}</TableHeaderCell>}
            {isSelected('venue_address') && <TableHeaderCell className="pt-8 pb-4">{getMessage('tickets.ticket.venueAddress')}</TableHeaderCell>}
            {isSelected('venue_code') && <TableHeaderCell className="pt-8 pb-4">{getMessage('tickets.ticket.venueCode')}</TableHeaderCell>}
            {hoaMode && isSelected('homeowner_association_code') && <TableHeaderCell className="pt-8 pb-4">{getMessage('tickets.ticket.homeownerAssociationCode')}</TableHeaderCell>}
            {hoaMode && isSelected('homeowner_association_name') && <TableHeaderCell className="pt-8 pb-4">{getMessage('tickets.ticket.homeownerAssociationName')}</TableHeaderCell>}
            {isSelected('category') && <TableHeaderCell className="pt-8 pb-4">{getMessage('tickets.ticket.category')}</TableHeaderCell>}
            {isSelected('reporter_external_id') && <TableHeaderCell className="pt-8 pb-4">{getMessage('tickets.ticket.reporterCode')}</TableHeaderCell>}
            {isSelected('reporter') && <TableHeaderCell className="pt-8 pb-4">{getMessage('tickets.ticket.reporterName')}</TableHeaderCell>}
            {isSelected('realizer_external_id') && <TableHeaderCell className="pt-8 pb-4">{getMessage('tickets.ticket.realizerCode')}</TableHeaderCell>}
            <TableHeaderCell className="pt-8 pb-4">{getMessage('tickets.ticket.realizer')}</TableHeaderCell>
          </TableHeaderRow>
        </TableHeader>
        <TableBody>
          {tickets.length === 0
             && (
               <TableBodyPlaceholder />
             )}
          {selectedColumns && selectedColumns.length > 0 && tickets.map(({
            id, ticketNumber, reportedAt, title, status, homeownerAssociationCode, venueAddress, lastCommentAt, venueCode,
            homeownerAssociationName, category, reporterCode, reporter, realizerCode, realizer,
          }) => (
            <TableBodyRow key={id} className="relative border-none odd:bg-light-100 text-typography-black [&>*:nth-child(2)]:pl-8 [&>*:last-child]:pr-8">
              <TableHeaderCell className="sticky left-0 z-15 m-10 pl-0 !pr-0 pt-0 bg-main border-main w-1">
                <label htmlFor={id} className="hover:cursor-pointer">
                  <div className="text-[0px] h-0">{getMessage('tickets.ticket.checkbox.label')}</div>
                  <input
                    className="m-4"
                    type="checkbox"
                    checked={selectedTicketsIds.includes(id)}
                    onChange={() => handleChecked(id)}
                    id={id}
                  />
                </label>
              </TableHeaderCell>
              <TableBodyCell>
                <Chip chipStyle={status === 'completed' ? 'gray' : 'primary'} className="w-fit">
                  {status && capitalize(status)}
                </Chip>
              </TableBodyCell>
              {isSelected('reported_at') && (
              <TableBodyCell>
                {reportedAt && (
                <Chip chipStyle="ghost" className="w-fit">
                  {format(new Date(reportedAt), 'dd-MM-yyyy')}
                </Chip>
                )}
              </TableBodyCell>
              )}
              {isSelected('last_comment_at') && (
              <TableBodyCell>
                {lastCommentAt && (
                <Chip chipStyle="ghost" className="w-fit">
                  {format(new Date(lastCommentAt), 'dd-MM-yyyy')}
                </Chip>
                )}
              </TableBodyCell>
              )}
              {isSelected('ticket_number') && <TableBodyCell>{ticketNumber}</TableBodyCell>}
              {isSelected('title') && (
              <TableBodyCell clickable={({ href: `/zgloszenia/${id}`, ariaLabel: `Przejdź do szczegółów zgłoszenia nr ${ticketNumber} ${title}` })}>
                {title}
              </TableBodyCell>
              )}
              {isSelected('venue_address') && <TableBodyCell>{venueAddress}</TableBodyCell>}
              {isSelected('venue_code') && <TableBodyCell>{venueCode}</TableBodyCell>}
              {hoaMode && isSelected('homeowner_association_code') && (
              <TableBodyCell>
                {homeownerAssociationCode}
              </TableBodyCell>
              ) }
              {hoaMode && isSelected('homeowner_association_name') && <TableBodyCell>{homeownerAssociationName}</TableBodyCell>}
              {isSelected('category') && <TableBodyCell>{category && capitalize(category)}</TableBodyCell>}
              {isSelected('reporter_external_id') && <TableBodyCell>{reporterCode}</TableBodyCell>}
              {isSelected('reporter') && <TableBodyCell>{reporter}</TableBodyCell>}
              {isSelected('realizer_external_id') && <TableBodyCell>{realizerCode}</TableBodyCell>}
              <TableBodyCell>{realizer}</TableBodyCell>
            </TableBodyRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

export default TicketsTableDesktop;
