import * as React from 'react';
import { useState, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import Button from '../../../../../components/Button/Button';
import Card from '../../../../../components/Card/Card';
import useMessages from '../../../../../hooks/useMessages';
import { usePostMeasurementsMutation } from '../../../../../api/appApi';
import { translateFormFieldsToApi } from '../../../../../api/modules/meters/metersTranslator';
import { MetersFormFields, ReadingRequest } from '../../../../../api/modules/meters/metersTypes';
import MetersUpdatePopup from '../../../TenantMetersPage/MetersListPage/MetersUpadatePopup/MetersUpdatePopup';
import MeterTitleLocationContainer from '../../../MeterTitleLocationContainer/MeterTitleLocationContainer';
import { FormNotice } from '../../../../../components/FormNotice/FormNotice';
import MeterReadingInput from '../../../MeterReadingInput/MeterReadingInput';
import Tooltip from '../../../../../components/Tooltip/Tooltip';

interface Props {
  meterGroup: ReadingRequest[],
  onSuccess: () => void
}

const MetersCard = ({ meterGroup, onSuccess }: Props) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const getMessage = useMessages();

  const [postMeasurements, result] = usePostMeasurementsMutation();
  const methods = useForm<MetersFormFields>({
    mode: 'onTouched',
  });

  const dataSent = result.status === 'fulfilled' || result.status === 'rejected';
  const dataRejected = result.status === 'rejected';

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const sendMeasurements = (measurements: MetersFormFields) => {
    if (dataSent) {
      return;
    }
    togglePopup();
    postMeasurements(translateFormFieldsToApi(measurements));
  };

  const handleSubmit = () => {
    methods.handleSubmit(sendMeasurements)();
  };

  useEffect(() => {
    if (result.isSuccess) {
      onSuccess();
    }
  }, [result]);

  return (
    <Card className="mb-4">
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <FormProvider {...methods}>
        <form onSubmit={(e) => {
          e.preventDefault();
          togglePopup();
        }}
        >
          <div>
            {meterGroup.map((meter) => (
              <div key={meter.meterId} className="border-b w-full last:border-b-0 py-6 first:pt-0">
                <MeterTitleLocationContainer meter={meter} />
                {!meter.canUpdate && (<span className="block text-text-md-medium text-typography-dark mb-2 mt-6">{getMessage('meters.metersList.unsent')}</span>)}
                {dataSent ? (
                  <div className="text-text-md-semibold mt-6">
                    {dataRejected && <FormNotice type="error" message={getMessage('local.meters.measurementSendingError')} />}
                  </div>
                ) : (
                  <MeterReadingInput measurementUnit={meter.measurementUnit} meterId={meter.meterId} meterType={meter.meterType} divClassName="mt-4" />
                )}
              </div>
            ))}
          </div>
          <div className="flex justify-end">
            <Tooltip content={getMessage('local.meters.measurementDisabledTooltip')}>
            <Button
              styleType="primary"
              disabled={dataSent || !methods.formState.isValid}
              onClick={togglePopup}
              className="mobile:w-full"
              ariaLabel={!methods.formState.isValid ? 'Wprowadź wartość odczytu aby aktywować przycisk' : 'Wyślij odczyt'}
            >
              {getMessage('send')}
            </Button>
            </Tooltip>
          </div>
          <MetersUpdatePopup
            isOpen={isPopupOpen}
            meterGroup={meterGroup}
            onAccept={handleSubmit}
            onClose={togglePopup}
            values={methods.getValues()}
          />
        </form>
      </FormProvider>
    </Card>
  );
};

export default MetersCard;
