import permissions from '../../../config/permissions.json';
import { Permission, PermissionsTree, PermissionCategory } from './generated/permissionsTypes';

export const permissionTree: PermissionsTree = permissions as PermissionsTree;
export const permissionCategories: PermissionCategory[] = Object.keys(permissionTree) as PermissionCategory[];
export const allPermissions: Permission[] = (Object.keys(permissionTree) as PermissionCategory[]).reduce(
  (arr, cat) => arr.concat(permissionTree[cat]),
  [] as Permission[],
);
export const roleTypes = ['worker', 'tenant', 'member', 'admin'] as const;
export type RoleType = typeof roleTypes[number];
