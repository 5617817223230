import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useEffect, useMemo } from 'react';
import useFilters from '../../../hooks/useFilters';
import useMessages from '../../../hooks/useMessages';
import { useGetAnnouncementsAuthorsQuery, useGetRecipientsForFilterQuery } from '../../../api/appApi';
import Filters from '../../../components/Filters/Filters';
import { FilterConfig } from '../../../components/FilterInput/FilterInput';
import { usePermissions } from '../../../hooks/usePermissions';

interface AnnouncementsFilterInputs {
  search?: string,
  from_date?: string,
  to_date?: string,
  created_by_ids?: [],
}

const AnnouncementsFilters = () => {
  const getMessage = useMessages();
  const { saveFilterValues, filterValues } = useFilters<AnnouncementsFilterInputs>('announcementsList');
  const { hasRoleType } = usePermissions();

  const methods = useForm<AnnouncementsFilterInputs>({
    defaultValues: {
      search: filterValues?.search || '',
      from_date: filterValues?.from_date || '',
      to_date: filterValues?.to_date || '',
      created_by_ids: filterValues?.created_by_ids || [],
    },
  });

  useEffect(() => {
    methods.watch(
      (filters) => {
        saveFilterValues(filters);
      },
    );
  }, [methods.watch]);

  const { data: authors } = useGetAnnouncementsAuthorsQuery();
  const { data: recipients } = useGetRecipientsForFilterQuery();

  const authorOptionsValues = useMemo(() => {
    if (!authors) return [];

    return authors.map(({ id, fullName }) => ({
      value: id as string,
      optionLabel: fullName,
    }));
  }, [authors]);

  const recipientsOptionsValues = useMemo(() => {
    if (!recipients) return [];

    return recipients.map(({ id, fullName }) => ({
      value: id as string,
      optionLabel: fullName,
    }));
  }, [recipients]);

  const recipientsFilter = {
    type: 'multiselect',
    id: 'created_for_ids',
    label: getMessage('announcements.announcement.recipients'),
    selectOptions: recipientsOptionsValues,
  } as FilterConfig;

  const filterFields: FilterConfig[] = useMemo(() => {
    const fields: FilterConfig[] = [
      {
        type: 'dateRange',
        id: 'from_date',
        toDateId: 'to_date',
        label: getMessage('announcements.announcement.date'),
      },
      {
        type: 'text',
        id: 'search',
        label: getMessage('announcements.announcement.title'),
        ariaLabel: 'Szukaj po tytule aktualności',
      },
      {
        type: 'multiselect',
        id: 'created_by_ids',
        label: getMessage('announcements.announcement.author'),
        selectOptions: authorOptionsValues,
      },
    ];
    return fields;
  }, [authors, recipients]);

  const announcementsFilters = hasRoleType('worker') ? filterFields.concat([recipientsFilter]) : filterFields;

  return (
    <Filters
      filterId="announcementsList"
      filterFields={announcementsFilters}
    />
  );
};

export default AnnouncementsFilters;
