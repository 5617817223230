import * as React from 'react';
import { useMemo } from 'react';
import { activityCategory } from '../../../api/modules/user/userTypes';
import useMessages from '../../../hooks/useMessages';
import Filters from '../../../components/Filters/Filters';
import { FilterConfig } from '../../../components/FilterInput/FilterInput';

const UsersActivityLogFilters = () => {
  const getMessage = useMessages();

  const categoryOptionValues = useMemo(() => activityCategory.map((category) => ({
    value: category as string,
    optionLabel: getMessage(`users.activityLog.menuOption.${category}`),
  })), [activityCategory]);

  const filterFields: FilterConfig[] = [
    {
      type: 'dateRange',
      id: 'from_date',
      toDateId: 'to_date',
      label: getMessage('users.activityLog.date'),
    },
    {
      type: 'multiselect',
      id: 'category',
      label: getMessage('users.activityLog.menuOption'),
      selectOptions: categoryOptionValues,
    },
    {
      type: 'text',
      id: 'account_search',
      label: getMessage('users.activityLog.fullName'),
      ariaLabel: 'Szukaj po imieniu i nazwisku',
    },
  ];

  return (
    <Filters
      filterId="usersActivityLog"
      filterFields={filterFields}
    />
  );
};

export default UsersActivityLogFilters;
