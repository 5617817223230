import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import { useGetDocumentStatusesQuery, usePostChangeDocumentMutation } from '../../../../api/appApi';
import useMessages from '../../../../hooks/useMessages';
import capitalize from '../../../../../utils/formatters';
import StatusIcon from '../../../../icons/bell.svg';
import Dialog from '../../../../components/Dialog/Dialog';
import { InputLabel } from '../../../../components/FormInputs/InputLabel';
import { Select } from '../../../../components/FormInputs/inputs/FormSelect';

interface Props {
  selectedDocumentsIds: string[],
}

const DocumentChangeStatusAction = ({ selectedDocumentsIds }: Props) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [postChangeDocument] = usePostChangeDocumentMutation();
  const { data: documentStatuses } = useGetDocumentStatusesQuery();
  const getMessage = useMessages();

  const statusOptionsValues = useMemo(() => documentStatuses?.map(({ id, name }) => ({
    value: id,
    optionLabel: capitalize(name),
  })) || [], [documentStatuses]);

  const handleClick = () => {
    if (selectedDocumentsIds.length > 0) setIsPopupOpen(true);
  };

  const changeStatus = (value: string) => {
    setSelectedStatus(value);
  };

  const onAccept = useCallback(() => {
    const documents: {
      [key: string]: {
        status_id: string
      }
    } = {};
    function populatePayload(id: string) {
      documents[id] = { status_id: selectedStatus };
    }
    selectedDocumentsIds.forEach((item) => populatePayload(item));
    postChangeDocument({ documents });
  }, [selectedStatus]);

  const onClose = () => {
    setSelectedStatus('');
    setIsPopupOpen(false);
  };

  const dynamicStyles = (selectedDocumentsIds.length > 0) ? 'border-typography-dark hover:border-hover hover:cursor-pointer hover:text-hover hover:bg-light-200' : 'text-stroke-grey-300 border-stroke-grey-300 hover:cursor-default';

  return (
    <div title={getMessage('documents.bulkAction.changeStatus')}>
      <StatusIcon
        role="button"
        className={classNames(dynamicStyles, 'border rounded text-typography-dark p-2 mr-1')}
        onClick={handleClick}
        tabIndex={(selectedDocumentsIds.length > 0) ? 0 : undefined}
        onKeyDown={(e) => e.key === 'Enter' && setIsPopupOpen(true)}
        aria-label={getMessage('documents.bulkAction.changeStatus')}
      />
      {isPopupOpen
        && (
          <Dialog
            isOpen={isPopupOpen}
            acceptButtonText={getMessage('confirm')}
            onClose={onClose}
            closeButtonText={getMessage('cancel')}
            onAccept={onAccept}
            isAcceptButtonDisabled={!selectedStatus}
          >
            <div className="mb-10">
              <InputLabel
                label={getMessage('documents.changeDocumentsStatus')}
              />
              <Select
                selectOptions={statusOptionsValues}
                onChange={(value) => changeStatus(value)}
                value={selectedStatus}
                placeholder={getMessage('choose')}
                autofocus
              />
            </div>
          </Dialog>
        ) }
    </div>
  );
};

export default DocumentChangeStatusAction;
