import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import queryString from 'query-string';
import {
  ApiMeasurements, ApiMeterHistoryResponse, ApiMeterRequestParams,
  ApiMetersResponse, MeterHistory, ApiMetersVenuesListResponse, MetersVenuesList,
} from './metersTypes';
import { ApiBaseQuery, ApiTagTypes } from '../../baseQuery';
import { translateMetersHistory, translateApiMetersVenuesList } from './metersTranslator';

export const metersQueries = (builder: EndpointBuilder<ApiBaseQuery, ApiTagTypes, 'api'>) => ({
  getMeters: builder.query<ApiMetersResponse, ApiMeterRequestParams>({
    query: (params) => {
      const stringifiedParams = queryString.stringify(params, { arrayFormat: 'bracket' });
      return `meters/list?${stringifiedParams}`;
    },
    providesTags: () => ['Meters'],
  }),
  getMeterHistory: builder.query<MeterHistory[], string | ''>({
    query: (meterId) => `meters/${meterId}/history`,
    transformResponse(baseQueryReturnValue: ApiMeterHistoryResponse): MeterHistory[] {
      return translateMetersHistory(baseQueryReturnValue);
    },
    providesTags: () => ['Meters'],
  }),
  postMeasurements: builder.mutation<any, ApiMeasurements>({
    query: (measurements) => ({
      url: 'meters/add_measurements',
      method: 'POST',
      body: measurements,
    }),
    invalidatesTags: ['Meters', 'MetersVenuesList', 'ActivityLog'],
  }),
  getMetersVenuesList: builder.query<MetersVenuesList, Record<string, string>>({
    query: (params) => {
      const stringifiedParams = queryString.stringify(params, { arrayFormat: 'bracket' });
      return `meters/venues/list?${stringifiedParams}`;
    },
    transformResponse(baseQueryReturnValue: ApiMetersVenuesListResponse): MetersVenuesList {
      return translateApiMetersVenuesList(baseQueryReturnValue);
    },
    providesTags: () => ['MetersVenuesList'],
  }),
});
