import * as React from 'react';
import { format } from 'date-fns';
import { useCallback, useMemo, useEffect } from 'react';
import usePagination from '../../../../hooks/usePagination';
import { useGetResolutionsQuery } from '../../../../api/appApi';
import ResponsiveTable, {
  ResponsiveTableHeader, ResponsiveTableBody, ResponsiveTableBodyRow,
} from '../../../../components/Table/ResponsiveTable';
import PaginationControls from '../../../../components/Table/PaginationControls';
import { FilterValues, TableId } from '../../../../store/appSlice';
import useFilters from '../../../../hooks/useFilters';
import { TableBodyCell } from '../../../../components/Table/Table';
import Chip from '../../../../components/Chip/Chip';
import capitalize from '../../../../../utils/formatters';
import useMessages from '../../../../hooks/useMessages';
import { EmployeeResolution } from '../../../../api/modules/resolutions/resolutionsTypes';
import useHomeownerAssociationsMode from '../../../../hooks/useHomeownerAssociationsMode';
import TableContentPlaceholder from '../../../../components/Table/TableContentPlaceholder';

interface Props {
  active?: 'true' | 'false',
}

const ResolutionsList = ({ active }: Props) => {
  const getMessage = useMessages();
  const hoaMode = useHomeownerAssociationsMode();
  const [selectedPage, setSelectedPage] = usePagination('employeeResolutionsList');
  const { filterValues } = useFilters('employeeResolutionsList');
  const filters: FilterValues = { ...filterValues };

  const setVotedFilterValue = useCallback((value: string | undefined) => {
    if (value === 'voted') return true;
    if (value === 'notVoted') return false;
    return '';
  }, []);

  useEffect(() => {
    setSelectedPage(1);
  }, [active]);

  const { data } = useGetResolutionsQuery({
    ...filters,
    voted: setVotedFilterValue(filters.voted),
    active: active || '',
    page: selectedPage.toString(),
  });

  const resolutionsData = (data?.resolutions || []) as EmployeeResolution[];
  const columnLabels = useMemo(() => [
    ...hoaMode ? [{ label: 'resolutions.column.homeownerAssociationCode' }] : [],
    { label: 'resolutions.column.resolutionNumber' },
    { label: 'resolutions.column.title' },
    { label: 'resolutions.column.onlineVotes' },
    { label: 'resolutions.column.createdAt' },
    { label: 'resolutions.column.votingDeadline' },
    { label: 'resolutions.column.status' },
  ], [hoaMode]);

  const ids = data?.resolutions.map(({ id }) => id) || [];

  return (
    <>
      <ResponsiveTable className="col-span-full">
        {data?.resolutions && data?.resolutions.length === 0 ? <TableContentPlaceholder /> : (
          <>
            <ResponsiveTableHeader ids={ids} columnLabels={columnLabels} tableName={'employeeResolutionsList' as TableId} />
            <ResponsiveTableBody>
              {resolutionsData.map(({
                id, homeownerAssociationCode, resolutionNumber, title, pwiVotes, createdAt, votingDeadline, status,
              }) => (
                <ResponsiveTableBodyRow
                  key={id}
                  className="border-b last:border-b-0"
                  clickable={({ href: `/uchwaly/${id}`, ariaLabel: `Przejdź do szczegółów uchwały ${resolutionNumber} ${title}` })}
                >
                  {hoaMode && (<TableBodyCell>{homeownerAssociationCode}</TableBodyCell>)}
                  <TableBodyCell>{resolutionNumber}</TableBodyCell>
                  <TableBodyCell>{title}</TableBodyCell>
                  <TableBodyCell title={getMessage('resolutions.tooltipText')}>{pwiVotes}</TableBodyCell>
                  <TableBodyCell>
                    <Chip chipStyle="ghost" className="w-fit">
                      {createdAt && format(new Date(createdAt), 'dd-MM-yyyy')}
                    </Chip>
                  </TableBodyCell>
                  <TableBodyCell>
                    <Chip chipStyle="ghost" className="w-fit">
                      {votingDeadline && format(new Date(votingDeadline), 'dd-MM-yyyy')}
                    </Chip>
                  </TableBodyCell>
                  <TableBodyCell>
                    <Chip chipStyle="primary" className="w-fit">
                      {status && capitalize(getMessage(`resolutions.status.${status}`))}
                    </Chip>
                  </TableBodyCell>
                </ResponsiveTableBodyRow>
              ))}
            </ResponsiveTableBody>
          </>
        )}
      </ResponsiveTable>
      {data?.metadata && (
        <PaginationControls
          className="col-span-full"
          selectedPage={selectedPage}
          setSelectedPage={setSelectedPage}
          paginationMetadata={data.metadata}
        />
      )}
    </>
  );
};

export default ResolutionsList;
