/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useCallback, useEffect, useState } from 'react';
import Dialog from '../../../components/Dialog/Dialog';
import useMessages from '../../../hooks/useMessages';
import {
  useDeleteDocumentCategoryMutation,
  useDeleteTaskCategoryMutation,
  useDeleteTicketCategoryMutation,
} from '../../../api/appApi';
import { InputError } from '../../../components/FormInputs/InputLabel';

interface Props {
  isOpen: boolean,
  onClose: () => void,
  categoryType: 'ticket' | 'task' | 'document',
  categoryId: string | null,
}

const DeleteCategoryPopup = ({
  isOpen, onClose, categoryType, categoryId,
}: Props) => {
  const getMessage = useMessages();
  const [deleteTicketCategory, ticketResult] = useDeleteTicketCategoryMutation();
  const [deleteTaskCategory, taskResult] = useDeleteTaskCategoryMutation();
  const [deleteDocumentCategory, documentResult] = useDeleteDocumentCategoryMutation();

  const [backendErrors, setBackendErrors] = useState([] as string[]);

  const methods = useForm<{ id: string }>({
    defaultValues: {
      id: categoryId || '',
    },
  });

  const onSubmit = (data: { id: string }) => {
    if (categoryType === 'ticket') {
      deleteTicketCategory(data);
    } else if (categoryType === 'task') {
      deleteTaskCategory(data);
    } else if (categoryType === 'document') {
      deleteDocumentCategory(data);
    }
  };

  const handleErrorsFromResult = useCallback((result: any) => {
    if (result.status === 'fulfilled') {
      setBackendErrors([]);
      onClose();
    } else if (result.status === 'rejected') {
      const errors = result.error?.data?.errors || [getMessage('parameters.categories.deleteDialog.error')];
      setBackendErrors(Array.isArray(errors) ? errors : [errors]);
    }
  }, []);

  useEffect(() => {
    handleErrorsFromResult(ticketResult);
  }, [ticketResult]);

  useEffect(() => {
    handleErrorsFromResult(taskResult);
  }, [taskResult]);

  useEffect(() => {
    handleErrorsFromResult(documentResult);
  }, [documentResult]);

  useEffect(() => {
    methods.reset({
      id: categoryId || '',
    });
    setBackendErrors([]);
  }, [categoryId]);

  return (
    <FormProvider {...methods}>
      <Dialog
        isOpen={isOpen}
        onAccept={methods.handleSubmit(onSubmit)}
        onClose={onClose}
        acceptButtonText={getMessage('parameters.categories.deleteDialog.button')}
        submitButton
        preventCloseOnAccept
      >
        <div className="text-head-sm mb-2">
          {getMessage('parameters.categories.deleteDialog.header')}
        </div>
        <div className="text-text-md-medium mb-8">
          {categoryType === 'ticket' && getMessage('parameters.categories.deleteDialog.ticketsSubHeader')}
          {categoryType === 'task' && getMessage('parameters.categories.deleteDialog.tasksSubHeader')}
          {categoryType === 'document' && getMessage('parameters.categories.deleteDialog.documentsSubHeader')}
        </div>
        {backendErrors && backendErrors.map((error) => (
          <InputError key={error} message={error} />
        ))}
      </Dialog>
    </FormProvider>
  );
};

export default DeleteCategoryPopup;
