/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

// Define a type for the slice state
export interface InputValue {
  inputId: string,
  inputValue: string | boolean,
}

interface PaginationPage {
  tableId: TableId,
  selectedPage: number,
}

export interface AppState {
  inputs: InputValue[],
  pagination: PaginationPage[],
  filters: Filter[],
  sortParams: SortParams[],
  selectedVenueId: string,
  selectedVenueName: string,
  selectedTicketsIds: string[],
  selectedTasksIds: string[],
  selectedDocumentsIds: string[],
  selectedSettlementsTitle: string,
  selectedSettlementsTenantId: string,
}

export interface InputPayload {
  id: string,
  value: string | boolean,
}

export interface VenueSelectPayload {
  userId: string,
  selectedVenueId: string,
  selectedVenueName: string,
}

export interface PaginationSelectPayload {
  id: TableId,
  selectedPage: number,
}

export interface FilterValues {
  [key: string]: any,
}

export interface Filter {
  id: TableId,
  values: FilterValues
}

export interface SetFilterPayload extends Filter {}

export interface SortParamsValues {
  [key: string]: string,
}

export interface SortParams {
  id: TableId,
  values: SortParamsValues
}

export interface SetSortParamsPayload extends SortParams {}

interface SelectedTicketIdPayload {
  id: string,
}

interface SelectedTaskIdPayload {
  id: string,
}

interface SelectedDocumentIdPayload {
  id: string,
}

interface SettlementTenantSelectPayload {
  userId: string,
  selectedSettlementsTenantId: string,
}

export type TableId = 'ticketsTable' | 'resolutionsList' | 'usersTable' | 'announcementsList' | 'permissionsTable' |
'metersHistory' | 'myActivityLog' | 'usersActivityLog' | 'employeeTicketsTable' | 'yearsBalanceTable' | 'monthsBalanceTable' |
'titlesBalanceTable' | 'tasksTable' | 'employeeResolutionsList' | `resolutionVotes${string}` | 'metersVenuesList' |
'userAccounts' | 'settlementsVenuesList' | `paymentsHistoryTable${string}` | 'documentsList' | 'journalsList';

const initialState: AppState = {
  inputs: [],
  pagination: [],
  filters: [],
  sortParams: [],
  selectedVenueId: '',
  selectedVenueName: '',
  selectedTicketsIds: [],
  selectedTasksIds: [],
  selectedDocumentsIds: [],
  selectedSettlementsTitle: '',
  selectedSettlementsTenantId: '',
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    saveInput: (state, action: PayloadAction<InputPayload>) => {
      state.inputs = state.inputs.filter((input) => input.inputId !== action.payload.id);
      state.inputs.push({ inputId: action.payload.id, inputValue: action.payload.value });
    },
    saveSelectedTicketId: (state, action: PayloadAction<SelectedTicketIdPayload>) => {
      if (state.selectedTicketsIds.includes(action.payload.id)) {
        state.selectedTicketsIds = state.selectedTicketsIds.filter((id) => id !== action.payload.id);
      } else {
        state.selectedTicketsIds = state.selectedTicketsIds.concat([action.payload.id]);
      }
    },
    resetSelectedTicketIds: (state) => {
      state.selectedTicketsIds = [];
    },
    saveSelectedTaskId: (state, action: PayloadAction<SelectedTaskIdPayload>) => {
      if (state.selectedTasksIds.includes(action.payload.id)) {
        state.selectedTasksIds = state.selectedTasksIds.filter((id) => id !== action.payload.id);
      } else {
        state.selectedTasksIds = state.selectedTasksIds.concat([action.payload.id]);
      }
    },
    resetSelectedTaskIds: (state) => {
      state.selectedTasksIds = [];
    },
    saveSelectedDocumentId: (state, action: PayloadAction<SelectedDocumentIdPayload>) => {
      if (state.selectedDocumentsIds.includes(action.payload.id)) {
        state.selectedDocumentsIds = state.selectedDocumentsIds.filter((id) => id !== action.payload.id);
      } else {
        state.selectedDocumentsIds = state.selectedDocumentsIds.concat([action.payload.id]);
      }
    },
    resetSelectedDocumentIds: (state) => {
      state.selectedDocumentsIds = [];
    },
    selectVenue: (state, action: PayloadAction<VenueSelectPayload>) => {
      localStorage.setItem(`selectedVenueId-${action.payload.userId}`, action.payload.selectedVenueId);
      localStorage.setItem(`selectedVenueName-${action.payload.userId}`, action.payload.selectedVenueName);
      state.selectedVenueId = action.payload.selectedVenueId;
      state.selectedVenueName = action.payload.selectedVenueName;
    },
    setPaginationPage: (state, action: PayloadAction<PaginationSelectPayload>) => {
      state.pagination = state.pagination.filter((pagination) => pagination.tableId !== action.payload.id);
      state.pagination.push({ tableId: action.payload.id, selectedPage: action.payload.selectedPage });
    },
    setFilter: (state, action: PayloadAction<SetFilterPayload>) => {
      state.filters = state.filters.filter((filter) => filter.id !== action.payload.id);
      state.filters.push({ id: action.payload.id, values: action.payload.values });
      const connectedPagination = state.pagination.find((pagination) => pagination.tableId === action.payload.id);
      if (connectedPagination) {
        connectedPagination.selectedPage = 1;
      }
    },
    setSortParams: (state, action: PayloadAction<SetSortParamsPayload>) => {
      state.sortParams = state.sortParams.filter((param) => param.id !== action.payload.id);
      state.sortParams.push({ id: action.payload.id, values: action.payload.values });
    },
    selectSettlementsTitle: (state, action: PayloadAction<string>) => {
      state.selectedSettlementsTitle = action.payload;
    },
    selectSettlementsTenantId: (state, action: PayloadAction<SettlementTenantSelectPayload>) => {
      localStorage.setItem(`selectedSettlementsTenantId-${action.payload.userId}`, action.payload.selectedSettlementsTenantId);
      state.selectedSettlementsTenantId = action.payload.selectedSettlementsTenantId;
    },
  },
});

export const {
  saveInput, selectVenue, setPaginationPage, setFilter, setSortParams, saveSelectedTicketId, selectSettlementsTitle,
  resetSelectedTicketIds, saveSelectedTaskId, resetSelectedTaskIds, saveSelectedDocumentId, resetSelectedDocumentIds,
  selectSettlementsTenantId,
} = appSlice.actions;

export default appSlice.reducer;
