import * as React from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import Layout from '../../../components/Layout/Layout';
import ContentWrapper from '../../../components/ContentWrapper/ContentWrapper';
import ParametersSidebar from '../ParametersSidebar';
import PageHeader from '../../../components/Layout/PageHeader';
import useMessages from '../../../hooks/useMessages';
import { useGetFullJournalsQuery } from '../../../api/appApi';
import Table, {
  TableHeader, TableHeaderRow, TableHeaderCell, TableBody, TableBodyRow, TableBodyCell,
} from '../../../components/Table/Table';
import PaginationControls from '../../../components/Table/PaginationControls';
import usePagination from '../../../hooks/usePagination';
import Button from '../../../components/Button/Button';
import { usePermissions } from '../../../hooks/usePermissions';

const JournalsPage = () => {
  const [selectedPage, setSelectedPage] = usePagination('journalsList');
  const getMessage = useMessages();
  useEffect(() => {
    setSelectedPage(1);
  }, []);

  const { data } = useGetFullJournalsQuery({
    page: selectedPage.toString(),
  });
  const navigate = useNavigate();
  const { checkModuleEnabled } = usePermissions();

  checkModuleEnabled('documents');

  return (
    <Layout currentPage="parameters">
      <div className="flex flex-grow h-full">
        <ParametersSidebar selectedPage="journals" />
        <div className="flex flex-col flex-grow">
          <PageHeader currentPage="parameters" pageTitle={getMessage('navigation.parameters.journals')}>
            <Button onClick={() => navigate('/parametry/dzienniki/nowy')}>{getMessage('parameters.journals.create')}</Button>
          </PageHeader>
          <ContentWrapper>
            {data?.journals && (
              <Table className="col-span-12">
                <TableHeader>
                  <TableHeaderRow>
                    <TableHeaderCell>{getMessage('parameters.journals.prefixes')}</TableHeaderCell>
                    <TableHeaderCell>{getMessage('parameters.journals.name')}</TableHeaderCell>
                    <TableHeaderCell divClassName="justify-end">{getMessage('parameters.journals.numeration')}</TableHeaderCell>
                  </TableHeaderRow>
                </TableHeader>
                <TableBody>
                  {data.journals.map((item: any) => (
                    <TableBodyRow
                      key={item.id}
                      clickable={({ href: `/parametry/dzienniki/${item.id}`, ariaLabel: `Przejdź do edycji dziennika ${item.name}` })}
                    >
                      <TableBodyCell>{item.prefix}</TableBodyCell>
                      <TableBodyCell>{item.name}</TableBodyCell>
                      <TableBodyCell className="text-right">{item.autonumbering && getMessage('parameters.journals.autonumbering')}</TableBodyCell>
                    </TableBodyRow>
                  ))}
                </TableBody>
              </Table>
            )}
            {data?.metadata && (
            <PaginationControls
              className="col-span-full"
              selectedPage={selectedPage}
              setSelectedPage={setSelectedPage}
              paginationMetadata={data.metadata}
            />
            )}
          </ContentWrapper>
        </div>
      </div>
    </Layout>
  );
};

export default JournalsPage;
