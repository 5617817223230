import * as React from 'react';
import { useMemo } from 'react';
import useMessages from '../../../../hooks/useMessages';
import Filters from '../../../../components/Filters/Filters';
import {
  useGetTicketCategoriesQuery, useGetTicketStatusesQuery,
} from '../../../../api/appApi';
import capitalize from '../../../../../utils/formatters';
import useHomeownerAssociationsMode from '../../../../hooks/useHomeownerAssociationsMode';
import { FilterConfig } from '../../../../components/FilterInput/FilterInput';

const EmployeeTicketsFilters = () => {
  const getMessage = useMessages();
  const { data: ticketCategories } = useGetTicketCategoriesQuery();
  const { data: ticketStatuses } = useGetTicketStatusesQuery();
  const hoaMode = useHomeownerAssociationsMode();

  const categoryOptionsValues = useMemo(() => ticketCategories?.map(({ id, name }) => ({
    value: id,
    optionLabel: capitalize(name),
  })), [ticketCategories]);

  const statusOptionsValues = useMemo(() => ticketStatuses?.map(({ id, name }) => ({
    value: id,
    optionLabel: capitalize(name),
  })), [ticketStatuses]);

  const filterFields: FilterConfig[] = useMemo(() => [
    {
      type: 'multiselect',
      id: 'status_ids',
      label: getMessage('tickets.ticket.status'),
      selectOptions: statusOptionsValues || [],
    },
    {
      type: 'dateRange',
      id: 'from_date',
      toDateId: 'to_date',
      label: getMessage('tickets.ticket.reportedAt'),
    },
    {
      type: 'dateRange',
      id: 'from_last_comment_at',
      toDateId: 'to_last_comment_at',
      label: getMessage('tickets.ticket.lastCommentAt'),
    },
    {
      type: 'text',
      id: 'number_title_search',
      label: getMessage('tickets.ticket.titleOrNumber'),
      ariaLabel: 'Szukaj po tytule lub numerze zgłoszenia',
    },
    {
      type: 'text',
      id: 'venue_search',
      label: getMessage('tickets.ticket.venueCodeAddress'),
      ariaLabel: 'Szukaj po adresie lub kodzie lokalu',
    },
    ...hoaMode ? [
      {
        type: 'text',
        id: 'homeowner_association_search',
        label: getMessage('tickets.ticket.homeownerAssociationCodeName'),
        ariaLabel: 'Szukaj po kodzie lub nazwie wspólnoty',
      } as FilterConfig,
    ] : [],
    {
      type: 'multiselect',
      id: 'category_ids',
      label: getMessage('tickets.ticket.category'),
      selectOptions: categoryOptionsValues || [],
    },
    {
      type: 'text',
      id: 'reporter_search',
      label: getMessage('tickets.ticket.reporterNameCode'),
      ariaLabel: 'Szukaj po imieniu i nazwisku zgłaszającego',
    },
    {
      type: 'text',
      id: 'realizer_search',
      label: getMessage('tickets.ticket.realizerNameCode'),
      ariaLabel: 'Szukaj po imieniu i nazwisku realizującego',
    },
  ], [hoaMode, ticketStatuses]);

  return (
    <Filters
      filterId="employeeTicketsTable"
      filterFields={filterFields}
    />
  );
};

export default EmployeeTicketsFilters;
