import * as React from 'react';
import { useMemo } from 'react';
import useMessages from '../../../hooks/useMessages';
import Filters from '../../../components/Filters/Filters';
import { FilterConfig } from '../../../components/FilterInput/FilterInput';
import { useGetTaskCategoriesQuery, useGetTaskStatusesQuery } from '../../../api/appApi';
import capitalize from '../../../../utils/formatters';
import useHomeownerAssociationsMode from '../../../hooks/useHomeownerAssociationsMode';

interface Props {
  open: boolean,
}

const TasksFilters = ({ open }: Props) => {
  const getMessage = useMessages();
  const hoaMode = useHomeownerAssociationsMode();
  const { data: taskCategories } = useGetTaskCategoriesQuery();
  const { data: taskStatuses } = useGetTaskStatusesQuery();

  const statusOptionsValues = useMemo(() => taskStatuses?.map(({ id, name }) => ({
    value: id,
    optionLabel: capitalize(name),
  })), [taskStatuses]);

  const categoryOptionsValues = useMemo(() => taskCategories?.map(({ id, name }) => ({
    value: id,
    optionLabel: capitalize(name),
  })), [taskCategories]);

  const filterFields : FilterConfig[] = useMemo(() => [
    ...open ? [] : [{
      type: 'multiselect',
      id: 'status_ids',
      label: getMessage('tasks.column.status'),
      selectOptions: statusOptionsValues,
    } as FilterConfig],
    {
      type: 'dateRange',
      id: 'from_created_at',
      toDateId: 'to_created_at',
      label: getMessage('tasks.column.creationDate'),
    },
    {
      type: 'dateRange',
      id: 'from_last_comment_at',
      toDateId: 'to_last_comment_at',
      label: getMessage('tasks.column.lastCommentDate'),
    },
    {
      type: 'text',
      id: 'number_title_search',
      label: getMessage('tasks.filter.numberTitle'),
      ariaLabel: 'Szukaj po numerze lub tytule zadania',
    },
    {
      type: 'text',
      id: 'venue_search',
      label: getMessage('tasks.filter.venueSearch'),
      ariaLabel: 'Szukaj po kodzie lub adresie lokalu',
    },
    ...hoaMode ? [{
      type: 'text',
      id: 'homeowner_association_search',
      label: getMessage('tasks.filter.homeownersAssociationSearch'),
      ariaLabel: 'Szukaj po kodzie lub nazwie wspólnoty',
    } as FilterConfig] : [],
    {
      type: 'text',
      id: 'creator_search',
      label: getMessage('tasks.filter.creatorNameCode'),
      ariaLabel: 'Szukaj po imieniu i nazwisku zgłaszającego',
    },
    {
      type: 'multiselect',
      id: 'category_id',
      label: getMessage('tickets.ticket.category'),
      selectOptions: categoryOptionsValues || [],
    },
    {
      type: 'text',
      id: 'realizer_search',
      label: getMessage('tasks.filter.realizerNameCode'),
      ariaLabel: 'Szukaj po imieniu i nazwisku realizującego',
    },
    {
      type: 'dateRange',
      id: 'from_estimated_start_time',
      toDateId: 'to_estimated_start_time',
      label: getMessage('tasks.column.plannedStartDate'),
    },
    {
      type: 'dateRange',
      id: 'from_estimated_finish_time',
      toDateId: 'to_estimated_finish_time',
      label: getMessage('tasks.column.plannedEndDate'),
    },
    {
      type: 'dateRange',
      id: 'from_started_at',
      toDateId: 'to_started_at',
      label: getMessage('tasks.column.startDate'),
    },
    {
      type: 'dateRange',
      id: 'from_finished_at',
      toDateId: 'to_finished_at',
      label: getMessage('tasks.column.endDate'),
    },
  ], [open, hoaMode]);

  return (
    <Filters
      filterId="tasksTable"
      filterFields={filterFields}
    />
  );
};

export default TasksFilters;
