/* eslint-disable react/jsx-props-no-spreading */

import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useEffect } from 'react';
import {
  usePostCompanyAttachmentsMutation,
  usePutColorPaletteMutation,
  usePutCompanyDetailsMutation,
} from '../../api/appApi';
import useMessages from '../../hooks/useMessages';
import Card from '../../components/Card/Card';
import {
  CompanyData, CompanyFormInputs, CompanySettings,
} from '../../api/modules/company/companyTypes';
import { FormInput, FormRadioButtons } from '../../components/FormInputs';
import Button from '../../components/Button/Button';
import {
  translateCompanyAttachmentsToApi,
  translateCompanyDetailsToApi,
} from '../../api/modules/company/companyTranslator';
import { FormNotice } from '../../components/FormNotice/FormNotice';
import CompanyAttachmentInput from './CompanyAttachmentInput';
import Chip from '../../components/Chip/Chip';

interface Props {
  companyData: CompanyData,
  companySettings: CompanySettings,
}

const CompanyDetailsForm = ({ companyData, companySettings }: Props) => {
  const getMessage = useMessages();

  const methods = useForm<CompanyFormInputs>({
    values: {
      name: companyData.name || '',
      streetAddress: companyData.streetAddress || '',
      postalCode: companyData.postalCode || '',
      city: companyData.city || '',
      phone: companyData.phone || '',
      website: companyData.website || '',
      oldLogo: companyData.logo,
      oldRegulations: companyData.regulations,
      oldBackgroundImage: companyData.backgroundImage,
      colorPalette: companySettings.colorPalette,
    },
    mode: 'onTouched',
  });

  const [postCompanyDetails, detailsResult] = usePutCompanyDetailsMutation();
  const [postCompanyAttachments, attachmentsResult] = usePostCompanyAttachmentsMutation();
  const [putColorPalette, paletteResult] = usePutColorPaletteMutation();

  const onSubmit = (data: CompanyFormInputs) => {
    postCompanyDetails(translateCompanyDetailsToApi(data));
    if (data.colorPalette) putColorPalette(data.colorPalette);
    translateCompanyAttachmentsToApi(data).then((data) => postCompanyAttachments(data));
  };

  const oldLogo = methods.watch('oldLogo');
  const oldBackgroundImage = methods.watch('oldBackgroundImage');
  const oldRegulations = methods.watch('oldRegulations');

  const colorPaletteOptions = [
    { value: 'red', element: <Chip className="red w-full bg-light-200" chipStyle="primary">{getMessage('company.settings.colorPalette.red')}</Chip> },
    { value: 'green', element: <Chip className="green w-full bg-light-200" chipStyle="primary">{getMessage('company.settings.colorPalette.green')}</Chip> },
    { value: 'violet', element: <Chip className="violet w-full bg-light-200" chipStyle="primary">{getMessage('company.settings.colorPalette.violet')}</Chip> },
  ];

  useEffect(() => {
    methods.reset();
    methods.setValue('oldLogo', companyData.logo);
    methods.setValue('oldRegulations', companyData.regulations);
    methods.setValue('oldBackgroundImage', companyData.backgroundImage);
  }, [companyData]);

  return (
    <Card className="col-span-full grid grid-cols-1">
      {detailsResult.isSuccess && paletteResult.isSuccess && <FormNotice type="success" message={getMessage('form.success')} />}
      {attachmentsResult.isSuccess && <FormNotice type="success" message={getMessage('company.attachments.success')} />}
      {detailsResult.isError && <FormNotice type="error" message={getMessage('form.error')} />}
      {/* @ts-ignore */}
      {paletteResult.isError && <FormNotice type="error" message={paletteResult.error?.data?.errors || getMessage('form.error')} />}
      {attachmentsResult.isError && <FormNotice type="error" message={getMessage('company.attachments.error')} />}
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} className="col-span-full grid grid-cols-1 md:grid-cols-2">
          <section className="col-span-full md:col-span-1">
            <FormInput
              id="name"
              type="text"
              label={getMessage('company.details.name')}
              className="w-full md:w-2/3"
              inputClassName="w-full"
              options={{ required: getMessage('company.details.form.name.error.required') }}
            />
            <FormInput
              id="postalCode"
              type="text"
              label={getMessage('company.details.postalCode')}
              className="w-full md:w-2/3"
              inputClassName="w-full"
              options={{
                pattern: {
                  value: /^[0-9]{2}[-][0-9]{3}/,
                  message: getMessage('form.postalCode.error.pattern'),
                },
              }}
            />
            <FormInput
              id="city"
              type="text"
              label={getMessage('company.details.city')}
              className="w-full md:w-2/3"
              inputClassName="w-full"
            />
            <FormInput
              id="streetAddress"
              type="text"
              label={getMessage('company.details.streetAddress')}
              className="w-full md:w-2/3"
              inputClassName="w-full"
            />
          </section>
          <section className="col-span-full md:col-span-1">
            <FormInput
              id="phone"
              type="text"
              label={getMessage('company.details.phone')}
              className="w-full md:w-2/3"
              inputClassName="w-full"
              options={{
                pattern: {
                  value: /^[0-9- +]+$/,
                  message: getMessage('form.phone.error.pattern'),
                },
              }}
            />
            <FormInput
              id="website"
              type="text"
              label={getMessage('company.details.website')}
              className="w-full md:w-2/3"
              inputClassName="w-full"
            />
          </section>
          <section className="col-span-full border-t mt-4 pt-4">
            <CompanyAttachmentInput
              id="logo"
              oldAttachment={oldLogo}
              changeAttachmentLabel={getMessage('company.details.changeLogo')}
              newAttachmentLabel={getMessage('company.details.addLogo')}
              oldId="oldLogo"
            />
            <CompanyAttachmentInput
              id="backgroundImage"
              oldAttachment={oldBackgroundImage}
              changeAttachmentLabel={getMessage('company.details.changeBackgroundImage')}
              newAttachmentLabel={getMessage('company.details.addBackgroundImage')}
              oldId="oldBackgroundImage"
            />
            <CompanyAttachmentInput
              id="regulations"
              oldAttachment={oldRegulations}
              changeAttachmentLabel={getMessage('company.details.changeRegulations')}
              newAttachmentLabel={getMessage('company.details.addRegulations')}
              oldId="oldRegulations"
            />
          </section>
          <section className="col-span-full border-t mt-4 pt-4">
            <FormRadioButtons id="colorPalette" inputOptions={colorPaletteOptions} label="company.settings.colorPalette" className="w-52" />
          </section>
          <div className="col-span-2 flex justify-end mt-8">
            <Button className="smallRange:w-full" type="submit">{getMessage('save')}</Button>
          </div>
        </form>
      </FormProvider>
    </Card>
  );
};
export default CompanyDetailsForm;
