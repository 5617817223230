import * as React from 'react';
import { useMemo, useState } from 'react';
import useMessages from '../../hooks/useMessages';
import PageHeader from '../../components/Layout/PageHeader';
import { usePermissions } from '../../hooks/usePermissions';
import ContentWrapper from '../../components/ContentWrapper/ContentWrapper';
import Tabs, { TabElement } from '../../components/Tabs/Tabs';
import { useAppSelector } from '../../hooks/reduxHooks';
import Button from '../../components/Button/Button';
import TenantResolutionsList from './ResolutionsList/TenantResolutionsList/TenantResolutionsList';
import TenantResolutionsFilters from './ResolutionsFilters/TenantResolutionsFilters/TenantResolutionsFilters';
import EmployeeResolutionsList from './ResolutionsList/EmployeeResolutionsList/EmployeeResolutionsList';
import EmployeeResolutionsFilters from './ResolutionsFilters/EmployeeResolutionsFilters/EmployeeResolutionsFilters';
import Layout from '../../components/Layout/Layout';
import FiltersIcon from '../../icons/filters.svg';

interface Props {
  active?: 'true' | 'false'
}

const ResolutionsPage = ({ active }: Props) => {
  const getMessage = useMessages();
  const {
    hasPermission, hasRoleType, checkPermission, checkModuleEnabled,
  } = usePermissions();
  const hasFilterSet = useAppSelector((state) => !!state.app.filters.find((filter) => filter.id === 'resolutionsList'));
  const [filtersOpen, setFiltersOpen] = useState(hasFilterSet);

  const tenantTabs: TabElement[] = useMemo(() => {
    if (hasPermission('resolutions_list')) {
      return [
        {
          label: getMessage('resolutions.tabs.all'),
          path: '/uchwaly',
        }, {
          label: getMessage('resolutions.tabs.active'),
          path: '/uchwaly/aktywne',
        }, {
          label: getMessage('resolutions.tabs.closed'),
          path: '/uchwaly/zamkniete',
        },
      ];
    }
    return [];
  }, [hasPermission]);

  const employeeTabs: TabElement[] = useMemo(() => {
    if (hasPermission('resolutions_list')) {
      return [
        {
          label: getMessage('resolutions.tabs.all'),
          path: '/uchwaly',
        }, {
          label: getMessage('resolutions.tabs.active'),
          path: '/uchwaly/aktywne',
        },
      ];
    }
    return [];
  }, [hasPermission]);

  checkPermission('resolutions_list');
  checkModuleEnabled('resolutions');

  return (
    <Layout currentPage="resolutions">
      <PageHeader pageTitle={getMessage('navigation.resolutions')} currentPage="resolutions">
        <Button
          Icon={FiltersIcon}
          styleType="secondary"
          size="small"
          onClick={() => setFiltersOpen((filtersOpen) => !filtersOpen)}
          className="h-8 py-1"
        >
          {getMessage('filters')}
        </Button>
      </PageHeader>
      {hasRoleType('worker') ? (
        <>
          <Tabs tabElements={employeeTabs} />
          {filtersOpen && <EmployeeResolutionsFilters />}
          <ContentWrapper>
            <EmployeeResolutionsList active={active} />
          </ContentWrapper>
        </>
      ) : (
        <>
          <Tabs tabElements={tenantTabs} />
          {filtersOpen && <TenantResolutionsFilters />}
          <ContentWrapper>
            <TenantResolutionsList active={active} />
          </ContentWrapper>
        </>
      )}
    </Layout>
  );
};

export default ResolutionsPage;
