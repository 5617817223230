import * as React from 'react';
import { useMediaQuery } from 'react-responsive';
import classnames from 'classnames';
import { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import useSortParams from '../../hooks/useSortParams';
import useMessages from '../../hooks/useMessages';
import { mobile } from '../../../utils/breakpoints';
import Card from '../Card/Card';
import {
  TableProps,
  TableHeaderRowProps,
  TableHeaderCellProps,
  TableBodyProps,
  TableBodyRowProps,
  TableHeader,
  TableHeaderRow,
} from './Table';
import SortArrows from './SortArrows';
import { TableId } from '../../store/appSlice';
import ArrowUp from '../../icons/dropdown-arrow-up.svg';
import ArrowDown from '../../icons/dropdown-arrow-down.svg';

const ResponsiveTable = ({ children, className, header }: TableProps) => (
  <Card className={classnames(className, 'p-5 gap-y-0')}>
    {header}
    <table className="w-full text-left grid grid-cols-3 md:grid-cols-none md:table overflow-hidden md:overflow-auto">
      {children}
    </table>
  </Card>
);

interface ResponsiveTableHeaderProps {
  columnLabels: {
    label: string,
    id?: string,
    divClassName?: string,
  }[]
  ids: string[],
  tableName?: TableId,
  firstItemClassName?: string,
}

const ResponsiveTableHeaderRow = ({
  children, className,
}: TableHeaderRowProps) => (
  <tr className={`grid grid-col md:table-row pr-14 md:pr-0 pt-4 first:pt-0 md:pt-0 border-b ${className}`}>
    {children}
  </tr>
);

const ResponsiveTableHeaderCell = ({
  children, className, divClassName, tableName, id,
}: TableHeaderCellProps) => {
  const { sortParamsValues, handleSaveSortParams } = useSortParams(tableName as TableId);
  const { order: sortOrder, sort: sortBy } = sortParamsValues;
  const sortable = !!id;
  const handleClick = () => {
    if (sortable) handleSaveSortParams(id);
  };

  return (
    <th
      key={sortBy}
      scope="col"
      className={classnames('h-10 md:h-5 pr-0 mb-2 md:mb-0 leading-normal text-text-md-semibold md:pr-8 last:pr-0 md:text-text-lg-semibold text-typography-dark', { 'hover:cursor-pointer': sortable }, className)}
      onClick={handleClick}
    >
      <div className={classnames('flex items-center mobile:break-normal', divClassName)}>
        {children}
        {sortable && <SortArrows id={sortBy} sortBy={sortBy} sortOrder={sortOrder} />}
      </div>
    </th>
  );
};

const ResponsiveTableHeader = ({
  columnLabels, ids, tableName, firstItemClassName,
}: ResponsiveTableHeaderProps) => {
  const isMobile = useMediaQuery({ query: mobile.raw });
  const getMessage = useMessages();

  return (
    isMobile ? (
      <TableHeader>
        {ids.map((id) => (
          <ResponsiveTableHeaderRow key={id}>
            {columnLabels.map(({ label, divClassName }) => (
              <ResponsiveTableHeaderCell key={label} divClassName={divClassName}>{getMessage(label)}</ResponsiveTableHeaderCell>
            ))}
          </ResponsiveTableHeaderRow>
        ))}
      </TableHeader>
    )
      : (
        <TableHeader>
          <TableHeaderRow>
            {columnLabels.map(({ label, id, divClassName }, index) => (
              <ResponsiveTableHeaderCell
                key={label}
                id={id}
                tableName={tableName}
                className={classnames({ [firstItemClassName || '']: !!firstItemClassName && index === 0 })}
                divClassName={divClassName}
              >
                {getMessage(label)}
              </ResponsiveTableHeaderCell>
            ))}
          </TableHeaderRow>
        </TableHeader>
      )
  );
};

const ResponsiveTableBody = ({ children }: TableBodyProps) => (
  <tbody className="text-typography-dark col-span-2">
    {children}
  </tbody>
);

interface ExpandableTableRowProps extends TableBodyRowProps {
  ExpandedChild?: React.ReactNode,
  onClick?: () => void,
}

const ExpandableResponsiveTableBodyRow = ({
  children, id, className, onClick, ExpandedChild,
}: ExpandableTableRowProps) => {
  const isMobile = useMediaQuery({ query: mobile.raw });
  const [open, setOpen] = useState(false);

  const handleClick = useCallback(() => {
    if (!isMobile) {
      if (onClick) onClick();
      setOpen((open) => !open);
    }
  }, [onClick, isMobile]);

  return (
    <>
      <tr
        key={id}
        className={
          classnames(
            'grid grid-col md:table-row pt-4 md:pt-0 first:pt-0 cursor-pointer hover:bg-gray-50 shadow-gray relative',
            className,
            { 'border-b-0': open && !isMobile },
          )
        }
      >
        {children}
        {!isMobile && (
        <button
          type="button"
          onClick={handleClick}
          aria-label="Rozwiń szczegóły dokumentu"
          className="absolute w-full h-full top-0 left-0 pr-2"
        >
          {open ? <ArrowUp className="inline float-right" /> : <ArrowDown className="inline float-right" />}
        </button>
        )}
      </tr>
      {open && !isMobile && ExpandedChild}
    </>
  );
};

const ResponsiveTableBodyRow = ({
  children, id, className, onClick, ExpandedChild, clickable,
}: ExpandableTableRowProps) => (ExpandedChild ? (
  <ExpandableResponsiveTableBodyRow id={id} className={className} onClick={onClick} ExpandedChild={ExpandedChild}>
    {children}
  </ExpandableResponsiveTableBodyRow>
) : (
  <tr
    key={id}
    className={
      classnames(
        'grid grid-col md:table-row pt-4 first:pt-0 border-b',
        className,
        { 'hover:bg-gray-50 focus-within:bg-gray-50 focus-within:border focus-within:border-hover cursor-pointer shadow-gray mobile:select-none relative': clickable },
      )
}
  >
    {clickable ? (
      <>
        {children}
        <Link
          to={clickable.href}
          state={clickable.linkState}
          aria-label={clickable.ariaLabel}
          onClick={clickable.onClick}
          className='after:absolute after:content-[""] after:w-full after:h-full after:top-0 after:left-0'
        />
      </>
    ) : children}
  </tr>
));

export default ResponsiveTable;
export {
  ResponsiveTableHeader,
  ResponsiveTableHeaderRow,
  ResponsiveTableHeaderCell,
  ResponsiveTableBody,
  ResponsiveTableBodyRow,
  ExpandableResponsiveTableBodyRow,
};
