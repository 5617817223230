import {
  ApiMe,
  ApiPostUserProfile,
  ApiUserProfile,
  UserProfileData,
  MyNewPasswordData,
  ApiPostMyNewPassword,
  UserAccount,
  ApiVenue,
  Venue,
  ApiTenant,
  Tenant,
  ApiActivityLogResponse,
  ActivityLog,
  TenantApiEvent,
  EmployeeApiEvent,
  AdminApiEvent,
  Event,
  ApiUserAccount,
  Account,
  ApiUserAccountsResponse,
  Accounts,
  ApiRole,
  Role,
  ApiRoles,
  UserRole,
  ApiUserRole,
  ApiAccountResponse,
  FullUserAccount,
  ChangeUserFormData,
  ApiPutUserAccount,
  ApiAccessGroupResponse,
  AccessGroup,
  CreateUserFormData,
  ApiPostUserAccount,
  NewPasswordData,
  ApiPostNewPassword,
} from './userTypes';

export const translateApiToProfile = (apiProfile: ApiUserProfile): UserProfileData => ({
  profileId: apiProfile.external_id,
  profileLogin: apiProfile.login,
  profileAddressCity: apiProfile.profile?.address_city,
  profileAddressPostalCode: apiProfile.profile?.address_postal_code,
  profileAddressStreet: apiProfile.profile?.address_street,
  profileCorrespondenceEmail: apiProfile.profile?.correspondence_email,
  profileEmail: apiProfile.email,
  profileTenantEmail: apiProfile.tenant?.email,
  profileName: apiProfile.full_name,
  profilePassword: '',
  profilePhoneNumber: apiProfile.profile?.phone,
  profileEmailAgreement: apiProfile.profile?.email_agreement,
});

export const translateProfileToApi = (profile: UserProfileData): ApiPostUserProfile => ({
  address_street: profile.profileAddressStreet,
  address_postal_code: profile.profileAddressPostalCode,
  address_city: profile.profileAddressCity,
  correspondence_email: profile.profileCorrespondenceEmail,
  email: profile.profileEmail,
  phone: profile.profilePhoneNumber,
  email_agreement: profile.profileEmailAgreement,
  login: profile.profileLogin,
});

const translateEvent = (apiEvent: TenantApiEvent | EmployeeApiEvent | AdminApiEvent): Event => ({
  date: apiEvent.created_at,
  description: apiEvent.description,
  menuOption: apiEvent.category,
  url: (apiEvent as TenantApiEvent | EmployeeApiEvent).url,
  venue: (apiEvent as TenantApiEvent).venue_address,
  externalId: (apiEvent as AdminApiEvent).account_external_id,
  fullName: (apiEvent as AdminApiEvent).account_full_name,
  roles: (apiEvent as AdminApiEvent).account_roles || [],
});

export const translateApiToActivityLog = (apiActivityLogResponse: ApiActivityLogResponse): ActivityLog => ({
  metadata: apiActivityLogResponse.metadata,
  events: apiActivityLogResponse.events.map((event) => translateEvent(event)),
});

const translateVenue = (apiVenue: ApiVenue): Venue => ({
  id: apiVenue.id,
  address: apiVenue.address,
  code: apiVenue.code,
  isMainTenant: apiVenue.main_tenant,
});

const translateTenant = (apiTenant: ApiTenant): Tenant => ({
  email: apiTenant.email,
  venues: apiTenant.venues.map((apiVenue) => translateVenue(apiVenue)),
});

const translateUserRole = (apiRole: ApiUserRole): UserRole => ({
  id: apiRole.id,
  isDefault: apiRole.default,
  name: apiRole.name,
  roleType: apiRole.role_type,
});

export const translateApiMeToUser = (apiMe: ApiMe): UserAccount => ({
  id: apiMe.me.id,
  fullName: apiMe.me.full_name,
  login: apiMe.me.login,
  roles: apiMe.me.roles.map((role) => translateUserRole(role)),
  permissions: apiMe.me.permissions,
  allPermissions: apiMe.me.all_permissions,
  tenant: apiMe.me.tenant ? translateTenant(apiMe.me.tenant) : undefined,
  colorPalette: apiMe.me.profile?.color_palette,
});

export const translateUserAccount = (apiAccount: ApiUserAccount): Account => ({
  correspondenceEmail: apiAccount.correspondence_email,
  email: apiAccount.email,
  emailAgreement: apiAccount.email_agreement,
  externalId: apiAccount.external_id,
  fullName: apiAccount.full_name,
  id: apiAccount.id,
  login: apiAccount.login,
  roles: apiAccount.roles || [],
});

const translateRole = (apiRole: ApiRole): Role => ({
  id: apiRole.id,
  name: apiRole.name,
  permissions: apiRole.permissions,
  isDefault: apiRole.default,
  roleType: apiRole.role_type,
});

export const translateFullUserAccount = (apiAccount: ApiAccountResponse): FullUserAccount => ({
  accessGroups: {
    homeownerAssociations: apiAccount.account.access_groups['tenants/homeowner_associations'],
    estates: apiAccount.account.access_groups['tenants/estates'],
    buildings: apiAccount.account.access_groups['tenants/buildings'],
    journals: apiAccount.account.access_groups['tickets/journals'],
  },
  active: apiAccount.account.active,
  email: apiAccount.account.email,
  externalId: apiAccount.account.external_id,
  fullName: apiAccount.account.full_name,
  id: apiAccount.account.id,
  login: apiAccount.account.login,
  loginOption: apiAccount.account.login_option,
  correspondenceEmail: apiAccount.account.profile?.correspondence_email,
  phone: apiAccount.account.profile?.phone,
  addressStreet: apiAccount.account.profile?.address_street,
  addressPostalCode: apiAccount.account.profile?.address_postal_code,
  addressCity: apiAccount.account.profile?.address_city,
  emailAgreement: apiAccount.account.profile?.email_agreement,
  roles: apiAccount.account.roles.map((apiRole) => translateRole(apiRole)),
  permissions: apiAccount.account.permissions,
});

export const translateUserAccounts = (apiAccounts: ApiUserAccountsResponse): Accounts => ({
  metadata: apiAccounts.metadata,
  accounts: apiAccounts.accounts.map((account) => translateUserAccount(account)),
});

export const translateUserPasswordToApi = (newPasswordData: NewPasswordData): ApiPostNewPassword => ({
  password: newPasswordData.newPassword,
  password_confirmation: newPasswordData.confirmNewPassword,
});

export const translateMyPasswordToApi = (newPasswordData: MyNewPasswordData): ApiPostMyNewPassword => ({
  ...translateUserPasswordToApi(newPasswordData),
  old_password: newPasswordData.oldPassword,
});

export const translateApiToRoles = (apiRoles: ApiRoles): Role[] => apiRoles.roles.map((role) => translateRole(role));

export const translateUserFormToPutRequest = (formData: ChangeUserFormData): ApiPutUserAccount => ({
  id: formData.id,
  email: formData.email,
  full_name: formData.fullName,
  login: formData.login,
  active: formData.active,
  phone: formData.phone,
  role_ids: formData.roleIds,
  access_groups: {
    'tenants/homeowner_associations': formData.homeownerAssociationIds,
    'tenants/buildings': formData.buildingsIds,
    'tenants/estates': formData.estatesIds,
    'tickets/journals': formData.journalsIds,
  },
});

export const translateUserFormToPostRequest = (formData: CreateUserFormData): ApiPostUserAccount => ({
  email: formData.email,
  full_name: formData.fullName,
  login: formData.login,
  external_id: formData.externalId,
  phone: formData.phone,
  role_ids: formData.roleIds,
  access_groups: {
    'tenants/homeowner_associations': formData.homeownerAssociationIds,
    'tenants/buildings': formData.buildingsIds,
    'tenants/estates': formData.estatesIds,
    'tickets/journals': formData.journalsIds,
  },
});

export const translateAccessGroups = (response: ApiAccessGroupResponse): AccessGroup[] => response.access_groups;
