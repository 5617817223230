/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { EmployeeShowTicketData } from '../../../api/modules/tickets/ticketsTypes';
import useMessages from '../../../hooks/useMessages';
import {
  useGetEmployeesQuery,
  useGetTaskCategoriesQuery,
  useGetFullTaskStatusesQuery,
  usePostTaskForDocumentMutation,
  usePostTaskForTicketMutation,
} from '../../../api/appApi';
import {
  FormDatePicker, FormFileInput,
  FormInput, FormSelect, FormTextarea,
} from '../../../components/FormInputs';
import Button from '../../../components/Button/Button';
import Card from '../../../components/Card/Card';
import {
  FullTaskStatus, TaskCategory, TaskInputs,
} from '../../../api/modules/tasks/tasksTypes';
import FormSearchableDropdown from '../../../components/FormInputs/FormSearchableDropdown';
import FileUploadPreview from '../../../components/AttachmentPreview/FileUploadPreview/FileUploadPreview';
import { translateTaskToFormData } from '../../../api/modules/tasks/tasksTranslator';
import useHomeownerAssociationsMode from '../../../hooks/useHomeownerAssociationsMode';
import { FormNotice } from '../../../components/FormNotice/FormNotice';
import capitalize from '../../../../utils/formatters';
import { FullDocument } from '../../../api/modules/documents/documentsTypes';

interface Props {
  ticket?: EmployeeShowTicketData,
  document?: FullDocument,
}

const NewTaskForm = ({ ticket, document }: Props) => {
  const getMessage = useMessages();
  const hoaMode = useHomeownerAssociationsMode();
  const [postTaskDataForTicket, ticketResult] = usePostTaskForTicketMutation();
  const [postTaskDataForDocument, documentResult] = usePostTaskForDocumentMutation();
  const { data: categories } = useGetTaskCategoriesQuery();
  const { data: statuses } = useGetFullTaskStatusesQuery();
  const navigate = useNavigate();

  const methods = useForm<TaskInputs>({
    defaultValues: {
      attachments: [{ description: '' }],
      tenant: ticket?.tenantName || document?.tenant?.name || '',
      venue: ticket?.venueAddress || '',
      homeownerAssociation: ticket?.homeownerAssociationName || '',
      ticket_id: ticket?.id,
      document_id: document?.id,
    },
  });

  useEffect(() => {
    methods.setValue('status_id', statuses?.find((status) => status.name === 'open')?.id || '');
  }, [statuses]);

  const { fields, append, remove } = useFieldArray({ name: 'attachments', control: methods.control });

  const attachments = methods.watch('attachments');
  const allAttachmentsSelected = attachments.every((attachment) => attachment.file?.length);

  useEffect(() => {
    if (allAttachmentsSelected) {
      append({ file: undefined, description: '' });
    }
  }, [allAttachmentsSelected]);

  useEffect(() => {
    if (ticketResult.status === 'fulfilled' || documentResult.status === 'fulfilled') {
      navigate('/zadania');
    }
  }, [ticketResult, documentResult]);

  const statusSelectOptions = useMemo(() => (statuses || []).map((status: FullTaskStatus) => ({
    value: status.id,
    optionLabel: capitalize(status.externalName),
  })), [statuses]);

  const categorySelectOptions = useMemo(() => (categories || []).map((category: TaskCategory) => ({
    value: category.id,
    optionLabel: category.name,
  })), [categories]);

  const onSubmit = (data: TaskInputs) => {
    if (data.ticket_id) {
      postTaskDataForTicket({ id: data.ticket_id, task: translateTaskToFormData(data) });
    } else if (data.document_id) {
      postTaskDataForDocument({ id: data.document_id, task: translateTaskToFormData(data) });
    }
  };

  const estimatedStartTime = methods.watch('estimated_start_time');
  const estimatedFinishTime = methods.watch('estimated_finish_time');
  const startTime = methods.watch('started_at');
  const finishTime = methods.watch('finished_at');

  return (
    <Card className="col-span-12 grid-cols-12">
      {/* @ts-ignore */}
      {ticketResult.error && <FormNotice type="error" message={ticketResult.error.data?.errors || getMessage('form.error')} />}
      {/* @ts-ignore */}
      {documentResult.error && <FormNotice type="error" message={documentResult.error.data?.errors || getMessage('form.error')} />}
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} className="grid grid-cols-12 col-span-12">
          { ticket && (
            <div className="col-span-12 text-header-sm mb-8">
              {`${getMessage('tasks.newTask.taskToTicket')} ${ticket.ticketNumber} ${ticket.title}`}
            </div>
          )}
          { document && (
            <div className="col-span-12 text-header-sm mb-8">
              {`${getMessage('tasks.newTask.taskToDocument')} ${document.documentNumber} ${document.title}`}
            </div>
          )}
          <div className="border-b col-span-12 grid grid-cols-12 pb-4">
            <section className="col-span-12 md:col-span-6">
              <FormSelect
                className="mt-4"
                id="category_id"
                label={`${getMessage('tickets.ticket.category')}*`}
                selectOptions={categorySelectOptions}
                options={{ required: getMessage('tasks.newTask.category.error.required') }}
              />
              <FormInput
                className="mt-4 w-full"
                inputClassName="w-full"
                id="title"
                type="text"
                label={`${getMessage('tasks.newTask.title')}*`}
                options={{ required: getMessage('tasks.newTask.title.error.required'), maxLength: { value: 150, message: getMessage('tasks.newTask.title.error.maxLength') } }}
              />
              <FormTextarea
                className="mt-6 w-full"
                inputClassName="w-full"
                id="description"
                label={`${getMessage('tasks.newTask.description')}*`}
                options={{ required: getMessage('tasks.newTask.description.error.required') }}
              />
              {fields.map(({ file, id }, index) => {
                const fileSelected = file && file.length > 0;
                return (
                  <div key={id} id={id} className="flex align-start mb-4 w-full">
                    {fileSelected && (
                      <FileUploadPreview attachment={attachments[index]} onDelete={() => remove(index)} />
                    )}
                    <div className="w-full">
                      {fileSelected && (
                        <FormInput
                          className="w-full"
                          inputClassName="text-xs w-full"
                          id={`attachments.${index}.description`}
                          type="text"
                          placeholder={getMessage('tickets.ticket.addAttachmentDescription')}
                        />
                      )}
                      <FormFileInput
                        inputClassName={fileSelected ? 'hidden' : ''}
                        id={`attachments.${index}.file`}
                        labelText={getMessage('tickets.ticket.addAttachment')}
                        labelClassName={fileSelected ? 'hidden' : ''}
                        withIcon
                      />
                    </div>
                  </div>
                );
              })}
            </section>
            <section className="col-span-12 md:col-span-2 md:col-start-10">
              <FormSelect
                className="mt-4"
                id="status_id"
                label={getMessage('tickets.ticket.status')}
                inline
                selectOptions={statusSelectOptions}
                options={{ required: getMessage('tasks.newTask.status.error.required') }}
              />
              <FormSearchableDropdown
                label={getMessage('tickets.ticket.assignedTo')}
                className="mt-4 w-full"
                id="realizer_id"
                query={useGetEmployeesQuery}
                ariaLabel={getMessage('form.ariaLabel.realizer')}
              />
            </section>
          </div>
          <div className="col-span-12 grid grid-cols-12 mt-4">
            <section className="col-span-12 md:col-span-6">
              <FormInput
                className="mt-4 w-full"
                inputClassName="w-full"
                id="tenant"
                type="text"
                disabled
                label={getMessage('tasks.newTask.tenant')}
              />
              <FormInput
                className="mt-4 w-full"
                inputClassName="w-full"
                id="venue"
                type="text"
                disabled
                label={getMessage('tasks.newTask.venue')}
              />
              {hoaMode && (
                <FormInput
                  className="mt-4 w-full"
                  inputClassName="w-full"
                  id="homeownerAssociation"
                  type="text"
                  disabled
                  label={getMessage('tasks.newTask.homeownerAssociation')}
                />
              )}
            </section>
            <section className="col-span-12 md:col-span-2 md:col-start-10">
              <FormDatePicker
                className="mt-4 w-full"
                id="estimated_start_time"
                label={getMessage('tasks.newTask.estimated_start_time')}
                maxDate={estimatedFinishTime ? new Date(estimatedFinishTime) : undefined}
              />
              <FormDatePicker
                className="mt-4 w-full"
                id="estimated_finish_time"
                label={getMessage('tasks.newTask.estimated_finish_time')}
                minDate={estimatedStartTime ? new Date(estimatedStartTime) : undefined}
              />
              <FormDatePicker
                className="mt-4 w-full"
                id="started_at"
                label={getMessage('tasks.newTask.started_at')}
                maxDate={finishTime ? new Date(finishTime) : undefined}
              />
              <FormDatePicker
                className="mt-4 w-full"
                id="finished_at"
                label={getMessage('tasks.newTask.finished_at')}
                minDate={startTime ? new Date(startTime) : undefined}
              />
            </section>
          </div>
          <div className="col-span-12 col-start-1">
            <Button type="submit" className="mt-6">{getMessage('save')}</Button>
          </div>
        </form>
      </FormProvider>
    </Card>
  );
};

export default NewTaskForm;
