import * as React from 'react';
import { useIntl } from 'react-intl';
import { useMemo } from 'react';
import { FullTask, Task } from '../../../../api/modules/tasks/tasksTypes';
import { FullDocument } from '../../../../api/modules/documents/documentsTypes';
import useMessages from '../../../../hooks/useMessages';

interface Props {
  task: FullTask,
  document: FullDocument,
}

const ConnectedDocumentSection = ({ task, document }: Props) => {
  const intl = useIntl();
  const getMessage = useMessages();

  const connectedTasks = useMemo(
    () => document?.tasks?.filter((documentsTask: Task) => documentsTask.id !== task.id) || [],
    [document, task],
  );

  return (
    <>
      <section>
        <table className="text-text-md-semibold text-typography-dark w-full mt-4 tablet:mt-0">
          <tbody>
            <tr>
              <td
                colSpan={2}
                className="pt-2 tablet:pt-0"
              >
                <a
                  className="text-main cursor-pointer underline hover:text-hover"
                  href={`/dokumenty/${document.id}`}
                  aria-label={intl.formatMessage(
                    { id: 'tasks.task.goToDocument' },
                    { documentNumber: document.documentNumber },
                  )}
                >
                  {`${getMessage('tasks.task.document')} nr ${document.documentNumber}`}
                </a>
              </td>
            </tr>
            <tr>
              <td className="pt-2">{getMessage('tasks.task.document.title')}</td>
              <td className="text-text-md-medium pt-2 text-right">{document.title}</td>
            </tr>
            {document?.creator?.name && (
              <tr>
                <td className="pt-2">{getMessage('tasks.task.document.creator')}</td>
                <td className="text-text-md-medium pt-2 text-right">{document.creator.name}</td>
              </tr>
            )}
            {document?.journal?.name && (
              <tr>
                <td className="pt-2">{getMessage('tasks.task.document.journal')}</td>
                <td className="text-text-md-medium pt-2 text-right">{document.journal.name}</td>
              </tr>
            )}
          </tbody>
        </table>
      </section>
      {connectedTasks && connectedTasks.length > 0 && (
        <section className="text-typography-dark mb-4 lg:mb-0">
          <p className="border-t tablet:border-none mt-4 pt-4 tablet:pt-0 tablet:mt-0 text-text-md-semibold">
            {intl.formatMessage(
              { id: 'documents.document.relatedTasks' },
              { tasksCount: connectedTasks.length },
            )}
          </p>
          {connectedTasks.map((task) => (
            <a
              key={task.id}
              className="underline cursor-pointer text-text-md-medium block hover:text-hover mobile:select-none"
              href={`/zadania/${task.id}`}
              tabIndex={0}
              aria-label={intl.formatMessage(
                { id: 'tasks.task.goToTask' },
                { taskNumberTitle: `${task.taskNumber} ${task.title}` },
              )}
            >
              {`${task.taskNumber} ${task.title}`}
            </a>
          ))}
        </section>
      )}
    </>
  );
};

export default ConnectedDocumentSection;
