import * as React from 'react';
import useMessages from '../../../hooks/useMessages';
import { usePermissions } from '../../../hooks/usePermissions';
import Layout from '../../../components/Layout/Layout';
import PageHeader from '../../../components/Layout/PageHeader';
import ContentWrapper from '../../../components/ContentWrapper/ContentWrapper';
import Card from '../../../components/Card/Card';
import DocumentForm from './DocumentForm';

const DocumentFormPage = () => {
  const getMessage = useMessages();
  const { checkPermission, checkModuleEnabled } = usePermissions();

  checkModuleEnabled('documents');
  checkPermission('documents_add');

  return (
    <Layout currentPage="documents">
      <PageHeader pageTitle={getMessage('documents.newDocument')} currentPage="documents" path="/dokumenty" />
      <ContentWrapper>
        <Card className="col-span-full">
          <DocumentForm />
        </Card>
      </ContentWrapper>
    </Layout>
  );
};

export default DocumentFormPage;
