import * as React from 'react';
import { useIntl } from 'react-intl';
import { useMatch, useParams } from 'react-router';
import { useState } from 'react';
import MetersCard from './EmployeeMetersCard/EmployeeMetersCard';
import { useGetMetersQuery } from '../../../../api/appApi';
import { translateApiToMeterGroups } from '../../../../api/modules/meters/metersTranslator';
import useMessages from '../../../../hooks/useMessages';
import { usePermissions } from '../../../../hooks/usePermissions';
import Layout from '../../../../components/Layout/Layout';
import ContentWrapper from '../../../../components/ContentWrapper/ContentWrapper';
import PageHeader from '../../../../components/Layout/PageHeader';
import DisabledCard from './EmployeeMetersCard/EmployeeDisabledCard';
import MeterListDesktopInfoCard from './MeterListInfoCard/MeterListDesktopInfoCard';
import MeterListMobileInfoCard from './MeterListInfoCard/MeterListMobileInfoCard';
import { FormNotice } from '../../../../components/FormNotice/FormNotice';

const EmployeeMetersListPage = () => {
  const params = useParams();
  const { id } = params;
  const match = useMatch('/liczniki/lokale/:id/do-odczytu');

  const { data } = useGetMetersQuery({ venues_ids: id, measurement_required: !!match || undefined });
  const [displayMeasurementSentNotice, setDisplayMeasurementSentNotice] = useState(false);

  const { checkPermission, checkModuleEnabled } = usePermissions();
  const getMessage = useMessages();
  const intl = useIntl();
  const metersGroups = data ? translateApiToMeterGroups(data, intl) : undefined;

  checkPermission('meters_list');
  checkModuleEnabled('meters');

  checkPermission('meters_list');

  const displayFormSuccessNotice = () => setDisplayMeasurementSentNotice(true);

  return (
    <Layout currentPage="local.meters">
      <PageHeader
        pageTitle={getMessage('navigation.local.meters')}
        currentPage="local.meters"
        path="/liczniki/lokale"
      />
      {id && <MeterListMobileInfoCard id={id} />}
      <ContentWrapper>
        {metersGroups && (
          <div className="col-span-full lg:col-span-9">
            {displayMeasurementSentNotice && <FormNotice noticeClassName="col-span-full" type="success" message={getMessage('local.meters.measurementSent')} />}
            {metersGroups.length === 0 ? (
              getMessage('local.meters.noMeters')
            ) : (
              metersGroups.map((metersGroup) => (match
                ? <MetersCard key={`g-${metersGroup[0].meterId}`} meterGroup={metersGroup} onSuccess={displayFormSuccessNotice} />
                : <DisabledCard key={`g-${metersGroup[0].meterId}`} meterGroup={metersGroup} />))
            )}
          </div>
        )}
        {id && <MeterListDesktopInfoCard id={id} />}
      </ContentWrapper>
    </Layout>
  );
};

export default EmployeeMetersListPage;
