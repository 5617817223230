import * as React from 'react';
import { useMemo, useState } from 'react';
import { usePermissions } from '../../hooks/usePermissions';
import Tabs, { TabElement } from '../../components/Tabs/Tabs';
import useMessages from '../../hooks/useMessages';
import Layout from '../../components/Layout/Layout';
import PageHeader from '../../components/Layout/PageHeader';
import ContentWrapper from '../../components/ContentWrapper/ContentWrapper';
import TasksList from './TasksList/TasksList';
import TasksFilters from './TasksFilters/TasksFilters';
import FiltersIcon from '../../icons/filters.svg';
import Button from '../../components/Button/Button';
import { useAppSelector } from '../../hooks/reduxHooks';

interface Props {
  tab?: 'unassigned' | 'open'
}

const TasksPage = ({ tab }: Props) => {
  const getMessage = useMessages();
  const { hasPermission, checkPermission, checkModuleEnabled } = usePermissions();
  const hasFilterSet = useAppSelector((state) => !!state.app.filters.find((filter) => filter.id === 'tasksTable'));
  const [filtersOpen, setFiltersOpen] = useState(hasFilterSet);

  checkModuleEnabled('tickets');

  const tabs: TabElement[] = useMemo(() => {
    if (hasPermission('tasks_list')) {
      return [
        {
          label: getMessage('tasks.tabs.open'),
          path: '/zadania/otwarte',
        },
        {
          label: getMessage('tasks.tabs.unassigned'),
          path: '/zadania/nieprzypisane',
        }, {
          label: getMessage('tasks.tabs.all'),
          path: '/zadania/',
        },
      ];
    }

    return [];
  }, [hasPermission]);

  checkPermission('tasks_list');

  return (
    <Layout currentPage="tasks">
      <PageHeader pageTitle={getMessage('tasks.header')} currentPage="tasks">
        <Button
          Icon={FiltersIcon}
          styleType="secondary"
          size="small"
          onClick={() => setFiltersOpen((filtersOpen) => !filtersOpen)}
          className="mx-3 py-1 appearance-none"
        >
          {getMessage('filters')}
        </Button>
      </PageHeader>
      <Tabs tabElements={tabs} />
      {filtersOpen && <TasksFilters open={tab === 'open'} />}
      <ContentWrapper className="md:grid-rows-[auto_minmax(200px,_max-content)_auto]">
        <TasksList prefilter={tab} />
      </ContentWrapper>
    </Layout>
  );
};

export default TasksPage;
