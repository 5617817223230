import * as React from 'react';
import { useController } from 'react-hook-form';
import classnames from 'classnames';
import DatePicker, { DateObject } from 'react-multi-date-picker';
import { useMonthsAndWeekDays } from '../../../hooks/useMessages';
import { InputLabel } from '../InputLabel';

const inputDefaultStyles = `inline-block px-2 py-1 font-normal text-typography-black bg-white bg-clip-padding
border border-solid border-stroke-grey-300 rounded transition ease-in-out my-1 text-text-md-semibold
mobile:text-base focus:text-typography-black focus:bg-white focus:border-blue-600 focus:outline-none`;

interface FormDatePickerProps {
  id: string,
  className?: string,
  inputClassName?: string,
  containerClassName?: string,
  label?: string,
  required?: boolean,
  minDate?: Date,
  maxDate?: Date,
}

const FormDatePicker = ({
  id, className, label, inputClassName, required, minDate, maxDate, containerClassName,
} : FormDatePickerProps) => {
  const { months, weekDays } = useMonthsAndWeekDays();
  const { field, fieldState } = useController({name: id});

  return (
    <div className={classnames('flex-col items-start inline-block', className)}>
      {label && <InputLabel id={id} label={label} />}
      <DatePicker
        value={new Date(field.value) || ''}
        onChange={(date: DateObject) => field.onChange(date?.format('YYYY-MM-DD'))}
        name={id}
        format="DD-MM-YYYY"
        inputClass={classnames(inputDefaultStyles, inputClassName)}
        containerClassName={containerClassName}
        className="red"
        months={months}
        weekDays={weekDays}
        weekStartDayIndex={1}
        required={required}
        minDate={minDate}
        maxDate={maxDate}
        id={id}
      />
      {fieldState.error && <p>{fieldState.error.message}</p>}
    </div>
  );
};

export default FormDatePicker;
