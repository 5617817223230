import * as React from 'react';
import { useState } from 'react';
import { useGetFullUserAccountQuery } from '../../../api/appApi';
import Card from '../../../components/Card/Card';
import ExpandableCard from '../../../components/ExpandableCard/ExpandableCard';
import { useSettlementsVenue } from '../../../hooks/settlements/useSettlementsVenue';
import PaymentsHistoryTable from './PaymentsHistoryTable';
import Tabs, { TabElement } from '../../../components/Tabs/Tabs';
import PageHeader from '../../../components/Layout/PageHeader';
import ContentWrapper from '../../../components/ContentWrapper/ContentWrapper';
import useMessages from '../../../hooks/useMessages';
import { usePermissions } from '../../../hooks/usePermissions';
import { useAppSelector } from '../../../hooks/reduxHooks';
import FiltersIcon from '../../../icons/filters.svg';
import Button from '../../../components/Button/Button';
import PaymentsHistoryFilters from './PaymentsHistoryFilters';

interface Props {
  tabs: TabElement[],
}

const PaymentsHistoryPage = ({ tabs }: Props) => {
  const getMessage = useMessages();
  const hasFilterSet = useAppSelector((state) => !!state.app.filters.find((filter) => filter.id === 'paymentsHistoryTable'));
  const [filtersOpen, setFiltersOpen] = useState(hasFilterSet);
  const { hasRoleType } = usePermissions();
  const { data: user } = useGetFullUserAccountQuery();
  const { venueId, venueAddress } = useSettlementsVenue();

  const { checkModuleEnabled } = usePermissions();
  checkModuleEnabled('settlements');
  checkModuleEnabled('payments');

  return (
    <>
      <PageHeader
        pageTitle={getMessage('navigation.settlements.history')}
        currentPage="settlements"
        path={hasRoleType('worker') ? '/rozrachunki/lokale/aktywne' : undefined}
        subheader={venueAddress}
      >
        <Button
          Icon={FiltersIcon}
          size="small"
          styleType="secondary"
          onClick={() => setFiltersOpen((filtersOpen) => !filtersOpen)}
          className="h-8 py-1"
        >
          {getMessage('filters')}
        </Button>
      </PageHeader>
      <Tabs tabElements={tabs} />
      {filtersOpen && <PaymentsHistoryFilters />}
      <ContentWrapper>
        {venueId ? (
          <Card className="col-span-12">
            <PaymentsHistoryTable venueId={venueId} />
          </Card>
        ) : (user?.tenant?.venues || []).map((venue) => (
          <ExpandableCard className="col-span-12" title={venue.address} key={venue.id}>
            <PaymentsHistoryTable venueId={venue.id} />
          </ExpandableCard>
        ))}
      </ContentWrapper>
    </>
  );
};

export default PaymentsHistoryPage;
