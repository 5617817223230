/* eslint-disable react/jsx-props-no-spreading */

import * as React from 'react';
import { useNavigate } from 'react-router';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useEffect, useMemo, useState } from 'react';
import useMessages from '../../../hooks/useMessages';
import {
  useGetDocumentsCategoriesQuery,
  useGetFullDocumentStatusesQuery,
  useGetEmployeesQuery,
  useGetFullJournalsQuery,
  useGetTenantsQuery,
  usePostDocumentMutation,
} from '../../../api/appApi';
import capitalize from '../../../../utils/formatters';
import { FormNotice } from '../../../components/FormNotice/FormNotice';
import {
  FormFileInput, FormInput, FormSelect, FormTextarea,
} from '../../../components/FormInputs';
import FormSearchableDropdown from '../../../components/FormInputs/FormSearchableDropdown';
import FileUploadPreview from '../../../components/AttachmentPreview/FileUploadPreview/FileUploadPreview';
import Button from '../../../components/Button/Button';
import { DocumentFormData } from '../../../api/modules/documents/documentsTypes';
import { translateDocumentToFormData } from '../../../api/modules/documents/documentsTranslator';

const DocumentForm = () => {
  const getMessage = useMessages();
  const navigate = useNavigate();
  const [postDocumentData, result] = usePostDocumentMutation();
  const { data: documentCategories } = useGetDocumentsCategoriesQuery();
  const { data: documentStatuses } = useGetFullDocumentStatusesQuery();
  const { data: journalsResponse } = useGetFullJournalsQuery({ items: '200' });

  const [autoNumbering, setAutoNumbering] = useState(false);

  const methods = useForm<DocumentFormData>({
    defaultValues: {
      attachments: [{ description: '' }],
    },
  });

  useEffect(() => {
    methods.setValue('statusId', documentStatuses?.find((status) => status.name === 'open')?.id);
  }, [documentStatuses]);

  const { fields, append, remove } = useFieldArray({ name: 'attachments', control: methods.control });

  const attachments = methods.watch('attachments');
  const allAttachmentsSelected = attachments.every((attachment) => attachment.file?.length);

  useEffect(() => {
    if (allAttachmentsSelected) {
      append({ file: undefined, description: '' });
    }
  }, [allAttachmentsSelected]);

  const onSubmit = (data: DocumentFormData) => {
    postDocumentData(translateDocumentToFormData(data));
  };

  useEffect(() => {
    if (result.status === 'fulfilled') navigate('/dokumenty');
  }, [result.status]);

  const categorySelectOptions = useMemo(() => documentCategories?.map(({ id, name }) => ({
    value: id,
    optionLabel: capitalize(name),
  })), [documentCategories]);

  const statusSelectOptions = useMemo(() => documentStatuses?.map(({ id, externalName }) => ({
    value: id,
    optionLabel: capitalize(externalName),
  })) || [], [documentStatuses]);

  const journalSelectOptions = useMemo(() => journalsResponse?.journals.map(({ id, name }) => ({
    value: id,
    optionLabel: capitalize(name),
  })) || [], [journalsResponse]);

  const selectedJournalId = methods.watch('journalId');

  useEffect(() => {
    const autoNumbering = journalsResponse?.journals.find((journal) => journal.id === selectedJournalId)?.autonumbering || false;
    setAutoNumbering(autoNumbering);
    if (autoNumbering) {
      methods.setValue('number', '');
    }
  }, [selectedJournalId]);

  return (
    <FormProvider {...methods}>
      { /* @ts-ignore */ }
      {result.isError && <FormNotice type="error" message={result.error?.data?.errors || getMessage('form.error')} />}
      <form onSubmit={methods.handleSubmit(onSubmit)} className="grid grid-cols-12 col-span-12">
        <section className="col-span-12 md:col-span-6">
          <FormInput
            className="mt-4 w-full"
            inputClassName="w-full"
            id="title"
            type="text"
            label={`${getMessage('documents.document.title')}*`}
            options={{ required: getMessage('documents.document.error.title.required'), maxLength: { value: 150, message: getMessage('documents.document.error.title.maxLength') } }}
          />
          {journalSelectOptions && (
            <FormSelect
              className="mt-4"
              id="journalId"
              label={`${getMessage('documents.document.journal')}*`}
              inline
              selectOptions={journalSelectOptions}
              options={{ required: getMessage('documents.document.error.journal.required') }}
            />
          )}
          <FormInput
            className="mt-4 w-full"
            inputClassName="w-full"
            id="number"
            type="text"
            label={`${getMessage('documents.document.documentNumber')}*`}
            disabled={autoNumbering}
            placeholder={autoNumbering ? getMessage('documents.document.documentNumber.auto') : ''}
            options={{
              validate: (value) => {
                if (!autoNumbering && !value) {
                  return getMessage('documents.document.error.number.required');
                }
                return true;
              },
            }}
          />
          <FormInput
            className="mt-4 w-full"
            inputClassName="w-full"
            id="sender"
            type="text"
            label={getMessage('documents.document.sender')}
          />
          <FormInput
            className="mt-4 w-full"
            inputClassName="w-full"
            id="receiver"
            type="text"
            label={getMessage('documents.document.receiver')}
          />
          <FormSearchableDropdown
            label={getMessage('documents.document.tenant')}
            className="mt-4 w-full"
            id="tenantId"
            query={useGetTenantsQuery}
            ariaLabel="Wyszukaj lokatora"
          />
          <FormTextarea
            className="mt-6 w-full"
            inputClassName="w-full"
            id="description"
            label={`${getMessage('documents.document.description')}*`}
            options={{ required: getMessage('documents.document.error.description.required') }}
          />
          {fields.map(({ file, id }, index) => {
            const fileSelected = file && file.length > 0;
            return (
              <div key={id} id={id} className="flex align-start mb-4 w-full">
                {fileSelected && (
                  <FileUploadPreview attachment={attachments[index]} onDelete={() => remove(index)} />
                )}
                <div className="w-full">
                  {fileSelected && (
                    <FormInput
                      className="w-full"
                      inputClassName="text-xs w-full"
                      id={`attachments.${index}.description`}
                      type="text"
                      placeholder={getMessage('documents.document.addAttachmentDescription')}
                    />
                  )}
                  <FormFileInput
                    inputClassName={fileSelected ? 'hidden' : ''}
                    id={`attachments.${index}.file`}
                    labelText={getMessage('documents.document.addAttachment')}
                    labelClassName={fileSelected ? 'hidden' : ''}
                    withIcon
                  />
                </div>
              </div>
            );
          })}
        </section>
        <section className="col-span-12 md:col-span-2 md:col-start-8">
          {statusSelectOptions && (
            <FormSelect
              className="mt-4"
              id="statusId"
              label={`${getMessage('documents.document.status')}*`}
              inline
              selectOptions={statusSelectOptions}
              options={{ required: getMessage('documents.document.error.status.required') }}
            />
          )}
          {categorySelectOptions && (
            <FormSelect
              className="mt-4"
              id="categoryId"
              label={`${getMessage('documents.document.category')}*`}
              inline
              selectOptions={categorySelectOptions}
              options={{ required: getMessage('documents.document.error.category.required') }}
            />
          )}
          <FormSearchableDropdown
            label={getMessage('documents.document.assignedTo')}
            className="mt-4 w-full"
            id="realizer_id"
            query={useGetEmployeesQuery}
            ariaLabel={getMessage('form.ariaLabel.realizer')}
          />
        </section>
        <div className="col-span-12 col-start-1">
          <Button type="submit" className="mt-6">{getMessage('save')}</Button>
        </div>
      </form>
    </FormProvider>
  );
};

export default DocumentForm;
