import { isRejectedWithValue } from '@reduxjs/toolkit';
import type { Middleware } from '@reduxjs/toolkit';

/**
 * Log a warning and show a toast!
 */
export const errorHandlingMiddleware: Middleware = () => (next) => (action) => {
  // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
  if (isRejectedWithValue(action)) {
    if (action?.payload?.status === 401) {
      window.location.href = '/accounts/sign_in';
    }
    if (action?.payload?.originalStatus === 404 || action?.payload?.status === 404) {
      window.location.href = '/404';
    }
  }

  return next(action);
};
