import * as React from 'react';
import { useState, PropsWithChildren } from 'react';
import { Document, Page } from 'react-pdf';

interface Props {
  source: string | File,
  pageToDisplay?: number,
  width?: number,
  height?: number,
  scale?: number,
  className?: string,
  renderTextAndAnnotations?: boolean,
}

const PdfPreviewer = ({
  source, pageToDisplay, width, height, scale, className, children, renderTextAndAnnotations,
}: PropsWithChildren<Props>) => {
  const [numPages, setNumPages] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  // provide http headers for already attached pdfs in order to keep their resizing smooth
  const file = source instanceof File
    ? source
    : { url: source, httpHeaders: { 'cache-control': 'no-cache' } };

  return (
    <Document
      file={file}
      className={`w-full ${className}`}
      onLoadSuccess={onDocumentLoadSuccess}
    >
      {pageToDisplay ? (
        <Page
          pageNumber={pageToDisplay}
          width={width}
          height={height}
          scale={scale}
          renderAnnotationLayer={renderTextAndAnnotations}
          renderTextLayer={renderTextAndAnnotations}
          className="shadow-lg"
        />
      )
        : Array.from(new Array(numPages), (el, index) => index).map((el, index) => (
          <Page
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            width={width}
            height={height}
            scale={scale}
            renderAnnotationLayer={renderTextAndAnnotations}
            renderTextLayer={renderTextAndAnnotations}
            className="shadow-lg my-4"
          />
        ))}
      {children}
    </Document>
  );
};

export default PdfPreviewer;
