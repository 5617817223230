import * as React from 'react';
import { useParams } from 'react-router-dom';
import PageHeader from '../../../components/Layout/PageHeader';
import ContentWrapper from '../../../components/ContentWrapper/ContentWrapper';
import Layout from '../../../components/Layout/Layout';
import useMessages from '../../../hooks/useMessages';
import { useGetDocumentQuery, useGetTicketQuery } from '../../../api/appApi';
import { usePermissions } from '../../../hooks/usePermissions';
import NewTaskForm from './NewTaskForm';
import { EmployeeShowTicketData } from '../../../api/modules/tickets/ticketsTypes';

interface Props {
  taskType: 'ticket' | 'document'
}

const TaskFormPage = ({ taskType }: Props) => {
  const getMessage = useMessages();
  const { checkPermission, checkModuleEnabled } = usePermissions();
  const { id } = useParams();
  const { data: ticketData } = useGetTicketQuery(id, { skip: taskType !== 'ticket' });
  const { data: documentData } = useGetDocumentQuery(id, { skip: taskType !== 'document' });

  checkPermission('tasks_add');
  checkModuleEnabled('tickets');

  return (
    <Layout currentPage="tasks">
      <PageHeader
        pageTitle={getMessage('tasks.newTask')}
        currentPage="tasks"
        path={`${taskType === 'ticket' ? `/zgloszenia/${id}` : `/dokumenty/${id}`}`}
      />
      <ContentWrapper>
        {(ticketData || documentData) && (
          <NewTaskForm ticket={ticketData as EmployeeShowTicketData} document={documentData} />
        )}
      </ContentWrapper>
    </Layout>
  );
};

export default TaskFormPage;
