import * as React from 'react';
import { useRef, useState } from 'react';
import { NavigationRoutes } from '../../Layout/Layout';
import TopBarHeader from './TopBarHeader/TopBarHeader';
import Navigation from '../Navigation';
import useClickOutside from '../../../hooks/useClickOutside';

interface Props {
  currentPage?: NavigationRoutes,
  pageTitle: string | undefined,
  path?: string,
}

const TopBar = ({ currentPage, pageTitle, path }: Props) => {
  const [topBarOpen, setTopBarOpen] = useState(false);
  const openedMenuRef = useRef(null);
  const headerRef = useRef(null);
  useClickOutside(openedMenuRef, () => setTopBarOpen(false), [headerRef]);

  return (
    <>
      <TopBarHeader
        pageTitle={pageTitle}
        onClick={() => setTopBarOpen((open) => !open)}
        ref={headerRef}
        path={path}
      />
      {topBarOpen && (
        <>
          <div ref={openedMenuRef} className="top-[50px] tablet:top-[80px] lg:hidden z-50 bg-background-light flex-col w-full border-r text-gray-800 flex-shrink-0 absolute">
            <Navigation currentPage={currentPage} closeNavigation={() => setTopBarOpen(false)} />
          </div>
          <div className="fixed z-40 top-0 left-0 w-full h-full bg-gray-800 opacity-70" />
        </>
      )}
    </>
  );
};

export default TopBar;
