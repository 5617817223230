import * as React from 'react';
import AnnouncementsWidget from './Widgets/AnnouncementsWidget';
import TicketsWidget from './Widgets/TicketsWidget';
import SettlementsWidget from './Widgets/SettlementsWidget';
import { usePermissions } from '../../hooks/usePermissions';
import ContentWrapper from '../../components/ContentWrapper/ContentWrapper';
import MetersWidget from './Widgets/MetersWidget';
import ResolutionsWidget from './Widgets/ResolutionsWidget';

const TenantDashboardPage = () => {
  const { hasPermission, isModuleEnabled } = usePermissions();

  return (
    <ContentWrapper>
      <div className="col-span-12 lg:col-span-4 lg:col-start-9 lg:row-start-1 lg:row-span-1 flex flex-wrap lg:flex-none gap-y-6">
        {isModuleEnabled('settlements') && hasPermission('settlements_balance_list') && <SettlementsWidget />}
        {isModuleEnabled('meters') && hasPermission('meters_measurement_add') && <MetersWidget />}
        {isModuleEnabled('resolutions') && hasPermission('resolutions_vote_add') && <ResolutionsWidget />}
      </div>
      <div className="col-span-12 col-start-1 lg:col-span-8 lg:col-start-1 lg:row-start-1 lg:row-span-2">
        {hasPermission('announcements_list') && <AnnouncementsWidget />}
        {isModuleEnabled('tickets') && hasPermission('tickets_add') && <TicketsWidget />}
      </div>
    </ContentWrapper>
  );
};

export default TenantDashboardPage;
