import * as React from 'react';
import { useCallback, useEffect } from 'react';
import usePagination from '../../../../hooks/usePagination';
import { useGetResolutionsQuery } from '../../../../api/appApi';
import Card from '../../../../components/Card/Card';
import ResolutionItem from '../../ResolutionItem/ResolutionItem';
import PaginationControls from '../../../../components/Table/PaginationControls';
import { FilterValues } from '../../../../store/appSlice';
import useFilters from '../../../../hooks/useFilters';
import { Resolution } from '../../../../api/modules/resolutions/resolutionsTypes';
import useMessages from '../../../../hooks/useMessages';

interface Props {
  active?: 'true' | 'false',
}

const ResolutionsList = ({ active }: Props) => {
  const [selectedPage, setSelectedPage] = usePagination('resolutionsList');
  const { filterValues } = useFilters('resolutionsList');
  const getMessage = useMessages();
  const filters: FilterValues = { ...filterValues };

  const setVotedFilterValue = useCallback((value: string | undefined) => {
    if (value === 'voted') return true;
    if (value === 'notVoted') return false;
    return '';
  }, []);

  useEffect(() => {
    setSelectedPage(1);
  }, [active]);

  const { data } = useGetResolutionsQuery({
    ...filters,
    voted: setVotedFilterValue(filters.voted),
    active: active || '',
    page: selectedPage.toString(),
  });

  return (
    <>
      <Card className="col-span-full">
        {data?.resolutions && data?.resolutions.length === 0 ? (<div>{getMessage('filters.noResults')}</div>)
          : (
            <ol>
              {data?.resolutions.map((resolution) => <ResolutionItem key={resolution.id} item={resolution as Resolution} />)}
            </ol>
          )}
      </Card>
      {data?.metadata && (
        <PaginationControls
          className="col-span-full"
          selectedPage={selectedPage}
          setSelectedPage={setSelectedPage}
          paginationMetadata={data.metadata}
        />
      )}
    </>
  );
};

export default ResolutionsList;
