import * as React from 'react';
import PageHeader from '../../components/Layout/PageHeader';
import useMessages from '../../hooks/useMessages';
import ContentWrapper from '../../components/ContentWrapper/ContentWrapper';
import Layout from '../../components/Layout/Layout';
import CompanyDetailsForm from './CompanyDetailsForm';
import CompanyTaxDetailsForm from './CompanyTaxDetailsForm';
import { useGetCompanyDataQuery, useGetCompanyTaxDataQuery, useGetCompanySettingsQuery } from '../../api/appApi';
import Tabs, { TabElement } from '../../components/Tabs/Tabs';
import MailServerConfig from './MailServerConfig';
import { usePermissions } from '../../hooks/usePermissions';

interface Props {
  tab: 'companyDetails' | 'companyTaxDetails' | 'MailServerConfig'
}

const CompanyDetailsPage = ({ tab }: Props) => {
  const getMessage = useMessages();
  const { data: companyDetails } = useGetCompanyDataQuery();
  const { data: companyTaxDetails } = useGetCompanyTaxDataQuery();  
  const { data: companySettings } = useGetCompanySettingsQuery();
  const { checkRoleType } = usePermissions();

  const tabs: TabElement[] = [
    {
      label: getMessage('tabs.settings.companyDetails'),
      path: '/ustawienia/dane',
    },
    {
      label: getMessage('tabs.settings.companyTaxDetails'),
      path: '/ustawienia/dane_podatkowe',
    },
    {
      label: getMessage('tabs.settings.mailServerConfig'),
      path: '/ustawienia/poczta',
    },
  ];

  checkRoleType('admin');

  return (
    <Layout currentPage="settings">
      <PageHeader pageTitle={getMessage('navigation.settings')} currentPage="settings" />
      <Tabs tabElements={tabs} />
      <ContentWrapper>
        {tab === 'companyDetails' && companyDetails && companySettings && <CompanyDetailsForm companyData={companyDetails} companySettings={companySettings} />}
        {tab === 'companyTaxDetails' && companyTaxDetails && companySettings && <CompanyTaxDetailsForm companyTaxData={companyTaxDetails} companySettings={companySettings} />}
        {tab === 'MailServerConfig' && <MailServerConfig />}
      </ContentWrapper>
    </Layout>
  );
};
export default CompanyDetailsPage;
