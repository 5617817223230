import * as React from 'react';
import { useIntl } from 'react-intl';
import useMessages from '../../../hooks/useMessages';
import Dialog from '../../../components/Dialog/Dialog';
import { Role } from '../../../api/modules/access/accessTypes';
import { usePostChangeRoleMutation } from '../../../api/appApi';
import { Permission } from '../../../../utils/generated/permissionsTypes';

interface Props {
  isOpen: boolean,
  role: Role,
  newRoleName: string,
  selectedPermissions: Permission[],
  affectedUsersCount?: number,
  onClose: () => void,
}

const RoleEditPopup = ({
  isOpen, role, newRoleName, selectedPermissions, affectedUsersCount, onClose,
}: Props) => {
  const getMessage = useMessages();
  const intl = useIntl();
  const [postChangeRole] = usePostChangeRoleMutation();

  const onAccept = () => {
    postChangeRole({
      id: role?.id || '',
      payload: {
        name: newRoleName,
        permissions_names: selectedPermissions,
      },
    });
  };

  return (
    <Dialog isOpen={isOpen} onAccept={onAccept} onClose={onClose}>
      <h3 className="text-xl font-semibold">
        { getMessage('user.roles.confirmation.changeRole.header') }
      </h3>
      <p className="mb-12 mt-8 mx-16 text-sm">
        { intl.formatMessage({ id: 'user.roles.confirmation.changeRole.affectedUsers' }, { usersCount: affectedUsersCount }) }
      </p>
    </Dialog>
  );
};

export default RoleEditPopup;
