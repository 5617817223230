import * as React from 'react';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { isPast } from 'date-fns';
import MetersCard from './MetersCard/MetersCard';
import { useGetMetersQuery } from '../../../../api/appApi';
import { translateApiToMeterGroups } from '../../../../api/modules/meters/metersTranslator';
import OutdatedMatersCard from './MetersCard/DisabledCard';
import useMessages from '../../../../hooks/useMessages';
import { usePermissions } from '../../../../hooks/usePermissions';
import { ApiMeterRequestParams } from '../../../../api/modules/meters/metersTypes';
import Spinner from '../../../../components/Spinner/Spinner';
import { FormNotice } from '../../../../components/FormNotice/FormNotice';

interface Props {
  venueId: string,
}

const MetersList = ({ venueId }: Props) => {
  const queryParams: ApiMeterRequestParams = { measurement_required: true, venues_ids: venueId };
  const [displayMeasurementSentNotice, setDisplayMeasurementSentNotice] = useState(false);
  const { data, isLoading } = useGetMetersQuery(queryParams, { refetchOnMountOrArgChange: true });
  const { hasPermission, checkPermission } = usePermissions();
  const getMessage = useMessages();
  const intl = useIntl();
  const metersGroups = data ? translateApiToMeterGroups(data, intl) : undefined;

  checkPermission('meters_list');

  const displayFormSuccessNotice = () => setDisplayMeasurementSentNotice(true);

  return (
    <div className="col-span-full">
      {isLoading && <Spinner className="m-auto mt-5" />}
      {metersGroups && metersGroups.map((metersGroup) => {
        if (metersGroup.find((meter) => !meter.canUpdate) || !hasPermission('meters_measurement_add')) {
          return (
            <OutdatedMatersCard
              meterGroup={metersGroup}
              key={`g-${metersGroup[0].meterId}`}
              label={metersGroup.find((meter) => isPast(meter.validUntil))
                ? getMessage('local.meters.outdated')
                : getMessage('local.meters.cannotSend')}
            />
          );
        }
        return <MetersCard key={`g-${metersGroup[0].meterId}`} meterGroup={metersGroup} onSuccess={displayFormSuccessNotice} />;
      })}
      {displayMeasurementSentNotice && <FormNotice noticeClassName="col-span-full" type="success" message={getMessage('local.meters.measurementSent')} />}
      {metersGroups && metersGroups.length === 0 && (
        <div>
          {getMessage('local.meters.noMeters')}
        </div>
      )}
    </div>
  );
};

export default MetersList;
