/* eslint-disable react/jsx-props-no-spreading */

import * as React from 'react';
import { useState, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { InputError } from '../../../components/FormInputs/InputLabel';
import useMessages from '../../../hooks/useMessages';
import { usePostChangePasswordMutation } from '../../../api/appApi';
import { translateMyPasswordToApi } from '../../../api/modules/user/userTranslator';
import { FormInput } from '../../../components/FormInputs';
import { MyNewPasswordData } from '../../../api/modules/user/userTypes';
import Dialog from '../../../components/Dialog/Dialog';
import { PASSWORD_REGEX } from '../../../../utils/consts';

interface Props {
  isOpen: boolean,
  onClose: () => void,
  onPasswordChanged: () => void,
}
const ChangeMyPasswordPopUp = ({
  isOpen, onClose, onPasswordChanged,
}: Props) => {
  const getMessage = useMessages();
  const [postChangePassword, result] = usePostChangePasswordMutation();
  const methods = useForm<MyNewPasswordData>({ mode: 'onTouched' });
  const [backendErrors, setBackendErrors] = useState([] as string[]);

  useEffect(() => {
    if (isOpen) {
      methods.setFocus('oldPassword');
    }
  }, [isOpen]);

  const onSubmit = (data: MyNewPasswordData) => {
    postChangePassword(translateMyPasswordToApi(data));
  };

  useEffect(() => {
    if (result.status === 'fulfilled') {
      setBackendErrors([]);
      methods.setValue('oldPassword', '');
      methods.setValue('newPassword', '');
      methods.setValue('confirmNewPassword', '');
      onPasswordChanged();
      onClose();
    } else if (result.status === 'rejected') {
      // @ts-ignore
      const errors = result.error?.data?.errors || [];
      setBackendErrors(Array.isArray(errors) ? errors : [errors]);
    }
  }, [result]);

  const handleSubmit = () => {
    methods.handleSubmit(onSubmit)();
  };

  return (
    <Dialog isOpen={isOpen} onAccept={handleSubmit} onClose={onClose} submitButton preventCloseOnAccept className="w-full" labelledBy="changeMyPasswordPopUpTitle">
      <FormProvider {...methods}>
        <h2 className="border-b w-full pb-8 text-center text-header-sm" id="changeMyPasswordPopUpTitle">Zmień hasło</h2>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          className="flex flex-col my-7 w-full items-center"
        >
          <FormInput
            type="password"
            className="mobile:w-full w-2/3"
            inputClassName="w-full"
            id="oldPassword"
            label={getMessage('profile.user.changePassword.oldPassword')}
            options={{ required: getMessage('profile.user.changePassword.oldPassword.error.required') }}
          />
          <FormInput
            type="password"
            className="mobile:w-full w-2/3"
            inputClassName="w-full"
            id="newPassword"
            label={getMessage('profile.user.changePassword.newPassword')}
            options={{
              required: getMessage('profile.user.changePassword.newPassword.error.required'),
              pattern: {
                value: PASSWORD_REGEX,
                message: getMessage('profile.user.changePassword.error.newPassword.pattern'),
              },
            }}
          />
          <FormInput
            type="password"
            className="mt-4 mobile:w-full w-2/3"
            inputClassName="w-full"
            id="confirmNewPassword"
            label={getMessage('profile.user.changePassword.confirmNewPassword')}
            options={{
              required: getMessage('profile.user.changePassword.confirmNewPassword.error.required'),
              pattern: {
                value: PASSWORD_REGEX,
                message: getMessage('profile.user.changePassword.error.changePassword.pattern'),
              },
            }}
          />
          {backendErrors && backendErrors.map((error) => (
            <InputError key={error} message={error} />
          ))}
        </form>
      </FormProvider>
    </Dialog>
  );
};

export default ChangeMyPasswordPopUp;
