import * as React from 'react';
import { useMemo, useState } from 'react';
import PageHeader from '../../../components/Layout/PageHeader';
import ContentWrapper from '../../../components/ContentWrapper/ContentWrapper';
import Layout from '../../../components/Layout/Layout';
import useMessages from '../../../hooks/useMessages';
import SettlementsVenueList from './SettlementsVenueList';
import Button from '../../../components/Button/Button';
import FiltersIcon from '../../../icons/filters.svg';
import { useAppSelector } from '../../../hooks/reduxHooks';
import SettlementsVenueFilters from './SettlementsVenueFilters';
import Tabs, { TabElement } from '../../../components/Tabs/Tabs';
import { usePermissions } from '../../../hooks/usePermissions';

interface Props {
  tab: 'all' | 'active',
}

const SettlementsVenueListPage = ({ tab }: Props) => {
  const getMessage = useMessages();
  const { checkModuleEnabled } = usePermissions();
  const hasFilterSet = useAppSelector((state) => !!state.app.filters.find((filter) => filter.id === 'metersVenuesList'));
  const [filtersOpen, setFiltersOpen] = useState(hasFilterSet);

  checkModuleEnabled('settlements');

  const tabs: TabElement[] = useMemo(() => [
    {
      label: getMessage('settlements.venuesList.tabs.active'),
      path: '/rozrachunki/lokale/aktywne',
    },
    {
      label: getMessage('settlements.venuesList.tabs.all'),
      path: '/rozrachunki/lokale',
    },
  ], []);

  return (
    <Layout currentPage="settlements">
      <PageHeader pageTitle={getMessage('navigation.settlements')} currentPage="settlements">
        <Button
          Icon={FiltersIcon}
          size="small"
          styleType="secondary"
          onClick={() => setFiltersOpen((filtersOpen) => !filtersOpen)}
          className="h-8 py-1"
        >
          {getMessage('filters')}
        </Button>
      </PageHeader>
      <Tabs tabElements={tabs} />
      {filtersOpen && <SettlementsVenueFilters tab={tab} />}
      <ContentWrapper>
        <SettlementsVenueList tab={tab} />
      </ContentWrapper>
    </Layout>
  );
};

export default SettlementsVenueListPage;
