import {
  ApiAnnouncementsResponse,
  Announcements,
  ApiAnnouncement,
  AnnouncementData,
  AnnouncementAttachment,
  ApiAnnouncementAttachment,
  ApiAnnouncementsAuthorsResponse,
  AnnouncementsAuthor,
  AnnouncementInputs,
  ApiSingleAnnouncement,
  ApiAnnouncementsRecipientsForFilterResponse,
  AnnouncementsRecipientForFilter,
  FullChangeAnnouncementData,
} from './announcementsTypes';

export const translateAnnouncementsRecipients = ({ recipients }: ApiAnnouncementsRecipientsForFilterResponse):
AnnouncementsRecipientForFilter[] => recipients?.map((item) => ({
  id: item.id,
  fullName: item.full_name,
}));

export const translateAnnouncementsAuthors = ({ authors }: ApiAnnouncementsAuthorsResponse): AnnouncementsAuthor[] => authors?.map((item) => ({
  id: item.id,
  fullName: item.full_name,
}));

const translateAnnouncementAttachment = (attachments: ApiAnnouncementAttachment[]): AnnouncementAttachment[] => attachments?.map((item) => ({
  filename: item.filename,
  path: item.path,
  contentType: item.content_type,
}));

export const translateSingleAnnouncement = (announcement: ApiSingleAnnouncement): AnnouncementData => {
  const hasRecipients = announcement.recipients !== undefined && Object.keys(announcement.recipients).length > 0;

  return (
    {
      attachments: translateAnnouncementAttachment(announcement.attachments),
      category: announcement.category,
      announcementNumber: announcement.announcement_number,
      author: announcement.author,
      createdAt: announcement.created_at,
      description: announcement.description,
      id: announcement.id,
      title: announcement.title,
      status: announcement.status,
      expiresAt: announcement.expires_at,
      ...(hasRecipients && {
        recipients: {
          roles: announcement.recipients['access/roles'],
          homeownerAssociations: announcement.recipients['tenants/homeowner_associations'],
          buildings: announcement.recipients['tenants/buildings'],
          estates: announcement.recipients['tenants/estates'],
          tenants: announcement.recipients['tenants/tenants'],
          employees: announcement.recipients['users/accounts'],
        },
      }),
    });
};

const translateAnnouncement = (announcement: ApiAnnouncement): AnnouncementData => ({
  attachments: translateAnnouncementAttachment(announcement.attachments),
  category: announcement.category,
  announcementNumber: announcement.announcement_number,
  author: announcement.author,
  createdAt: announcement.created_at,
  description: announcement.description,
  id: announcement.id,
  title: announcement.title,
  status: announcement.status,
  expiresAt: announcement.expires_at,
});

export const translateAnnouncements = ({ announcements, metadata }: ApiAnnouncementsResponse): Announcements => ({
  metadata,
  announcements: announcements.map((apiAnnouncement) => translateAnnouncement(apiAnnouncement)),
});

export const translateAnnouncementToApi = (data: AnnouncementInputs) => {
  const form = new FormData();
  form.append('announcement[title]', data.title);
  form.append('announcement[description]', data.description);
  if (data.expiresAt) form.append('announcement[expires_at]', data.expiresAt);
  data.attachments.forEach((attachment) => {
    if (attachment.file?.length) {
      form.append('announcement[attachments][]', attachment.file[0]);
    }
  });
  data.employees.forEach((employee) => {
    form.append('announcement[recipients]users/accounts[]', employee);
  });
  data.homeownerAssociations.forEach((hoa) => {
    form.append('announcement[recipients]tenants/homeowner_associations[]', hoa);
  });
  data.buildings.forEach((building) => {
    form.append('announcement[recipients]tenants/buildings[]', building);
  });
  data.estates.forEach((estate) => {
    form.append('announcement[recipients]tenants/estates[]', estate);
  });
  data.tenants.forEach((tenant) => {
    form.append('announcement[recipients]tenants/tenants[]', tenant);
  });
  data.roles.forEach((role) => {
    form.append('announcement[recipients]access/roles[]', role);
  });
  return form;
};

export const translateChangeAnnouncementToApi = async (data: FullChangeAnnouncementData): Promise<FormData> => {
  const form = new FormData();
  form.append('id', data.id);
  form.append('announcement[title]', data.title);
  form.append('announcement[description]', data.description);
  if (data.expiresAt) form.append('announcement[expires_at]', data.expiresAt);

  const files = await Promise.all(
    data.oldAttachments.map(
      async (file) => fetch(file.path)
        .then((r) => r.blob())
        .then((blobFile) => new File([blobFile], file.filename, { type: file.contentType })),
    ),
  );

  files.forEach((file) => form.append('announcement[attachments][]', file));

  data.attachments.forEach((attachment) => {
    if (attachment.file?.length) {
      form.append('announcement[attachments][]', attachment.file[0]);
    }
  });

  if (data.employees) {
    data.employees.forEach((employee) => {
      form.append('announcement[recipients]users/accounts[]', employee);
    });
  }
  if (data.homeownerAssociations) {
    data.homeownerAssociations.forEach((hoa) => {
      form.append('announcement[recipients]tenants/homeowner_associations[]', hoa);
    });
  }
  if (data.buildings) {
    data.buildings.forEach((building) => {
      form.append('announcement[recipients]tenants/buildings[]', building);
    });
  }
  if (data.estates) {
    data.estates.forEach((estate) => {
      form.append('announcement[recipients]tenants/estates[]', estate);
    });
  }
  if (data.tenants) {
    data.tenants.forEach((tenant) => {
      form.append('announcement[recipients]tenants/tenants[]', tenant);
    });
  }
  if (data.roles) {
    data.roles.forEach((role) => {
      form.append('announcement[recipients]access/roles[]', role);
    });
  }
  return form;
};
