import * as React from 'react';
import AttachmentTypeIcon from './AttachmentTypeIcon/AttachmentTypeIcon';
import PdfPreviewer from '../PdfPreviewer/PdfPreviewer';
import PreviewReplacement from './PreviewReplacement/PreviewReplacement';
import useMessages from '../../hooks/useMessages';

type Props = {
  filename: string,
  path: string,
  href?: string,
  description?: string,
  contentType: string
  className?: string,
  imageClassName?: string,
};

const FilePreview = ({
  contentType, path, filename, imageClassName,
}: {
  contentType: string,
  path: string,
  filename: string,
  imageClassName?: string,
}) => {
  switch (contentType) {
    case 'application/pdf':
      return <PdfPreviewer source={path} pageToDisplay={1} width={200} />;
    case 'image/png':
    case 'image/jpeg':
    case 'image/jpg':
      return <img src={path} alt={filename} className={imageClassName || 'overflow-hidden h-[160px] w-[200px]'} />;
    default:
      return <PreviewReplacement widthInPx="200" heightInPx="160" />;
  }
};

const AttachmentPreview = (attachment: Props) => {
  const {
    path, contentType, filename, description, href, className, imageClassName,
  } = attachment;
  const getMessage = useMessages();

  return (
    <li
      className={`my-2 mr-3 flex items-center text-gray-500 ${className}`}
      title={`${description ? (`opis: ${description} `) : ''}(${getMessage('opensInNewTab')})`}
    >
      <a
        href={href || path}
        target={href ? '' : '_blank'}
        className="inline-block rounded border border-gray font-semibold text-gray-500 text-xs text-center hover:border-hover truncate relative"
        rel="noreferrer"
      >
        <div className={imageClassName || 'overflow-hidden h-[160px] w-[200px]'}>
          <FilePreview contentType={contentType} path={path} filename={filename} imageClassName={imageClassName} />
        </div>
        <div className="p-1 bg-gray-200 w-full flex self-end items-center absolute bottom-0 index-5">
          <AttachmentTypeIcon contentType={contentType} />
          <p className="truncate grow-0 text-text-md-medium text-typography-dark">
            {filename}
            <span className="inline-block h-0 w-0">
              {href ? '' : getMessage('opensInNewTab')}
            </span>
          </p>
        </div>
      </a>
    </li>
  );
};

export default AttachmentPreview;
