import * as React from 'react';
import { useIntl } from 'react-intl';
import DashboardIcon from '../../../icons/dashboard.svg';
import NavigationItem from './NavigationItem';
import MetersIcon from '../../../icons/home.svg';
import GaugeIcon from '../../../icons/meters.svg';
import TicketsIcon from '../../../icons/tickets.svg';
import AnnouncementsIcon from '../../../icons/announcements.svg';
import ResolutionsIcon from '../../../icons/resolutions.svg';
import ProfileIcon from '../../../icons/profile.svg';
import CreditCardIcon from '../../../icons/credit-card.svg';
import ClipboardIcon from '../../../icons/clipboard.svg';
import UsersIcon from '../../../icons/users.svg';
import ParametersIcon from '../../../icons/gauge.svg';
import DocumentsIcon from '../../../icons/documents.svg';
import { NavigationRoutes } from '../../Layout/Layout';
import { usePermissions } from '../../../hooks/usePermissions';

interface Props {
  currentPage?: NavigationRoutes,
}

const MainNavigation = ({ currentPage } : Props) => {
  const { hasRoleType, isModuleEnabled } = usePermissions();
  const intl = useIntl();

  let settlementsPath;

  if (hasRoleType('worker')) {
    settlementsPath = '/rozrachunki/lokale/aktywne';
  } else {
    settlementsPath = isModuleEnabled('payments') ? '/rozrachunki/platnosci' : '/rozrachunki/analiza_salda';
  }

  return (
    <div className="overflow-y-auto overflow-x-hidden flex-grow md:h-sidebar">
      <div className="border-t mx-2 sm:mx-3 md:mx-7 h-px" />
      <ul className="flex flex-col py-4 space-y-1">
        {(hasRoleType('tenant') || hasRoleType('worker')) && (
          <NavigationItem
            to="/"
            label={intl.formatMessage({ id: 'navigation.dashboard' })}
            selected={currentPage === 'dashboard'}
            Icon={DashboardIcon}
          />
        )}
        {hasRoleType('admin') && (
        <>
          {(isModuleEnabled('tickets') || isModuleEnabled('documents')) && (
            <NavigationItem
              to="/parametry/statusy"
              label={intl.formatMessage({ id: 'navigation.parameters' })}
              selected={currentPage === 'parameters'}
              Icon={ParametersIcon}
            />
          )}
          <NavigationItem
            to="/uzytkownicy"
            label={intl.formatMessage({ id: 'navigation.users' })}
            selected={currentPage === 'users'}
            Icon={UsersIcon}
          />
          <NavigationItem
            to="/uprawnienia/role"
            label={intl.formatMessage({ id: 'navigation.roles' })}
            selected={currentPage === 'roles'}
            Icon={MetersIcon}
          />
          <NavigationItem
            to="/ustawienia/dane"
            label={intl.formatMessage({ id: 'navigation.settings' })}
            selected={currentPage === 'settings'}
            Icon={AnnouncementsIcon}
          />
          { hasRoleType('worker') && (<div className="border-t mx-2 sm:mx-3 md:mx-7 h-px" />) }
        </>
        )}
        <NavigationItem
          to={settlementsPath}
          label={intl.formatMessage({ id: 'navigation.settlements' })}
          selected={currentPage === 'payments' || currentPage === 'settlements'}
          Icon={CreditCardIcon}
          permissionNeeded={['settlements_balance_list']}
          module="settlements"
        />
        {hasRoleType('worker') ? (
          <NavigationItem
            to="/liczniki/lokale"
            label={intl.formatMessage({ id: 'navigation.local.meters' })}
            selected={currentPage === 'local' || currentPage === 'local.meters'}
            Icon={GaugeIcon}
            permissionNeeded={['meters_list']}
            module="meters"
          />
        ) : (
          <>
            {isModuleEnabled('meters') && (
              <NavigationItem
                to="/liczniki"
                label={intl.formatMessage({ id: 'navigation.local.meters' })}
                selected={currentPage === 'local.meters'}
                permissionNeeded={['meters_list']}
                module="meters"
                Icon={GaugeIcon}
              />
            )}
            {isModuleEnabled('tickets') && (
              <NavigationItem
                to="/liczba-osob"
                label={intl.formatMessage({ id: 'navigation.local.peopleCount' })}
                selected={currentPage === 'local.peopleCount'}
                permissionNeeded={['tenants_people_entries_manage']}
                module="tickets"
                Icon={UsersIcon}
              />
            )}
          </>
        )}
        <NavigationItem
          to="/aktualnosci"
          label={intl.formatMessage({ id: 'navigation.announcements' })}
          selected={currentPage === 'announcements'}
          Icon={AnnouncementsIcon}
          permissionNeeded={['announcements_list']}
        />
        <NavigationItem
          to="/zgloszenia"
          label={intl.formatMessage({ id: 'navigation.tickets' })}
          selected={currentPage === 'tickets'}
          Icon={TicketsIcon}
          permissionNeeded={['tickets_add']}
          module="tickets"
        />
        <NavigationItem
          to="/zadania/otwarte"
          label={intl.formatMessage({ id: 'navigation.tasks' })}
          selected={currentPage === 'tasks'}
          Icon={ClipboardIcon}
          permissionNeeded={['tasks_list']}
          module="tickets"
        />
        <NavigationItem
          to="/dokumenty"
          label={intl.formatMessage({ id: 'navigation.documents' })}
          selected={currentPage === 'documents'}
          Icon={DocumentsIcon}
          permissionNeeded={['documents_list']}
          module="documents"
        />
        <NavigationItem
          to="/uchwaly"
          label={intl.formatMessage({ id: 'navigation.resolutions' })}
          selected={currentPage === 'resolutions'}
          Icon={ResolutionsIcon}
          permissionNeeded={['resolutions_list']}
          module="resolutions"
        />
        <NavigationItem
          to="/profil"
          label={intl.formatMessage({ id: 'navigation.profile' })}
          selected={currentPage === 'profile'}
          Icon={ProfileIcon}
        />
      </ul>
    </div>
  );
};

export default MainNavigation;
