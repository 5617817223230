import * as React from 'react';
import { useMemo } from 'react';
import useMessages from '../../../../hooks/useMessages';
import Filters from '../../../../components/Filters/Filters';
import useHomeownerAssociationsMode from '../../../../hooks/useHomeownerAssociationsMode';
import { FilterConfig } from '../../../../components/FilterInput/FilterInput';

const resolutionsStates = [
  'voted', 'notVoted',
] as const;

const EmployeeResolutionsFilters = () => {
  const getMessage = useMessages();
  const hoaMode = useHomeownerAssociationsMode();

  const stateOptionValues = useMemo(() => resolutionsStates.map((state) => ({
    value: state,
    optionLabel: getMessage(`resolutions.filters.state.${state}`),
  })), []);

  const filterFields: FilterConfig[] = useMemo(() => [
    ...hoaMode ? [{
      type: 'text',
      id: 'homeowner_association_search',
      label: getMessage('resolutions.filters.homeownerAssociationCodeName'),
      ariaLabel: 'Szukaj po kodzie lub nazwie wspólnoty',
    } as FilterConfig] : [],
    {
      type: 'text',
      id: 'number_search',
      label: getMessage('resolutions.filters.number'),
      ariaLabel: 'Szukaj po numerze uchwały',
    },
    {
      type: 'text',
      id: 'title_search',
      label: getMessage('resolutions.filters.title'),
      ariaLabel: 'Szukaj po tytule uchwały',
    },
    {
      type: 'dateRange',
      id: 'from_created_at',
      toDateId: 'to_created_at',
      label: getMessage('resolutions.filters.creationDate'),
    },
    {
      type: 'dateRange',
      id: 'from_voting_deadline',
      toDateId: 'to_voting_deadline',
      label: getMessage('resolutions.filters.voteDate'),
    },
    {
      type: 'select',
      id: 'voted',
      label: getMessage('resolutions.filters.state'),
      selectOptions: stateOptionValues,
    },
  ], [hoaMode]);

  return (
    <Filters
      filterId="employeeResolutionsList"
      filterFields={filterFields}
    />
  );
};

export default EmployeeResolutionsFilters;
