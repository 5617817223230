import * as React from 'react';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from '../../components/Layout/Layout';
import PageHeader from '../../components/Layout/PageHeader';
import Tabs, { TabElement } from '../../components/Tabs/Tabs';
import ContentWrapper from '../../components/ContentWrapper/ContentWrapper';
import useMessages from '../../hooks/useMessages';
import UsersList from './UsersList/UsersList';
import Button from '../../components/Button/Button';
import FiltersIcon from '../../icons/filters.svg';
import { useAppSelector } from '../../hooks/reduxHooks';
import UsersFilters from './UsersFilters/UsersFilters';
import PlusIcon from '../../icons/plus.svg';
import UsersActivityLog from './UsersActivityLog/UsersActivityLog';
import UsersActivityLogFilters from './UsersActivityLog/UsersActivityLogFilters';
import { usePermissions } from '../../hooks/usePermissions';

interface Props {
  tab: 'all' | 'workers' | 'activityLog';
}

const UsersPage = ({ tab } : Props) => {
  const getMessage = useMessages();
  const navigate = useNavigate();
  const hasFilterSet = useAppSelector((state) => !!state.app.filters.find((filter) => filter.id === 'usersTable'));
  const [filtersOpen, setFiltersOpen] = useState(hasFilterSet);
  const { checkRoleType } = usePermissions();

  const tabs: TabElement[] = useMemo(() => [
    {
      label: getMessage('users.tabs.all'),
      path: '/uzytkownicy',
    },
    {
      label: getMessage('users.tabs.workers'),
      path: '/uzytkownicy/pracownicy',
    },
    {
      label: getMessage('users.tabs.activityLog'),
      path: '/uzytkownicy/rejestr_zdarzen',
    },
  ], []);
  checkRoleType('admin');

  return (
    <Layout currentPage="users">
      <PageHeader pageTitle={getMessage('users.header')} currentPage="users">
        <Button
          Icon={FiltersIcon}
          styleType="secondary"
          size="small"
          onClick={() => setFiltersOpen((filtersOpen) => !filtersOpen)}
          className="mx-3 py-1 appearance-none"
        >
          {getMessage('filters')}
        </Button>
        <Button
          onClick={() => navigate('/uzytkownicy/nowy')}
          className="py-1"
          size="small"
          Icon={PlusIcon}
        >
          {getMessage('users.newUser.create')}
        </Button>
      </PageHeader>
      <Tabs tabElements={tabs} />
      {filtersOpen && (
        tab === 'activityLog' ? <UsersActivityLogFilters /> : <UsersFilters includeRoleType={tab === 'all'} />
      )}
      <ContentWrapper>
        {tab === 'activityLog' ? <UsersActivityLog /> : <UsersList prefilter={tab} />}
      </ContentWrapper>
    </Layout>
  );
};

export default UsersPage;
