import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { ApiBaseQuery, ApiTagTypes } from '../../baseQuery';
import {
  ApiResidencyResponse, Residency, ApiPostResidencyUpdate,
} from './residencyTypes';
import { translateApitoResidency } from './residencyTranslator';

export const residencyQueries = (builder: EndpointBuilder<ApiBaseQuery, ApiTagTypes, 'api'>) => ({
  getResidency: builder.query<Residency[], string>({
    query: (id) => `/tenants/venues/${id}/residency_amendments/list`,
    transformResponse(baseQueryReturnValue: ApiResidencyResponse): Residency[] {
      return translateApitoResidency(baseQueryReturnValue);
    },
    providesTags: ['Residency'],
  }),
  postResidencyUpdate: builder.mutation<any, ApiPostResidencyUpdate>({
    query: (residency) => ({
      url: `/tenants/venues/${residency.venue_id}/residency_amendments/add`,
      method: 'POST',
      body: residency,
    }),
    invalidatesTags: ['Residency', 'ActivityLog'],
  }),
});
