import * as React from 'react';
import { useMemo, useState, useEffect } from 'react';
import { format } from 'date-fns';
import { useForm, FormProvider } from 'react-hook-form';
import Button from '../../../../../components/Button/Button';
import Card from '../../../../../components/Card/Card';
import useMessages from '../../../../../hooks/useMessages';
import { usePostMeasurementsMutation } from '../../../../../api/appApi';
import { translateFormFieldsToApi } from '../../../../../api/modules/meters/metersTranslator';
import { MetersFormFields, ReadingRequest } from '../../../../../api/modules/meters/metersTypes';
import MetersUpdatePopup from '../MetersUpadatePopup/MetersUpdatePopup';
import MeterTitleLocationContainer from '../../../MeterTitleLocationContainer/MeterTitleLocationContainer';
import MeterReadingInput from '../../../MeterReadingInput/MeterReadingInput';
import { FormNotice } from '../../../../../components/FormNotice/FormNotice';
import Tooltip from '../../../../../components/Tooltip/Tooltip';

interface Props {
  meterGroup: ReadingRequest[],
  onSuccess: () => void,
}

const MetersCard = ({ meterGroup, onSuccess }: Props) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const getMessage = useMessages();
  const [postMeasurements, result] = usePostMeasurementsMutation();
  const methods = useForm<MetersFormFields>({
    mode: 'onTouched',
  });
  const validUntil = useMemo(
    () => Math.min(...meterGroup.map((reading) => reading.validUntil.getTime())),
    [meterGroup],
  );

  const dataSent = result.status === 'fulfilled' || result.status === 'rejected';
  const dataRejected = result.status === 'rejected';

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const sendMeasurements = (measurements: MetersFormFields) => {
    if (dataSent) {
      return;
    }
    togglePopup();
    postMeasurements(translateFormFieldsToApi(measurements));
  };

  const handleSubmit = () => {
    methods.handleSubmit(sendMeasurements)();
  };

  useEffect(() => {
    if (result.isSuccess) {
      onSuccess();
    }
  }, [result]);

  return (
    <Card className="mb-4">
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <FormProvider {...methods}>
        <form onSubmit={(e) => {
          e.preventDefault();
          togglePopup();
        }}
        >
          <div>
            {meterGroup.map((meter) => (
              <div key={meter.meterId} className="flex mobile:flex-col border-b justify-between w-full last:border-b-0 py-6 first:pt-0 mobile:items-start items-center last:mb-6 mobile:py-0">
                <MeterTitleLocationContainer
                  meter={meter}
                />
                {dataSent ? (
                  <div className="text-text-md-semibold mobile:mt-6">
                    {dataRejected && (<FormNotice type="error" message={getMessage('local.meters.measurementSendingError')} />)}
                  </div>
                ) : (<MeterReadingInput measurementUnit={meter.measurementUnit} meterId={meter.meterId} meterType={meter.meterType} />)}
              </div>
            ))}
          </div>
          <div className="flex flex-wrap justify-end mr-6 items-center mobile:justify-start mobile:mr-0">
            <div className="mr-2 text-typography-dark text-text-md-semibold mobile:mb-3">
              {`${getMessage('local.meters.fillBefore')} ${format(validUntil, 'dd.MM.yyyy')}`}
            </div>

            <Tooltip content={getMessage('local.meters.measurementDisabledTooltip')}>
              <Button
                styleType="primary"
                disabled={dataSent || !methods.formState.isValid}
                onClick={togglePopup}
                className="mobile:w-full"
                ariaLabel={!methods.formState.isValid ? 'Wprowadź wartość odczytu aby aktywować przycisk' : 'Wyślij odczyt'}
              >
                {getMessage('send')}
              </Button>
            </Tooltip>
          </div>
          <MetersUpdatePopup
            isOpen={isPopupOpen}
            meterGroup={meterGroup}
            onAccept={handleSubmit}
            onClose={togglePopup}
            values={methods.getValues()}
          />
        </form>
      </FormProvider>
    </Card>
  );
};

export default MetersCard;
