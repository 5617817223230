import * as React from 'react';
import { useMemo } from 'react';
import { MonthsTitle } from '../../../../api/modules/settlements/settlementsTypes';
import {
  TableBodyCell,
} from '../../../../components/Table/Table';
import ResponsiveTable, {
  ResponsiveTableBody,
  ResponsiveTableBodyRow,
  ResponsiveTableHeader,
} from '../../../../components/Table/ResponsiveTable';
import { useAppSelector } from '../../../../hooks/reduxHooks';
import { useSettlementsVenue } from '../../../../hooks/settlements/useSettlementsVenue';
import TableContentPlaceholder from '../../../../components/Table/TableContentPlaceholder';
import Spinner from '../../../../components/Spinner/Spinner';

interface Props {
  year: string,
  month: string,
  data?: MonthsTitle[],
  isLoading: boolean,
  isSuccess: boolean,
}

const MonthsBalanceTable = ({
  data, year, month, isLoading, isSuccess,
}: Props) => {
  const selectedTitleId = useAppSelector((state) => state.app.selectedSettlementsTitle);

  const { venueId } = useSettlementsVenue();
  const columnLabels = useMemo(() => [
    { label: 'settlements.monthsBalance.title' },
    { label: 'settlements.monthsBalance.bankAccount' },
    { label: 'settlements.monthsBalance.balance', divClassName: 'md:justify-end' },
  ], []);

  return (
    <ResponsiveTable className="col-span-full">
      {isLoading && <Spinner />}
      {isSuccess && (data && data.length > 0 ? (
        <>
          <ResponsiveTableHeader
            columnLabels={columnLabels}
            ids={data?.map((mt) => mt.id) || []}
            tableName="monthsBalanceTable"
          />
          <ResponsiveTableBody>
            {data.filter((title) => (selectedTitleId ? title.id === selectedTitleId : true)).map(({
              name, bankAccountNumber, balance, id,
            }: MonthsTitle) => (
              <ResponsiveTableBodyRow
                key={name}
                clickable={({
                  href: `/rozrachunki/analiza_salda/${year}/${month}/${id}?venueId=${venueId}`,
                  ariaLabel: `Przejdź do analizy tytułu ${name}`,
                })}
              >
                <TableBodyCell>{name}</TableBodyCell>
                <TableBodyCell>{bankAccountNumber}</TableBodyCell>
                <TableBodyCell className="md:text-right">{balance}</TableBodyCell>
              </ResponsiveTableBodyRow>
            ))}
          </ResponsiveTableBody>
        </>
      ) : <TableContentPlaceholder />)}
    </ResponsiveTable>
  );
};

export default MonthsBalanceTable;
