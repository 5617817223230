import * as React from 'react';

const DetailItem = ({ label, value }: { label: string, value: string }) => (
  <div className="mb-2">
    <span className="mb-1 text-text-md-semibold text-typography-black">{label}</span>
    <p className="text-text-md-medium text-typography-dark">{value}</p>
  </div>
);

export default DetailItem;
