import * as React from 'react';
import { useIntl } from 'react-intl';
import useMessages from '../../../hooks/useMessages';
import Dialog from '../../../components/Dialog/Dialog';

interface Props {
  isOpen: boolean,
  onClose: () => void,
  onAccept: () => void,
  name: string,
}

const RemoveJournalPopUp = ({
  isOpen, onClose, onAccept, name,
}: Props) => {
  const getMessage = useMessages();
  const intl = useIntl();

  return (
    <Dialog
      isOpen={isOpen}
      acceptButtonText={getMessage('confirm')}
      onClose={onClose}
      closeButtonText={getMessage('cancel')}
      onAccept={onAccept}
    >
      <p className="mb-10">
        {intl.formatMessage(
          { id: 'parameters.journals.journal.confirmRemoving' },
          { journalName: name },
        )}
      </p>
    </Dialog>
  );
};

export default RemoveJournalPopUp;
