import * as React from 'react';
import format from 'date-fns/format';
import { useNavigate } from 'react-router-dom';
import { TableBodyCell } from '../../../../components/Table/Table';
import ResponsiveTable, { ResponsiveTableHeader, ResponsiveTableBody, ResponsiveTableBodyRow } from '../../../../components/Table/ResponsiveTable';
import Chip from '../../../../components/Chip/Chip';
import { TableId } from '../../../../store/appSlice';
import capitalize from '../../../../../utils/formatters';
import Button from '../../../../components/Button/Button';
import useMessages from '../../../../hooks/useMessages';
import TableBodyPlaceholder from '../../../../components/Table/TableBodyPlaceholder';

interface Props {
  tickets: {
    id: string,
    status: string,
    reportedAt: string,
    title: string,
    lastCommentAt: string,
    venueAddress: string,
    ticketNumber: string,
  }[]
}

const TicketsTableMobile = ({ tickets }: Props) => {
  const navigate = useNavigate();
  const handleNavigate = (id: string) => navigate(`/zgloszenia/${id}`);
  const getMessage = useMessages();

  const ids = tickets?.map(({ id }) => id);

  const columnLabels = [
    { label: 'tickets.ticket.status' },
    { label: 'tickets.ticket.reportedAt' },
    { label: 'tickets.ticket.lastCommentAt' },
    { label: 'tickets.ticket.id' },
    { label: 'tickets.ticket.title' },
    { label: 'tickets.ticket.venueAddress' },
    { label: 'tickets.ticket.placeholder', divClassName: 'sr-only' },
  ];

  return (
    <ResponsiveTable className="col-span-full md:mb-0">
      {tickets && tickets?.length === 0 ? <TableBodyPlaceholder /> : (
        <>
          <ResponsiveTableHeader ids={ids} columnLabels={columnLabels} tableName={'ticketsList' as TableId} />
          <ResponsiveTableBody>
            {tickets && tickets?.map(({
              status, reportedAt, lastCommentAt, id, title, venueAddress, ticketNumber,
            }) => (
              <ResponsiveTableBodyRow key={id} className="border-b last:border-b-0 border-separate font-medium">
                <TableBodyCell>
                  <Chip chipStyle={status === 'completed' ? 'gray' : 'primary'} className="w-fit">
                    {status && capitalize(status)}
                  </Chip>
                </TableBodyCell>
                <TableBodyCell>
                  <Chip chipStyle={status === 'unread' ? 'gray' : 'ghost'} className="w-fit">
                    {format(new Date(reportedAt), 'dd-MM-yyyy')}
                  </Chip>
                </TableBodyCell>
                <TableBodyCell>
                  <Chip chipStyle={status === 'unread' ? 'gray' : 'ghost'} className="w-fit">
                    {format(new Date(lastCommentAt), 'dd-MM-yyyy')}
                  </Chip>
                </TableBodyCell>
                <TableBodyCell className="max-w-[4rem]">{ticketNumber}</TableBodyCell>
                <TableBodyCell className={`w-fit text-typography-black ${status === 'unread' ? 'font-bold' : ''}`}>{title}</TableBodyCell>
                <TableBodyCell className="max-w-[13rem] truncate">{venueAddress}</TableBodyCell>
                <TableBodyCell>
                  <Button
                    onClick={() => handleNavigate(id)}
                    styleType="secondary"
                    size="small"
                    ariaLabel={getMessage('tickets.ticket.ticketDetails')}
                  >
                    {getMessage('more')}
                  </Button>
                </TableBodyCell>
              </ResponsiveTableBodyRow>
            ))}
          </ResponsiveTableBody>
        </>
      )}
    </ResponsiveTable>
  );
};

export default TicketsTableMobile;
