import * as React from 'react';
import DesktopInfoCard from '../../../../../components/InfoCard/DesktopInfoCard/DesktopInfoCard';
import TicketInfoCard from './TicketInfoCard';

const TicketDesktopInfoCard = ({ className }: { className?: string }) => (
  <DesktopInfoCard className={className}>
    <TicketInfoCard />
  </DesktopInfoCard>
);

export default TicketDesktopInfoCard;
