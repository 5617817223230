import * as React from 'react';
import useMessages from '../../hooks/useMessages';

const TableContentPlaceholder = () => {
  const getMessage = useMessages();

  return (
    <thead className="col-span-full">
      <tr>
        <th className="text-text-md-medium">{getMessage('filters.noResults')}</th>
        <td />
      </tr>
    </thead>
  );
};

export default TableContentPlaceholder;
