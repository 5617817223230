import * as React from 'react';
import { useEffect } from 'react';
import { format } from 'date-fns';
import usePagination from '../../../hooks/usePagination';
import useFilters from '../../../hooks/useFilters';
import { useGetDocumentsQuery } from '../../../api/appApi';
import ResponsiveTable, {
  ResponsiveTableBody,
  ResponsiveTableBodyRow,
  ResponsiveTableHeader,
} from '../../../components/Table/ResponsiveTable';
import TableContentPlaceholder from '../../../components/Table/TableContentPlaceholder';
import { TableId } from '../../../store/appSlice';
import { TableBodyCell } from '../../../components/Table/Table';
import Chip from '../../../components/Chip/Chip';
import PaginationControls from '../../../components/Table/PaginationControls';
import { Document } from '../../../api/modules/documents/documentsTypes';
import useMessages from '../../../hooks/useMessages';

const TenantDocumentsList = () => {
  const getMessage = useMessages();
  const [selectedPage, setSelectedPage] = usePagination('documentsList');
  const { filterValues } = useFilters('documentsList');

  useEffect(() => {
    setSelectedPage(1);
  }, []);

  const { data } = useGetDocumentsQuery({
    ...filterValues,
    page: selectedPage.toString(),
  }, { refetchOnMountOrArgChange: true });
  const documentsData = data?.documents || [] as Document[];

  const ids = data?.documents.map(({ id }) => id) || [];

  const columnLabels = [
    { label: 'documents.column.documentNumber' },
    { label: 'documents.column.date' },
    { label: 'documents.column.title' },
    { label: 'documents.column.description' },
    { label: 'documents.column.creator' },
  ];

  return (
    <>
      <ResponsiveTable className="col-span-full">
        {documentsData.length === 0 ? <TableContentPlaceholder /> : (
          <>
            <ResponsiveTableHeader ids={ids} columnLabels={columnLabels} tableName={'ticketsTable' as TableId} />
            <ResponsiveTableBody>
              {documentsData.map(({
                id, documentNumber, creationDate, title, description, creator, revoked,
              }) => (
                <ResponsiveTableBodyRow
                  key={id}
                  className="border-b last:border-b-0"
                  clickable={(!revoked
                    ? { href: `/dokumenty/${id}`, ariaLabel: `Przejdź do szczegółów dokumentu ${documentNumber} ${title}` }
                    : undefined)}
                >
                  <TableBodyCell>{documentNumber}</TableBodyCell>
                  <TableBodyCell>
                    <Chip chipStyle="ghost" className="w-fit">
                      {format(new Date(creationDate), 'dd-MM-yyyy')}
                    </Chip>
                  </TableBodyCell>
                  <TableBodyCell className="font-semibold text-typography-black">{title}</TableBodyCell>
                  <TableBodyCell className="overflow-ellipsis">{revoked ? getMessage('documents.document.revoked') : description}</TableBodyCell>
                  <TableBodyCell>{creator}</TableBodyCell>
                </ResponsiveTableBodyRow>
              ))}
            </ResponsiveTableBody>
          </>
        )}
      </ResponsiveTable>
      {data?.metadata && (
        <PaginationControls
          className="col-span-full"
          selectedPage={selectedPage}
          setSelectedPage={setSelectedPage}
          paginationMetadata={data.metadata}
        />
      )}
    </>
  );
};

export default TenantDocumentsList;
