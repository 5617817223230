import * as React from 'react';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import Layout from '../../../components/Layout/Layout';
import ParametersSidebar from '../ParametersSidebar';
import ContentWrapper from '../../../components/ContentWrapper/ContentWrapper';
import PageHeader from '../../../components/Layout/PageHeader';
import Card from '../../../components/Card/Card';
import { useChangeJournalMutation, useDeleteJournalMutation, useGetJournalQuery } from '../../../api/appApi';
import JournalForm from '../JournalFormPage/JournalForm';
import { FullJournal } from '../../../api/modules/documents/documentsTypes';
import { FormNotice } from '../../../components/FormNotice/FormNotice';
import useMessages from '../../../hooks/useMessages';
import RemoveJournalPopUp from '../JournalEditPage/RemoveJournalPopUp';
import Button from '../../../components/Button/Button';

const JournalEditPage = () => {
  const intl = useIntl();
  const { id } = useParams();
  const { data: journal } = useGetJournalQuery(id, { skip: !id });
  const [deleteJournal, { error: deleteError, status: deleteStatus }] = useDeleteJournalMutation();
  const [patchJournal, { isSuccess: isChangeSuccess, error: changeError, status: changeStatus }] = useChangeJournalMutation();
  const navigate = useNavigate();
  const getMessage = useMessages();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  useEffect(() => {
    if (deleteStatus === 'fulfilled') navigate('/parametry/dzienniki');
  }, [changeStatus, deleteStatus]);

  if (!id) return null;
  const onSubmit = (data: FullJournal) => patchJournal({ id, journal: data });

  const handleDeleteJournal = () => {
    deleteJournal({ id });
  };

  return (
    <Layout currentPage="parameters">
      <div className="flex flex-grow h-full">
        <ParametersSidebar selectedPage="journals" />
        <div className="flex flex-col flex-grow">
          <PageHeader
            currentPage="parameters"
            pageTitle={intl.formatMessage(
              { id: 'parameters.journals.journal' },
              { journalName: journal?.name },
            )}
            path="/parametry/dzienniki"
          >
            <Button type="button" styleType="secondary" onClick={() => setIsDialogOpen(true)}>{getMessage('remove')}</Button>
          </PageHeader>
          <ContentWrapper>
            <Card className="col-span-12">
              {isChangeSuccess && <FormNotice type="success" message={getMessage('form.success')} />}
              {/* @ts-ignore */}
              {deleteError && <FormNotice type="error" message={deleteError.data.errors || getMessage('form.error')} />}
              {/* @ts-ignore */}
              {changeError && <FormNotice type="error" message={changeError.data.errors || getMessage('form.error')} />}
              <JournalForm journal={journal} onSubmit={onSubmit} />
              <RemoveJournalPopUp
                isOpen={isDialogOpen}
                onClose={() => setIsDialogOpen(false)}
                onAccept={handleDeleteJournal}
                name={journal?.name || ''}
              />
            </Card>
          </ContentWrapper>
        </div>
      </div>
    </Layout>
  );
};

export default JournalEditPage;
