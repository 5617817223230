const calculateChange = (initialValue: number | undefined, finalValue: number): {
  percentageChange?: number,
  change: number,
} => {
  let percentageChange;
  let change;
  if (initialValue) {
    percentageChange = ((finalValue - initialValue) / initialValue) * 100;
    change = finalValue - initialValue;
  } else {
    change = finalValue;
  }
  return ({
    percentageChange,
    change,
  });
};

export default calculateChange;
