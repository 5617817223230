/* eslint-disable react/jsx-props-no-spreading */

import * as React from 'react';
import { useEffect } from 'react';
import { useForm, FormProvider, useFieldArray } from 'react-hook-form';
import { useNavigate } from 'react-router';
import Layout from '../../../components/Layout/Layout';
import PageHeader from '../../../components/Layout/PageHeader';
import ContentWrapper from '../../../components/ContentWrapper/ContentWrapper';
import Card from '../../../components/Card/Card';
import useMessages from '../../../hooks/useMessages';
import Button from '../../../components/Button/Button';
import {
  FormInput, FormTextarea, FormDatePicker, FormFileInput,
} from '../../../components/FormInputs';
import { useGetAnnouncementRecipientsQuery, usePostAnnouncementMutation } from '../../../api/appApi';
import FormSearchableMultiselectDropdown from '../../../components/FormInputs/inputs/FormSearchableMultiselectDropdown';
import { translateAnnouncementToApi } from '../../../api/modules/announcements/announcementsTranslator';
import { AnnouncementInputs } from '../../../api/modules/announcements/announcementsTypes';
import FileUploadPreview from '../../../components/AttachmentPreview/FileUploadPreview/FileUploadPreview';
import { usePermissions } from '../../../hooks/usePermissions';
import useHomeownerAssociationsMode from '../../../hooks/useHomeownerAssociationsMode';
import { FormNotice } from '../../../components/FormNotice/FormNotice';

const AnnouncementForm = () => {
  const getMessage = useMessages();
  const hoaMode = useHomeownerAssociationsMode();
  const [postAnnouncement, result] = usePostAnnouncementMutation();
  const { checkPermission } = usePermissions();
  const navigate = useNavigate();
  const methods = useForm<AnnouncementInputs>({
    defaultValues: {
      attachments: [{ description: '' }],
      employees: [],
      homeownerAssociations: [],
      estates: [],
      buildings: [],
      tenants: [],
      roles: [],
    },
  });

  const onSubmit = (data: AnnouncementInputs) => {
    postAnnouncement(translateAnnouncementToApi(data));
  };

  const { fields, append, remove } = useFieldArray({ name: 'attachments', control: methods.control });

  const attachments = methods.watch('attachments');
  const allAttachmentsSelected = attachments.every((attachment) => attachment.file?.length);

  useEffect(() => {
    if (allAttachmentsSelected) {
      append({ file: undefined, description: '' });
    }
  }, [allAttachmentsSelected]);

  useEffect(() => {
    if (result.status === 'fulfilled') navigate('/aktualnosci');
  }, [result.status]);

  checkPermission('announcements_notice_add');

  return (
    <Layout currentPage="announcements">
      <PageHeader pageTitle={getMessage('announcements.notice.new')} currentPage="announcements" path="/aktualnosci" />
      <ContentWrapper>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)} className="col-span-full">
            <Card className="grid grid-cols-12">
              { /* @ts-ignore */ }
              {result.isError && <FormNotice noticeClassName="col-span-full" type="error" message={result.error.data?.errors || getMessage('form.error')} />}
              <section className="col-span-full md:col-span-6">
                <FormInput
                  className="w-full"
                  inputClassName="w-full"
                  id="title"
                  type="text"
                  label={`${getMessage('announcements.notice.form.title')}*`}
                  options={{
                    required: getMessage('announcements.notice.form.error.title.required'),
                    maxLength: { value: 150, message: getMessage('announcements.notice.form.error.title.maxLength') },
                  }}
                />
                <FormTextarea
                  className="mt-6 w-full"
                  inputClassName="w-full"
                  id="description"
                  label={`${getMessage('announcements.notice.form.description')}*`}
                  options={{ required: getMessage('announcements.notice.form.error.description.required') }}
                />
                <div className="flex flex-wrap w-full">
                  {fields.map(({ file, id }, index) => {
                    const fileSelected = file && file.length > 0;
                    return (
                      <div key={id} id={id} className="mb-4">
                        {fileSelected && (
                          <FileUploadPreview attachment={attachments[index]} onDelete={() => remove(index)} />
                        )}
                        <FormFileInput
                          inputClassName={fileSelected ? 'hidden' : ''}
                          id={`attachments.${index}.file`}
                          labelText={getMessage('tickets.ticket.addAttachment')}
                          labelClassName={fileSelected ? 'hidden' : ''}
                          withIcon
                        />
                      </div>
                    );
                  })}
                </div>
                <FormDatePicker
                  id="expiresAt"
                  label={getMessage('announcements.notice.form.expirationDate')}
                  minDate={new Date()}
                />
              </section>
            </Card>
            <p className="text-header-sm my-4">{getMessage('announcements.notice.form.receiversGroups')}</p>
            <Card className="grid">
              <section className="col-span-12">
                <FormSearchableMultiselectDropdown
                  label={getMessage('announcements.notice.form.employees')}
                  id="employees"
                  query={useGetAnnouncementRecipientsQuery}
                  queryOptions={{ group: 'employees' }}
                  ariaLabel={getMessage('form.ariaLabel.employees')}
                />
                {hoaMode && (
                  <FormSearchableMultiselectDropdown
                    label={getMessage('announcements.notice.form.homeownerAssociations')}
                    id="homeownerAssociations"
                    query={useGetAnnouncementRecipientsQuery}
                    queryOptions={{ group: 'homeowner_associations' }}
                    ariaLabel={getMessage('form.ariaLabel.hoa')}
                  />
                )}
                <FormSearchableMultiselectDropdown
                  label={getMessage('announcements.notice.form.estates')}
                  id="estates"
                  query={useGetAnnouncementRecipientsQuery}
                  queryOptions={{ group: 'estates' }}
                  ariaLabel={getMessage('form.ariaLabel.estates')}
                />
                <FormSearchableMultiselectDropdown
                  label={getMessage('announcements.notice.form.buildings')}
                  id="buildings"
                  query={useGetAnnouncementRecipientsQuery}
                  queryOptions={{ group: 'buildings' }}
                  ariaLabel={getMessage('form.ariaLabel.buildings')}
                />
                <FormSearchableMultiselectDropdown
                  label={getMessage('announcements.notice.form.tenants')}
                  id="tenants"
                  query={useGetAnnouncementRecipientsQuery}
                  queryOptions={{ group: 'tenants' }}
                  ariaLabel={getMessage('form.ariaLabel.tenants')}
                />
                <FormSearchableMultiselectDropdown
                  label={getMessage('announcements.notice.form.roles')}
                  id="roles"
                  query={useGetAnnouncementRecipientsQuery}
                  queryOptions={{ group: 'roles' }}
                  ariaLabel={getMessage('form.ariaLabel.roles')}
                />
              </section>
            </Card>
            <div className="grid grid-cols-3">
              <Button type="submit" className="mt-6 col-start-4">{getMessage('save')}</Button>
            </div>
          </form>
        </FormProvider>
      </ContentWrapper>
    </Layout>
  );
};

export default AnnouncementForm;
