import * as React from 'react';
import format from 'date-fns/format';
import { useState } from 'react';
import { SettlementDocument } from '../../../../../api/modules/settlements/settlementsTypes';
import useMessages from '../../../../../hooks/useMessages';
import Chip from '../../../../../components/Chip/Chip';
import ArrowUp from '../../../../../icons/dropdown-arrow-up.svg';
import ArrowDown from '../../../../../icons/dropdown-arrow-down.svg';
import ItemsTable from '../ItemsTable';

interface Props {
  document: SettlementDocument,
}

const MobileDocumentItem = ({ document }: Props) => {
  const getMessage = useMessages();
  const [itemsTableOpen, setItemsTableOpen] = useState(false);

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      role="button"
      tabIndex={0}
      className="grid grid-cols-12 my-4 leading-normal text-text-md-semibold text-typography-dark border-b"
      onClick={() => !!document.items && document.items.length && setItemsTableOpen((open) => !open)}
    >
      <div className="col-span-5">
        {getMessage('settlements.titleBalance.header.operation')}
      </div>
      <div className="mb-4 col-span-6 flex flex-col items-start">
        <span className="text-typography-black">{document.operation}</span>
        <span>{document.description}</span>
      </div>
      <div className="col-span-1 flex justify-end">
        {!!document.items && document.items.length > 0 && (
          <div>
            {itemsTableOpen ? <ArrowUp className="inline float-right ml-1 mt-2" /> : <ArrowDown className="inline float-right ml-1 mt-2" />}
          </div>
        )}
      </div>
      <div className="col-span-5">
        {getMessage('settlements.titleBalance.header.creationDate')}
      </div>
      <div className="mb-4 col-span-7">
        <Chip chipStyle="gray" className="w-fit">
          {format(new Date(document.date), 'dd-MM-yyyy')}
        </Chip>
      </div>
      <div className="col-span-5">
        {getMessage('settlements.titleBalance.header.paymentDate')}
      </div>
      <div className="mb-4 col-span-7">
        {document.deadline && (
          <Chip chipStyle="gray" className="w-fit">
            {format(new Date(document.deadline), 'dd-MM-yyyy')}
          </Chip>
        )}
      </div>
      <div className="col-span-5">
        {getMessage('settlements.titleBalance.header.status')}
      </div>
      <div className="mb-4 col-span-7">
        <div id={`status-checkbox-${document.id}`} className="hidden">
          {document.open
            ? getMessage('settlements.titleBalance.header.status.open')
            : getMessage('settlements.titleBalance.header.status.notOpened')}
        </div>
        <input
          type="checkbox"
          disabled
          checked={!document.open}
          aria-labelledby={`status-checkbox-${document.id}`}
          title={document.open ? getMessage('settlements.titleBalance.header.status.open') : getMessage('settlements.titleBalance.header.status.notOpened')}
        />
      </div>
      <div className="col-span-5">
        {getMessage('settlements.titleBalance.header.deposits')}
      </div>
      <div className="mb-4 col-span-7">
        {document.debits}
      </div>
      <div className="col-span-5">
        {getMessage('settlements.titleBalance.header.credits')}
      </div>
      <div className="mb-4 col-span-7">
        {document.credits}
      </div>
      <div className="col-span-5">
        {getMessage('settlements.titleBalance.header.balance')}
      </div>
      <div className="mb-4 col-span-7">
        <span className="text-typography-black">{document.balance}</span>
      </div>
      {document.items && document.items.length > 0 && itemsTableOpen && (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
        <div
          className="col-span-12"
          onClick={(e) => e.stopPropagation()}
          role="button"
          aria-labelledby={`status-checkbox-${document.id}`}
          tabIndex={0}
        >
          <ItemsTable
            value={document.value}
            vat={document.vat}
            documentId={document.id}
            items={document.items}
            attachment={document.attachment}
            readAt={document.readAt}
          />
        </div>
      )}
    </div>
  );
};

export default MobileDocumentItem;
