/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import MailServerConfigForm from './MailServerConfigForm';
import Card from '../../components/Card/Card';
import TestMailServerConfigForm from './TestMailServerConfigForm';

const MailServerConfig = () => (
  <Card className="col-span-full grid grid-cols-1">
    <MailServerConfigForm />
    <TestMailServerConfigForm />
  </Card>
);

export default MailServerConfig;
