import * as React from 'react';
import useMessages from '../../../hooks/useMessages';

const PreviewReplacement = ({ widthInPx, heightInPx }: { widthInPx: string, heightInPx: string }) => {
  const getMessage = useMessages();

  return (
    <div className={`text-typography-dark text-text-sm-medium w-[${widthInPx}px] h-[${heightInPx}px] flex justify-center items-center`}>
      <span className="inline-block">
        {getMessage('attachment.previewUnavailable')}
      </span>
    </div>
  );
};

export default PreviewReplacement;
