import { PaginationMetadata } from '../../paginationTypes';
import { ApiTask, Task } from '../tasks/tasksTypes';

export type ApiDocumentsResponse = {
  documents: ApiDocument[],
  metadata: PaginationMetadata,
};

export type ApiDocument = {
  category: string,
  created_at: string,
  creator: string,
  description: string,
  document_number: string,
  id: string,
  journal: string,
  realizer: string,
  receiver: string,
  sender: string,
  status: string,
  tenant: string,
  title: string,
  revoked: boolean,
};

export type ApiFullDocument = {
  document: {
    id: string,
    document_number: string,
    title: string,
    sender: string,
    receiver: string,
    description: string,
    created_at: string,
    journal: FullJournal,
    status: ApiDocumentStatus,
    category: DocumentCategory,
    creator: {
      id: string,
      full_name: string
    },
    realizer: {
      id: string,
      full_name: string
    },
    tenant?: {
      id: string,
      name: string,
    },
    attachments: ApiDocumentAttachment[],
    comments: ApiDocumentComment[]
    tasks: ApiTask[],
    revoked: boolean,
  }
};

export type FullDocument = {
  id: string,
  documentNumber: string,
  title: string,
  sender: string,
  receiver: string,
  description: string,
  creationDate: string,
  journal: FullJournal,
  status: DocumentStatus,
  category: DocumentCategory,
  creator: {
    id: string,
    name: string
  },
  realizer: {
    id: string,
    name: string
  },
  tenant?: {
    id: string,
    name: string,
  },
  tasks: Task[],
  attachments: Attachment[],
  comments: DocumentComment[],
  revoked: boolean,
};

export type ApiDocumentAttachment = {
  description: string,
  filename: string,
  content_type: string,
  path: string
};

export interface ApiDocumentComment {
  attachments: ApiDocumentAttachment[]
  author: string,
  content: string,
  created_at: string,
}

export interface DocumentComment {
  author: string,
  content: string,
  createdAt: string,
  attachments: Attachment[]
}

export interface Attachment {
  description: string,
  filename: string,
  path: string,
  contentType: string,
}

export type Document = Omit<ApiDocument, 'document_number' | 'created_at'> & { documentNumber: string, creationDate: string };

export type DocumentsResponse = {
  documents: Document[],
  metadata: PaginationMetadata,
};

export type ApiDocumentStatus = {
  id: string,
  object_type: string,
  name: string,
  active: boolean,
  internal_name: string,
  external_name: string,
  description: string,
};

export type ApiDocumentStatuses = {
  statuses: ApiDocumentStatus[]
};

export type DocumentStatus = {
  id: string,
  name: string,
};

export type FullDocumentStatus = {
  id: string,
  objectType: string,
  name: 'open' | 'completed' | string,
  active: boolean,
  internalName: string,
  externalName: string,
  description: string,
};

export interface DocumentCategory {
  id: string,
  name: string,
}

export interface ApiDocumentCategories {
  document_categories: DocumentCategory[]
}

export interface ApiPostDocument {
  category_id: string,
  description: string,
  document_number: string,
  journal_id: string,
  realizer_id?: string,
  receiver: string,
  sender: string,
  status_id?: string,
  tenant_id?: string,
  title: string,
  attachments: DocumentAttachment[],
}

export type ApiPostDocumentComment = {
  id: string,
  payload: FormData,
};

export type DocumentFormData = {
  title: string,
  journalId: string,
  number: string,
  sender: string,
  receiver: string,
  tenantId?: string,
  description: string,
  statusId?: string,
  categoryId: string,
  assigneeId?: string,
  attachments: DocumentAttachment[],
};

type DocumentAttachment = {
  file: FileList | undefined,
  description: string
};

export type Journal = {
  autonumbering: boolean,
  id: string,
  name: string,
  prefix: string,
};

export type Journals = {
  journals: Journal[]
  metadata: PaginationMetadata,
};

export type FullJournal = Omit<Journal, 'id'> & {
  description: string
};

export type ApiFullJournal = {
  journal: FullJournal
};

export type ApiPostFullJournal = ApiFullJournal & {
  id: string,
};

export interface ApiPostChangeDocumentData {
  documents: {
    [id: string]: {
      status_id?: string,
      category_id?: string,
      realizer_id?: string,
    }
  }
}

export const documentDefaultColumnIds = [
  'document_number', 'created_at', 'creator', 'description', 'journal', 'title', 'status', 'category', 'realizer',
];
export const documentCustomizableColumnIds = [
  'sender', 'receiver', 'tenant',
];

export type DocumentColumnId = typeof documentCustomizableColumnIds[number];
