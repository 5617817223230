/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { useForm, FormProvider, useFieldArray } from 'react-hook-form';
import useMessages from '../../../hooks/useMessages';
import {
  usePostDocumentCommentMutation,
  usePostTaskCommentMutation,
  usePostTicketCommentMutation,
} from '../../../api/appApi';
import { FormTextarea, FormInput, FormFileInput } from '../../FormInputs';
import Button from '../../Button/Button';
import FileUploadPreview from '../../AttachmentPreview/FileUploadPreview/FileUploadPreview';
import { translateTicketCommentToFormData } from '../../../api/modules/tickets/ticketsTranslator';
import { translateTaskCommentToFormData } from '../../../api/modules/tasks/tasksTranslator';
import { FormNotice } from '../../FormNotice/FormNotice';
import { translateDocumentCommentToFormData } from '../../../api/modules/documents/documentsTranslator';

type CommentAttachment = {
  file: FileList | undefined,
  description: string
};

export interface CommentFormData {
  content: string,
  publicComment: boolean
  attachments: CommentAttachment[]
  ticketId?: string
  taskId?: string
}

interface Props {
  ticketId?: string,
  taskId?: string,
  documentId?: string,
  publicComment: boolean,
}

const CommentForm = ({
  ticketId, taskId, documentId, publicComment,
}: Props) => {
  const getMessage = useMessages();
  const [postTicketCommentData, { error: postTicketError }] = usePostTicketCommentMutation();
  const [postTaskCommentData, { error: postTaskError }] = usePostTaskCommentMutation();
  const [postDocumentCommentData, { error: postDocumentError }] = usePostDocumentCommentMutation();
  const methods = useForm<CommentFormData>({
    defaultValues: {
      attachments: [{ description: '' }],
    },
  });
  const { fields, append, remove } = useFieldArray({ name: 'attachments', control: methods.control });
  const attachments = methods.watch('attachments');
  const allAttachmentsSelected = attachments.every((attachment) => attachment.file?.length);

  React.useEffect(() => {
    if (allAttachmentsSelected) {
      append({ file: undefined, description: '' });
    }
  }, [allAttachmentsSelected]);

  const onSubmit = (data: CommentFormData) => {
    if (ticketId) {
      postTicketCommentData({
        id: ticketId,
        payload: translateTicketCommentToFormData({ ...data, publicComment }),
      });
    } else if (taskId) {
      postTaskCommentData({
        id: taskId,
        payload: translateTaskCommentToFormData({ ...data }),
      });
    } else if (documentId) {
      postDocumentCommentData({
        id: documentId,
        payload: translateDocumentCommentToFormData({ ...data }),
      });
    }
    methods.reset();
  };

  return (
    <FormProvider {...methods}>
      {/* @ts-ignore */}
      {postTicketError && <FormNotice type="error" message={postTicketError?.data?.errors || getMessage('form.error')} />}
      {/* @ts-ignore */}
      {postTaskError && <FormNotice type="error" message={postTaskError?.data?.errors || getMessage('form.error')} />}
      {/* @ts-ignore */}
      {postDocumentError && <FormNotice type="error" message={postDocumentError?.data?.errors || getMessage('form.error')} />}
      <form onSubmit={methods.handleSubmit(onSubmit)} className="w-full pt-2 text-sm flex flex-col">
        <FormTextarea
          className="block"
          inputClassName="w-full"
          id="content"
          options={{ required: getMessage('tickets.ticket.comments.error.content.required'),
          validate: (value) => {
            if (!/\S/.test(value)) {
              return getMessage('tickets.ticket.comments.error.content.required');
            }
            return true;
          }
           }}
          placeholder={getMessage('tickets.ticket.comments.write')}
          rows={1}
          ariaLabel="Wprowadź treść komentarza do zgłoszenia"
          label={getMessage('tickets.ticket.comments.comment')}
        />
        <div className="flex flex-wrap items-end justify-between mobile:mt-2">
          <div className="mobile:w-full">
            {fields.map(({ file, id }, index) => {
              const fileSelected = file && file.length > 0;
              return (
                <div key={id} id={id} className="flex flex-wrap align-start my-3 ">
                  {fileSelected && (
                    <FileUploadPreview attachment={attachments[index]} onDelete={() => remove(index)} />
                  )}
                  <div className="mobile:w-full">
                    {fileSelected && (
                      <FormInput
                        className="mobile:w-full mobile:mt-1"
                        inputClassName="text-text-sm-medium mobile:w-full"
                        id={`attachments.${index}.description`}
                        type="text"
                        placeholder={getMessage('tickets.ticket.addAttachmentDescription')}
                      />
                    )}
                    <FormFileInput
                      inputClassName={fileSelected ? 'hidden' : ''}
                      id={`attachments.${index}.file`}
                      labelText={getMessage('tickets.ticket.addAttachment')}
                      labelClassName="overflow-hidden h-1 w-1"
                    />
                  </div>
                </div>
              );
            })}
          </div>
          <Button type="submit" className="mobile:w-full mobile:mt-4">{getMessage('sendResponse')}</Button>
        </div>
      </form>
    </FormProvider>
  );
};
export default CommentForm;
