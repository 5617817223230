import * as React from 'react';
import ArrowUp from '../../icons/dropdown-arrow-up.svg';
import ArrowDown from '../../icons/dropdown-arrow-down.svg';
import { Permission, PermissionCategory, SimplifiedPermissionTree } from '../../../utils/generated/permissionsTypes';
import { permissionCategories, permissionTree } from '../../../utils/permissions';
import useMessages, { usePermissionMessages } from '../../hooks/useMessages';
import useToggleArray from '../../hooks/useToggleArray';
import Button from '../Button/Button';

interface Props {
  startingPermissions: Permission[],
  onSubmit: (selectedPermissions: Permission[]) => void,
  secondButtonCallback?: () => void,
  secondButtonText?: string,
}

const PermissionEditTree = ({
  startingPermissions, onSubmit, secondButtonCallback, secondButtonText,
}: Props) => {
  const getPermissionMessage = usePermissionMessages();
  const getMessage = useMessages();
  const [isOpen, toggleOpen] = useToggleArray(permissionCategories);
  const [isSelected, toggleSelect, allSelectedPermissions] = useToggleArray(startingPermissions);

  // eslint-disable-next-line arrow-body-style
  const isCategorySelected = (category: PermissionCategory): boolean => {
    return (permissionTree as SimplifiedPermissionTree)[category]
      .filter((categoryPermission: string) => !allSelectedPermissions.includes(categoryPermission)).length === 0;
  };

  const selectCategory = (category: PermissionCategory): void => {
    if (isCategorySelected(category)) {
      permissionTree[category].forEach((permission) => toggleSelect(permission));
    } else {
      permissionTree[category].forEach((permission) => {
        if (!isSelected(permission)) {
          toggleSelect(permission);
        }
      });
    }
  };

  return (
    <div>
      {permissionCategories.map((category) => (
        <React.Fragment key={category}>
          <div
            className="w-full border-b-2 border-black hover:bg-gray-50 py-3 pt-4 mt-4"
            tabIndex={0}
            role="button"
            onClick={() => toggleOpen(category)}
            onKeyPress={() => toggleOpen(category)}
          >
            <input
              id={`${category}-checkbox`}
              type="checkbox"
              checked={isCategorySelected(category)}
              onChange={() => selectCategory(category)}
              onClick={(e) => e.stopPropagation()}
              className="h-4 w-4 mx-3 text-black bg-black border border-black rounded-xl accent-black"
            />
            <label
              htmlFor={`${category}-checkbox`}
              className="font-bold"
            >
              {getPermissionMessage(category, null)}
            </label>
            {isOpen(category) ? <ArrowUp className="inline ml-1 float-right m-2" /> : <ArrowDown className="inline ml-1 float-right m-2" />}
          </div>
          {isOpen(category) && (
            <div role="list">
              {(permissionTree[category] as Permission[]).map((permission) => (
                <div role="listitem" className="border-b py-3 pl-6 text-sm" key={`${category}-${permission}`}>
                  <span className="align-middle">
                    <input
                      id={`${permission}-checkbox`}
                      type="checkbox"
                      checked={isSelected(permission)}
                      onChange={() => toggleSelect(permission)}
                      className="h-4 w-4 mr-3 text-black bg-black border border-black rounded-xl accent-black"
                    />
                  </span>
                  <label htmlFor={`${permission}-checkbox`}>{getPermissionMessage(category, permission)}</label>
                </div>
              ))}
            </div>
          )}
        </React.Fragment>
      ))}
      <Button type="submit" onClick={() => onSubmit(allSelectedPermissions as Permission[])} styleType="primary" className="w-1/6 mt-12">
        {getMessage('save')}
      </Button>
      {secondButtonCallback && secondButtonText && (
        <Button styleType="secondary" onClick={() => secondButtonCallback()} className="mt-8 ml-4">
          {secondButtonText}
        </Button>
      )}
    </div>
  );
};

export default PermissionEditTree;
