import * as React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { FullTaskStatus } from '../../../api/modules/tasks/tasksTypes';
import { FullTicketStatus } from '../../../api/modules/tickets/ticketsTypes';
import { FullDocumentStatus } from '../../../api/modules/documents/documentsTypes';
import Card from '../../../components/Card/Card';
import { usePutStatusMutation } from '../../../api/appApi';
import { FormCheckboxInput, FormInput, FormTextarea } from '../../../components/FormInputs';
import Button from '../../../components/Button/Button';
import useMessages from '../../../hooks/useMessages';
import { FormNotice } from '../../../components/FormNotice/FormNotice';

interface Props {
  status: FullTaskStatus | FullTicketStatus | FullDocumentStatus,
}

const StatusForm = ({ status }: Props) => {
  const getMessage = useMessages();
  const [putStatus, { error, isSuccess }] = usePutStatusMutation();

  const methods = useForm({
    values: {
      id: status?.id,
      external_name: status?.externalName,
      internal_name: status?.internalName,
      description: status?.description,
      active: status?.active,
    },
  });

  const onSubmit = (data: any) => putStatus(data);

  return (
    <Card className="col-span-full flex flex-col">
      {isSuccess && <FormNotice type="success" message={getMessage('form.success')} />}
      {/* @ts-ignore */}
      {error && <FormNotice type="error" message={error.data.errors || getMessage('form.error')} />}
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} className="flex flex-col">
          <FormCheckboxInput
            id="active"
            label={getMessage('parameters.statuses.form.active')}
            className="mb-2 -ml-2"
            disabled={status?.name === 'open' || status?.name === 'completed'}
          />
          <FormInput
            id="external_name"
            type="text"
            label={getMessage('parameters.statuses.form.externalName')}
            className="mb-2"
            options={{ required: getMessage('parameters.statuses.form.externalName.required') }}
          />
          <FormInput
            id="internal_name"
            type="text"
            label={getMessage('parameters.statuses.form.internalName')}
            className="mb-2"
            options={{ required: getMessage('parameters.statuses.form.internalName.required') }}
          />
          <FormTextarea id="description" label={getMessage('parameters.statuses.form.description')} />
          <div className="col-span-12 col-start-1">
            <Button type="submit" className="mt-6">{getMessage('save')}</Button>
          </div>
        </form>
      </FormProvider>
    </Card>
  );
};

export default StatusForm;
