import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useGetTicketQuery } from '../../../api/appApi';
import useMessages from '../../../hooks/useMessages';
import PageHeader from '../../../components/Layout/PageHeader';
import ContentWrapper from '../../../components/ContentWrapper/ContentWrapper';
import Layout from '../../../components/Layout/Layout';
import EmployeeTicketPage from './EmployeeTicketPage/EmployeeTicketPage';
import TenantTicketPage from './TenantTicketPage/TenantTicketPage';
import { usePermissions } from '../../../hooks/usePermissions';
import TicketMobileInfoCard from './EmployeeTicketPage/TicketInfoCard/TicketMobileInfoCard';

const TicketPage = () => {
  const { checkModuleEnabled } = usePermissions();
  const { id } = useParams();
  if (!id) return null;
  const { data: ticket } = useGetTicketQuery(id);
  const { hasRoleType } = usePermissions();
  const getMessage = useMessages();

  checkModuleEnabled('tickets');

  return (
    <Layout currentPage="tickets">
      <PageHeader pageTitle={`${getMessage('tickets.ticket.ticket')} ${ticket ? ticket.ticketNumber : ''}`} currentPage="tickets" path="/zgloszenia" />
      {hasRoleType('worker')
          && <TicketMobileInfoCard />}
      <ContentWrapper>
        {hasRoleType('worker')
          ? (<EmployeeTicketPage />)
          : (<TenantTicketPage />) }
      </ContentWrapper>
    </Layout>
  );
};

export default TicketPage;
