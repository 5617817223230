import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from './reduxHooks';
import { setSortParams, SortParamsValues, TableId } from '../store/appSlice';

const useSortParams = (tableId: TableId) => {
  const dispatch = useAppDispatch();

  const sortParamsValues = useAppSelector((state) => state.app.sortParams.find((sortParams) => sortParams.id === tableId)?.values) || {};
  const { order: sortOrder, sort: sortBy } = sortParamsValues;

  const saveSortParamsValues = useCallback(
    (newSortParams: SortParamsValues) => dispatch(setSortParams({ id: tableId, values: newSortParams })),
    [tableId],
  );

  const handleSaveSortParams = useCallback((columnName: string) => {
    if (sortBy === columnName) {
      const newOrder = sortOrder === 'desc' ? 'asc' : 'desc';
      saveSortParamsValues({ sort: columnName, order: newOrder });
    } else {
      saveSortParamsValues({ sort: columnName, order: 'asc' });
    }
  }, [sortBy, sortOrder]);

  return { saveSortParamsValues, sortParamsValues, handleSaveSortParams };
};

export default useSortParams;
