import { useMemo } from 'react';
import { useGetCompanySettingsQuery } from '../api/appApi';

const useHomeownerAssociationsMode = (): boolean => {
  const { data: settings } = useGetCompanySettingsQuery();

  return useMemo(() => !!settings?.homeownerAssociationsMode, [settings]);
};

export default useHomeownerAssociationsMode;
