import classNames from 'classnames';
import * as React from 'react';
import { useMediaQuery } from 'react-responsive';
import { mobile } from '../../../utils/breakpoints';
import Dropdown from '../Dropdown/Dropdown';

interface Props {
  tabElements: {
    label: string,
    isActive: boolean,
    onClick: () => void,
  }[]
}

interface TabButtonProps {
  label: string,
  onClick: () => void,
  styles: string,
  activeStyles: string,
}

const TabButton = ({
  label, onClick, styles, activeStyles,
}: TabButtonProps) => (
  <button type="button" onClick={onClick} className={classNames(styles, activeStyles)}>
    {label}
  </button>
);

const WidgetTabs = ({ tabElements }: Props) => {
  const isMobile = useMediaQuery({ query: mobile.raw });

  return (
    <div className="md:flex">
      {isMobile ? (
        <Dropdown label={tabElements.find((item) => item.isActive)?.label || 'wszystkie'} listClassName="bg-background-white border border-stroke-grey-100 w-fit -translate-x-4">
          {tabElements.map((tabItem) => (
            <TabButton
              key={tabItem.label}
              label={tabItem.label}
              onClick={tabItem.onClick}
              styles="text-text-md-semibold hover:text-main hover:underline h-fit py-2 px-4 w-full"
              activeStyles={tabItem.isActive ? 'bg-light-200 text-main' : 'text-typography-dark'}
            />
          ))}
        </Dropdown>
      ) : tabElements.map((tabItem) => (
        <TabButton
          key={tabItem.label}
          label={tabItem.label}
          onClick={tabItem.onClick}
          styles="text-text-md-semibold hover:text-main h-fit px-3 ml-2 rounded"
          activeStyles={tabItem.isActive ? 'bg-background-white text-main' : 'bg-transparent text-typography-dark'}
        />
      ))}
    </div>
  );
};

export default WidgetTabs;
