import * as React from 'react';
import { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import {
  useGetTaskStatusesQuery,
  useGetTasksQuery,
} from '../../../api/appApi';
import { FilterValues } from '../../../store/appSlice';
import usePagination from '../../../hooks/usePagination';
import useFilters from '../../../hooks/useFilters';
import PaginationControls from '../../../components/Table/PaginationControls';
import useSortParams from '../../../hooks/useSortParams';
import { md } from '../../../../utils/breakpoints';
import TasksListDesktop from './TasksListDesktop';
import TasksListMobile from './TasksListMobile';

interface Props {
  prefilter?: 'open' | 'unassigned'
}

const TasksList = ({ prefilter }: Props) => {
  const [selectedPage, setSelectedPage] = usePagination('tasksTable');
  const { filterValues } = useFilters('tasksTable');
  const { sortParamsValues } = useSortParams('tasksTable');
  const { data: taskStatuses } = useGetTaskStatusesQuery();
  const filters: FilterValues = { ...filterValues };
  const queryParameters: Record<string, string> = {
    ...sortParamsValues,
    ...filters,
    page: selectedPage.toString(),
  };
  if (prefilter === 'open' && taskStatuses) {
    queryParameters['status_ids[]'] = taskStatuses[0].id;
  }
  if (prefilter === 'unassigned') {
    queryParameters.unassigned = 'true';
  }
  const { data: tasksData } = useGetTasksQuery(queryParameters);

  useEffect(() => {
    setSelectedPage(1);
  }, [prefilter]);

  const isTabletOrDesktop = useMediaQuery({ query: `(min-width: ${md})` });

  return (
    <>
      {isTabletOrDesktop ? (
        <TasksListDesktop tasks={tasksData?.tasks || []} />
      ) : <TasksListMobile tasks={tasksData?.tasks || []} />}
      {tasksData?.metadata && (
        <PaginationControls
          className="col-span-full"
          selectedPage={selectedPage}
          setSelectedPage={setSelectedPage}
          paginationMetadata={tasksData.metadata}
        />
      )}
    </>
  );
};

export default TasksList;
