import * as React from 'react';
import { useGetVenueDetailsQuery } from '../../../../../api/appApi';
import useMessages from '../../../../../hooks/useMessages';
import DetailsItemsList from './DetailsItemsList';
import DesktopInfoCard from '../../../../../components/InfoCard/DesktopInfoCard/DesktopInfoCard';

const MeterListDesktopInfoCard = ({ id }: { id: string }) => {
  const { data } = useGetVenueDetailsQuery(id);
  const getMessage = useMessages();

  if (!data) return null;

  return (
    <DesktopInfoCard title={getMessage('meters.metersList.info')} ariaLabel="Informacje dotyczące lokalu">
      <DetailsItemsList data={data} />
    </DesktopInfoCard>
  );
};

export default MeterListDesktopInfoCard;
