import {
  ApiTicketResponse,
  ApiTicketsResponse,
  ApiTicketComment,
  ApiAttachment,
  ApiTicketsListItem,
  EmployeeApiTicketsListItem,
  TenantTicketsListItemData,
  EmployeeTicketsListItemData,
  TicketComment,
  TicketAttachment,
  Tickets,
  EmployeeApiSingleTicket,
  EmployeeShowTicketData,
  TenantShowTicketData,
  ApiTicketStatuses,
  TicketStatuses, FullTicketStatus,
} from './ticketsTypes';
import { TicketInputs } from '../../../pages/TicketsPage/TicketForm/TicketForm';
import { CommentFormData } from '../../../components/CommentsList/CommentForm/CommentForm';
import { translateTask } from '../tasks/tasksTranslator';

const translateAttachments = (attachments: ApiAttachment[]): TicketAttachment[] => attachments.map((attachments) => ({
  contentType: attachments.content_type,
  filename: attachments.filename,
  path: attachments.path,
  description: attachments.description,
}));

const translateComments = (comments: ApiTicketComment[]): TicketComment[] => comments?.map((comment) => ({
  author: comment.author,
  content: comment.content,
  createdAt: comment.created_at,
  attachments: translateAttachments(comment.attachments),
}));

export const translateSingleTicket = ({ ticket }: ApiTicketResponse): TenantShowTicketData | EmployeeShowTicketData => ({
  id: ticket.id,
  ticketNumber: ticket.ticket_number,
  title: ticket.title,
  status: { id: ticket.status.id, name: ticket.status.external_name },
  description: ticket.description,
  category: { id: ticket.category?.id, name: ticket.category?.name },
  reporter: ticket.reporter,
  reportedAt: ticket.reported_at,
  venueAddress: ticket.venue_address,
  publicComments: translateComments(ticket.public_comments),
  attachments: translateAttachments(ticket.attachments),
  realizer: (ticket as EmployeeApiSingleTicket).realizer,
  privateComments: translateComments((ticket as EmployeeApiSingleTicket).private_comments),
  homeownerAssociationName: (ticket as EmployeeApiSingleTicket).homeowner_association_name,
  homeownerAssociationId: (ticket as EmployeeApiSingleTicket).homeowner_association_id,
  tenantId: (ticket as EmployeeApiSingleTicket).tenant_id,
  tenantName: (ticket as EmployeeApiSingleTicket).tenant_name,
  venueId: (ticket as EmployeeApiSingleTicket).venue_id,
  tasks: ((ticket as EmployeeApiSingleTicket).tasks || []).map((apiTask) => translateTask(apiTask)),
});

type ApiTicket = ApiTicketsListItem | EmployeeApiTicketsListItem;
type TicketItem = TenantTicketsListItemData | EmployeeTicketsListItemData;

export const translateTicket = (apiTicket: ApiTicket): TicketItem => ({
  id: apiTicket.id,
  ticketNumber: apiTicket.ticket_number,
  title: apiTicket.title,
  status: apiTicket.status as string,
  category: apiTicket.category,
  reporter: apiTicket.reporter,
  reportedAt: apiTicket.reported_at,

  lastCommentAt: (apiTicket as EmployeeApiTicketsListItem).last_comment_at,
  homeownerAssociationCode: (apiTicket as EmployeeApiTicketsListItem).homeowner_association_code,
  venueAddress: (apiTicket as EmployeeApiTicketsListItem).venue_address,
  venueCode: (apiTicket as EmployeeApiTicketsListItem).venue_code,
  homeownerAssociationName: (apiTicket as EmployeeApiTicketsListItem).homeowner_association_name,
  reporterCode: (apiTicket as EmployeeApiTicketsListItem).reporter_external_id,
  realizer: (apiTicket as EmployeeApiTicketsListItem).realizer,
  realizerCode: (apiTicket as EmployeeApiTicketsListItem).realizer_external_id,
});

export const translateApiToTickets = ({ tickets, metadata }: ApiTicketsResponse) : Tickets => ({
  metadata,
  tickets: tickets.map((apiTicket) => translateTicket(apiTicket)),
});

export const translateTicketsToFormData = (data: TicketInputs): FormData => {
  const form = new FormData();
  form.append('ticket[title]', data.title);
  form.append('ticket[category_id]', data.category_id);
  form.append('ticket[description]', data.description);
  if (data.status_id) form.append('ticket[status_id]', data.status_id);
  if (data.realizer_id) form.append('ticket[realizer_id]', data.realizer_id);
  if (data.venue_id) form.append('ticket[venue_id]', data.venue_id);
  if (data.homeowner_association_id) form.append('ticket[homeowner_association_id]', data.homeowner_association_id);
  if (data.tenant_id) form.append('ticket[tenant_id]', data.tenant_id);
  data.attachments.forEach((attachment, index) => {
    if (attachment.file?.length) {
      form.append(`ticket[attachments][${index}][file]`, attachment.file[0]);
      form.append(`ticket[attachments][${index}][description]`, attachment.description);
    }
  });
  return form;
};

export const translateTicketCommentToFormData = (data: CommentFormData): FormData => {
  const form = new FormData();
  form.append('comment[content]', data.content);
  form.append('comment[public]', data.publicComment.toString());
  data.attachments.forEach((attachment, index) => {
    if (attachment.file?.length) {
      form.append(`comment[attachments][${index}][file]`, attachment.file[0]);
      form.append(`comment[attachments][${index}][description]`, attachment.description);
    }
  });
  return form;
};

export const translateTicketStatus = ({ statuses }: ApiTicketStatuses): TicketStatuses => ({
  statuses: statuses.map((status) => ({
    id: status.id,
    name: status.external_name,
  })),
});

export const translateFullTicketStatus = ({ statuses }: ApiTicketStatuses): FullTicketStatus[] => statuses.map((status) => ({
  id: status.id,
  objectType: 'ticket',
  name: status.name,
  active: status.active,
  internalName: status.internal_name,
  externalName: status.external_name,
  description: status.description,
}));
