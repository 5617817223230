import * as React from 'react';
import { useMemo } from 'react';
import useMessages from '../../../hooks/useMessages';
import { FilterConfig } from '../../../components/FilterInput/FilterInput';
import Filters from '../../../components/Filters/Filters';

const TenantDocumentsFilters = () => {
  const getMessage = useMessages();

  const filterFields: FilterConfig[] = useMemo(() => [
    {
      type: 'text',
      id: 'number_title_search',
      label: getMessage('documents.filter.titleOrNumber'),
      ariaLabel: 'Szukaj po numerze lub tytule dokumentu',
    },
    {
      type: 'dateRange',
      id: 'from_created_at',
      toDateId: 'to_created_at',
      label: getMessage('documents.column.date'),
    },
    {
      type: 'text',
      id: 'creator_search',
      label: getMessage('documents.column.creator'),
      ariaLabel: 'Szukaj po imieniu i nazwisku autora',
    },
  ], []);

  return (
    <Filters
      filterId="documentsList"
      filterFields={filterFields}
    />
  );
};

export default TenantDocumentsFilters;
