import * as React from 'react';
import MeterTitleLocationContainer from '../../../MeterTitleLocationContainer/MeterTitleLocationContainer';

interface Props {
  meterType: string,
  meterLocation?: string | null,
  meterCode?: string,
}

const HistoryCardHeader = ({ meterType, meterLocation, meterCode }: Props) => (
  <div className="flex flex-wrap">
    <MeterTitleLocationContainer
      meter={{
        meterType, meterCode, meterLocation,
      }}
    />
  </div>
);

export default HistoryCardHeader;
